import { useFormContext, Controller } from "react-hook-form";
import { GithubPicker, RGBColor } from "react-color";

interface Props {
  color: string | undefined; // RGBColor
}

const ColorField = ({ color }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="color"
        className="block text-sm font-medium leading-5 text-gray-900"
      >
        Farbe
      </label>
      <div className="mt-1">
        <Controller
          control={control}
          name="color"
          rules={{ required: false }}
          defaultValue={color}
          render={({ field: { onChange, value } }) => (
            <GithubPicker
              color={value || "#fff"}
              onChangeComplete={(color) => {
                onChange(color.hex);
              }}
              triangle="hide"
            />
          )}
        />
      </div>
    </>
  );
};

export default ColorField;
