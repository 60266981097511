import { forwardRef } from "react";
import { useQuery } from "@apollo/client";
import { UNIT_MANY } from "../../../schema/unit";
import { Unit } from "../../../types";

import { Loading, Error } from "../../ui/Alerts";

const UnitSelect = forwardRef((props: any, ref: any) => {
  const { loading, error, data } = useQuery(UNIT_MANY);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <select
      {...props}
      ref={ref}
      autoComplete="type"
      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
    >
      <option value=""></option>
      {data.unitMany.map((unit: Unit) => (
        <option key={unit._id} value={unit._id}>
          {unit.name}
        </option>
      ))}
    </select>
  );
});

export default UnitSelect;
