import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Transition } from "@headlessui/react";
import {
  useParams,
  useHistory,
  match,
  RouteComponentProps,
} from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";

import {
  EMPLOYEE_BY_ID,
  EMPLOYEE_UPDATE_BY_ID,
  EMPLOYEE_REMOVE_BY_ID,
  EMPLOYEE_MANY,
} from "../../schema/employee";
import { SKILL_MANY, FILTER_SKILL_MANY } from "../../schema/skill";
import { SKILL_SCALE_MANY } from "../../schema/skillScale";
import { Competence, SkillScale, Review } from "../../types";

import { useDrop } from "react-dnd";
import { ItemTypes } from "../../config/ItemTypes";

import SliderOverContext from "../../context/SliderOverContext";
import SlideOverList from "../skills/SliderOverList/SlideOverList";
import useToggle from "../../hooks/useToogle";

import { Loading, Error } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import PageHeader from "../ui/Headings/PageHeader";
import { Breadcrumb } from "../ui/navigation/Breadcrumb";
import {
  HorizontalNavigation,
  HorizontalNavigationOption,
} from "../ui/navigation/HorizontalNavigation";

import Form, { FormData } from "./Form";
import { H3 } from "../ui/Typo/Typo";
import CompetenceFields from "../competences/Fields";
import Stats from "../competences/Stats";

import ProfileChart from "../charts/ProfileChart";
import { usePermissions } from "hooks/usePermissions";
import { PermissionRequired } from "components/ui/permissions";
import { PermissionFlag } from "types/permission";

interface ParamTypes {
  id: string;
}

interface Props {
  required: string;
  match?: match<ParamTypes>;
}

// type RouteProps = RouteComponentProps<{ id: string }>

const Profile = ({}: Props & RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { formatMessage: f } = useIntl();

  const { hasPermissionScope } = usePermissions();
  const [open, toggle] = useToggle();
  const [openSkills, toggleSkills] = useToggle();
  const [isOpen, setIsOpen] = useState(false);
  const [tabs, setTabs] = useState<HorizontalNavigationOption[]>([]);
  const [selectedTab, setSelectedTab] = useState<HorizontalNavigationOption>();

  const { loading, error, data } = useQuery(EMPLOYEE_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateEmployee] = useMutation(EMPLOYEE_UPDATE_BY_ID, {
    refetchQueries: [{ query: EMPLOYEE_MANY }],
  });
  const [updateEmployeeCompetences] = useMutation(EMPLOYEE_UPDATE_BY_ID, {
    refetchQueries: [
      {
        query: EMPLOYEE_BY_ID,
        variables: {
          _id: id,
        },
      },
      {
        query: FILTER_SKILL_MANY,
      },
    ],
    update(cache, { data: { employeeUpdateById } }) {
      try {
        cache.writeQuery({
          query: EMPLOYEE_BY_ID,
          variables: { _id: id },
          data: {
            employeeById: employeeUpdateById,
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
  });
  const [deleteEmployee] = useMutation(EMPLOYEE_REMOVE_BY_ID, {
    refetchQueries: [{ query: EMPLOYEE_MANY }],
  });
  const { data: skillData } = useQuery(SKILL_MANY);
  const { data: skillScaleData } = useQuery(SKILL_SCALE_MANY);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.SKILL,
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return;
      }
      console.log(`Dropped ${(item as any).skill.name}`);
      const skill = (item as any).skill;

      const newCompetence = {
        skillId: skill._id,
        value: 50,
      };

      const competences = data?.employeeById.competences.map(
        (c: Competence) => ({
          skillId: c.skillId,
          value: c.value,
        })
      );

      updateEmployeeCompetences({
        variables: {
          _id: id,
          competences: [...competences, newCompetence],
        },
      });

      return { name: "Profile" };
    },
    // drop: () => (
    //   { name: 'Profile' }
    // ),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    if (!id) {
      history.push("/");
      return;
    }
  }, [id, history]);

  useEffect(() => {
    const _tabs: HorizontalNavigationOption[] = [
      { value: "employee", label: "Mitarbeiter" },
    ];
    data?.employeeById?.reviews.forEach((review: Review) => {
      _tabs.push({ value: review._id, label: review.name });
    });
    setTabs(_tabs);
    setSelectedTab(_tabs[0]);
  }, [data]);

  const onUpdateCompetences = (competences: Competence[]) => {
    updateEmployee({
      variables: {
        _id: id,
        competences: competences.map((c) => ({
          skillId: c.skillId,
          value: +c.value,
        })),
      },
    });
  };

  const onDeleteCompetence = (competence: Competence) => {
    const competences = data?.employeeById.competences
      .map((c: any) => ({
        skillId: c.skillId,
        value: +c.value,
      }))
      .filter((item: any) => {
        return item.skillId !== competence.skillId;
      });

    updateEmployeeCompetences({
      variables: {
        _id: id,
        competences: competences,
      },
    });
  };

  const onUpdate = (formData: FormData) => {
    updateEmployee({
      variables: {
        _id: id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        gender: formData.gender,
        email: formData.email,
        hireDate: formData.hireDate,
        jobId: formData.jobId,
        salary: +formData.salary,
        unitId: formData.unitId,
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteEmployee({ variables: { _id: id } });
    history.goBack();
  };

  const renderStats = (value: String) => {
    switch (value) {
      case "employee":
        return (
          <Stats
            humatics={data.employeeById?.humatics}
            actualRevenue={data.employeeById?.annualizedRevenue}
            targetRevenue={data.employeeById?.targetRevenue}
            competenceCoverage={data.employeeById?.competenceCoverage}
          />
        );
      default:
        // `value` is id of review
        const review = data.employeeById?.reviews.find(
          (r: Review) => r._id === value
        );

        return (
          <Stats
            humatics={review?.humatics}
            actualRevenue={data.employeeById?.annualizedRevenue}
            targetRevenue={data.employeeById?.targetRevenue}
            competenceCoverage={review?.competenceCoverage}
          />
        );
    }
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const isActive = canDrop && isOver;

  return (
    <>
      <PermissionRequired
        module="employees.competency_profile"
        flag={PermissionFlag.WRITE}
      >
        <SliderOverContext.Provider value={{ open: open, toggle }}>
          <SlideOver title={f({ id: "employee.actions.edit" })}>
            <Form onSubmit={onUpdate} data={data.employeeById} />
          </SlideOver>
        </SliderOverContext.Provider>

        <SliderOverContext.Provider
          value={{ open: openSkills, toggle: toggleSkills }}
        >
          <SlideOverList
            data={skillData?.skillMany}
            employee={data.employeeById}
            competences={data.employeeById?.competences}
            onSelect={(competences: Competence[]) =>
              onUpdateCompetences(competences)
            }
            defaultCompetenceValue={50}
          >
            {f({ id: "profile.actions.drop" })}
          </SlideOverList>
        </SliderOverContext.Provider>
      </PermissionRequired>

      <PageHeader
        title={`${data.employeeById?.firstName} ${data.employeeById?.lastName}`}
        onUpdate={
          hasPermissionScope("employees.profile", PermissionFlag.WRITE)
            ? toggle
            : undefined
        }
        onDelete={
          hasPermissionScope("employees.profile", PermissionFlag.WRITE)
            ? onDelete
            : undefined
        }
      >
        <Breadcrumb>
          <Breadcrumb.Item url="/">{f({ id: "nav.home" })}</Breadcrumb.Item>
          <Breadcrumb.Item url="/employees">
            {f({ id: "employees.name" })}
          </Breadcrumb.Item>
          <Breadcrumb.Item url={`/employees/${data.employeeById?._id}/details`}>
            {`${data.employeeById?.firstName} ${data.employeeById?.lastName}`}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{f({ id: "profile.name" })}</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>

      {/* Stats */}
      <div className="px-6">
        <HorizontalNavigation
          options={tabs}
          selected={selectedTab}
          onSelect={setSelectedTab}
        />
      </div>
      {selectedTab && renderStats(selectedTab.value)}

      <hr className="my-6 border-2 rounded-full border-gray-200" />

      <div className="my-6">
        <span className="relative z-0 inline-flex shadow-sm">
          <PermissionRequired
            module="employees.competency_profile"
            flag={PermissionFlag.WRITE}
          >
            <button
              type="button"
              className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
              onClick={toggleSkills}
            >
              {f({ id: "profile.competences.add" })}
            </button>
          </PermissionRequired>
          <button
            type="button"
            className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
            onClick={() => setIsOpen(!isOpen)}
          >
            {f({ id: "views.table" })}
          </button>
        </span>
      </div>

      <Transition show={isOpen}>
        <CompetenceFields
          data={data.employeeById?.competences}
          onSubmit={(formData) => onUpdateCompetences(formData.competences)}
        />
      </Transition>

      <div
        ref={drop}
        className={
          isActive ? "p-4 rounded border-2 border-dashed border-blue-500" : ""
        }
      >
        <div className="my-4 text-xs uppercase font-light text-gray-500">
          <H3>
            {isActive
              ? "Loslassen zum hinzufügen"
              : f({ id: "profile.actions.drag" })}
          </H3>
        </div>

        <ProfileChart
          width={900}
          height={400}
          data={data.employeeById?.competences}
          markers={skillScaleData?.skillScaleMany.map((s: SkillScale) => ({
            label: s.name,
            value: s.value * 100,
          }))}
          revenue={data.employeeById?.annualizedRevenue}
          onDelete={onDeleteCompetence}
          onChange={(competences) => {
            // console.log(
            //   "onChange",
            //   competences.map((d) => d.value)
            // );

            updateEmployeeCompetences({
              variables: {
                _id: id,
                competences: competences.map((c: Competence) => ({
                  skillId: c.skillId,
                  value: c.value,
                })),
              },
            });
          }}
        />
      </div>
    </>
  );
};

// export default withRouter(Profile);
export default Profile;
