import { useForm } from "react-hook-form";

import type { Role } from "types/role";

interface Props {
  id: string;
  role: Role,
  onSubmit: (formData: any) => void;
};

export const DeleteRoleForm = ({
  id,
  role,
  onSubmit = () => { },
}: Props) => {
  const {
    handleSubmit,
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={id}>
      <div className="flex-1 flex flex-col justify-between">
        <div className="px-4 divide-y divide-gray-200 sm:px-6">
          <div className="space-y-6 pt-6 pb-5">
            Sind Sie sicher, dass sie die Rolle "<b>{role.name}</b>" löschen
            möchten?<br />
            Dieser Vorgang lässt sich nicht rückgängig machen.
          </div>
        </div>
      </div>
    </form>
  );
};
