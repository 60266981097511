import { useState, ReactElement } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { pathOr } from "ramda";

import {
  HorizontalNavigation,
  HorizontalNavigationOption,
} from "../ui/navigation/HorizontalNavigation";
import { LicenseConfigurator } from "./LicenseConfigurator";
import { LicenseList } from "./LicenseList";
import { LicenseCancellationForm } from './LicenseCancellationForm';
import { LicenseReactivationForm } from './LicenseReactivationForm';
import SlideOver from "components/ui/SlideOver";
import SliderOverContext from "context/SliderOverContext";
import useToggle from "hooks/useToogle";
import { License } from "types/license";
import { ACTIVE_EMPLOYEE_COUNT } from "schema/employee";
import { PRODUCT_MANY } from "schema/product";
import {
  LICENSES_MANY,
  LICENSE_CREATE_MANY,
  LICENSE_CANCEL,
  LICENSE_REACTIVATE,
} from "schema/license";
import { IProductData } from './LicenseConfigurator';

const tabs: HorizontalNavigationOption[] = [
  { value: "licenses", label: "Lizenzen" },
  { value: "configurator", label: "Lizenzkonfigurator" },
];

enum SlideOpen {
  NONE = "none",
  CANCELLATION_FORM = "cancellationForm",
  REACTIVATION_FORM = "reactivationForm",
};

interface IState {
  tab: HorizontalNavigationOption,
  selectedLicense: License | null,
  slideOpen: SlideOpen,
};

export const LicenseProductTab = () => {
  const [ state, setState ] = useState<IState>({
    tab: tabs[0],
    selectedLicense: null,
    slideOpen: SlideOpen.NONE,
  });
  const [ open, toggle ] = useToggle();
  const { data: productData } = useQuery(PRODUCT_MANY);
  const { data: employeeData } = useQuery(ACTIVE_EMPLOYEE_COUNT, { fetchPolicy: 'no-cache' });
  const { data: licenseData } = useQuery(LICENSES_MANY, { fetchPolicy: 'no-cache' });
  const products = pathOr([], ["productMany"], productData);
  const licenses = pathOr([], ["licenseMany"], licenseData);
  const employeeCount = pathOr(0, ["activeEmployeeCount"], employeeData);
  const refetchLicenses = { refetchQueries: [{ query: LICENSES_MANY }] };
  const [createLicenses] = useMutation(LICENSE_CREATE_MANY, refetchLicenses);
  const [cancelLicense] = useMutation(LICENSE_CANCEL, refetchLicenses);
  const [reactivateLicense] = useMutation(LICENSE_REACTIVATE, refetchLicenses);

  const openCancelLicense = (license: License) => {
    setState({ ...state, selectedLicense: license, slideOpen: SlideOpen.CANCELLATION_FORM });
    toggle();
  };

  const handleCancelLicense = (license: License) => {
    cancelLicense({
      variables: { _id: license._id },
    });
    toggle();
  };

  const openReactivateLicense = (license: License) => {
    setState({ ...state, selectedLicense: license, slideOpen: SlideOpen.REACTIVATION_FORM });
    toggle();
  };

  const handleReactivateLicense = (license: License) => {
    reactivateLicense({
      variables: { _id: license._id },
    });
    toggle();
  };

  const handleCreateLicenses = (data: IProductData[]) => {
    createLicenses({
      variables: {
        licenses: data.map((d: IProductData) => ({
          _id: d.licenseId,
          productId: d._id,
          seats: d.seats,
          type: d.type,
        })),
      }
    });
    setState({ ...state, tab: tabs[0] });
  };

  let tab: ReactElement;
  switch (state.tab.value) {
    case "licenses":
      tab = <LicenseList
        licenses={licenses}
        onCancelLicense={openCancelLicense}
        onReactivateLicense={openReactivateLicense}
      />;
      break;
    case "configurator":
      tab = <LicenseConfigurator
        products={products}
        licenses={licenses}
        employeeCount={employeeCount}
        onSubmit={handleCreateLicenses}
      />;
      break;
    default:
      tab = <div></div>;
  }

  return (
    <SliderOverContext.Provider value={{ open: open, toggle }}>
      {
        state.selectedLicense !== null && state.slideOpen === SlideOpen.CANCELLATION_FORM
        ? <SlideOver
            title="Lizenz deaktivieren"
            description="Hier können Sie eine Lizenz dauerhaft ˚deaktivieren."
            formId="license-cancellation-form"
          >
            <LicenseCancellationForm
              license={state.selectedLicense}
              onSubmit={handleCancelLicense}
            />
          </SlideOver>
          : null
      }

      {
        state.selectedLicense !== null && state.slideOpen === SlideOpen.REACTIVATION_FORM
          ? <SlideOver
            title="Lizenz reaktivieren"
            description="Hier können Sie eine Lizenz reaktivieren."
            formId="license-reactivation-form"
          >
            <LicenseReactivationForm
              license={state.selectedLicense}
              onSubmit={handleReactivateLicense}
            />
          </SlideOver>
          : null
      }

      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <HorizontalNavigation
          options={tabs}
          selected={state.tab}
          onSelect={tab => setState({ ...state, tab })}
        />
        <section aria-labelledby="payment_details_heading">
          <div className="shadow sm:rounded-md sm:overflow-hidden p-3">
            { tab }
          </div>
        </section>
      </div>
    </SliderOverContext.Provider>
  );
};
