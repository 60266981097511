import { useFormContext } from "react-hook-form";

const CostsField = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="costs"
        className="block text-sm font-medium text-gray-700"
      >
        Kosten
      </label>
      <input
        type="number"
        id="costs"
        {...register("costs")}
        className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      />
    </>
  );
};

export default CostsField;
