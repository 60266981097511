import { ArrowNarrowDownIcon, ArrowNarrowUpIcon } from "@heroicons/react/solid";

import { numberFormater, percentFormater } from "../../../config/i18n";
import { Humatics } from "../../../types";
import Badge from "./Badge";
import { PermissionRequired } from "components/ui/permissions";

interface Props {
  showMetrics: String[];
  humatics: Humatics;
  jobHumatics?: Humatics;
  competenceCoverage?: number;
}

const Stats = ({
  showMetrics,
  humatics,
  jobHumatics,
  competenceCoverage,
}: Props) => {
  const colorName: any = (metric: string) => {
    if (!humatics || !jobHumatics) {
      return;
    }
    const value1 = (humatics as any)[metric];
    const value2 = (jobHumatics as any)[metric];

    const diff = Math.abs(1 - value1 / value2);
    if (diff > 0 && diff <= 0.2) {
      return "green";
    } else if (diff >= 0.2 && diff < 0.3) {
      return "yellow";
    } else if (diff >= 0.3) {
      return "red";
    } else {
      return "gray";
    }
  };

  const tArrow: any = () => {
    if (!humatics || !jobHumatics) {
      return;
    }
    const diff = humatics && jobHumatics ? 1 - humatics.t / jobHumatics.t : 0;
    if (diff < 0) {
      return <ArrowNarrowUpIcon className="w-4 h-4 text-current" />;
    } else if (diff > 0) {
      return <ArrowNarrowDownIcon className="w-4 h-4 text-current" />;
    } else {
      return null;
    }
  };

  const h = humatics ? numberFormater.format(humatics.h) : null;
  const employeesH = humatics
    ? numberFormater.format(humatics.employeesH)
    : null;
  const t = humatics ? numberFormater.format(humatics.t) : null;
  const ef = humatics ? numberFormater.format(humatics.ef) : null;
  const phi = humatics ? numberFormater.format(humatics.phi) : null;
  const r = humatics ? numberFormater.format(Math.floor(humatics.r)) : null;
  const s = humatics ? numberFormater.format(humatics.s) : null;
  const mu = humatics ? numberFormater.format(humatics.mu) : null;
  const c =
    competenceCoverage != null
      ? percentFormater.format(competenceCoverage)
      : null;

  return (
    <PermissionRequired module="organization.organigram.kpis">
      <div className="grid grid-cols-2 gap-1">
        {showMetrics.includes("h") && (
          <Badge colorName={colorName("h")}>h {h}</Badge>
        )}
        {showMetrics.includes("employeesH") && (
          <Badge colorName={colorName("h")}>h {employeesH}</Badge>
        )}
        <Badge colorName={colorName("t")}>
          t {t} {tArrow()}
        </Badge>
        {showMetrics.includes("ef") && (
          <Badge colorName={colorName("ef")}>ef {ef}</Badge>
        )}
        {showMetrics.includes("phi") && (
          <Badge colorName={colorName("phi")}>if {phi}</Badge>
        )}
        {showMetrics.includes("r") && (
          <Badge colorName={colorName("r")}>r {r}</Badge>
        )}
        {showMetrics.includes("s") && (
          <Badge colorName={colorName("s")}>s {s}</Badge>
        )}
        {showMetrics.includes("mu") && (
          <Badge colorName={colorName("mu")}>sp {mu}</Badge>
        )}
        {showMetrics.includes("c") && <Badge colorName="gray">{c}</Badge>}
      </div>
    </PermissionRequired>
  );
};

export default Stats;
