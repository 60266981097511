import { gql } from "@apollo/client";

export const INVITATION_MANY = gql`
  query {
    invitationMany {
      signupToken
      employeeId
      employee {
        firstName
        lastName
      }
      organizationId
      role {
        _id
        name
      }
      status
      createdAt
      _id
    }
  }
`;

export const INVITATION_BY_ID = gql`
  query($_id: ID!) {
    invitationById(_id: $_id) {
      signupToken
      employeeId
      employee {
        firstName
        lastName
      }
      organizationId
      role {
        _id
        name
      }
      status
      _id
    }
  }
`;

export const INVITATION_CREATE_ONE = gql`
  mutation($employeeId: ID!, $organizationId: ID!, $role: String) {
    invitationCreateOne(
      input: {
        employeeId: $employeeId
        organizationId: $organizationId
        role: $role
      }
    ) {
      _id
      createdAt
      status
    }
  }
`;

export const INVITATION_UPDATE_BY_ID = gql`
  mutation($employeeId: ID!, $organizationId: ID!, $role: String) {
    invitationUpdateById(
      input: {
        employeeId: $employeeId
        organizationId: $organizationId
        role: $role
      }
      _id: $_id
    ) {
      _id
    }
  }
`;

export const INVITATION_REMOVE_BY_ID = gql`
  mutation($_id: ID!) {
    invitationRemoveById(_id: $_id) {
      employeeId
    }
  }
`;
