import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { PencilIcon } from "@heroicons/react/solid";
import {
  SKILL_SCALE_MANY,
  SKILL_SCALE_UPDATE_BY_ID,
  SKILL_SCALE_REMOVE_BY_ID,
} from "../../schema/skillScale";
import { SkillScale } from "../../types";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import SlideOver from "../ui/SlideOver";
import Form, { FormData } from "./Form";
import { PermissionRequired } from "components/ui/permissions";
import { PermissionFlag } from "types/permission";

interface Props {
  skillScale: SkillScale;
}

const Item = ({ skillScale }: Props) => {
  const [open, toggle] = useToggle();

  const [updateSkillScale] = useMutation(SKILL_SCALE_UPDATE_BY_ID, {
    refetchQueries: [{ query: SKILL_SCALE_MANY }],
  });
  const [deleteSkillScale] = useMutation(SKILL_SCALE_REMOVE_BY_ID, {
    refetchQueries: [{ query: SKILL_SCALE_MANY }],
  });

  const onUpdate = (formData: FormData) => {
    updateSkillScale({
      variables: {
        _id: skillScale._id,
        name: formData.name,
        description: formData.description,
        level: +formData.level,
        value: +formData.value,
      },
    });
    toggle();
  };

  const onDelete = () => {
    deleteSkillScale({ variables: { _id: skillScale._id } });
  };

  return (
    <>
      <PermissionRequired module="competencies.competency_scaling" flag={PermissionFlag.WRITE}>
        <SliderOverContext.Provider value={{ open: open, toggle }}>
          <SlideOver title="Kompetenskalierung bearbeiten">
            <Form onSubmit={onUpdate} data={skillScale} />
          </SlideOver>
        </SliderOverContext.Provider>
      </PermissionRequired>
      <tr>
        <td className="px-6 py-4">
          <div className="flex items-center">
            <div className="text-gray-900 text-sm leading-5 font-medium">
              {skillScale.name}
              <p className="text-sm text-gray-500 overflow-ellipsis">
                {skillScale.description}
              </p>
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            {skillScale.level}
          </span>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            {skillScale.value}
          </span>
        </td>
        
        <PermissionRequired module="competencies.competency_scaling" flag={PermissionFlag.WRITE}>
          <td className="px-6 py-4 whitespace-nowrap">
            <span className="relative z-0 inline-flex shadow-sm">
              <button
                className="inline-flex items-center h-7 pl-2.5 pr-2 rounded-l-md text-xs text-gray-700 border border-r-0 border-gray-200 leading-none py-0"
                onClick={toggle}
              >
                <PencilIcon className="mr-1 h-4 w-4 text-gray-400 group-hover:text-gray-500 " />
                Bearbeiten
              </button>
              <button
                className="inline-flex items-center h-7 pl-2.5 pr-2 rounded-r-md text-xs text-white bg-red-600 hover:bg-red-500 border border-l-0 border-red-700 leading-none py-0"
                onClick={onDelete}
              >
                Löschen
              </button>
            </span>
          </td>
        </PermissionRequired>
      </tr>
    </>
  );
};

export default Item;
