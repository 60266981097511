import { useApolloClient } from "@apollo/client";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { isLoggedInVar, roleVar } from "../../../cache";

export type FormData = {
  username: string;
  password: string;
  signupToken: string;
};

interface ParamTypes {
  signupToken: string;
}

const Signup = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>();

  const { signupToken } = useParams<ParamTypes>();
  const history = useHistory();
  const client = useApolloClient();

  const onSubmit = async (data: FormData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_URI}/signup`,
        data
      );
      // const decoded = jwt.decode(response?.data?.token);
      // console.log("decoded", decoded);

      const { token, role } = response?.data;
      localStorage.setItem("token", token as string);
      localStorage.setItem("role", role as string);
      isLoggedInVar(true);
      roleVar(role);
      client.resetStore();

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      history.push("/");
    } catch (e) {
      setError("username", {
        type: "manual",
        message: e.message,
      });
      console.error(e);
    }
  };

  return (
    <div className="absolute h-100 left-0 top-0 min-w-full min-h-full flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-auto w-1/2" src="/logo.svg" alt="8Skills" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Anmelden
          </h2>

          <p className="mt-2 text-center text-sm text-gray-600">
            oder {}
            <Link
              to="/users/login"
              className="font-medium text-orange-600 hover:text-orange-500"
            >
              Einloggen
            </Link>
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">
                Benutzername
              </label>
              <input
                type="text"
                id="username"
                autoComplete="username"
                placeholder="Benutzername"
                {...register("username", { required: "Benutzername fehlt" })}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Passwort
              </label>
              <input
                type="password"
                id="password"
                autoComplete="current-password"
                placeholder="Passwort"
                {...register("password", { required: "Passwort fehlt" })}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="signupToken" className="sr-only">
                Anmeldetoken
              </label>
              <input
                type="text"
                id="signupToken"
                defaultValue={signupToken}
                placeholder="Anmeldetoken"
                {...register("signupToken", { required: "Anmeldetoken fehlt" })}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm"
              />
            </div>
          </div>

          <div className="flex flex-col space-y-1">
            {errors.username && (
              <span className="text-sm font-light text-red-500">
                {errors.username.message}
              </span>
            )}
            {errors.password && (
              <span className="text-sm font-light text-red-500">
                {errors.password.message}
              </span>
            )}
            {errors.signupToken && (
              <span className="text-sm font-light text-red-500">
                {errors.signupToken.message}
              </span>
            )}
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded"
              />
              <label
                htmlFor="remember_me"
                className="ml-2 block text-sm text-gray-900"
              >
                Logindaten merken
              </label>
            </div>

            <div className="text-sm">
              <Link
                to="/users/forgotPassword"
                className="font-medium text-orange-600 hover:text-orange-500"
              >
                Passwort vergessen?
              </Link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-coral-500 hover:bg-coral-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg
                  className="h-5 w-5 text-orange-400 group-hover:text-orange-300"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              Anmelden
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
