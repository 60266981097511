import { useContext } from "react";
import { useSpring, animated, config } from "react-spring";
import { useIntl } from "react-intl";

import { useQuery } from "@apollo/client";
import { APPLICANT_MANY } from "../../../schema/applicant";
import { EMPLOYEE_MANY } from "../../../schema/employee";

import { Applicant, Employee } from "../../../types";
import FilterContext from "../../../context/FilterContext";
import { Loading, Error } from "../../ui/Alerts";
import ApplicantItem from "./ApplicantItem";
import EmployeeItem from "./EmployeeItem";

import { Divider } from "../../ui/layout/Divider";

const Sidebar = () => {
  const { formatMessage: f } = useIntl();

  const { open } = useContext(FilterContext);

  const { loading, error, data } = useQuery(APPLICANT_MANY);
  const {
    loading: loadingEmployee,
    error: errorEmployee,
    data: dataEmployee,
  } = useQuery(EMPLOYEE_MANY, {
    variables: { isApplicant: true },
  });

  const slideInStyles = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, width: 0 },
    to: {
      opacity: open ? 1 : 0,
      width: open ? 400 : 0,
    },
  });

  return (
    <animated.div style={{ ...slideInStyles, overflow: "hidden" }}>
      {/* <DevTool control={control} /> */}

      {loading && <Loading />}
      {error && <Error error={error} />}

      <div className="bg-gray-100 dark:bg-gray-900 dark:text-white text-gray-600 px-4 py-2 rounded-md">
        <div className="my-5 flex justify-between items-center">
          <div className="text-xs text-gray-400 tracking-wider uppercase">
            Simulation
          </div>
        </div>

        <div className="my-5 bg-white p-3 w-full flex flex-col rounded-md dark:bg-gray-800 shadow">
          <div className="space-y-1 py-2">
            <span className="block text-sm font-medium leading-5 text-gray-900">
              {dataEmployee?.employeeMany.length} {f({ id: "employees.name" })}
            </span>
            <ul className="divide-y divide-gray-200 max-h-64">
              {dataEmployee?.employeeMany.map((employee: Employee) => (
                <EmployeeItem key={employee._id} employee={employee} />
              ))}
            </ul>
          </div>
        </div>

        <Divider />

        <div className="my-5 bg-white p-3 w-full flex flex-col rounded-md dark:bg-gray-800 shadow">
          <div className="space-y-1 py-2">
            <span className="block text-sm font-medium leading-5 text-gray-900">
              {data?.applicantMany.length} {f({ id: "applicants.name" })}
            </span>
            <ul className="divide-y divide-gray-200">
              {data?.applicantMany.map((applicant: Applicant) => (
                <ApplicantItem key={applicant._id} applicant={applicant} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

export default Sidebar;
