import { PermissionFlag } from "types/permission";

type RuleConfig = {
  requires: string;
  maxLevelMissing: PermissionFlag;
  maxLevelExisting: PermissionFlag;
};

type RuleLicenseMapping = {
  [ruleName: string]: RuleConfig,
};

export const RULE_LICENSE_MAPPING: RuleLicenseMapping = {
  "organization.organigram.show": {
    requires: "conlexia.core+",
    maxLevelMissing: PermissionFlag.NONE,
    maxLevelExisting: PermissionFlag.WRITE,
  },
  "home.dashboard": {
    requires: "conlexia.analytics",
    maxLevelMissing: PermissionFlag.NONE,
    maxLevelExisting: PermissionFlag.WRITE,
  },
  "home.competencies": {
    requires: "conlexia.analytics",
    maxLevelMissing: PermissionFlag.NONE,
    maxLevelExisting: PermissionFlag.WRITE,
  },
  "home.organization": {
    requires: "conlexia.analytics",
    maxLevelMissing: PermissionFlag.NONE,
    maxLevelExisting: PermissionFlag.WRITE,
  },
  "home.employees": {
    requires: "conlexia.analytics",
    maxLevelMissing: PermissionFlag.NONE,
    maxLevelExisting: PermissionFlag.WRITE,
  },
};
