import React from "react";
import {
  useFormContext,
  Controller,
  FieldErrors,
  FieldName,
} from "react-hook-form";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";
import { de } from "date-fns/locale";

registerLocale("de", de);

interface Props {
  name: string;
  label: string;
}

// interface Props<T> {
//   name: FieldName<T>
// }

// const DateField = <T extends object>({ name }: Props<T>) => {
const DateField = ({ name, label }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="date"
        className="block text-sm font-medium leading-5 text-gray-900"
      >
        {label}
      </label>
      <Controller
        control={control}
        name={name as any}
        rules={{ required: false }}
        // defaultValue={parseISO(
        //   data?.hireDate || new Date().toISOString()
        // )}
        render={({ field }) => {
          const value =
            typeof field.value === "string"
              ? parseISO(field.value)
              : field.value;

          return (
            <ReactDatePicker
              dateFormat="dd.MM.yyyy"
              locale="de"
              placeholderText="Datum wählen"
              onChange={field.onChange}
              onBlur={field.onBlur}
              selected={value}
              className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          );
        }}
      />
      {errors[name] && (
        <span className="text-sm font-light text-red-500">Datum fehlt</span>
      )}
    </>
  );
};

export default DateField;
