import { useMutation, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";

import { EMPLOYEE_MANY } from "schema/employee";
import { ROLE_MANY } from "schema/role";
import { USER_CREATE_ONE, USER_MANY } from "schema/user";

import SliderOverContext from "context/SliderOverContext";
import useToggle from "hooks/useToogle";

import { Error, Loading } from "components/ui/Alerts";
import SlideOver from "components/ui/SlideOver";
import { H1 } from "components/ui/Typo/Typo";
import Form, { FormData } from "./Form";
import List from "./List";
import Tabs from "./Tabs";

const Index = () => {
  const {
    loading: userLoading,
    error: userError,
    data: userData,
    refetch,
  } = useQuery(USER_MANY);
  const {
    loading: employeeLoading,
    error: employeeError,
    data: employeeData,
  } = useQuery(EMPLOYEE_MANY);
  const { loading: roleLoading, error: roleError, data: roleData } = useQuery(
    ROLE_MANY
  );
  const [createUser] = useMutation(USER_CREATE_ONE);
  const [open, toggle] = useToggle();
  const { formatMessage: f } = useIntl();

  const onSubmit = (formData: FormData) => {
    createUser({
      variables: {
        username: formData.username,
        password: formData.password,
        locked: Boolean(formData.locked),
        employeeId: formData.employeeId,
        role: formData.role,
      },
    });
    toggle();
    refetch();
  };

  const loading = userLoading || employeeLoading || roleLoading;
  const error = userError || employeeError || roleError;

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Benutzer hinzufügen">
          <Form
            employees={employeeData?.employeeMany}
            roles={roleData?.roleMany}
            onSubmit={onSubmit}
          />
        </SlideOver>

        <Tabs />

        {/* SectionHeader */}
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
          <div className="ml-4 mt-2">
            <H1>{f({ id: "users.name" })}</H1>
          </div>
          <div className="p-6 bg-white ml-4 mt-2 flex-shrink-0">
            <span className="inline-flex rounded-md shadow-sm">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray bg-coral-300 hover:bg-coral-200 focus:outline-none focus:shadow-outline-indigo focus:border-orange-400 active:bg-coral-400"
                onClick={toggle}
              >
                <svg
                  className="group-hover:text-light-blue-600 text-light-blue-500 mr-2"
                  width="12"
                  height="20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 5a1 1 0 011 1v3h3a1 1 0 110 2H7v3a1 1 0 11-2 0v-3H2a1 1 0 110-2h3V6a1 1 0 011-1z"
                  />
                </svg>
                {f({ id: "users.new" })}
              </button>
            </span>
          </div>
        </div>

        <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
          {`${userData?.userMany.length} Einträge`}
        </h2>
        <List data={userData?.userMany} />
      </SliderOverContext.Provider>
    </>
  );
};

export default Index;
