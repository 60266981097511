import { ReactElement, ReactNode, useState } from "react";

import {
  AcademicCapIcon,
  ChartBarIcon,
  OfficeBuildingIcon,
  TemplateIcon,
  UserIcon,
} from "@heroicons/react/solid";

import {
  VerticalNavigation,
  VerticalNavigationOption,
} from "./ui/navigation/VerticalNavigation";

import { useIntl } from "react-intl";

import { BlurOverlay } from "components/ui/permissions/BlurOverlay";
import DashboardEmployees from "./dashboard/Employees";
import Dashboard from "./dashboard/Index";
import DashboardMatching from "./dashboard/Matching";
import DashboardOrganization from "./dashboard/Organization";
import DashboardOverview from "./dashboard/Overview";
import DashboardSearch from "./dashboard/Search";
import DashboardSkills from "./dashboard/Skills";

const Home = () => {
  const { formatMessage: f } = useIntl();

  const tabs: VerticalNavigationOption<string>[] = [
    {
      type: "option",
      id: "overview",
      value: "#overview",
      label: "Übersicht",
      icon: <TemplateIcon className="h-5 w-5" />,
      renderOption: (children: ReactNode, option: any) => (
        <a href={option.value}>{children}</a>
      ),
    },
    // {
    //   type: "option",
    //   id: "matching",
    //   value: "#matching",
    //   label: "Matching",
    //   icon: <FilterIcon className="h-5 w-5" />,
    //   renderOption: (children: ReactNode, option: any) => (
    //     <a href={option.value}>{children}</a>
    //   ),
    // },
    // {
    //   type: "option",
    //   id: "search",
    //   value: "#search",
    //   label: "Suche",
    //   icon: <SearchIcon className="h-5 w-5" />,
    //   renderOption: (children: ReactNode, option: any) => (
    //     <a href={option.value}>{children}</a>
    //   ),
    // },
    {
      type: "option",
      id: "dashboard",
      value: "#dashboard",
      label: "Kennzahlen",
      icon: <ChartBarIcon className="h-5 w-5" />,
      renderOption: (children: ReactNode, option: any) => (
        <a href={option.value}>{children}</a>
      ),
    },
    {
      type: "option",
      id: "org",
      value: "#org",
      label: f({ id: "organizations.name" }),
      icon: <OfficeBuildingIcon className="h-5 w-5" />,
      renderOption: (children: ReactNode, option: any) => (
        <a href={option.value}>{children}</a>
      ),
    },
    {
      type: "option",
      id: "skills",
      value: "#skills",
      label: f({ id: "skills.name" }),
      icon: <AcademicCapIcon className="h-5 w-5" />,
      renderOption: (children: ReactNode, option: any) => (
        <a href={option.value}>{children}</a>
      ),
    },
    {
      type: "option",
      id: "employees",
      value: "#employees",
      label: f({ id: "employees.name" }),
      icon: <UserIcon className="h-5 w-5" />,
      renderOption: (children: ReactNode, option: any) => (
        <a href={option.value}>{children}</a>
      ),
    },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  let tab: ReactElement;
  let module: string;
  switch (selectedTab.id) {
    case "overview":
      tab = <DashboardOverview />;
      module = "home.dashboard";
      break;
    case "matching":
      tab = <DashboardMatching />;
      module = "home.dashboard";
      break;
    case "search":
      tab = <DashboardSearch />;
      module = "home.dashboard";
      break;
    case "dashboard":
      tab = <Dashboard />;
      module = "home.dashboard";
      break;
    case "org":
      tab = <DashboardOrganization />;
      module = "home.organization";
      break;
    case "skills":
      tab = <DashboardSkills />;
      module = "home.competencies";
      break;
    case "employees":
      tab = <DashboardEmployees />;
      module = "home.employees";
      break;
    default:
      throw Error("Tab unreachable");
  }

  return (
    <div className="flex">
      <div className="w-64 h-screen bg-white">
        <nav className="mt-10">
          <VerticalNavigation
            options={tabs}
            selected={selectedTab}
            onSelect={setSelectedTab}
          />
        </nav>
      </div>

      <div className="w-full bg-gray-100 dark:bg-gray-900 dark:text-white text-gray-600 text-sm">
        <div className="flex-grow flex flex-col p-5 relative">
          <BlurOverlay module={module}>{tab}</BlurOverlay>
        </div>
      </div>
    </div>
  );
};

export default Home;
