import { useFormContext } from "react-hook-form";

const EmailField = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="email"
        className="block text-sm font-medium leading-5 text-gray-900"
      >
        E-Mail
      </label>
      <input
        type="email"
        id="email"
        {...register("email", {
          required: true,
          pattern: /^\S+@\S+$/i,
        })}
        autoComplete="email"
        className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      />

      {errors.email && (
        <span className="text-sm font-light text-red-500">
          E-Mail fehlt
        </span>
      )}
    </>
  );
};

export default EmailField;
