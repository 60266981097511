import React from "react";
import { useLocation } from "react-router-dom";

import { PermissionFlag, scope } from "types/permission";
import { usePermissions } from "hooks/usePermissions";

interface IProps {
  module: string;
  children: any;
  flag?: PermissionFlag,
  scopeRequired?: scope;
  transparent?: boolean;
  debug?: boolean;
};

export const PermissionRequired: React.FC<IProps> = ({
  module,
  children,
  flag = PermissionFlag.READ,
  scopeRequired = scope.SELF,
  transparent = false,
  debug = false,
}) => {
  const location = useLocation();
  const { hasPermissionScope, loading } = usePermissions();

  if (debug) {
    console.log('usePermissions > location', location.pathname, location);
    console.log('usePermissions > module', module);
    console.log('usePermissions > flag', flag);
    console.log('usePermissions > scopeRequired', scopeRequired);
    console.log('usePermissions > hasPermissionScope', hasPermissionScope(module, flag, scopeRequired));
  }

  if (loading) { return transparent ? children: null; }

  if (hasPermissionScope(module, flag, scopeRequired)) {
    return children;
  } else {
    return null;
  }
};
