import { useMutation, useQuery } from "@apollo/client";
import {
  SEMINAR_TYPE_CREATE_ONE,
  SEMINAR_TYPE_MANY,
} from "../../schema/seminarType";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import Tabs from "../seminars/Tabs";
import Error from "../ui/Alerts/Error";
import Loading from "../ui/Alerts/Loading";
import SlideOver from "../ui/SlideOver";
import { H1 } from "../ui/Typo/Typo";
import Form, { FormData } from "./Form";
import List from "./List";

const Index = () => {
  const { loading, error, data } = useQuery(SEMINAR_TYPE_MANY);
  const [createSeminarType] = useMutation(SEMINAR_TYPE_CREATE_ONE, {
    refetchQueries: [{ query: SEMINAR_TYPE_MANY }],
  });
  const [open, toggle] = useToggle();

  const onSubmit = (formData: FormData) => {
    createSeminarType({
      variables: {
        name: formData.name,
        description: formData.description,
      },
    });
    toggle();
  };

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Neuen Seminartyp hinzufügen">
          <Form onSubmit={onSubmit} />
        </SlideOver>

        <Tabs />

        {/* SectionHeader */}
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
          <div className="ml-4 mt-2">
            <H1>Seminartypen</H1>
          </div>
          <div className="p-6 bg-white ml-4 mt-2 flex-shrink-0">
            <span className="inline-flex rounded-md shadow-sm">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray bg-coral-300 hover:bg-coral-200 focus:outline-none focus:shadow-outline-indigo focus:border-orange-400 active:bg-coral-400"
                onClick={toggle}
              >
                <svg
                  className="group-hover:text-light-blue-600 text-light-blue-500 mr-2"
                  width="12"
                  height="20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 5a1 1 0 011 1v3h3a1 1 0 110 2H7v3a1 1 0 11-2 0v-3H2a1 1 0 110-2h3V6a1 1 0 011-1z"
                  />
                </svg>
                Neuer Seminartyp
              </button>
            </span>
          </div>
        </div>

        <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
          {`${data?.seminarTypeMany.length} Einträge`}
        </h2>
        <List data={data?.seminarTypeMany} />
      </SliderOverContext.Provider>
    </>
  );
};

export default Index;
