import { useState, Fragment } from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveSwarmPlot } from "@nivo/swarmplot";
import { ResponsiveLine } from "@nivo/line";
import { Disclosure, Listbox, Transition } from "@headlessui/react";
import { ChevronUpIcon, CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { useIntl } from "react-intl";

import { EMPLOYEE_BY_SCALE } from "../../schema/employee";
import { SKILL_MANY } from "../../schema/skill";
import { METRIC_HUMATICS_BY_DATE } from "../../schema/metric";
import { Skill } from "../../types";

import { H3 } from "../ui/Typo/Typo";
import { filterChartData, relativeNumbers } from "../../utils/filter";
import {
  currencyFormater,
  numberFormater,
  percentFormater,
} from "../../config/i18n";

import { Loading } from "../ui/Alerts";
import Stats from "./Stats";

const items = [5, 10, 15];

const Main = () => {
  const history = useHistory();
  const { formatMessage: f } = useIntl();

  const [selected, setSelected] = useState(items[0]);

  const { loading, data: skillData } = useQuery(SKILL_MANY);
  const { data: swarmData } = useQuery(EMPLOYEE_BY_SCALE, {
    variables: {
      boundaries: [0, 70, 100],
    },
  });
  const { data: lineData } = useQuery(METRIC_HUMATICS_BY_DATE, {
    variables: { type: "employees" },
  });

  const swarmResults =
    swarmData?.employeeByScale.map((d: any, index: number) => ({
      id: index,
      group: d._id.toString(),
      count: d.count,
      salary: d.employees.salary,
    })) || [];

  const maxCount = swarmResults.reduce((memo: any, item: any) => {
    return Math.max(memo, item.count);
  }, 0);

  const maxSalary = swarmResults.reduce((memo: any, item: any) => {
    return Math.max(memo, item.salary);
  }, 0);

  const lineResults = [
    {
      id: f({ id: "humatics.h" }),
      data: filterChartData(lineData?.metricHumaticsByDate, "employeesH"),
    },
    {
      id: f({ id: "humatics.t" }),
      data: filterChartData(lineData?.metricHumaticsByDate, "t"),
    },
    {
      id: f({ id: "humatics.r" }),
      data: filterChartData(lineData?.metricHumaticsByDate, "r"),
    },
    {
      id: f({ id: "humatics.phi" }),
      data: filterChartData(lineData?.metricHumaticsByDate, "phi"),
    },
  ];

  const lineDataKnowledgeValue = relativeNumbers(
    lineData?.metricHumaticsByDate,
    "knowledgeValue"
  );
  const lineDataPhi = relativeNumbers(lineData?.metricHumaticsByDate, "phi");

  const lineResults2 = [
    {
      id: f({ id: "knowledgevalue" }),
      data: filterChartData(lineDataKnowledgeValue, "knowledgeValue"),
    },
    {
      id: f({ id: "humatics.phi" }),
      data: filterChartData(lineDataPhi, "phi"),
    },
  ];

  const barData =
    skillData?.skillMany
      .map((skill: Skill) => ({
        _id: skill._id,
        name: skill.name,
        supply: skill.supply,
        demand: skill.demand,
      }))
      .sort((a: Skill, b: Skill) => b.supply - a.supply)
      .slice(0, selected) || [];

  return (
    <>
      {loading && <Loading />}

      <Stats />

      <div className="w-full h-64 rounded-lg shadow bg-white dark:bg-gray-800 my-5">
        <ResponsiveLine
          data={lineResults.reverse()}
          margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
          theme={{ background: "#ffffff" }}
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: "day",
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: "linear",
            stacked: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: "%b",
            tickValues: "every 4 weeks",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Zeitverlauf",
            legendPosition: "middle",
            legendOffset: 36,
          }}
          axisLeft={{
            tickSize: 10,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Wert",
            legendPosition: "middle",
            legendOffset: -50,
          }}
          curve="monotoneX"
          enablePointLabel={false}
          enableSlices="x"
          sliceTooltip={({ slice }) => {
            return (
              <div className="flex flex-col items-center bg-white shadow-sm text-xs rounded py-1 px-2">
                {slice.points.map((point) => (
                  <div
                    key={point.id}
                    style={{ color: point.serieColor }}
                    className="leading-normal"
                  >
                    <span>{point.serieId}</span>{" "}
                    <span className="font-bold">
                      {numberFormater.format(+point.data.yFormatted)}
                    </span>
                  </div>
                ))}
              </div>
            );
          }}
          pointSize={6}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          enableGridX={false}
          enableGridY={false}
          enableArea={true}
          legends={[
            {
              anchor: "top",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: -50,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 180,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>

      <div className="w-full h-64 rounded-lg shadow bg-white dark:bg-gray-800 my-5">
        <ResponsiveLine
          data={lineResults2.reverse()}
          margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
          theme={{ background: "#ffffff" }}
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: "day",
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: "linear",
            stacked: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: "%b",
            tickValues: "every 4 weeks",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Zeitverlauf",
            legendPosition: "middle",
            legendOffset: 36,
          }}
          axisLeft={{
            tickSize: 10,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Wert relativ",
            legendPosition: "middle",
            legendOffset: -50,
          }}
          curve="monotoneX"
          enablePointLabel={false}
          enableSlices="x"
          sliceTooltip={({ slice }) => {
            return (
              <div className="flex flex-col items-center bg-white shadow-sm text-xs rounded py-1 px-2">
                {slice.points.map((point) => (
                  <div
                    key={point.id}
                    style={{ color: point.serieColor }}
                    className="leading-normal"
                  >
                    <span>{point.serieId}</span>{" "}
                    <span className="font-bold">
                      {numberFormater.format(+point.data.yFormatted)}
                    </span>
                  </div>
                ))}
              </div>
            );
          }}
          pointSize={6}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          enableGridX={false}
          enableGridY={false}
          enableArea={true}
          legends={[
            {
              anchor: "top",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: -50,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 180,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>

      <div className="w-full rounded-lg shadow bg-white dark:bg-gray-800 my-5">
        <div className="pt-3 flex space-x-4 justify-center items-center mx-auto">
          <H3>Mit welchen </H3>
          <div className="w-20">
            <Listbox value={selected} onChange={setSelected}>
              {({ open }) => (
                <>
                  <div className="relative mt-1">
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                      <span className="block truncate">{selected}</span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options
                        static
                        className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                      >
                        {items.map((item: number, index: number) => (
                          <Listbox.Option
                            key={index}
                            className={({ active }) =>
                              `${
                                active
                                  ? "text-amber-900 bg-amber-100"
                                  : "text-gray-900"
                              }
                                cursor-default select-none relative py-2 pl-10 pr-4`
                            }
                            value={item}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`${
                                    selected ? "font-medium" : "font-normal"
                                  } block truncate`}
                                >
                                  {item}
                                </span>
                                {selected ? (
                                  <span
                                    className={`${
                                      active
                                        ? "text-amber-600"
                                        : "text-amber-600"
                                    }
                                      absolute inset-y-0 left-0 flex items-center pl-3`}
                                  >
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
          <H3>Kompetenzen wird welcher Umsatz realisiert?</H3>
        </div>
        <div className="h-80">
          <ResponsiveBar
            data={barData}
            keys={["supply", "demand"]}
            indexBy="name"
            groupMode="grouped"
            margin={{ top: 40, right: 80, bottom: 150, left: 80 }}
            padding={0.3}
            colors={{ scheme: "nivo" }}
            tooltip={(item: any) => (
              <div className="flex flex-col items-center text-center text-xs p-0.5">
                <span className="font-semibold" style={{ color: item.color }}>
                  {item.id === "supply"
                    ? f({ id: "revenues.actual" })
                    : f({ id: "revenues.target" })}
                </span>
                {currencyFormater.format(item.value)}
              </div>
            )}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 20,
            }}
            enableLabel={false}
            labelFormat={(v) => `${currencyFormater.format(+v)}`}
            labelTextColor="inherit:darker(1.4)"
            labelSkipWidth={32}
            labelSkipHeight={16}
            onClick={({ data }) => history.push(`/skills/${data._id}/details`)}
          />
        </div>
        <div className="p-6 pt-0">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>{f({ id: "views.table" })}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "transform rotate-180" : ""
                    } w-5 h-5 text-gray-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-100">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Kompetenz
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          IST Umsatz
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          SOLL Umsatz
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Abweichung in % vom SOLL Umsatz
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {barData?.map((skill: Skill) => (
                        <tr key={skill._id}>
                          <td className="px-6 py-4">{skill.name}</td>
                          <td className="px-6 py-4">
                            {currencyFormater.format(skill.supply)}
                          </td>
                          <td className="px-6 py-4">
                            {currencyFormater.format(skill.demand)}
                          </td>
                          <td className="px-6 py-4">
                            {percentFormater.format(
                              skill.demand / skill.supply
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>

      <div className="w-full rounded-lg shadow bg-white dark:bg-gray-800">
        <div className="pt-3 text-center">
          <H3>
            Anzahl und Verteilung aller Kompetenzen innerhalb des
            Skalierungsschemas
          </H3>
        </div>
        <div className="h-64">
          <ResponsiveSwarmPlot
            data={swarmResults}
            groups={["0", "70", "100"]}
            value="count"
            valueScale={{
              type: "linear",
              min: 0,
              max: maxCount,
              reverse: false,
            }}
            size={{
              key: "salary",
              values: [0, maxSalary],
              sizes: [0, 10],
            }}
            forceStrength={4}
            isInteractive={false}
            simulationIterations={100}
            borderColor={{
              from: "color",
              modifiers: [
                ["darker", 0.6],
                ["opacity", 0.5],
              ],
            }}
            margin={{ top: 80, right: 100, bottom: 80, left: 100 }}
          />
        </div>
      </div>
    </>
  );
};

export default Main;
