import { Link } from "react-router-dom";
import { useParams, useHistory, RouteComponentProps } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { ResponsiveLine } from "@nivo/line";
import { useIntl } from "react-intl";

import {
  TEAM_BY_ID,
  TEAM_UPDATE_BY_ID,
  TEAM_REMOVE_BY_ID,
  TEAM_MANY,
} from "../../schema/team";
import { METRIC_HUMATICS_BY_DATE } from "../../schema/metric";
import { Employee } from "../../types";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { Loading, Error } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import Form, { FormData } from "./Form";

import Stats from "../units/Stats";
import { H3 } from "../ui/Typo/Typo";
import RevenueFields from "../revenues/Fields";
import RequirementFields from "../requirements/Fields";

interface ParamTypes {
  id: string;
}

const Details = ({}: RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [open, toggle] = useToggle();
  const { formatMessage: f } = useIntl();

  const { loading, error, data } = useQuery(TEAM_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateTeam] = useMutation(TEAM_UPDATE_BY_ID, {
    refetchQueries: [
      { query: TEAM_MANY },
      { query: TEAM_BY_ID, variables: { _id: id } },
    ],
  });
  const [deleteTeam] = useMutation(TEAM_REMOVE_BY_ID, {
    refetchQueries: [{ query: TEAM_MANY }],
  });

  const { data: chartData } = useQuery(METRIC_HUMATICS_BY_DATE, {
    variables: { type: "team", objectId: id },
  });

  const onUpdateRevenues = (formData: FormData) => {
    updateTeam({
      variables: {
        _id: id,
        revenues: formData.revenues.map((r) => ({
          ...r,
          year: +r.year,
          target: +r.target,
          actual: +r.actual,
        })),
      },
    });
  };

  const onUpdateRequirements = (formData: FormData) => {
    updateTeam({
      variables: {
        _id: id,
        requirements: formData.requirements.map((r) => ({
          ...r,
          quantity: +r.quantity,
          jobId: r.jobId,
        })),
      },
    });
  };

  const onUpdate = (formData: FormData) => {
    updateTeam({
      variables: {
        _id: id,
        name: formData.name,
        description: formData.description,
        leaderId: formData.leaderId,
        memberIds: formData.memberIds,
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteTeam({ variables: { _id: id } });
    history.goBack();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const filterChartData = (key: String) => {
    return (
      chartData?.metricHumaticsByDate?.map((d: any) => {
        //@ts-ignore
        return { x: d.date, y: d.values[key] };
      }) || []
    );
  };

  const groupedData = [
    {
      id: f({ id: "humatics.h" }),
      data: filterChartData("h"),
    },
    {
      id: f({ id: "humatics.t" }),
      data: filterChartData("t"),
    },
    {
      id: f({ id: "humatics.ef" }),
      data: filterChartData("ef"),
    },
    {
      id: f({ id: "humatics.mu" }),
      data: filterChartData("mu"),
    },
  ];

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Team bearbeiten">
          <Form onSubmit={onUpdate} data={data?.teamById} />
        </SlideOver>
      </SliderOverContext.Provider>

      <H3>Kompetenzwerte des Teams</H3>
      <div className="pt-2 pb-6 h-64">
        <ResponsiveLine
          data={groupedData}
          margin={{ top: 80, right: 50, bottom: 80, left: 60 }}
          theme={{ background: "#f7fafc" }}
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: "day",
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: "linear",
            stacked: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: "%b %d",
            tickValues: "every 4 weeks",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Zeitverlauf",
            legendPosition: "middle",
            legendOffset: 36,
          }}
          axisLeft={{
            tickSize: 10,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Wert",
            legendPosition: "middle",
            legendOffset: -50,
          }}
          curve="monotoneX"
          enablePointLabel={true}
          pointSize={6}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          enableArea={false}
          enableGridX={false}
          enableGridY={false}
          legends={[
            {
              anchor: "top",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: -50,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 180,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>

      <Stats
        humatics={data?.teamById.humatics}
        knowledgeValue={data?.teamById.knowledgeValue}
      />

      <hr className="my-6 border-2 rounded-full border-gray-200" />

      <div className="bg-gray-100">
        <div className="bg-white shadow">
          <div className="container mx-auto px-6">
            <div className="flex items-center justify-between py-5">
              <div>
                <Link
                  to="/teams"
                  className="inline-flex items-center text-sm text-gray-500 leading-none pb-2"
                >
                  <i className="fas fa-angle-left"></i>
                  <span>{f({ id: "teams.name" })}</span>
                </Link>
                <div className="flex items-center">
                  <h1 className="px-2 text-2xl text-gray-900 font-extrabold">
                    {data?.teamById?.name}
                  </h1>
                </div>
              </div>
              <div className="flex items-center">
                <span className="shadow-sm rounded-md">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                    onClick={toggle}
                  >
                    {f({ id: "actions.edit" })}
                  </button>
                </span>
                <span className="ml-3 shadow-sm rounded-md">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline-red focus:border-red-700 active:bg-red-700 transition duration-150 ease-in-out"
                    onClick={onDelete}
                  >
                    {f({ id: "actions.delete" })}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto p-6">
          <div className="flex -mx-2">
            <div className="w-3/5 px-2">
              <div className="mb-6">
                <h2 className="text-lg font-medium text-gray-900 pb-6">
                  {f({ id: "summary.name" })}
                </h2>
                <div className="bg-white rounded shadow overflow-hidden">
                  <div className="px-6 py-5">
                    <dl>
                      <div className="flex">
                        <div className="w-1/2 mb-6">
                          <dt className="text-sm font-medium text-gray-500">
                            {f({ id: "meta.name" })}
                          </dt>
                          <dd className="text-base font-normal text-gray-900">
                            {data?.teamById.name}
                          </dd>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="w-full mb-6">
                          <dt className="text-sm font-medium text-gray-500">
                            {f({ id: "meta.description" })}
                          </dt>
                          <dd className="text-sm font-normal text-gray-900">
                            {data?.teamById.description}
                          </dd>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="mb-6">
                <h2 className="text-lg font-medium text-gray-900 pb-6">
                  {f({ id: "revenues.name" })}
                </h2>
                <div className="bg-white rounded shadow overflow-hidden text-gray-600">
                  <RevenueFields
                    onSubmit={onUpdateRevenues}
                    data={data?.teamById.revenues}
                  />
                </div>
              </div>
              <div className="mb-6">
                <h2 className="text-lg font-medium text-gray-900 pb-6">
                  {f({ id: "requirements.name" })}
                </h2>
                <RequirementFields
                  onSubmit={onUpdateRequirements}
                  data={data?.teamById.requirements}
                />
              </div>
            </div>
            <div className="w-2/5 px-2">
              <h2 className="text-lg font-medium text-gray-900 pb-6">
                {data?.teamById.memberIds.length} Mitglieder
              </h2>
              <div className="bg-white rounded shadow text-gray-600 mb-6">
                {data?.teamById.members.map(
                  (employee: Employee, index: number) => {
                    return (
                      <div
                        key={employee._id}
                        className={`${
                          index % 2 ? "bg-gray-50" : "bg-white-50"
                        } px-4 py-5`}
                      >
                        <div className="text-sm font-medium text-gray-500">
                          <Link to={`/employees/${employee._id}/details`}>
                            {employee.firstName} {employee.lastName}
                          </Link>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
