import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon, FilterIcon, PlusIcon } from "@heroicons/react/solid";
import { useState } from "react";

import { useIntl } from "react-intl";

import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { ResponsiveLine } from "@nivo/line";

import {
  EMPLOYEE_CREATE_ONE,
  EMPLOYEE_MANY,
  EMPLOYEE_MANY_GROUP_BY_UNIT,
} from "../../schema/employee";
import {
  METRIC_HUMATICS_BY_DATE,
  METRIC_HUMATICS_BY_DATE_GROUP,
} from "../../schema/metric";
import { Employee, EmployeeByUnit } from "../../types/employee";

import { selectedEmployeesVar } from "../../cache";
import FilterContext from "../../context/FilterContext";
import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";
import { filterChartData } from "../../utils/filter";

import {
  HorizontalNavigation,
  HorizontalNavigationOption,
} from "../ui/navigation/HorizontalNavigation";

import { BlurOverlay, PermissionRequired } from "components/ui/permissions";
import { PermissionFlag, scope } from "types/permission";
import { numberFormater } from "../../config/i18n";
import { Error, Loading } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import { H1, H3 } from "../ui/Typo/Typo";
import Filter, { FormData as FilterData } from "./Filter";
import Form, { FormData } from "./Form";
import List from "./List";
import Tabs from "./Tabs";

const Index = () => {
  const [open, toggle] = useToggle();
  const [openFilter, toggleFilter] = useToggle();
  const { formatMessage: f } = useIntl();

  const { loading, error, data, refetch } = useQuery(
    EMPLOYEE_MANY_GROUP_BY_UNIT,
    {
      variables: { skillIds: null },
    }
  );
  const [createEmployee] = useMutation(EMPLOYEE_CREATE_ONE, {
    refetchQueries: [
      { query: EMPLOYEE_MANY_GROUP_BY_UNIT, variables: { skillIds: null } },
      { query: EMPLOYEE_MANY },
    ],
  });

  const tabs: HorizontalNavigationOption[] = [
    { value: "h", label: f({ id: "humatics.h" }) },
    { value: "t", label: f({ id: "humatics.t" }) },
  ];

  // Selections
  const selectedEmployees = useReactiveVar(selectedEmployeesVar);
  const [selectedMetric, setSelectedMetric] = useState<
    HorizontalNavigationOption
  >(tabs[0]);

  // Charts
  const { data: chartData } = useQuery(METRIC_HUMATICS_BY_DATE, {
    variables: { type: "employees" },
  });
  const { data: chartDataGroup } = useQuery(METRIC_HUMATICS_BY_DATE_GROUP, {
    variables: {
      type: "employee",
      objectIds: selectedEmployees,
    },
  });

  const onSubmit = (formData: FormData) => {
    createEmployee({
      variables: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        gender: formData.gender,
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        photo: formData.photo,
        birthDate: formData.birthDate,
        hireDate: formData.hireDate,
        retireDate: formData.retireDate,
        terminationDate: formData.terminationDate,
        personnalNumber: formData.personnalNumber,
        jobId: formData.jobId ? formData.jobId : null,
        salary: +formData.salary,
        competences: formData.competences,
        unitId: formData.unitId ? formData.unitId : null,
        managerId: formData.managerId ? formData.managerId : null,
        employmentType: formData.employmentType,
        employmentStatus: formData.employmentStatus,
      },
    });
    toggle();
  };

  const onSubmitFilter = (filterData: FilterData) => {
    //setFilterData(filterData);
    refetch({ ...(filterData as any) });
    toggleFilter();
  };

  const groupedDataAll = [
    {
      id: f({ id: "humatics.h" }),
      data: filterChartData(chartData?.metricHumaticsByDate, "employeesH"),
    },
    {
      id: f({ id: "humatics.t" }),
      data: filterChartData(chartData?.metricHumaticsByDate, "t"),
    },
  ];

  // TODO: use d3.groupBy ?
  const groupedDataSelected = selectedEmployees.map((id: string) => {
    // FIXME: Improve this dirty fix for showing employee name as id
    let employee: any;
    data?.employeeManyGroupByUnit.forEach((item: EmployeeByUnit) => {
      const result = item.employees.find((e: Employee) => e._id === id);
      if (result) {
        employee = result;
      }
    });

    return {
      id: employee ? `${employee.firstName} ${employee.lastName}` : id,
      data: filterChartData(
        chartDataGroup?.metricHumaticsByDateGroup,
        selectedMetric.value,
        id
      ),
    };
  });

  const groupedData = selectedEmployees.length
    ? groupedDataSelected
    : groupedDataAll;

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      {selectedEmployees.length > 0 ? (
        <>
          <H3>Vergleich der Kompetenzwerte</H3>

          <HorizontalNavigation
            options={tabs}
            selected={selectedMetric}
            onSelect={setSelectedMetric}
          />
        </>
      ) : (
        <H3>{f({ id: "employees.humatics" })}</H3>
      )}

      <div className="h-64 relative">
        <BlurOverlay module="employees.humatics">
          <ResponsiveLine
            data={groupedData}
            margin={{ top: 60, right: 50, bottom: 60, left: 60 }}
            theme={{ background: "#f7fafc" }}
            xScale={{
              type: "time",
              format: "%Y-%m-%d",
              useUTC: false,
              precision: "day",
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
              type: "linear",
              stacked: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              format: "%b",
              tickValues: "every 4 weeks",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Zeitverlauf",
              legendPosition: "middle",
              legendOffset: 36,
            }}
            axisLeft={{
              tickSize: 10,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Wert",
              legendPosition: "middle",
              legendOffset: -50,
            }}
            curve="monotoneX"
            enablePointLabel={false}
            enableSlices="x"
            sliceTooltip={({ slice }) => {
              return (
                <div className="flex flex-col items-center bg-white shadow-sm text-xs rounded py-1 px-2">
                  {slice.points.map((point) => (
                    <div
                      key={point.id}
                      style={{ color: point.serieColor }}
                      className="leading-normal"
                    >
                      <span>{point.serieId}</span>{" "}
                      <span className="font-bold">
                        {numberFormater.format(+point.data.yFormatted)}
                      </span>
                    </div>
                  ))}
                </div>
              );
            }}
            pointSize={6}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            enableGridX={false}
            enableGridY={false}
            enableArea={true}
            legends={[
              {
                anchor: "top",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: -50,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 180,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </BlurOverlay>
      </div>

      <PermissionRequired
        module="employees.create"
        flag={PermissionFlag.WRITE}
        scopeRequired={scope.GROUP}
      >
        <SliderOverContext.Provider value={{ open: open, toggle }}>
          <SlideOver title={f({ id: "employees.add" })}>
            <Form onSubmit={onSubmit} />
          </SlideOver>
        </SliderOverContext.Provider>
      </PermissionRequired>

      <Tabs />

      {/* SectionHeader */}
      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
        <div className="ml-4 mt-2">
          <H1>{f({ id: "employees.name" })}</H1>
        </div>
        <div className="p-6 bg-white ml-4 mt-2 flex-shrink-0 space-x-4">
          <PermissionRequired
            module="employees.create"
            flag={PermissionFlag.WRITE}
            scopeRequired={scope.GROUP}
          >
            <span className="inline-flex rounded-md shadow-sm">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray bg-coral-300 hover:bg-coral-200 focus:outline-none focus:shadow-outline-indigo focus:border-orange-400 active:bg-coral-400"
                onClick={toggle}
              >
                <PlusIcon className="w-5 h-5 mr-1" />
                {f({ id: "employees.new" })}
              </button>
            </span>
          </PermissionRequired>
          <PermissionRequired module="employees.filter">
            <span className="inline-flex shadow-sm rounded-md">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                onClick={toggleFilter}
              >
                <FilterIcon className="h-4 w-4 mr-1" />
                {f({ id: "actions.filter" })}
              </button>
            </span>
          </PermissionRequired>
        </div>
      </div>

      <div className="flex space-x-5 justify-between">
        <div className="w-full overflow-hidden">
          <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
            {`${data?.employeeManyGroupByUnit.reduce(
              (memo: any, item: EmployeeByUnit) => memo + item.count,
              0
            )} Einträge`}
            <span className="pl-4">{`${selectedEmployees.length} Ausgewählt`}</span>
          </h2>

          <div className="py-4 pb-72">
            {data?.employeeManyGroupByUnit.map(
              (item: EmployeeByUnit, index: 0) => {
                return (
                  <Disclosure key={index} defaultOpen>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full mb-2 px-4 py-2 text-sm font-medium text-left text-white bg-brand-primary rounded-lg hover:bg-gray-900 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                          <span>{item.name}</span>
                          <ChevronUpIcon
                            className={`${
                              open ? "transform rotate-180" : ""
                            } w-5 h-5 text-gray-400`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="pl-4 pb-4">
                          <List data={item.employees} />
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                );
              }
            )}
          </div>
        </div>

        <FilterContext.Provider
          value={{ open: openFilter, toggle: toggleFilter }}
        >
          <Filter onSubmit={onSubmitFilter} />
        </FilterContext.Provider>
      </div>
    </>
  );
};

export default Index;
