import { useParams, useHistory, RouteComponentProps } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  SKILL_GROUP_BY_ID,
  SKILL_GROUP_UPDATE_BY_ID,
  SKILL_GROUP_REMOVE_BY_ID,
  SKILL_GROUP_MANY,
} from "../../schema/skillGroup";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { Loading, Error } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import PageHeader from "../ui/Headings/PageHeader";
import { Breadcrumb } from "../ui/navigation/Breadcrumb";

import Form, { FormData } from "./Form";
import List from "../skills/List";

interface ParamTypes {
  id: string;
}

const Details = ({}: RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [open, toggle] = useToggle();

  const { loading, error, data } = useQuery(SKILL_GROUP_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateSkillGroup] = useMutation(SKILL_GROUP_UPDATE_BY_ID, {
    refetchQueries: [{ query: SKILL_GROUP_MANY }],
  });
  const [deleteSkillGroup] = useMutation(SKILL_GROUP_REMOVE_BY_ID, {
    refetchQueries: [{ query: SKILL_GROUP_MANY }],
  });

  const onUpdate = (formData: FormData) => {
    updateSkillGroup({
      variables: {
        _id: id,
        name: formData.name,
        description: formData.description,
        skillIds: formData.skillIds,
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteSkillGroup({ variables: { _id: id } });
    history.goBack();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Kompetenzgruppe bearbeiten">
          <Form onSubmit={onUpdate} data={data.skillGroupById} />
        </SlideOver>
        <PageHeader
          title={data?.skillGroupById?.name}
          onUpdate={toggle}
          onDelete={onDelete}
        >
          <Breadcrumb>
            <Breadcrumb.Item url="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item url="/skillGroups">Kompetenzgruppen</Breadcrumb.Item>
            <Breadcrumb.Item>{data?.skillGroupById?.name}</Breadcrumb.Item>
          </Breadcrumb>
        </PageHeader>
        <p className="px-6 mt-1 max-w-2xl text-sm leading-5 text-gray-500">
          {data.skillGroupById.description}
        </p>

        <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
          {`${data?.skillGroupById?.skills.length} Einträge`}
        </h2>
        <List data={data?.skillGroupById?.skills} />
      </SliderOverContext.Provider>
    </>
  );
};

export default Details;
