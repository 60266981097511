import { useQuery } from "@apollo/client";

import { ResponsiveLine } from "@nivo/line";

import { useIntl } from "react-intl";

import { EMPLOYEE_DEMOGRAPHICS } from "../../schema/employee";
import { Employee } from "../../types";

import { H3 } from "../ui/Typo/Typo";
import Avatar from "../employees/Avatar";
import MicroChart from "../charts/MicroChart";

import { numberFormater } from "../../config/i18n";

const DashboardOrganization = () => {
  const { formatMessage: f } = useIntl();
  const { data: lineData } = useQuery(EMPLOYEE_DEMOGRAPHICS);

  const filterChartData = (key: String) => {
    return (
      lineData?.employeeDemographics?.map((d: any) => {
        //@ts-ignore
        return { x: d.date, y: d.values[key] };
      }) || []
    );
  };

  const groupedData = [
    {
      id: "Wissenswert",
      data: filterChartData("m"),
    },
  ];

  return (
    <>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {f({ id: "organizations.name" })}
      </h3>
      <div className="mt-5 flex flex-row items-flex-start justify-between space-x-4">
        <div className="w-full space-y-4">
          {/* Box 1 */}
          <H3>{f({ id: "dashboard.employees.demographics" })}</H3>
          <p className="mt-1 text-sm text-gray-600">
            {f({ id: "dashboard.employees.demographics.description" })}
          </p>
          <div className="w-full h-80 rounded-lg shadow bg-white dark:bg-gray-800">
            <ResponsiveLine
              data={groupedData}
              margin={{ top: 50, right: 50, bottom: 100, left: 80 }}
              xScale={{
                type: "time",
                format: "%Y-%m-%d",
                useUTC: false,
                precision: "day",
              }}
              xFormat="time:%Y-%m-%d"
              yScale={{
                type: "linear",
                stacked: false,
              }}
              yFormat=" >-.2r"
              axisLeft={{
                legend: "Wert",
                legendPosition: "middle",
                legendOffset: -60,
              }}
              axisBottom={{
                format: "%Y",
                tickValues: "every 2 year",
                tickSize: 5,
                legend: "Zeitverlauf",
                legendOffset: 40,
              }}
              curve="monotoneX"
              enablePointLabel={false}
              enableSlices="x"
              sliceTooltip={({ slice }) => {
                // TODO: useMemo?
                const item = lineData?.employeeDemographics.find(
                  (d: any) => d.date === slice.points[0].data.xFormatted
                );
                const employee = item?.employee as Employee;

                return (
                  <div className="flex items-center bg-white shadow-sm text-xs rounded py-1 px-2">
                    <div className="flex-shrink-0">
                      <Avatar employee={employee} className="w-10 h-10" />
                    </div>
                    <div className="ml-3">
                      <div className="text-sm font-medium leading-none text-black">
                        {employee.firstName} {employee.lastName}
                      </div>
                      <div className="text-xs font-medium leading-relaxed text-gray-400">
                        {employee.job?.name}
                      </div>
                      {slice.points.map((point) => (
                        <div key={point.id} className="leading-normal">
                          <span>{point.serieId}</span>{" "}
                          <span className="font-bold">
                            {numberFormater.format(+point.data.yFormatted)}
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className="ml-1 w-10 h-10">
                      <MicroChart data={employee.competences} />
                    </div>
                  </div>
                );
              }}
              pointSize={8}
              pointColor="white"
              // pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              markers={[]}
              useMesh={false}
              enableArea={true}
              legends={[
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: 70,
                  itemsSpacing: 2,
                  itemDirection: "left-to-right",
                  itemWidth: 150,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardOrganization;
