import { Controller, useFormContext } from "react-hook-form";

import SkillLevelSelect from "./SkillLevelSelect";

const SkillLevelField = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="skill-level-id"
        className="block text-sm font-medium leading-5 text-gray-900"
      >
        Kompetenzlevel
      </label>
      <Controller
        control={control}
        name="skillLevelId"
        render={({ field }) => <SkillLevelSelect {...field} />}
        rules={{ required: true }}
      />
      {errors.skillLevelId && (
        <span className="text-sm font-light text-red-500">
          Kompetenzlevel fehlt
        </span>
      )}
    </>
  );
};

export default SkillLevelField;
