import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";

import { useIntl } from "react-intl";

import { ResponsiveBar } from "@nivo/bar";

import { EMPLOYEES_WITH_IMPORTANT_SKILLS } from "../../schema/employee";
import { Skill } from "../../types";

import { H3 } from "../ui/Typo/Typo";

const DashboardEmployees = () => {
  const history = useHistory();
  const { formatMessage: f } = useIntl();

  const { data: barData } = useQuery(EMPLOYEES_WITH_IMPORTANT_SKILLS);

  const barResults = barData?.employeesWithImportantSkills || [];

  return (
    <>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {f({ id: "employees.name" })}
      </h3>
      <div className="mt-5 flex flex-row items-flex-start justify-between space-x-4">
        <div className="w-full space-y-4">
          <H3>{f({ id: "dashboard.employees.skills" })}</H3>
          <div className="w-full rounded-md shadow bg-white dark:bg-gray-800">
            <div className="h-64">
              <ResponsiveBar
                data={barResults}
                keys={["count"]}
                indexBy="name"
                margin={{ top: 50, right: 50, bottom: 50, left: 80 }}
                padding={0.3}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={["#fecd1b", "#485155"]}
                tooltip={({ id, value, color, data }) => (
                  <div className="flex flex-col items-center text-center text-xs p-0.5">
                    <span className="font-semibold" style={{ color: color }}>
                      {data.name}
                    </span>
                    <span className="font-bold">
                      {value} wichtige Kompetenzen
                    </span>
                    <ul>
                      {(data.skills as any).map((skill: Skill) => (
                        <li>{skill.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
                labelFormat="d"
                borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: f({ id: "employees.name" }),
                  legendPosition: "middle",
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Wichtige Kompetenzen",
                  legendPosition: "middle",
                  legendOffset: -50,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                legends={[]}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                onClick={({ data }) =>
                  history.push(`/employees/${data._id}/details`)
                }
              />
            </div>
            <div className="p-6 pt-0">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                      <span>{f({ id: "views.table" })}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "transform rotate-180" : ""
                        } w-5 h-5 text-gray-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {f({ id: "employees.name" })}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {f({ id: "skills.name" })}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {barResults.map((item: any) => (
                            <tr>
                              <td className="px-6 py-4">
                                <Link to={`/employees/${item._id}/details`}>
                                  {item.name}
                                </Link>
                              </td>
                              <td className="px-6 py-4">
                                <div className="flex flex-wrap space-x-2 items-center">
                                  {item.skills?.map((skill: Skill) => (
                                    <Link to={`/skills/${skill._id}/details`}>
                                      <span
                                        key={skill._id}
                                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
                                      >
                                        {skill.name}
                                      </span>
                                    </Link>
                                  ))}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardEmployees;
