import React, { forwardRef } from "react";
import { useQuery } from "@apollo/client";
import { SKILL_SCALE_MANY } from "../../../schema/skillScale";
import { SkillScale } from "../../../types";

import { Loading, Error } from "../../ui/Alerts";

const SkillScaleSelect = forwardRef((props: any, ref: any) => {
  const { loading, error, data } = useQuery(SKILL_SCALE_MANY);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <select
      {...props}
      ref={ref}
      autoComplete="type"
      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
    >
      <option value=""></option>
      {data.skillScaleMany.map((skillScale: SkillScale) => (
        <option key={skillScale._id} value={skillScale._id}>
          {skillScale.name}
        </option>
      ))}
    </select>
  );
});

export default SkillScaleSelect;
