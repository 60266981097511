import { useFormContext, Controller } from "react-hook-form";

import SkillGroupSelect from "./SkillGroupSelect";

const SkillGroupField = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="skill-group-id"
        className="block text-sm font-medium leading-5 text-gray-900"
      >
        Kompetenzgruppe
      </label>
      <Controller
        control={control}
        name="skillGroupId"
        render={({ field }) => <SkillGroupSelect {...field} />}
        rules={{ required: false }}
      />
      {errors.skillGroupId && (
        <span className="text-sm font-light text-red-500">
          Kompetenzgruppe fehlt
        </span>
      )}
    </>
  );
};

export default SkillGroupField;
