import { Link } from "react-router-dom";
import { Organization } from "../../types";
import Avatar from "../employees/Avatar";

interface Props {
  organization: Organization;
}

const Item = ({ organization }: Props) => {
  return (
    <tr>
      <td className="px-6 py-4">
        <div className="flex items-center">
          <Link to={`/org/${organization._id}/details`} className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {organization.name}
            </div>
            <div className="text-sm text-gray-500">
              {organization.description}
            </div>
          </Link>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        {organization.managerId && (
          <div className="flex items-center">
            <div>
              <Link
                to={`/employees/${organization.managerId}/profile`}
                className="text-xs leading-4 font-medium text-gray-500 group-hover:text-gray-700 group-focus:underline transition ease-in-out duration-150"
              >
                <Avatar employee={organization.manager} className="w-10 h-10" />
              </Link>
            </div>
            <div className="ml-3">
              <p className="text-sm leading-5 font-medium text-gray-700 group-hover:text-gray-900">
                {organization.manager?.firstName}{" "}
                {organization.manager?.lastName}
              </p>
              <Link
                to={`/employees/${organization.managerId}/profile`}
                className="text-xs leading-4 font-medium text-gray-500 group-hover:text-gray-700 group-focus:underline transition ease-in-out duration-150"
              >
                Profil anzeigen
              </Link>
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export default Item;
