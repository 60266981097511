import { Link } from "react-router-dom";
import { Transition, Menu } from "@headlessui/react";
import { useIntl } from "react-intl";

import { Vacancy } from "../../types";

interface Props {
  vacancy: Vacancy;
}

const ContextMenu = ({ vacancy }: Props) => {
  const { formatMessage: f } = useIntl();

  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <Menu.Button
            aria-label="Menu"
            className="w-8 h-8 inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition ease-in-out duration-150"
          >
            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
            </svg>
          </Menu.Button>

          <Transition
            show={open}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute left-0 w-56 mt-2 origin-top-left bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
            >
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={`/vacancies/${vacancy._id}/details`}
                      className={`${
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                      } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                    >
                      {f({ id: "actions.details" })}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={`/vacancies/${vacancy._id}/profile`}
                      className={`${
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                      } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                    >
                      {f({ id: "profile.name" })}
                    </Link>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={{
                        pathname: `/vacancies/${vacancy._id}/match`,
                        state: { competences: vacancy.competences },
                      }}
                      className={`${
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                      } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                    >
                      {f({ id: "vacancies.match" })}
                    </Link>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default ContextMenu;
