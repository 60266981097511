import { Fragment, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { useDrag, DragSourceMonitor } from "react-dnd";
import { ItemTypes } from "../../../config/ItemTypes";

import { Transition, Menu } from "@headlessui/react";
import { Skill } from "../../../types";

interface Props {
  skill: Skill;
  checked: boolean;
  onSelect?: (checked: boolean) => void;
}

const Item = ({ skill, checked, onSelect }: Props) => {
  const [{ isDragging, opacity }, drag] = useDrag({
    item: { type: ItemTypes.SKILL, skill: skill },
    options: {
      dropEffect: "copy",
    },
    end: (item: { type: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      console.log(isDragging);

      if (item && dropResult) {
        console.log(
          `You dropped ${(item as any).skill.name} into ${dropResult.name}!`
        );
      }
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging(),
    }),
  });

  const onCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.currentTarget.checked;
    onSelect?.(checked);
  };

  return (
    <li ref={drag} className="px-6 py-5 relative cursor-move">
      <div className="group flex justify-between items-left space-x-2">
        <input
          type="checkbox"
          className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none z-10"
          defaultChecked={checked}
          onChange={onCheck}
        />
        <Link
          to={`/skills/${skill._id}/details`}
          className="flex-grow -m-1 p-1 block overflow-hidden"
        >
          <span className="absolute inset-0 group-hover:bg-gray-50 z-0"></span>
          <div className="flex-1 flex items-center min-w-0 relative">
            <div className="ml-4 truncate">
              <div className="text-sm leading-5 font-medium text-gray-900 truncate">
                {skill.name}
              </div>
              <div className="text-sm leading-5 text-gray-500 truncate">
                {skill.description}
              </div>
            </div>
          </div>
        </Link>
        <div className="relative inline-block text-left">
          <Menu as="div">
            {({ open }) => (
              <>
                <Menu.Button as={Fragment}>
                  <button
                    className="group relative w-8 h-8 inline-flex items-center justify-center focus:outline-none"
                    id={`options-menu-skill-${skill._id}`}
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="flex items-center justify-center h-full w-full rounded-full group-focus:bg-gray-200 transition ease-in-out duration-150">
                      <svg
                        className="w-5 h-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                      </svg>
                    </span>
                  </button>
                </Menu.Button>

                <Transition
                  show={open}
                  className="origin-top-right absolute z-10 top-0 right-9 w-48 rounded-md shadow-lg"
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-out duration-100"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <div className="rounded-md bg-white shadow-xs">
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby={`options-menu-skill-${skill._id}`}
                    >
                      <Link
                        to={`/skills/${skill._id}/details`}
                        className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        role="menuitem"
                      >
                        Details
                      </Link>
                    </div>
                  </div>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    </li>
  );
};

export default Item;
