import { useParams, useHistory, RouteComponentProps } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  USER_BY_ID,
  USER_UPDATE_BY_ID,
  USER_REMOVE_BY_ID,
  USER_MANY,
} from "../../schema/user";
import { EMPLOYEE_MANY } from "schema/employee";
import { ROLE_MANY } from "schema/role";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { Loading, Error } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import PageHeader from "../ui/Headings/PageHeader";
import { Breadcrumb } from "../ui/navigation/Breadcrumb";
import Form, { FormData } from "./Form";

interface ParamTypes {
  id: string;
}

const Details = ({}: RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [open, toggle] = useToggle();

  const {
    loading: employeeLoading,
    error: employeeError,
    data: employeeData,
  } = useQuery(EMPLOYEE_MANY);
  const {
    loading: roleLoading,
    error: roleError,
    data: roleData,
  } = useQuery(ROLE_MANY);
  const {
    loading: userLoading,
    error: userError,
    data: userData,
  } = useQuery(USER_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateUser] = useMutation(USER_UPDATE_BY_ID, {
    refetchQueries: [{ query: USER_MANY }],
  });
  const [deleteUser] = useMutation(USER_REMOVE_BY_ID, {
    refetchQueries: [{ query: USER_MANY }],
  });

  const onUpdate = (formData: FormData) => {
    updateUser({
      variables: {
        _id: id,
        username: formData.username,
        password: formData.password,
        locked: Boolean(formData.locked),
        role: formData.role,
        employeeId: formData.employeeId,
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteUser({ variables: { _id: id } });
    history.goBack();
  };

  const loading = userLoading || employeeLoading || roleLoading;
  const error = userError || employeeError || roleError;

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Benutzer bearbeiten">
          <Form
            onSubmit={onUpdate}
            roles={roleData.roleMany}
            employees={employeeData.employeeMany}
            data={userData.userById}
          />
        </SlideOver>

        <PageHeader
          title={userData?.userById?.username}
          onUpdate={toggle}
          onDelete={onDelete}
        >
          <Breadcrumb>
            <Breadcrumb.Item url="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item url="/users">Benutzer</Breadcrumb.Item>
            <Breadcrumb.Item>{userData?.userById?.username}</Breadcrumb.Item>
          </Breadcrumb>
        </PageHeader>
      </SliderOverContext.Provider>
    </>
  );
};

export default Details;
