import { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { FilterIcon, BeakerIcon } from "@heroicons/react/solid";
import Select from "react-select";
import { useIntl } from "react-intl";

import {
  ORGANIZATION_MANY,
  ORGANIZATION_TREE,
} from "../../schema/organization";
import { TEAM_MANY, TEAM_TREE } from "../../schema/team";
import { Team } from "../../types";

import FilterContext from "../../context/FilterContext";
import useToggle from "../../hooks/useToogle";

import { Loading, Error } from "../ui/Alerts";
import Filter, { FormData } from "./Filter";
import Sidebar from "./Sidebar/Sidebar";
import SearchInput from "../ui/SearchInput";
import { H1 } from "../ui/Typo/Typo";
import { TreeLayout } from "../orgchart/TreeLayout";
import { PermissionRequired } from "components/ui/permissions";

const OrgChart = () => {
  const [open, toggle] = useToggle();
  const [openFilter, toggleFilter] = useToggle();
  const { formatMessage: f } = useIntl();

  const [query, setQuery] = useState("");
  const [teamIds, setTeamIds] = useState<string[]>([]);
  const [filterData, setFilterData] = useState<FormData>();
  const [root, setRoot] = useState<any>({});

  const {
    loading,
    error,
    data: orgData,
  } = useQuery(ORGANIZATION_MANY, { fetchPolicy: "network-only" });
  const [getOrganizationTree, { data: orgTreeData }] = useLazyQuery(
    ORGANIZATION_TREE,
    {
      variables: {
        _id: orgData?.organizationMany[0]?._id,
      },
    }
  );
  const { loading: teamLoading, data: teamData } = useQuery(TEAM_MANY);
  const [getTeamTree, { data: teamTreeData }] = useLazyQuery(TEAM_TREE);

  useEffect(() => {
    if (orgData?.organizationMany.length) {
      getOrganizationTree();
    }
  }, [orgData, getOrganizationTree]);

  useEffect(() => {
    if (teamIds.length == 0) {
      setRoot(orgTreeData?.organizationTree);
    } else if (teamIds.length == 1) {
      setRoot(teamTreeData?.teamTree[0]);
    } else {
      setRoot({
        name: "Mehrere Teams",
        children: teamTreeData?.teamTree,
      });
    }
  }, [orgTreeData, teamTreeData]);

  const onSubmit = (formData: FormData) => {
    setFilterData(formData);
    //toggleFilter();
  };

  const options =
    teamData?.teamMany.map(
      (team: Team) =>
        ({
          id: team._id,
          name: team.name,
        } as any)
    ) || [];

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
        <div className="ml-4 mt-2">
          <H1>{f({ id: "orgchart.name" })}</H1>
        </div>
        <PermissionRequired module="organization.organigram.teams.search">
          <div className="mt-2 ml-2 flex-grow">
            <SearchInput placeholder="Suche nach Name..." onChange={setQuery} />
          </div>
        </PermissionRequired>
        <PermissionRequired module="organization.organigram.filters">
          <div className="mt-2 ml-2 w-56 flex-shrink">
            <Select
              placeholder="Teams wählen..."
              options={options}
              value={options.filter(({ id }: any) => teamIds?.includes(id))}
              getOptionLabel={({ name }: any) => name}
              getOptionValue={({ id }: any) => id}
              isLoading={teamLoading}
              isMulti
              className="text-xs"
              classNamePrefix="select"
              onChange={
                // (values: any) => setTeamIds((values ?? []).map((d: any) => d.id))
                (values: any) => {
                  const ids = (values ?? []).map((d: any) => d.id);
                  setTeamIds(ids);
                  getTeamTree({ variables: { teamIds: ids } });
                }
              }
            />
          </div>
          <div className="p-6 bg-white ml-4 mt-2 flex-shrink-0 space-x-4">
            <span className="inline-flex shadow-sm rounded-md">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-r-0 border-gray-300 text-sm leading-5 font-medium rounded-md rounded-r-none text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                onClick={toggleFilter}
              >
                <FilterIcon className="h-4 w-4 mr-1" />
                {f({ id: "actions.filter" })}
              </button>
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                onClick={toggle}
              >
                <BeakerIcon className="h-4 w-4 mr-1" />
                Simulation
              </button>
            </span>
          </div>
        </PermissionRequired>
      </div>

      <div className="absolute left-0 w-full flex space-x-5 justify-between">
        <div className="w-full bg-gray-50 border rounded-md p-3 overflow-hidden relative">
          <TreeLayout data={root} query={query} filterData={filterData} />
        </div>

        <PermissionRequired module="organization.organigram.filters">
          <FilterContext.Provider
            value={{ open: openFilter, toggle: toggleFilter }}
          >
            <Filter onSubmit={onSubmit} />
          </FilterContext.Provider>
        </PermissionRequired>

        <FilterContext.Provider value={{ open: open, toggle: toggle }}>
          <Sidebar />
        </FilterContext.Provider>
      </div>
    </>
  );
};

export default OrgChart;
