import { Transition } from "@headlessui/react";
import React, { useContext } from "react";
import SlideOverContext from "../../../context/SliderOverContext";
import Item from "./SlideOverListItem";
import { Job } from "../../../types";

interface Props {
  data: Array<Job>;
  children: React.ReactNode;
};

const List = ({ data = [], children }: Props) => {
  const { open, toggle } = useContext(SlideOverContext);

  return (
    <Transition show={open}>
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              className="w-screen max-w-md"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                <header className="p-6">
                  <div className="flex items-start justify-between space-x-3">
                    <h2 className="text-lg leading-7 font-medium text-gray-900">
                      {children}
                    </h2>
                    <div className="h-7 flex items-center">
                      <button
                        aria-label="Close panel"
                        className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150"
                        onClick={toggle}
                      >
                        <svg
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </header>
                <ul className="divide-y divide-gray-200 overflow-y-auto">
                  {data.map((job: Job) => (
                    <Item key={job._id} job={job} />
                  ))}
                </ul>
              </div>
            </Transition.Child>
          </section>
        </div>
      </div>
    </Transition>
  );
};

export default List;
