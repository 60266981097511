const Footer = () => {
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="xl:col-span-1">
            <img className="h-14" src="/logo.svg" alt="8Skills" />
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div className="mt-12 md:mt-0">
                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  Rechtliches
                </h4>
                <ul className="mt-4">
                  <li>
                    <a
                      href="https://www.convectum.at/impressum"
                      target="_blank"
                      className="text-base leading-6 text-gray-500 hover:text-gray-900"
                    >
                      Impressum
                    </a>
                  </li>
                  <li className="mt-4">
                    <a
                      href="https://www.convectum.at/datenschutz"
                      target="_blank"
                      className="text-base leading-6 text-gray-500 hover:text-gray-900"
                    >
                      Datenschutz
                    </a>
                  </li>
                  <li className="mt-4">
                    <a
                      href="https://www.convectum.at/agb"
                      target="_blank"
                      className="text-base leading-6 text-gray-500 hover:text-gray-900"
                    >
                      AGB
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-base leading-6 text-gray-400 xl:text-center">
            &copy; 2024 Convectum GmbH. Alle Rechte vorbehalten.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
