import {
  useEffect,
  useRef,
  useState,
  useCallback,
  MutableRefObject,
  RefObject,
} from "react";
import * as d3 from "d3";

import styled from "styled-components";
import { Popover } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";

import { Competence } from "../../types";
import { percentFormater, currencyFormater } from "../../config/i18n";

const SVG = styled.svg`
  path,
  line {
    fill: none;
    stroke: rgba(153, 153, 153, 0.2);
    stroke-width: 1px;
    shape-rendering: crispEdges;
  }
  .marker {
    line {
      stroke: #0028da;
      stroke-width: 1px;
      stroke-linecap: round;
    }
    text {
      fill: #0028da;
    }
  }
`;

interface MarkerItem {
  label: string;
  value: number;
}

interface Props {
  width: number;
  height: number;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  barColor?: string;
  readonly?: boolean;
  data: Competence[];
  markers?: MarkerItem[];
  revenue?: number;
  onChange?: (competences: Competence[]) => void;
  onDelete?: (competence: Competence) => void;
}

const ProfileChart = ({
  width,
  height,
  top = 20,
  right = 175,
  bottom = 20,
  left = 30,
  barColor = "#fecd1b",
  readonly = false,
  data,
  markers = [],
  revenue = 0,
  onChange,
  onDelete,
}: Props) => {
  const ref = useRef(null);
  // const draggedRef: RefObject<SVGCircleElement> = useRef(null);
  // const draggedRef = useRef<SVGCircleElement>(null);
  const draggedRef = useRef() as MutableRefObject<SVGCircleElement>;
  // const draggedRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState<[number, number]>([
    0,
    0,
  ]);
  const [
    selectedCompetence,
    setSelectedCompetence,
  ] = useState<Competence | null>(null);

  // useEffect(() => {
  //   const svg = d3.select(ref.current as any)
  //     .attr("width", width)
  //     .attr("height", height);
  //   const g = svg
  //     .append("g")
  //     .attr("transform", `translate(0,${height - 20})`);
  // }, [width, height]);

  /**
   * Scales
   */

  const yScale = d3
    .scaleLinear()
    .domain([0, 100])
    .range([height - bottom, 0]);

  const xScale = d3
    .scaleBand()
    .rangeRound([0, width])
    .padding(0.4)
    .domain(data.map((d) => d.skillId));

  /**
   * MouseHandler
   */

  const rectMouseOverHandler = useCallback((event: MouseEvent, d: any) => {
    const x = (xScale(d.skillId) as number) + xScale.bandwidth() / 2;
    const y = yScale(d.value);
    let html = d.skill?.name;

    if (revenue) {
      const sum = data.reduce((memo: any, item: any) => memo + item.value, 0);
      const percent = percentFormater.format(d.value / 100);
      const val = currencyFormater.format((d.value / sum) * revenue);
      html = `${d.skill?.name} ${percent} (${val})`;
    }

    d3.select("#tooltip")
      .classed("hidden", false)
      .transition()
      .style("left", `${x - 32}px`)
      .style("top", `${y + 12}px`);
    d3.select("#tooltip span").html(html);
  }, []);

  const rectMouseOutHandler = useCallback((event: MouseEvent) => {
    d3.select("#tooltip").classed("hidden", true);
  }, []);

  const rectClickHandler = useCallback((event: MouseEvent, d: any) => {
    const x = (xScale(d.skillId) as number) + xScale.bandwidth() / 2;
    const y = yScale(d.value);

    setShowPopover(true);
    setPopoverPosition([x, y]);
    setSelectedCompetence(d);
  }, []);

  const circleMouseOverHandler = useCallback((event: MouseEvent) => {
    d3.select(event.target as SVGCircleElement).attr("r", 7);
  }, []);

  const circleMouseOutHandler = useCallback((event: MouseEvent) => {
    d3.select(event.target as SVGCircleElement).attr("r", 5);
  }, []);

  /**
   * DragHandler
   * https://github.com/d3/d3-drag
   */
  const drag = () => {
    return d3
      .drag()
      .on("start", dragStartHandler)
      .on("drag", dragHandler)
      .on("end", dragEndHandler) as any;
  };

  const dragStartHandler = (event: any, d: any) => {
    const circle = d3.select(event.sourceEvent.target as SVGCircleElement);
    draggedRef.current = circle as any;
    circle.raise().attr("stroke", "#485155");
  };

  const dragHandler = (event: any, d: any) => {
    const circle = draggedRef.current as any;
    const rect = d3.select(circle.node().parentNode).select("rect");

    const cy = Math.max(0, Math.min(height - bottom, event.y + event.dy));
    circle.attr("cy", cy);
    rect.attr("y", cy);
    rect.attr("height", height - bottom - cy);
  };

  const dragEndHandler = useCallback(
    (event: any, d: any) => {
      (draggedRef.current as any) = null;

      const cy = Math.max(0, Math.min(height - bottom, event.y + event.dy));
      const value = yScale.invert(cy);
      const changedCompetence = {
        ...d,
        ...{ value },
      } as Competence;
      const competences = data.map((competence: any) => {
        const { __typename, ...obj } = competence;
        return obj;
      });
      const index = competences.findIndex(
        (competence: any) => competence.skillId === changedCompetence.skillId
      );
      const { __typename, ...obj } = changedCompetence as any;
      competences[index] = obj;

      console.log(
        "dragEndHandler",
        competences.map((d) => d.value)
      );
      onChange?.(competences);
    },
    [data]
  );

  /**
   * Draw the chart
   */
  const draw = () => {
    const svg = d3.select(ref.current);

    // Bind data values to bar elements
    const bar = svg.selectAll(".bar").data(data);

    /*
    const x1Scale = d3
      .scaleBand()
      .rangeRound([0, xScale.bandwidth()])
      .domain(["value"]);
    */

    /*
    const yScale = d3
      .scaleLinear()
      // .domain([0, d3.max(data, (d) => +d.value) as number])
      .domain([0, 100])
      .range([height - bottom, 0]);
      */

    // bar
    //   .transition()
    //   .duration(300)
    //   .attr("height", (d) => height - bottom - yScale(d.value))
    //   .attr("y", (d) => height - bottom - yScale(d.value));

    // Enter selection: append new bars as needed
    const barEnter = bar.enter().append("g").attr("class", "bar");

    // bar
    //   .on("mouseover", () => console.log("mouseover"))
    //   .on("mouseout", () => console.log("mouseout"));

    // Append rect
    barEnter
      .append("rect")
      .attr("x", (d) => xScale(d.skillId) as number)
      .attr("y", height - bottom)
      .attr("width", xScale.bandwidth())
      .attr("height", 0)
      //.attr("fill", (d) => d.skill.color || barColor)
      .attr("fill", (d) => barColor)
      //.attr("style", () => `fill: url(#stripes)`)
      .on("mouseover", rectMouseOverHandler)
      .on("mouseout", rectMouseOutHandler)
      .on("click", rectClickHandler)
      .transition()
      .duration(300)
      // .attr("x", (d) => x1Scale("value"))
      .attr("y", (d) => yScale(d.value))
      // .attr("width", x1Scale.bandwidth())
      .attr("height", (d) => height - bottom - yScale(d.value));

    // @ts-ignore
    // const barUpdate = barEnter.merge(bar);

    // barUpdate
    //   .attr("x", 0)
    //   .attr("y", 0)
    //   .attr("width", 0)
    //   .attr("height", 100);

    // Update selection
    bar
      .select("rect")
      .transition()
      .attr("x", (d) => xScale(d.skillId) as number)
      .attr("y", (d) => yScale(d.value))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => height - bottom - yScale(d.value));

    bar
      .select("circle")
      .call(drag())
      .transition()
      .attr("cx", (d) => (xScale(d.skillId) as number) + xScale.bandwidth() / 2)
      .attr("cy", (d) => yScale(d.value));

    // Append circle
    barEnter
      .append("circle")
      .attr("fill", "white")
      .attr("stroke", barColor)
      .attr("stroke-width", 2)
      .attr("cx", (d) => (xScale(d.skillId) as number) + xScale.bandwidth() / 2)
      .attr("cy", (d) => yScale(d.value))
      .attr("r", () => 5)
      .attr("cursor", "ns-resize")
      .on("mouseover", circleMouseOverHandler)
      .on("mouseout", circleMouseOutHandler)
      .call(drag());

    // Exit selection: remove bars as needed
    bar
      .exit()
      .transition()
      .duration(300)
      .attr("y", height)
      .attr("height", 0)
      .remove();

    // Axis
    const xAxis = d3
      .axisBottom(xScale)
      .tickFormat((val, index) => data[index]?.skill?.name);
    const yAxis = d3
      .axisLeft(yScale)
      .tickSize(-width)
      .tickFormat((val) => `${val}%`);
    svg
      .select(".x-axis")
      .attr("transform", `translate(0,${height - 20})`)
      .call(xAxis as any)
      .selectAll("text")
      .attr("text-anchor", "start")
      .attr("transform", "rotate (30 -10 0)");
    svg
      .select(".y-axis")
      // .attr("transform", "translate(50,0)")
      .call(yAxis as any)
      .append("text")
      .attr("y", 6)
      .attr("dy", "0.71em")
      .attr("text-anchor", "end")
      .text("value");

    // Marker
    const marker = svg.selectAll(".marker").data(markers);

    const markerEnter = marker.enter().append("g").attr("class", "marker");

    // Enter
    markerEnter
      .append("line")
      .attr("x1", 0)
      .attr("x2", width - 10)
      .attr("y1", (d) => yScale(d.value))
      .attr("y2", (d) => yScale(d.value))
      .attr("stroke-width", 2)
      .attr("stroke-dasharray", "1,8");

    // Update
    marker
      .select("line")
      .transition()
      .duration(500)
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", (d) => yScale(d.value))
      .attr("y2", (d) => yScale(d.value));

    // Enter
    markerEnter
      .append("text")
      .attr("x", width)
      .attr("y", (d) => yScale(d.value))
      .attr("dy", "0.4em")
      .attr("text-anchor", "start")
      .attr("font-size", "0.7em")
      .text((d) => `${percentFormater.format(d.value / 100)} ${d.label}`);

    // Update
    marker
      .select("text")
      .text((d) => `${percentFormater.format(d.value / 100)} ${d.label}`)
      .transition()
      .duration(500)
      .attr("x", width)
      .attr("y", (d) => yScale(d.value));

    // Exit
    marker
      .exit()
      .transition()
      .duration(300)
      .attr("y1", 0)
      .attr("y", 0)
      .remove();

    if (readonly) {
      barEnter.selectAll("circle").remove();
      barEnter.selectAll("rect").on("click", null);
    }
  };

  const drawHandler = useCallback(() => {
    draw();
  }, [data, markers]);

  useEffect(() => {
    drawHandler();
  }, [data, drawHandler]);

  return (
    <div className="relative">
      {/* Popover */}
      <Popover className="relative">
        <>
          {showPopover && (
            <div>
              <Popover.Panel
                static
                className="absolute z-10 px-4 mt-3 transform sm:px-0"
                style={{
                  left: `${popoverPosition[0]}px`,
                  top: `${popoverPosition[1]}px`,
                }}
              >
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                    <XIcon
                      className="h-5 w-5 text-gray-400 group-hover:text-gray-500 cursor-pointer"
                      onClick={() => setShowPopover(false)}
                    />
                    {selectedCompetence?.skill.name}
                  </div>
                  <div className="p-4 bg-gray-50">
                    {/* <span className="flex items-center">
                      <span className="text-sm font-medium text-gray-900">
                        {selectedSkill?.name}
                      </span>
                    </span>
                    <span className="block text-sm text-gray-500">
                      {selectedSkill?.description}
                    </span> */}

                    <button
                      className="inline-flex items-center h-7 pl-2.5 pr-2 rounded-lg text-xs text-white bg-red-600 hover:bg-red-500 border py-0"
                      onClick={() => {
                        onDelete?.(selectedCompetence as any);
                        setShowPopover(false);
                      }}
                    >
                      Löschen
                    </button>
                  </div>
                </div>
              </Popover.Panel>
            </div>
          )}
        </>
      </Popover>

      {/* Tooltip */}
      <div
        id="tooltip"
        className="hidden absolute bg-black text-white text-xs rounded w-32 py-1 px-4"
      >
        <span className="break-words"></span>
        <svg
          className="absolute text-black h-2 w-full left-0 top-full"
          x="0px"
          y="0px"
          viewBox="0 0 255 255"
          xmlSpace="preserve"
        >
          <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
        </svg>
      </div>

      <SVG width={width + right} height={height + top + bottom + 40}>
        <g ref={ref} transform={`translate(${left},${top})`}>
          <g className="x-axis" transform={`translate(0,${height})`}></g>
          <g className="y-axis" transform={`translate(${left},0)`}></g>
        </g>

        <defs>
          <pattern
            id="stripes"
            width="100"
            height="10"
            patternUnits="userSpaceOnUse"
            patternTransform="rotate(45)"
          >
            <rect
              fill={barColor}
              strokeWidth="1"
              fillOpacity="0.8"
              width="100"
              height="5"
            ></rect>
          </pattern>
        </defs>
      </SVG>
    </div>
  );
};

export default ProfileChart;
