import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Spring, config } from "react-spring/renderprops";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";

import { FormData } from "../Filter";

interface Props {
  data: any;
  hasChildren?: boolean;
  onClick?: () => void;
  filterData?: FormData;
}

const JobNode = ({
  data,
  hasChildren = false,
  onClick,
  filterData,
}: Props) => {
  const outer = useRef<HTMLDivElement>(null);
  const [x, setX] = useState(data.x);

  const { data: job } = data;

  useEffect(() => {
    // Run once, after mounting
    // inner.current?.getBoundingClientRect()?.left;
    const width = outer.current?.clientWidth;
    if (width) {
      setX(width / 2);
    }
  }, [data]);

  return (
    <Spring
      config={config.stiff}
      from={{ left: data.x0, top: data.y0, opacity: 0 }}
      to={{ left: data.x, top: data.y, opacity: 1 }}
    >
      {(props) => (
        <div
          ref={outer}
          className={`absolute p-1 bg-white ${
            data.found === true ? "border-blue-200" : "border-gray-200"
          } border rounded-md shadow-sm text-xs`}
          style={{ left: props.left, top: props.top, marginLeft: -x }}
        >
          <Link
            to={`/jobs/${job?._id}/details`}
            className="block text-center p-1"
          >
            {job?.name}
          </Link>

          <button
            className="w-full flex justify-center rounded-md hover:bg-gray-100"
            onClick={() => {
              onClick?.();
            }}
          >
            {hasChildren ? (
              <ChevronUpIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600" />
            ) : (
              job?.children?.length > 0 && (
                <ChevronDownIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600" />
              )
            )}
          </button>
        </div>
      )}
    </Spring>
  );
};

export default JobNode;
