import { useFormContext, Controller } from "react-hook-form";

import SkillScaleSelect from "./SkillScaleSelect";

const SkillScaleField = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="skill-scale-id"
        className="block text-sm font-medium leading-5 text-gray-900"
      >
        Kompetenzskalierung
      </label>
      <Controller
        control={control}
        name="skillScaleId"
        render={({ field }) => <SkillScaleSelect {...field} />}
        rules={{ required: false }}
      />
      {errors.skillScaleId && (
        <span className="text-sm font-light text-red-500">
          Kompetenzskalierung fehlt
        </span>
      )}
    </>
  );
};

export default SkillScaleField;
