import React from "react";
import clsx from "clsx";
import { NavLink as ReactRouterNavLink } from "react-router-dom";
import type { NavLinkProps } from "react-router-dom";

import { usePermissions } from "hooks/usePermissions";
import { scope } from "types/permission";

export interface IProps extends NavLinkProps {
  module?: string;
  scopeRequired?: scope,
}

export const NavLink: React.FC<IProps> = ({
  module,
  scopeRequired,
  ...props
}) => {
  const { hasReadScope, loading } = usePermissions();

  const disabled = module ? (loading || !hasReadScope(module, scopeRequired)) : false;

  if (disabled) {
    return (
      <span
        className={clsx(props.className, "text-opacity-25 cursor-not-allowed")}
      >
        {props.children}
      </span>
    );
  }

  return <ReactRouterNavLink { ...props } />;
};
