import { useFormContext } from "react-hook-form";

const FirstNameField = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="first-name"
        className="block text-sm font-medium leading-5 text-gray-900"
      >
        Vorname
      </label>
      <input
        type="text"
        id="first-name"
        {...register("firstName", { required: true })}
        autoComplete="given-name"
        className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      />

      {errors.firstName && (
        <span className="text-sm font-light text-red-500">
          Vorname fehlt
        </span>
      )}
    </>
  );
};

export default FirstNameField;
