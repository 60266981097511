import { useMemo } from "react";
import { DragSourceMonitor, useDrag } from "react-dnd";

import { ItemTypes } from "../../../config/ItemTypes";
import { Employee } from "../../../types";

import { FormData } from "../Filter";
import { findMatches } from "../TreeUtils";

import Avatar from "../../employees/Avatar";
import EmployeeTooltip from "./EmployeeTooltip";
import Stats from "./Stats";

interface Props {
  employee: Employee;
  filterData?: FormData;
}

const EmployeeItem = ({ employee, filterData }: Props) => {
  const [collected, drag] = useDrag({
    item: { type: ItemTypes.EMPLOYEE, employee: employee },
    options: {
      dropEffect: "copy",
    },
    end: (item: { type: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      console.log(collected.isDragging);

      if (item && dropResult) {
        console.log(
          `You dropped ${(item as any).employee.firstName} into ${
            dropResult.name
          }!`
        );
      }
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging(),
    }),
  });

  const hasMatches = useMemo(
    () => (): boolean => {
      return findMatches(filterData, employee);
    },
    [employee, filterData]
  );

  return (
    <div
      ref={drag}
      {...collected}
      className="p-2 mt-2 flex flex-col items-center cursor-move border-t border-neutral-300"
    >
      <div className="mb-3 flex flex-row">
        <Avatar employee={employee} className="flex-none w-8 h-8" />
        <div className="ml-2">
          <div className="text-sm font-medium leading-none text-black">
            <EmployeeTooltip employee={employee}>
              <>
                {employee.firstName} {employee.lastName}
              </>
            </EmployeeTooltip>
            {hasMatches() && (
              <span className="flex h-3 w-3 ml-1 relative float-right">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-draintree-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-draintree-500"></span>
              </span>
            )}
          </div>
          <div className="text-xs font-medium text-gray-400">
            {employee.job?.name}
          </div>
        </div>
      </div>
      <Stats
        showMetrics={["h", "t", "ef", "mu", "c"]}
        humatics={employee?.humatics}
        jobHumatics={employee?.job?.humatics}
        competenceCoverage={employee?.competenceCoverage}
      />
    </div>
  );
};

export default EmployeeItem;
