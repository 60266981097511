import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Transition } from "@headlessui/react";
import styled, { css } from "styled-components";
import clsx from "clsx";
import { useIntl } from "react-intl";

import Arc from "../charts/Arc";
import MetricTooltip from "../competences/MetricTooltip";

import { IS_CONCEALED } from "../../schema/settings";
import { numberFormater, currencyFormater } from "../../config/i18n";
import { Humatics } from "../../types";

interface BoxProps {
  colorGradient?: boolean | false;
}

const Box = styled.div.attrs({
  className:
    "bg-white shadow rounded-lg w-auto flex-auto inline-flex flex-col content-start justify-between m-2",
})`
  ${(props: BoxProps) =>
    props.colorGradient &&
    css`
      position: relative;

      &:after {
        position: absolute;
        left: 1%;
        bottom: 85px;
        content: "";
        width: 98%;
        height: 3px;
        background: #df3341;
        background: -moz-linear-gradient(
          left,
          #df3341 0%,
          #d4f355 50%,
          #61c0ec 100%
        );
        background: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(0%, #df3341),
          color-stop(50%, #d4f355),
          color-stop(100%, #61c0ec)
        );
        background: -webkit-linear-gradient(
          left,
          #df3341 0%,
          #d4f355 50%,
          #61c0ec 100%
        );
        background: -o-linear-gradient(
          left,
          #df3341 0%,
          #d4f355 50%,
          #61c0ec 100%
        );
        background: -ms-linear-gradient(
          left,
          #df3341 0%,
          #d4f355 50%,
          #61c0ec 100%
        );
        background: linear-gradient(
          to right,
          #df3341 0%,
          #d4f355 50%,
          #61c0ec 100%
        );
      }
    `}
`;

interface Props {
  title?: string;
  humatics: Humatics;
  actualRevenue?: number;
  targetRevenue?: number;
  knowledgeValue?: number;
  competenceCoverage?: number;
}

const Stats = ({
  title,
  humatics,
  actualRevenue,
  targetRevenue,
  knowledgeValue,
  competenceCoverage,
}: Props) => {
  const { data: settingsData } = useQuery(IS_CONCEALED);
  const [isOpen, setIsOpen] = useState(true);

  const { formatMessage: f } = useIntl();

  const valueClassName = clsx(
    "mt-1 text-2xl md:text-3xl leading-9 font-semibold text-gray-900",
    {
      concealed: settingsData.isConcealed,
    }
  );

  return (
    <div className="p-6 bg-gray-50 rounded-lg">
      <h3
        className="text-gray-500 text-xs font-medium uppercase tracking-wide cursor-pointer hover:underline"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title || f({ id: "stats.name" })}
      </h3>
      <Transition show={isOpen}>
        <div className="mt-5 flex-col">
          <Box>
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500">
                  <MetricTooltip text={f({ id: "humatics.h.description" })}>
                    <>{f({ id: "humatics.h" })}</>
                  </MetricTooltip>
                </dt>
                <dd className={valueClassName} title={`${humatics?.h}`}>
                  {numberFormater.format(humatics?.h)}
                </dd>
              </dl>
              <span className="text-sm leading-7 font-light text-gray-500 truncate">
                {humatics?.h2 != null && (
                  <>
                    {f({ id: "target" })}: {numberFormater.format(humatics.h2)}
                  </>
                )}
              </span>
            </div>
          </Box>
          <Box colorGradient>
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500">
                  <MetricTooltip text={f({ id: "humatics.t.description" })}>
                    <>{f({ id: "humatics.t" })}</>
                  </MetricTooltip>
                </dt>
                <dd className={valueClassName} title={`${humatics?.t}`}>
                  {numberFormater.format(humatics?.t)}
                </dd>
              </dl>
              <span className="text-sm leading-7 font-light text-gray-500 truncate">
                {humatics?.t2 != null && (
                  <>
                    {f({ id: "target" })}: {numberFormater.format(humatics.t2)}
                  </>
                )}
              </span>
            </div>
          </Box>
          <Box>
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500">
                  <MetricTooltip text={f({ id: "humatics.ef.description" })}>
                    <>{f({ id: "humatics.ef" })}</>
                  </MetricTooltip>
                </dt>
                <dd className={valueClassName} title={`${humatics?.ef}`}>
                  {numberFormater.format(humatics?.ef)}
                </dd>
              </dl>
            </div>
          </Box>
          <Box>
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500">
                  <MetricTooltip text={f({ id: "humatics.mu.description" })}>
                    <>{f({ id: "humatics.mu" })}</>
                  </MetricTooltip>
                </dt>
                <dd className={valueClassName} title={`${humatics?.mu}`}>
                  {numberFormater.format(humatics?.mu)}
                </dd>
                <span className="text-sm leading-7 font-light text-gray-500 truncate">
                  {humatics?.mu2 != null && (
                    <>
                      {f({ id: "target" })}:{" "}
                      {numberFormater.format(humatics.mu2)}
                    </>
                  )}
                </span>
              </dl>
            </div>
          </Box>
          {competenceCoverage != null && (
            <Box>
              <div className="px-4 py-5 sm:p-6">
                <dl>
                  <dt className="text-sm leading-5 font-medium text-gray-500">
                    <MetricTooltip
                      text={f({ id: "competencecoverage.description" })}
                    >
                      <>{f({ id: "competencecoverage" })}</>
                    </MetricTooltip>
                  </dt>
                  <dd className={valueClassName}>
                    <div className="w-12 h-12 m-3">
                      <Arc
                        width={50}
                        height={50}
                        value={competenceCoverage * 100}
                      />
                    </div>
                  </dd>
                </dl>
              </div>
            </Box>
          )}
          {(actualRevenue != null || targetRevenue != null) && (
            <Box>
              <div className="px-4 py-5 sm:p-6">
                <dl>
                  <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                    {f({ id: "revenues.year.actual" })}
                  </dt>
                  <dd className={valueClassName}>
                    {actualRevenue != null &&
                      currencyFormater.format(actualRevenue)}
                  </dd>
                  <span className="text-sm leading-7 font-light text-gray-500 truncate">
                    {targetRevenue != null && (
                      <>
                        {f({ id: "target" })}:{" "}
                        {currencyFormater.format(targetRevenue)}
                      </>
                    )}
                  </span>
                </dl>
              </div>
            </Box>
          )}
          {knowledgeValue != null && (
            <Box>
              <div className="px-4 py-5 sm:p-6">
                <dl>
                  <dt className="text-sm leading-5 font-medium text-gray-500">
                    <MetricTooltip text={f({ id: "humatics.m.description" })}>
                      <>{f({ id: "humatics.m" })}</>
                    </MetricTooltip>
                  </dt>
                  <dd className={valueClassName}>
                    {knowledgeValue != null
                      ? currencyFormater.format(knowledgeValue)
                      : null}
                  </dd>
                </dl>
              </div>
            </Box>
          )}
        </div>
      </Transition>
    </div>
  );
};

export default Stats;
