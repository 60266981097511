import { gql } from "@apollo/client";

export const ORGANIZATION_MANY = gql`
  query {
    organizationMany {
      name
      description
      managerId
      manager {
        firstName
        lastName
        photo
      }
      revenues {
        year
        target
        actual
      }
      _id
    }
  }
`;

export const ORGANIZATION_BY_ID = gql`
         query($_id: ID!) {
           organizationById(_id: $_id) {
             name
             description
             managerId
             revenues {
               year
               target
               actual
             }
             employeeCount
             requiredEmployeeCount
             knowledgeValue
             totalSalary
             requiredSalary
             actualRevenue
             annualizedRevenue
             targetRevenue
             humatics {
               competenceValues
               employeesH
               m
               h
               t
               phi
               r
               ef
               s
             }
             jobHumatics {
               employeesH
               h
               t
               phi
               r
               ef
               s
             }
             _id
           }
         }
       `;

export const ORGANIZATION_CREATE_ONE = gql`
  mutation ($name: String!, $description: String, $managerId: ID) {
    organizationCreateOne(
      input: { name: $name, description: $description, managerId: $managerId }
    ) {
      _id
      name
    }
  }
`;

export const ORGANIZATION_UPDATE_BY_ID = gql`
  mutation (
    $_id: ID!
    $name: String
    $description: String
    $managerId: ID
    $revenues: [RevenueInput]
  ) {
    organizationUpdateById(
      input: {
        name: $name
        description: $description
        managerId: $managerId
        revenues: $revenues
      }
      _id: $_id
    ) {
      _id
      name
    }
  }
`;

export const ORGANIZATION_REMOVE_BY_ID = gql`
  mutation ($_id: ID!) {
    organizationRemoveById(_id: $_id) {
      name
    }
  }
`;

export const ORGANIZATION_KNOWLEDGE_VALUE = gql`
  query {
    organizationKnowledgeValue
  }
`;

export const ORGANIZATION_TREE = gql`
  query ($_id: ID!) {
    organizationTree(_id: $_id) {
      ...ChildrenFields
      children {
        ...ChildrenFields
        children {
          ...ChildrenFields
          children {
            ...ChildrenFields
            children {
              ...ChildrenFields
            }
          }
        }
      }
    }
  }
  fragment ChildrenFields on OrganizationTree {
    _id
    name
    parentId
    organizationId
    employees {
      _id
      firstName
      lastName
      photo
      retireDate
      terminationDate
      age
      seniority
      competences {
        skillId
        skill {
          name
        }
        value
      }
      humatics {
        h
        t
        ef
        r
        mu
      }
      job {
        name
        humatics {
          h
          t
          ef
          r
          mu
        }
      }
      competenceCoverage
    }
    employeeCount
    humatics {
      employeesH
      h
      t
      ef
      phi
      r
    }
    jobHumatics {
      employeesH
      h
      t
      ef
      phi
      r
    }
  }
`;
