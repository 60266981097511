import { ResponsiveBar } from "@nivo/bar";
import { percentFormater } from "../../config/i18n";

interface Props {
  data: object[];
}

const MicroChart = ({ data = [] }: Props) => {
  return (
    <ResponsiveBar
      data={data}
      keys={["value"]}
      indexBy="skillId"
      padding={0.2}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={["#b39a40", "#485155"]}
      tooltip={({ value, data }) => (
        <div className="flex flex-col items-center text-center text-xs p-0.5">
          <span className="font-semibold">{(data as any).skill?.name}:</span>
          {percentFormater.format(value / 100)}
        </div>
      )}
      enableLabel={false}
      enableGridY={false}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  );
};

export default MicroChart;
