import { useLazyQuery } from "@apollo/client";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { useEffect } from "react";

import { SKILL_LEVEL_BY_ID } from "../../../schema/skillLevel";
import { Skill, SkillLevel } from "../../../types";

interface Props {
  skillLevel: SkillLevel;
  onSelect?: (skills: Skill[]) => void;
}

const Item = ({ skillLevel, onSelect }: Props) => {
  const [getSkillLevel, { data }] = useLazyQuery(SKILL_LEVEL_BY_ID, {
    variables: {
      _id: skillLevel._id,
    },
  });

  useEffect(() => {
    if (data) {
      onSelect?.(data.skillLevelById?.skills);
    }
  }, [data]);

  return (
    <li className="px-6 py-5 relative cursor-pointer">
      <div className="group flex justify-between items-center space-x-2">
        <div
          className="-m-1 p-1 block overflow-hidden"
          onClick={() => getSkillLevel({ variables: { _id: skillLevel._id } })}
        >
          <span className="absolute inset-0 group-hover:bg-gray-50"></span>
          <div className="flex-1 flex items-center min-w-0 relative">
            <div className="ml-4 truncate">
              <div className="text-sm leading-5 font-medium text-gray-900 truncate">
                {skillLevel.name}
              </div>
              <div className="text-sm leading-5 text-gray-500 truncate">
                {skillLevel.description}
              </div>
            </div>
          </div>
        </div>
        <div className="relative inline-block text-left">
          <ChevronRightIcon className="h-5 w-5" />
        </div>
      </div>
    </li>
  );
};

export default Item;
