import { AnyNsRecord } from "dns";
import { useForm } from "react-hook-form";
import { Translation, TranslationLocales } from "../../types";

interface Props {
  onSubmit: (formData: any) => void;
  data?: Translation;
}

export type FormData = {
  name: string;
  locales: TranslationLocales;
};

const Form = ({ onSubmit, data }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ defaultValues: data });

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="slide-over-form">
      <div className="flex-1 flex flex-col justify-between">
        <div className="px-4 divide-y divide-gray-200 sm:px-6">
          <div className="space-y-6 pt-6 pb-5">
            <div className="space-y-1">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                {...register("name", { required: true })}
                className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />

              {errors.name && (
                <span className="text-sm font-light text-red-500">
                  Name fehlt
                </span>
              )}
            </div>

            <hr className="my-6 border-2 rounded-full border-gray-200" />

            <div className="space-y-1">
              <label
                htmlFor="locales-de"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Deutsch
              </label>

              <div className="mt-1">
                <input
                  type="text"
                  id="locales-de"
                  {...register("locales.de", { required: true })}
                  className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="space-y-1">
              <label
                htmlFor="locales-en"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Englisch
              </label>

              <div className="mt-1">
                <input
                  type="text"
                  id="locales-en"
                  {...register("locales.en", { required: false })}
                  className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="space-y-1">
              <label
                htmlFor="locales-it"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Italienisch
              </label>

              <div className="mt-1">
                <input
                  type="text"
                  id="locales-it"
                  {...register("locales.it", { required: false })}
                  className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="space-y-1">
              <label
                htmlFor="locales-ru"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Russisch
              </label>

              <div className="mt-1">
                <input
                  type="text"
                  id="locales-ru"
                  {...register("locales.ru", { required: false })}
                  className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="space-y-1">
              <label
                htmlFor="locales-tr"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Türkisch
              </label>

              <div className="mt-1">
                <input
                  type="text"
                  id="locales-tr"
                  {...register("locales.tr", { required: false })}
                  className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="space-y-1">
              <label
                htmlFor="locales-ar"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Arabisch
              </label>

              <div className="mt-1">
                <input
                  type="text"
                  id="locales-ar"
                  {...register("locales.ar", { required: false })}
                  className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="space-y-1">
              <label
                htmlFor="locales-zh"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Chinesisch
              </label>

              <div className="mt-1">
                <input
                  type="text"
                  id="locales-zh"
                  {...register("locales.zh", { required: false })}
                  className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
