import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { Seminar, Employee } from "../../types";
import Avatar from "../employees/Avatar";

interface Props {
  seminar: Seminar;
}

const Item = ({ seminar }: Props) => {
  return (
    <tr>
      <td className="px-6 py-4">
        <div className="flex items-center">
          <Link to={`/seminars/${seminar?._id}/details`} className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {seminar?.name}
            </div>
            <p className="text-sm text-gray-500 overflow-ellipsis">
              {seminar?.description}
            </p>
          </Link>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-500">
          {seminar.participantIds?.length} Teilnehmer
        </div>

        <div className="pt-2 px-6 flex items-center flex-no-wrap">
          {seminar.participants.map((employee: Employee) => (
            <Link
              key={employee._id}
              to={`/employees/${employee._id}/details`}
              className="w-12 h-12 bg-cover bg-center rounded-md -ml-2"
            >
              <Avatar
                employee={employee}
                className="h-full w-full overflow-hidden object-cover rounded-full border-2 border-white dark:border-gray-700 shadow"
              />
            </Link>
          ))}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {seminar?.createdAt &&
          format(parseISO(seminar.createdAt), "do MMMM", {
            locale: de,
          })}
      </td>
    </tr>
  );
};

export default Item;
