// import { Link } from "react-router-dom";
import { File } from "../../types";

interface Props {
  file: File;
  onDelete?: (file: File) => void;
}

const Item = ({ file, onDelete }: Props) => {
  return (
    <li className="flex items-center px-4 py-3">
      <a href={file.url} className="ml-3 text-base text-gray-900 flex-grow">
        {file.name}
      </a>
      <a
        href={file.url}
        className="ml-auto text-sm font-medium text-gray-900 px-2 flex-grow-0"
      >
        Download
      </a>
      <button
        type="button"
        className="ml-auto text-sm font-medium text-gray-900 flex-grow-0"
        onClick={() => {
          onDelete?.(file);
        }}
      >
        Löschen
      </button>
    </li>
  );
};

export default Item;
