import { ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { useReactiveVar, useLazyQuery } from "@apollo/client";
import { useIntl } from "react-intl";

import { FILTER_EMPLOYEE_MANY } from "../../schema/employee";
import { FILTER_JOB_MANY } from "../../schema/job";

import { Unit } from "../../types";
import { selectedUnitsVar } from "../../cache";

import SliderOverContext from "../../context/SliderOverContext";
import EmployeeSlideOverList from "../employees/SliderOverList/SlideOverList";
import JobSlideOverList from "../jobs/SliderOverList/SlideOverList";
import useToggle from "../../hooks/useToogle";
import Avatar from "../employees/Avatar";
import { Loading, Error } from "../ui/Alerts";

interface Props {
  unit: Unit;
}

const Item = ({ unit }: Props) => {
  const { formatMessage: f } = useIntl();
  const selectedUnits = useReactiveVar(selectedUnitsVar);

  const [employeeOpen, employeeToggle] = useToggle();
  const [jobOpen, jobToggle] = useToggle();

  const [
    getEmployees,
    { loading: employeeLoading, error: employeeError, data: employeeData },
  ] = useLazyQuery(FILTER_EMPLOYEE_MANY, {
    variables: { unitId: unit._id },
  });
  const [getJobs, { loading: jobLoading, error: jobError, data: jobData }] =
    useLazyQuery(FILTER_JOB_MANY, {
      variables: { unitId: unit._id },
    });

  const onClickEmployees = () => {
    employeeToggle();
    getEmployees({ variables: { unitId: unit._id } });
  };

  const onClickJobs = () => {
    jobToggle();
    getJobs({ variables: { unitId: unit._id } });
  };

  const onCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const newIds = selectedUnits?.includes(unit._id)
      ? selectedUnits?.filter((id) => id !== unit._id)
      : [...(selectedUnits ?? []), unit._id];
    selectedUnitsVar(newIds);
  };

  return (
    <tr>
      <td className="px-6 py-4">
        <input
          type="checkbox"
          className="cursor-pointer relative w-5 h-5 border rounded border-gray-400 dark:border-gray-200 bg-white dark:bg-gray-800 outline-none"
          checked={selectedUnits.includes(unit._id)}
          onChange={onCheck}
        />
      </td>
      <td className="px-6 py-4">
        <div className="flex items-center">
          <Link to={`/units/${unit._id}/details`} className="ml-4">
            <div className="text-sm font-medium text-gray-900">{unit.name}</div>
            <div className="text-sm text-gray-500">{unit.description}</div>
          </Link>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        {/* Employee SlideOver */}
        <SliderOverContext.Provider
          value={{ open: employeeOpen, toggle: employeeToggle }}
        >
          <EmployeeSlideOverList data={employeeData?.employeeMany}>
            {employeeLoading && <Loading />}
            {employeeError && <Error error={employeeError} />}
            Mitarbeiter in Einheit
            <br />
            <mark>{unit.name}</mark>
          </EmployeeSlideOverList>
        </SliderOverContext.Provider>
        <button
          className="text-sm text-gray-900 cursor-pointer hover:underline disabled:opacity-50"
          disabled={unit.employeeCount == 0}
          onClick={onClickEmployees}
        >
          {unit.employeeCount} {f({ id: "employees.name" })}
        </button>
        <br />

        {/* Job SlideOver */}
        <SliderOverContext.Provider
          value={{ open: jobOpen, toggle: jobToggle }}
        >
          <JobSlideOverList data={jobData?.jobMany}>
            {jobLoading && <Loading />}
            {jobError && <Error error={jobError} />}
            Arbeitsplätze in Einheit
            <br />
            <mark>{unit.name}</mark>
          </JobSlideOverList>
        </SliderOverContext.Provider>
        <button
          className="text-sm text-gray-900 cursor-pointer hover:underline disabled:opacity-50"
          disabled={unit.jobCount == 0}
          onClick={onClickJobs}
        >
          {unit.jobCount} {f({ id: "jobs.name" })}
        </button>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        {unit.managerId && (
          <div className="flex items-center">
            <div>
              <Link
                to={`/employees/${unit.managerId}/profile`}
                className="text-xs leading-4 font-medium text-gray-500 group-hover:text-gray-700 group-focus:underline transition ease-in-out duration-150"
              >
                <Avatar employee={unit.manager} className="w-10 h-10" />
              </Link>
            </div>
            <div className="ml-3">
              <p className="text-sm leading-5 font-medium text-gray-700 group-hover:text-gray-900">
                {unit.manager?.firstName} {unit.manager?.lastName}
              </p>
              <Link
                to={`/employees/${unit.managerId}/profile`}
                className="text-xs leading-4 font-medium text-gray-500 group-hover:text-gray-700 group-focus:underline transition ease-in-out duration-150"
              >
                Profil anzeigen
              </Link>
            </div>
          </div>
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <Link
          to={`/org/${unit.organizationId}/details`}
          className="text-sm font-medium text-gray-900"
        >
          {unit.organization?.name}
        </Link>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <Link
          to={`/units/${unit.parentId}/details`}
          className="text-sm font-medium text-gray-900"
        >
          {unit.parent?.name}
        </Link>
      </td>
    </tr>
  );
};

export default Item;
