import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Spring, config } from "react-spring/renderprops";

import Stats from "./Stats";

interface Props {
  data: any;
}

const OrganizationNode = ({ data }: Props) => {
  const outer = useRef<HTMLDivElement>(null);
  const [x, setX] = useState(data.x);

  const { data: organization } = data;

  useEffect(() => {
    // Run once, after mounting
    // inner.current?.getBoundingClientRect()?.left;
    const width = outer.current?.clientWidth;
    if (width) {
      setX(width / 2);
    }
  }, [data]);

  return (
    <Spring
      config={config.stiff}
      from={{ left: data.x0, top: data.y0, opacity: 0 }}
      to={{ left: data.x, top: data.y, opacity: 1 }}
    >
      {(props) => (
        <div
          ref={outer}
          className={`absolute p-1 bg-white ${
            data.found === true ? "border-blue-200" : "border-gray-200"
          } border rounded-md shadow-sm text-xs`}
          style={{ left: props.left, top: props.top, marginLeft: -x }}
        >
          <Link
            to={`/org/${organization?._id}/details`}
            className="block text-center p-1"
          >
            {organization?.name}
          </Link>

          <Stats
            showMetrics={["employeesH", "t", "phi", "r"]}
            humatics={organization?.humatics}
          />
        </div>
      )}
    </Spring>
  );
};

export default OrganizationNode;
