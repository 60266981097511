// https://www.apollographql.com/docs/tutorial/local-state/
import { InMemoryCache, makeVar } from "@apollo/client";

export const cache: InMemoryCache = new InMemoryCache({
  // addTypename: false,
  typePolicies: {
    // Employee: {
    //   // keyFields: ["_id"],
    //   fields: {
    //     files: {
    //       merge(existing = [], incoming: any[]) {
    //         return [...existing, ...incoming];
    //       },
    //     },
    //   },
    // },
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
        role: {
          read() {
            return roleVar();
          },
        },
        isConcealed: {
          read() {
            return isConcealedVar();
          },
        },
        locale: {
          merge(_prev, next) {
            console.log("set locale", next);
            return next;
          },
          read() {
            return localeVar();
          },
        },
        selectedUnits: {
          // overwrite previous array when updating
          merge(_prev, next) {
            return next;
          },
          read() {
            return selectedUnitsVar() ?? [];
          },
        },
        selectedEmployees: {
          // overwrite previous array when updating
          merge(_prev, next) {
            return next;
          },
          read() {
            return selectedEmployeesVar() ?? [];
          },
        },
      },
    },
  },
});

// Initializes to true if localStorage includes a 'token' key,
// false otherwise
export const isLoggedInVar = makeVar<boolean>(!!localStorage.getItem("token"));

// Initializes to true if localStorage includes a 'token' key,
// false otherwise
export const roleVar = makeVar<string>(localStorage.getItem("role") || "");

export const isConcealedVar = makeVar<boolean>(
  localStorage.getItem("concealed") === "true"
);

export const localeVar = makeVar<string>(
  localStorage.getItem("locale") || "de"
);

// Initializes to an empty array
export const selectedUnitsVar = makeVar<string[]>([]);
export const selectedEmployeesVar = makeVar<string[]>([]);
