import { createContext } from "react";

type ContextType = {
  open: boolean;
  toggle: () => void;
};

const FilterContext = createContext<ContextType>({
  open: false,
  toggle: () => {},
});

export default FilterContext;
