import { Employee } from "../../types";
import { UserIcon } from "@heroicons/react/solid";

interface Props {
  employee?: Employee;
  className?: string;
}

const getInitials = (string: string) =>
  string
    .split(" ")
    .map(([firstLetter]: any) => firstLetter)
    .filter((_, index, array) => index === 0 || index === array.length - 1)
    .join("")
    .toUpperCase();

const Avatar = ({ employee, className }: Props) => {
  const initials = getInitials(`${employee?.firstName} ${employee?.lastName}`);

  return employee?.photo ? (
    <img
      className={`bg-gray-300 rounded-full flex-shrink-0 ${className}`}
      src={employee?.photo}
      alt=""
    />
  ) : employee?.firstName || employee?.lastName ? (
    <span
      className={`inline-flex items-center justify-center rounded-full bg-gray-400 ${className}`}
    >
      <span className="text-sm font-medium leading-none text-white">
        {initials}
      </span>
    </span>
  ) : null;
};

export default Avatar;
