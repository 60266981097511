import { useEffect } from "react";
import {
  useParams,
  useHistory,
  match,
  RouteComponentProps,
} from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";

import { VACANCY_BY_ID } from "../../schema/vacancy";

import { Loading, Error } from "../ui/Alerts";
import List from "../matches/List";
import { Divider } from "../ui/layout/Divider";

interface Props {
  location: RouteComponentProps<any>["location"];
  match?: match<ParamTypes>;
}

interface ParamTypes {
  id: string;
}

const Match = ({ location }: Props) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (!id) {
      history.push("/");
      return;
    }
  }, [id, history]);

  const { loading, error, data } = useQuery(VACANCY_BY_ID, {
    variables: {
      _id: id,
    },
  });

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      <div className="pb-6">
        <div className="inline-flex items-center text-sm text-gray-500 leading-none pb-2">
          <span>{f({ id: "vacancies.match" })}</span>
        </div>
        <div className="flex items-center">
          <h1 className="px-2 text-2xl text-gray-900 font-extrabold">
            {data?.vacancyById.name}
          </h1>
        </div>
      </div>

      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
        {`${data?.vacancyById.matches.applicants.length} Ergebnisse`}
      </h2>
      <List data={data?.vacancyById.matches.applicants} type="applicants" />

      <div className="py-4">
        <Divider>
          <Divider.Content>{f({ id: "employees.name" })}</Divider.Content>
        </Divider>
      </div>

      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
        {`${data?.vacancyById.matches.employees.length} Ergebnisse`}
      </h2>
      <List data={data?.vacancyById.matches.employees} type="employees" />
    </>
  );
};

export default Match;
