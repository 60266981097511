import Tooltip from "../ui/overlays/Tooltip";

interface Props {
  children: React.ReactNode;
  text: string;
}

const MetricTooltip = ({ children, text }: Props) => {
  return (
    <div className="flex items-center align-middle relative">
      {children}
      <Tooltip>
        <p className="text-sm font-medium text-gray-800 pb-1 normal-case tracking-normal">
          {text}
        </p>
      </Tooltip>
    </div>
  );
};

export default MetricTooltip;
