import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  OfficeBuildingIcon,
  CubeIcon,
  CashIcon,
} from "@heroicons/react/outline";
import { ShareIcon } from "@heroicons/react/solid";

const Tabs = () => {
  const { formatMessage: f } = useIntl();
  return (
    <div>
      <div className="sm:hidden">
        <select
          className="form-select block w-full"
          defaultValue="Organisation"
        >
          <option>{f({ id: "organizations.name" })}</option>
          <option>{f({ id: "units.name" })}</option>
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="flex -mb-px">
            <Link
              to="/org"
              className="group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              <OfficeBuildingIcon className="mr-1 h-6 w-6 text-gray-400 group-hover:text-gray-500" />
              <span>{f({ id: "organizations.name" })}</span>
            </Link>
            <Link
              to="/units"
              className="ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              <CubeIcon className="mr-1 h-6 w-6 text-gray-400 group-hover:text-gray-500" />
              <span>{f({ id: "units.name" })}</span>
            </Link>
            <Link
              to="/orgchart"
              className="ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              <ShareIcon className="mr-1 h-6 w-6 text-gray-400 group-hover:text-gray-500 origin-center transform rotate-90" />
              <span>{f({ id: "orgchart.name" })}</span>
            </Link>
            <Link
              to="/revenues"
              className="ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              <CashIcon className="mr-1 h-6 w-6 text-gray-400 group-hover:text-gray-500" />
              <span>Umsatzmatrix</span>
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
