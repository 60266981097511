import { useState } from "react";
import { Transition } from "@headlessui/react";
import { useIntl } from "react-intl";

import MetricTooltip from "../competences/MetricTooltip";
import { currencyFormater } from "../../config/i18n";

interface Props {
  supply: number;
  demand: number;
}

const Stats = ({ supply, demand }: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const { formatMessage: f } = useIntl();

  return (
    <div className="p-6 bg-gray-50 rounded-lg">
      <h3
        className="text-gray-500 text-xs font-medium uppercase tracking-wide cursor-pointer hover:underline"
        onClick={() => setIsOpen(!isOpen)}
      >
        {f({ id: "metrics.name" })}
      </h3>
      <Transition show={isOpen}>
        <div className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
          <div className="bg-white shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500">
                  <MetricTooltip
                    text={f({ id: "skills.supply.projected.description" })}
                  >
                    <>{f({ id: "skills.supply" })}</>
                  </MetricTooltip>
                </dt>
                <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                  {currencyFormater.format(supply)}
                </dd>
              </dl>
            </div>
          </div>
          <div className="bg-white shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500">
                  <MetricTooltip
                    text={f({ id: "skills.demand.projected.description" })}
                  >
                    <>{f({ id: "skills.demand" })}</>
                  </MetricTooltip>
                </dt>
                <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                  {currencyFormater.format(demand)}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Stats;
