import { useState } from "react";
// import { useQuery } from "@apollo/client";

import List from "components/matches/List";
// import { JOB_MANY } from "../../schema/job";

import JobSelect from "components/common/form/JobSelect";

const DashboardMatching = () => {
  // const { loading, error, data: jobs } = useQuery(JOB_MANY);
  const [industry, setIndustry] = useState(null);
  const [job, setJob] = useState(null);

  return (
    <>
      <h3 className="text-lg leading-6 font-medium text-gray-900">Matching</h3>
      <div className="mt-5 flex flex-row space-x-12">
        <div>
          <label
            htmlFor="job-id"
            className="block text-sm font-medium leading-5 text-gray-500"
          >
            Branche wählen
          </label>
        </div>
        <div>
          <label
            htmlFor="job-id"
            className="block text-sm font-medium leading-5 text-gray-500"
          >
            Arbeitsplatz wählen
          </label>
          <JobSelect />
        </div>
      </div>

      <List data={[]} />
    </>
  );
};

export default DashboardMatching;
