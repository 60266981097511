import clsx from "clsx";
import { Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react'
import { pipe, sort, isEmpty, reverse, includes } from "ramda";
import {
  DotsVerticalIcon,
  UserGroupIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/solid";

import type { License } from 'types/license';
import useToggle from "hooks/useToogle";

const ACTIVE_STATES = ['active', 'in_trial'];

const byType = (l1: License, l2: License) => (
  l1.type.localeCompare(l2.type)
);
const subsFirst = pipe<
  License[],
  License[],
  License[]
>(
  sort(byType),
  reverse
);

const isActiveState = ({ status }: License) => includes(status, ACTIVE_STATES);

const getStatusLabel = (status: string) => {
  let label;
  let icon;
  let color;
  const className = "grid grid-cols-2 gap-x-1 place-items-center";
  switch (status) {
    case "active":
      label = 'Aktiviert';
      icon = <CheckCircleIcon width="18" height="18" />;
      color = "text-green-600";
      break;
    case "in_trial":
      label = 'Probezeit';
      icon = <CheckCircleIcon width="18" height="18" />;
      color = "text-green-600";
      break;
    case "inactive":
    case "cancelled":
      label = 'Inaktiv';
      icon = <ExclamationCircleIcon width="18" height="18" />;
      color = "text-gray-600";
      break;
    case "non_renewing":
      label = 'Gekündigt';
      icon = <ExclamationCircleIcon width="18" height="18" />;
      color = "text-red-600";
      break;
    default:
      label = 'Status unbekannt';
      icon = <ExclamationCircleIcon width="18" height="18" />;
      color = "text-gray-600";
      break;
  }

  return (
    <span className={clsx(className, color)} style={{ gridTemplateColumns: "1fr 18px" }}>
      <span>{ label }</span>{ icon }
    </span>
  );
};

const NoLicenses = () => (
  <div className="text-center p-4">
    <i>Keine Lizenzen erworben</i>
  </div>
);

interface LicenseCardProp {
  license: License,
  onCancelLicense?: (license: License) => void;
  onReactivateLicense?: (license: License) => void;
}

const LicenseCard = ({
  license,
  onCancelLicense,
  onReactivateLicense,
}: LicenseCardProp) => {
  const [isOpen, toggle] = useToggle();

  const handleDeactivate = () => {
    if (onCancelLicense) { onCancelLicense(license) };
    toggle();
  };

  const handleReactivate = () => {
    if (onReactivateLicense) { onReactivateLicense(license) };
    toggle();
  };

  return <>
    <div key={license._id} className="rounded shadow-md mb-3 last:mb-0">
      <div className={clsx("flex justify-between p-4 text-white rounded-t", {
        "bg-brand-primary": !(license.status === "active"),
        "bg-brand-secondary": license.status === "active"
      })}>
        <span className="text-lg font-bold">
          {
            license.product.name
          }
        </span>
        <button onClick={toggle}>
          <DotsVerticalIcon width="18" height="18" />
        </button>
      </div>
      <div className="flex justify-between p-4">
        {
          license.type === 'subscription'
            ? <span className="mr-2 grid grid-cols-2 gap-x-1 place-items-center" style={{ gridTemplateColumns: "18px 1fr" }}>
                <UserGroupIcon width="18" height="18" />
                Benutzer: {license.seats}
              </span>
            : <span>Firmenlizenz</span>
        }
        <span>{ getStatusLabel(license.status) }</span>
      </div>
    </div>

    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={toggle}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900 font-lg p-3 text-center border-b border-gray-100 relative"
              >
                { license.product.name }
                <button
                  type="button"
                  className="absolute top-1 right-1 focus:outline-none"
                  onClick={toggle}
                >
                  <XIcon width="18" height="18" />
                </button>
              </Dialog.Title>
              {
                isActiveState(license)
                  ? <div className="hover:bg-gray-100 flex flex-start border-b border-gray-100 cursor-pointer last:border-b-0" onClick={handleDeactivate}>
                      <span className="text-red-600 grid grid-cols-2 gap-x-1 place-items-center p-3" style={{ gridTemplateColumns: "18px 1fr" }}>
                        <XCircleIcon width="18" height="18" />
                        <span>Lizenz deaktivieren</span>
                      </span>
                    </div>
                  : <div className="hover:bg-gray-100 flex flex-start border-b border-gray-100 cursor-pointer last:border-b-0" onClick={handleReactivate}>
                      <span className="text-green-600 grid grid-cols-2 gap-x-1 place-items-center p-3" style={{ gridTemplateColumns: "18px 1fr" }}>
                        <CheckCircleIcon width="18" height="18" />
                        <span>Lizenz aktivieren</span>
                      </span>
                    </div>
              }
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  </>;
};

interface IProps {
  licenses: License[],
  onCancelLicense?: (license: License) => void;
  onReactivateLicense?: (license: License) => void;
}

export const LicenseList = ({
  licenses,
  onCancelLicense,
  onReactivateLicense,
}: IProps) => {
  return <>
    {
      subsFirst(licenses).map((l: License, idx: number) => (
        <LicenseCard
          key={l._id}
          license={l}
          onCancelLicense={onCancelLicense}
          onReactivateLicense={onReactivateLicense}
        />
      ))
    }
    {
      isEmpty(licenses)
        ? <NoLicenses />
        : null
    }
  </>;
}
