import { useQuery } from "@apollo/client";
import { forwardRef } from "react";
import { SKILL_LEVEL_MANY } from "../../../schema/skillLevel";
import { SkillLevel } from "../../../types";

import { Error, Loading } from "../../ui/Alerts";

const SkillLevelSelect = forwardRef((props: any, ref: any) => {
  const { loading, error, data } = useQuery(SKILL_LEVEL_MANY);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <select
      {...props}
      ref={ref}
      autoComplete="type"
      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
    >
      <option value=""></option>
      {data.skillLevelMany.map((skillLevel: SkillLevel) => (
        <option key={skillLevel._id} value={skillLevel._id}>
          {skillLevel.name}
        </option>
      ))}
    </select>
  );
});

export default SkillLevelSelect;
