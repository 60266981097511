import React from "react";
import { usePermissions } from "hooks/usePermissions";
import { scope as PermissionScope, PermissionFlag } from "types/permission";

interface IProps {
  module: string;
  flag?: PermissionFlag;
  scopeRequired?: PermissionScope;
  children?: any;
};

export const BlurOverlay: React.FC<IProps> = ({
  module,
  flag = PermissionFlag.READ,
  scopeRequired = PermissionScope.SELF,
  children = null,
}) => {
  const { loading, hasPermissionScope } = usePermissions();

  if (loading) { return null; };

  return (
    hasPermissionScope(module, flag, scopeRequired)
      ? children
      : <>
          <div className="absolute backdrop-filter backdrop-blur top-0 right-0 left-0 bottom-0 z-blur" />
          { children }
        </>
  );
}
