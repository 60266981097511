import { gql } from "@apollo/client";

export const licenseTypes = gql`
  input LicenseInput {
    _id: ID
    productId: String!
    type: String!
    seats: Int
  }
`;

export const LICENSES_MANY = gql`
  query {
    licenseMany {
      _id
      product {
        _id
        name
      }
      status
      key
      seats
      type
    }
  }
`;

export const LICENSE_UPDATE_SEATS = gql`
  mutation(
    $_id: ID!
    $seats: [String]!
  ) {
    licenseUpdateSeats(
      _id: $_id
      seats: $seats
    ) {
      _id
      seats
    }
  }
`;

export const LICENSE_CREATE_MANY = gql`
  mutation(
    $licenses: [LicenseInput]!
  ) {
    licenseCreateMany(
      licenses: $licenses
    ) {
      _id
      key
      product {
        _id
        name
      }
      seats
      type
      status
    }
  }
`;

export const LICENSE_CANCEL = gql`
  mutation(
    $_id: ID!
  ) {
    licenseCancel(
      _id: $_id
    ) { _id }
  }
`;

export const LICENSE_REACTIVATE = gql`
  mutation(
    $_id: ID!
  ) {
    licenseReactivate(
      _id: $_id
    ) { _id }
  }
`;
