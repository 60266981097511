import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import {
  RouteComponentProps,
  match,
  useHistory,
  useParams,
} from "react-router-dom";

import { EMPLOYEE_BY_ID } from "../../schema/employee";
import { REVIEW_CREATE_ONE, REVIEW_MANY } from "../../schema/review";
import { SKILL_SCALE_MANY } from "../../schema/skillScale";
import { Competence, SkillScale } from "../../types";

import ReviewChart from "../charts/ReviewChart";
import { Error, Loading } from "../ui/Alerts";
import { Divider } from "../ui/layout/Divider";

interface Props {
  location: RouteComponentProps<any>["location"];
  match?: match<ParamTypes>;
}

interface ParamTypes {
  id: string;
}

type FormData = {
  name: string;
  description: string;
  employeeId: string;
};

const Form = ({ location }: Props) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { formatMessage: f } = useIntl();

  const [competences, setCompetences] = useState<Competence[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ defaultValues: { employeeId: id } });

  const { loading, error, data, refetch } = useQuery(EMPLOYEE_BY_ID, {
    variables: {
      _id: id,
    },
  });

  const [createReview] = useMutation(REVIEW_CREATE_ONE, {
    refetchQueries: [{ query: REVIEW_MANY }],
  });

  const { data: skillScaleData } = useQuery(SKILL_SCALE_MANY);

  useEffect(() => {
    if (!id) {
      history.push("/");
      return;
    }
  }, [id, history]);

  useEffect(() => {
    // Clone current competences from employee and reset values
    const newCompetences = data
      ? [...data.employeeById.competences].map((c) => ({
          ...c,
          value: 0,
        }))
      : [];
    setCompetences(newCompetences);
  }, [data]);

  const onSubmit = (formData: FormData) => {
    createReview({
      variables: {
        name: formData.name,
        description: formData.description,
        employeeId: formData.employeeId,
        competences: competences.map((c) => ({
          skillId: c.skillId,
          value: +c.value,
        })),
      },
    });
    refetch();
    history.goBack();
  };

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      <div className="flex items-center justify-between py-5">
        <div className="pb-6">
          <div className="inline-flex items-center text-sm text-gray-500 leading-none pb-2">
            <span>{f({ id: "employee.actions.review" })}</span>
          </div>
          <div className="flex items-center">
            <h1 className="px-2 text-2xl text-gray-900 font-extrabold">
              {data?.employeeById.firstName} {data?.employeeById.lastName}
            </h1>
          </div>
        </div>
        <div className="flex items-center">
          <span className="shadow-sm rounded-md">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
              onClick={() => history.goBack()}
            >
              {f({ id: "actions.back" })}
            </button>
          </span>
        </div>
      </div>

      <Divider />

      <form onSubmit={handleSubmit(onSubmit)} id="employee-review-form">
        <div className="space-y-6 pt-6 pb-5">
          <div className="space-y-1">
            <label
              htmlFor="name"
              className="block text-sm font-medium leading-5 text-gray-900"
            >
              {f({ id: "meta.abstract" })}
            </label>
            <input
              type="text"
              id="name"
              maxLength={10}
              {...register("name", { required: true, max: 10 })}
              className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <p className="mt-2 text-sm text-gray-500">Thema dieser Bewertung</p>

            {errors.name && (
              <span className="text-sm font-light text-red-500">
                Name fehlt
              </span>
            )}
          </div>
          <div className="space-y-1">
            <label
              htmlFor="description"
              className="block text-sm font-medium leading-5 text-gray-900"
            >
              {f({ id: "meta.description" })}
            </label>
            <div className="mt-1">
              <textarea
                id="description"
                {...register("description")}
                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
              ></textarea>
            </div>
            <p className="mt-2 text-sm text-gray-500">
              Kurze Beschreibung dieser Bewertung
            </p>
          </div>
          <div className="space-y-1">
            <label className="block text-sm font-medium leading-5 text-gray-900">
              Kompetenzen
            </label>
            <ReviewChart
              width={900}
              height={400}
              employeeData={data?.employeeById.competences}
              data={competences}
              actualRevenue={data?.employeeById.targetRevenue}
              markers={skillScaleData?.skillScaleMany.map((s: SkillScale) => ({
                label: s.name,
                value: s.value * 100,
              }))}
              onChange={setCompetences}
            />
          </div>
        </div>

        <button
          type="submit"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-coral-500 hover:bg-coral-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400"
        >
          {f({ id: "actions.save" })}
        </button>
      </form>
    </>
  );
};

export default Form;
