import { useFormContext } from "react-hook-form";

const GenderField = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="gender"
        className="block text-sm font-medium leading-5 text-gray-900"
      >
        Geschlecht
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="max-w-xs rounded-md shadow-sm">
          <select
            id="gender"
            {...register("gender")}
            className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 rounded focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
          >
            <option value="male">Mann</option>
            <option value="female">Frau</option>
            <option value="other">Divers</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default GenderField;
