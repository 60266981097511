import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { ResponsiveLine } from "@nivo/line";
import { useState } from "react";
import { useIntl } from "react-intl";

import { METRIC_HUMATICS_BY_DATE_GROUP } from "../../schema/metric";
import { UNIT_CREATE_ONE, UNIT_MANY } from "../../schema/unit";

import { selectedUnitsVar } from "../../cache";
import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";
import { filterChartData } from "../../utils/filter";

import {
  HorizontalNavigation,
  HorizontalNavigationOption,
} from "../ui/navigation/HorizontalNavigation";

import Tabs from "../organization/Tabs";
import { Error, Loading } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import { H1, H3 } from "../ui/Typo/Typo";
import Form, { FormData } from "./Form";
import List from "./List";

const Unit = () => {
  const { formatMessage: f } = useIntl();
  const { loading, error, data } = useQuery(UNIT_MANY);
  const [createUnit] = useMutation(UNIT_CREATE_ONE, {
    refetchQueries: [{ query: UNIT_MANY }],
  });

  const tabs: HorizontalNavigationOption[] = [
    { value: "h", label: f({ id: "humatics.h" }) },
    { value: "t", label: f({ id: "humatics.t" }) },
  ];

  // Selections
  const selectedUnits = useReactiveVar(selectedUnitsVar);
  const [selectedMetric, setSelectedMetric] = useState<
    HorizontalNavigationOption
  >(tabs[0]);

  // Charts
  const { data: chartData } = useQuery(METRIC_HUMATICS_BY_DATE_GROUP, {
    variables: {
      type: "unit",
      objectIds: selectedUnits,
    },
  });

  const [open, toggle] = useToggle();

  const onSubmit = (formData: FormData) => {
    createUnit({
      variables: {
        name: formData.name,
        description: formData.description,
        managerId: formData.managerId ? formData.managerId : null,
        organizationId: formData.organizationId,
        parentId: formData.parentId ? formData.parentId : null,
      },
    });
    toggle();
  };

  const groupedData = selectedUnits.map((id: string) => {
    // FIXME: Improve this dirty fix for showing unit name as id
    const result = data.unitMany.find((unit: any) => unit._id === id);

    return {
      id: result?.name || id,
      data: filterChartData(
        chartData?.metricHumaticsByDateGroup,
        selectedMetric.value,
        id
      ),
    };
  });

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Einheit hinzufügen">
          <Form onSubmit={onSubmit} />
        </SlideOver>
      </SliderOverContext.Provider>

      {selectedUnits.length > 0 && (
        <>
          <H3>Vergleich der Kompetenzwerte</H3>

          <HorizontalNavigation
            options={tabs}
            selected={selectedMetric}
            onSelect={setSelectedMetric}
          />

          <div className="h-64">
            <ResponsiveLine
              data={groupedData}
              margin={{ top: 60, right: 50, bottom: 60, left: 60 }}
              theme={{ background: "#f7fafc" }}
              xScale={{
                type: "time",
                format: "%Y-%m-%d",
                useUTC: false,
                precision: "day",
              }}
              xFormat="time:%Y-%m-%d"
              yScale={{
                type: "linear",
                stacked: false,
              }}
              yFormat=" >-.2f"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                format: "%b %d",
                tickValues: "every 4 weeks",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Zeitverlauf",
                legendPosition: "middle",
                legendOffset: 36,
              }}
              axisLeft={{
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Wert",
                legendPosition: "middle",
                legendOffset: -50,
              }}
              curve="monotoneX"
              enablePointLabel={false}
              pointSize={6}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              enableGridX={false}
              enableGridY={false}
              //enableArea={true}
              legends={[
                {
                  anchor: "top",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: -50,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 180,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        </>
      )}

      <Tabs />

      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
        <div className="ml-4 mt-2">
          <H1>{f({ id: "units.name" })}</H1>
        </div>
        <div className="p-6 bg-white ml-4 mt-2 flex-shrink-0">
          <span className="inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-brand-primary bg-coral-300 hover:bg-coral-200 focus:outline-none focus:shadow-outline-indigo focus:border-orange-400 active:bg-coral-400"
              onClick={toggle}
            >
              <svg
                className="group-hover:text-light-blue-600 text-light-blue-500 mr-2"
                width="12"
                height="20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6 5a1 1 0 011 1v3h3a1 1 0 110 2H7v3a1 1 0 11-2 0v-3H2a1 1 0 110-2h3V6a1 1 0 011-1z"
                />
              </svg>
              Neue Einheit
            </button>
          </span>
        </div>
      </div>

      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
        {`${data?.unitMany.length} Einträge`}
        <span className="pl-4">{`${selectedUnits.length} Ausgewählt`}</span>
      </h2>
      <List data={data?.unitMany} />
    </>
  );
};

export default Unit;
