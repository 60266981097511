import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import axios from "axios";
import { isLoggedInVar, roleVar } from "../../../cache";
import { H1 } from "../../ui/Typo/Typo";

const Logout = () => {
  const history = useHistory();
  const client = useApolloClient();

  useEffect(() => {
    const logout = async () => {
      try {
        await axios.delete(`${process.env.REACT_APP_AUTH_URI}/logout`);
        // Evict and garbage-collect the cached user object
        client.cache.evict({ fieldName: "me" });
        client.cache.gc();
        // client.clearStore();
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        isLoggedInVar(false);
        roleVar("guest");

        history.push("/users/login");
      } catch (e) {
        console.error(e);
      }
    };
    logout();
  }, [client, history]);

  return <H1>Logout erfolgreich</H1>;
};

export default Logout;
