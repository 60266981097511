import React from "react";
import Item from "./Item";
import { Applicant } from "../../types";

interface Props {
  data: Array<Applicant>;
};

const List: React.FC<Props> = ({ data = [] }) => {
  return (
    <ul className="mt-3 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {data.map((applicant: Applicant) => (
        <Item key={applicant._id} applicant={applicant} />
      ))}
    </ul>
  );
};

export default List;
