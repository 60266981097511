import { useEffect } from "react";
import {
  useParams,
  useHistory,
  match,
  RouteComponentProps,
} from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  REVIEW_BY_ID,
  REVIEW_REMOVE_BY_ID,
  REVIEW_MANY,
} from "../../schema/review";

import { Loading, Error } from "../ui/Alerts";
import PageHeader from "../ui/Headings/PageHeader";
import { Breadcrumb } from "../ui/navigation/Breadcrumb";

import Stats from "../competences/Stats";

import ProfileChart from "../charts/ProfileChart";

interface ParamTypes {
  id: string;
}

interface Props {
  required: string;
  match?: match<ParamTypes>;
}

const Review = ({}: Props & RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();

  const { loading, error, data } = useQuery(REVIEW_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [deleteReview] = useMutation(REVIEW_REMOVE_BY_ID, {
    refetchQueries: [{ query: REVIEW_MANY }],
  });

  useEffect(() => {
    if (!id) {
      history.push("/");
      return;
    }
  }, [id, history]);

  const onDelete = () => {
    deleteReview({ variables: { _id: id } });
    history.goBack();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <>
      <PageHeader title={data?.reviewById?.name} onDelete={onDelete}>
        <Breadcrumb>
          <Breadcrumb.Item url="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item
            url={`/employees/${data?.reviewById?.employeeId}/review`}
          >
            Bewertungen
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data?.reviewById?.name}</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>

      <p className="px-6 my-3 text-2xl font-light">
        {data.reviewById?.employee?.firstName}{" "}
        {data.reviewById?.employee?.lastName}
      </p>

      <Stats
        humatics={data.reviewById?.humatics}
        competenceCoverage={data.reviewById?.competenceCoverage}
      />

      <hr className="my-6 border-2 rounded-full border-gray-200" />

      <p className="px-6 mt-1 max-w-2xl text-sm leading-5 text-gray-500">
        {data?.reviewById.description}
      </p>

      <ProfileChart
        width={1024}
        height={400}
        data={data?.reviewById?.competences}
        revenue={data.reviewById?.employee?.salary}
        readonly
      />
    </>
  );
};

export default Review;
