import { gql } from "@apollo/client";

export const SEMINAR_TYPE_MANY = gql`
  query {
    seminarTypeMany {
      name
      description
      createdAt
      _id
    }
  }
`;

export const SEMINAR_TYPE_BY_ID = gql`
  query($_id: ID!) {
    seminarTypeById(_id: $_id) {
      name
      description
      seminars {
        name
        createdAt
        _id
      }
      createdAt
      _id
    }
  }
`;

export const SEMINAR_TYPE_CREATE_ONE = gql`
  mutation($name: String!, $description: String) {
    seminarTypeCreateOne(input: { name: $name, description: $description }) {
      _id
      name
    }
  }
`;

export const SEMINAR_TYPE_UPDATE_BY_ID = gql`
  mutation($_id: ID!, $name: String!, $description: String) {
    seminarTypeUpdateById(
      input: { name: $name, description: $description }
      _id: $_id
    ) {
      _id
      name
    }
  }
`;

export const SEMINAR_TYPE_REMOVE_BY_ID = gql`
  mutation($_id: ID!) {
    seminarTypeRemoveById(_id: $_id) {
      name
    }
  }
`;
