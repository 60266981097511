export const filterChartData = (
  data: [] = [],
  key: String,
  objectId?: String
) => {
  if (objectId) {
    return (
      data.flatMap((d: any) => {
        if (d.objectId === objectId) {
          //@ts-ignore
          return { x: d.date, y: d.values[key] };
        } else {
          return [];
        }
      }) || []
    );
  } else {
    return (
      data.map((d: any) => {
        //@ts-ignore
        return { x: d.date, y: d.values[key] };
      }) || []
    );
  }
};

/**
 * Remap provided data to relative numbers
 * @param {Array} data
 * @param {String} key
 */
export const relativeNumbers = (data: any = [], key: String) => {
  // @ts-ignore
  const arr = data.map((obj: any) => obj.values[key]);
  const max = Math.max(...arr);
  const min = Math.min(...arr);
  const diff = max - min;
  return data.map((obj: any) => {
    let values = { ...obj.values };
    // @ts-ignore
    values[key] = (100 / diff) * (values[key] - min);
    return { ...obj, values };
  });
};