import { Link } from "react-router-dom";
import { Job } from "../../../types";

interface Props {
  job: Job;
};

const Item = ({ job }: Props) => {
  return (
    <li className="px-6 py-5 relative">
      <div className="group flex justify-between items-center space-x-2">
        <Link
          to={`/jobs/${job._id}/details`}
          className="-m-1 p-1 block"
        >
          <span className="absolute inset-0 group-hover:bg-gray-50"></span>
          <div className="flex-1 flex items-center min-w-0 relative">
            <div className="ml-4 truncate">
              <div className="text-sm leading-5 font-medium text-gray-900 truncate">
                {job.name}
              </div>
              <div className="text-sm leading-5 text-gray-500 truncate">
                {job.description}
              </div>
            </div>
          </div>
        </Link>
      </div>
    </li>
  );
};

export default Item;
