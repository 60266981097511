import { Fragment, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { ResponsivePie } from "@nivo/pie";

import { useIntl } from "react-intl";

import { SKILL_MANY } from "../../schema/skill";
import { Skill } from "../../types";

import { H3 } from "../ui/Typo/Typo";
import { currencyFormater } from "../../config/i18n";

import { Loading } from "../ui/Alerts";

const SkillChart = () => {
  const { formatMessage: f } = useIntl();

  const { loading, data, refetch } = useQuery(SKILL_MANY, {
    variables: {
      filter: { core: true },
    },
  });

  const items = [
    { name: f({ id: "attrs.skills.core" }) },
    { name: f({ id: "attrs.skills.future" }) },
    { name: f({ id: "attrs.skills.forEmployee" }) },
    { name: f({ id: "attrs.skills.forManager" }) },
  ];

  const [selected, setSelected] = useState(items[0]);

  useEffect(() => {
    let filter = {};
    switch (selected?.name) {
      case f({ id: "attrs.skills.core" }):
        filter = { core: true };
        break;
      case f({ id: "attrs.skills.future" }):
        filter = { future: true };
        break;
      case f({ id: "attrs.skills.forEmployee" }):
        filter = { forEmployee: true };
        break;
      case f({ id: "attrs.skills.forManager" }):
        filter = { forManager: true };
        break;
      default:
        break;
    }

    // TODO typescript as `SkillFilterInput`
    refetch({ filter: filter as any });
  }, [selected, refetch]);

  const defs = [
    {
      id: "dots",
      type: "patternDots",
      background: "inherit",
      color: "rgba(255, 255, 255, 0.3)",
      size: 4,
      padding: 1,
      stagger: true,
    },
    {
      id: "lines",
      type: "patternLines",
      background: "inherit",
      color: "rgba(255, 255, 255, 0.3)",
      rotation: -45,
      lineWidth: 6,
      spacing: 10,
    },
  ];

  const pieData =
    data?.skillMany
      .map((skill: Skill) => ({
        id: skill._id,
        label: skill.name,
        value: skill.supply,
        demand: skill.demand,
        color: skill.color,
      }))
      .sort((a: Skill, b: Skill) => b.supply - a.supply)
      .slice(0, 5) || [];

  return (
    <>
      {loading && <Loading />}

      <div className="flex space-x-4 justify-start items-center">
        <H3>{f({ id: "analytics.skills.types.description" })}</H3>
        <Listbox value={selected} onChange={setSelected}>
          {({ open }) => (
            <>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                  <span className="block truncate">{selected.name}</span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    static
                    className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    {items.map((item: any, index: number) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `${
                            active
                              ? "text-amber-900 bg-amber-100"
                              : "text-gray-900"
                          }
                              cursor-default select-none relative py-2 pl-10 pr-4`
                        }
                        value={item}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`${
                                selected ? "font-medium" : "font-normal"
                              } block truncate`}
                            >
                              {item.name}
                            </span>
                            {selected ? (
                              <span
                                className={`${
                                  active ? "text-amber-600" : "text-amber-600"
                                }
                                    absolute inset-y-0 left-0 flex items-center pl-3`}
                              >
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>

      <div className="w-full h-64 rounded-lg shadow bg-white dark:bg-gray-800">
        <ResponsivePie
          data={pieData}
          sortByValue={true}
          margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          enableArcLabels={false}
          arcLabel={(d: any) => `${currencyFormater.format(d.value)}`}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
          arcLinkLabel={(d: any) => `${d.label}`}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="inherit:darker(1.4)"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          tooltip={(item: any) => (
            <div className="flex flex-col items-center text-center text-xs p-0.5 bg-white shadow-sm">
              <span
                className="font-semibold"
                style={{ color: item.datum.color }}
              >
                {item.datum.label}
              </span>
              <dl className="px-3 flex flex-row items-center space-x-2 justify-between">
                <dt className="text-xs leading-5 font-bold text-gray-500">
                  {f({ id: "revenues.actual" })}:
                </dt>
                <dd className="text-xs">
                  {currencyFormater.format(item.datum.value)}
                </dd>
                <dt className="text-xs leading-5 font-bold text-gray-500">
                  {f({ id: "revenues.target" })}:
                </dt>
                <dd className="text-xs">
                  {currencyFormater.format(item.datum.data.demand)}
                </dd>
              </dl>
            </div>
          )}
          defs={defs}
          fill={[{ match: (d: any) => d.data.demand > 0, id: "lines" }]}
        />
      </div>
    </>
  );
};

export default SkillChart;
