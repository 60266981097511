import { Link } from "react-router-dom";
import { currencyFormater } from "../../config/i18n";
import { Applicant } from "../../types";
import MicroChart from "../charts/MicroChart";
import Avatar from "../employees/Avatar";

interface Props {
  applicant: Applicant;
}

const Item = ({ applicant }: Props) => {
  const formattedSalary = currencyFormater.format(applicant.salary);

  return (
    <li className="col-span-1 bg-white rounded-lg shadow">
      <div className="w-full flex items-center justify-between p-6 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <Link to={`/applicants/${applicant._id}/details`}>
              <h3 className="text-gray-900 text-sm leading-5 font-medium truncate">
                {applicant.firstName} {applicant.lastName}
              </h3>
            </Link>
            <span className="flex-shrink-0 inline-block px-2 py-0.5 text-teal-800 text-xs leading-4 font-medium bg-coral-100 rounded-full">
              {formattedSalary}
            </span>
          </div>
          <p className="mt-1 text-gray-500 text-sm leading-5 truncate">
            <Link to={`/units/${applicant.unitId}/details`}>
              {applicant.unit?.name}
            </Link>
          </p>
        </div>
        <Link to={`/applicants/${applicant._id}/details`}>
          <Avatar employee={applicant as any} className="w-10 h-10" />
        </Link>
      </div>
      <div className="border-t border-gray-200">
        <div className="-mt-px flex">
          <div className="-ml-px w-0 flex-1 flex">
            <Link
              to={`/applicants/${applicant._id}/profile`}
              className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
            >
              <div className="h-4 w-4">
                <MicroChart data={applicant.competences} />
              </div>
              <span className="ml-3">Kompetenzen</span>
            </Link>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Item;
