import { gql } from "@apollo/client";

export const UNIT_MANY = gql`
  query {
    unitMany(sort: NAME_ASC) {
      name
      description
      managerId
      manager {
        firstName
        lastName
        photo
      }
      organizationId
      organization {
        name
      }
      parentId
      parent {
        name
      }
      revenues {
        year
        target
        actual
      }
      requirements {
        quantity
        jobId
      }
      employeeCount
      jobCount
      totalSalary
      actualRevenue
      targetRevenue
      required {
        employeeCount
        totalSalary
      }
      _id
    }
  }
`;

export const UNIT_BY_ID = gql`
         query($_id: ID!) {
           unitById(_id: $_id) {
             name
             description
             manager {
               firstName
               lastName
             }
             organizationId
             organization {
               name
             }
             parentId
             parent {
               name
             }
             revenues {
               year
               target
               actual
             }
             requirements {
               quantity
               jobId
             }
             required {
               employeeCount
               totalSalary
             }
             employeeCount
             jobCount
             knowledgeValue
             totalSalary
             actualRevenue
             annualizedRevenue
             targetRevenue
             humatics {
               competenceValues
               employeesH
               m
               h
               t
               phi
               r
               ef
               s
             }
             jobHumatics {
               employeesH
               h
               t
               phi
               r
               ef
               s
             }
             _id
           }
         }
       `;

export const UNIT_CREATE_ONE = gql`
  mutation (
    $name: String!
    $description: String
    $managerId: ID
    $organizationId: ID!
    $parentId: ID
  ) {
    unitCreateOne(
      input: {
        name: $name
        description: $description
        managerId: $managerId
        organizationId: $organizationId
        parentId: $parentId
      }
    ) {
      _id
      name
    }
  }
`;

export const UNIT_UPDATE_BY_ID = gql`
  mutation (
    $_id: ID!
    $name: String
    $description: String
    $managerId: ID
    $organizationId: ID
    $parentId: ID
    $revenues: [RevenueInput]
    $requirements: [RequirementInput]
  ) {
    unitUpdateById(
      input: {
        name: $name
        description: $description
        managerId: $managerId
        organizationId: $organizationId
        parentId: $parentId
        revenues: $revenues
        requirements: $requirements
      }
      _id: $_id
    ) {
      _id
      name
    }
  }
`;

export const UNIT_REMOVE_BY_ID = gql`
  mutation ($_id: ID!) {
    unitRemoveById(_id: $_id) {
      name
    }
  }
`;
