import { useMutation, useQuery } from "@apollo/client";
import { DocumentDownloadIcon } from "@heroicons/react/solid";
import { ResponsiveLine } from "@nivo/line";
import { useIntl } from "react-intl";
import {
  Link,
  RouteComponentProps,
  useHistory,
  useParams,
} from "react-router-dom";

import { METRIC_HUMATICS_BY_DATE } from "../../schema/metric";
import {
  UNIT_BY_ID,
  UNIT_MANY,
  UNIT_REMOVE_BY_ID,
  UNIT_UPDATE_BY_ID,
} from "../../schema/unit";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { Error, Loading } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import { Divider } from "../ui/layout/Divider";
import Form, { FormData } from "./Form";

import { currencyFormater, numberFormater } from "../../config/i18n";
import MetricTooltip from "../competences/MetricTooltip";
import RequirementFields from "../requirements/Fields";
import RevenueFields from "../revenues/Fields";
import { H3 } from "../ui/Typo/Typo";
import Stats from "./Stats";

interface ParamTypes {
  id: string;
}

const Details = ({}: RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [open, toggle] = useToggle();
  const { formatMessage: f } = useIntl();

  const { loading, error, data } = useQuery(UNIT_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateUnit] = useMutation(UNIT_UPDATE_BY_ID, {
    refetchQueries: [{ query: UNIT_MANY }],
  });
  const [deleteUnit] = useMutation(UNIT_REMOVE_BY_ID, {
    refetchQueries: [{ query: UNIT_MANY }],
  });

  const { data: chartData } = useQuery(METRIC_HUMATICS_BY_DATE, {
    variables: { type: "unit", objectId: id },
  });

  const onUpdateRevenues = (formData: FormData) => {
    updateUnit({
      variables: {
        _id: id,
        revenues: formData.revenues.map((r) => ({
          ...r,
          year: +r.year,
          target: +r.target,
          actual: +r.actual,
        })),
      },
    });
  };

  const onUpdateRequirements = (formData: FormData) => {
    updateUnit({
      variables: {
        _id: id,
        requirements: formData.requirements.map((r) => ({
          ...r,
          quantity: +r.quantity,
          jobId: r.jobId,
        })),
      },
    });
  };

  const onUpdate = (formData: FormData) => {
    updateUnit({
      variables: {
        _id: id,
        name: formData.name,
        description: formData.description,
        managerId: formData.managerId ? formData.managerId : null,
        organizationId: formData.organizationId,
        parentId: formData.parentId ? formData.parentId : null,
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteUnit({ variables: { _id: id } });
    history.goBack();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const filterChartData = (key: String) => {
    return (
      chartData?.metricHumaticsByDate?.map((d: any) => {
        //@ts-ignore
        return { x: d.date, y: d.values[key] };
      }) || []
    );
  };

  const groupedData = [
    {
      id: f({ id: "humatics.h" }),
      data: filterChartData("h"),
    },
    {
      id: f({ id: "humatics.t" }),
      data: filterChartData("t"),
    },
    {
      id: f({ id: "humatics.r" }),
      data: filterChartData("r"),
    },
    {
      id: f({ id: "humatics.phi" }),
      data: filterChartData("phi"),
    },
  ];

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Einheit bearbeiten">
          <Form onSubmit={onUpdate} data={data.unitById} />
        </SlideOver>

        <H3>Kompetenzwerte der Einheit</H3>
        <div className="h-64 relative">
          <ResponsiveLine
            data={groupedData.reverse()}
            margin={{ top: 60, right: 50, bottom: 60, left: 60 }}
            theme={{ background: "#f7fafc" }}
            xScale={{
              type: "time",
              format: "%Y-%m-%d",
              useUTC: false,
              precision: "day",
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
              type: "linear",
              stacked: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              format: "%b",
              tickValues: "every 4 weeks",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Zeitverlauf",
              legendPosition: "middle",
              legendOffset: 36,
            }}
            axisLeft={{
              tickSize: 10,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Wert",
              legendPosition: "middle",
              legendOffset: -50,
            }}
            curve="monotoneX"
            enablePointLabel={false}
            enableSlices="x"
            sliceTooltip={({ slice }) => {
              return (
                <div className="flex flex-col items-center bg-white shadow-sm text-xs rounded py-1 px-2">
                  {slice.points.map((point) => (
                    <div
                      key={point.id}
                      style={{ color: point.serieColor }}
                      className="leading-normal"
                    >
                      <span>{point.serieId}</span>{" "}
                      <span className="font-bold">
                        {numberFormater.format(+point.data.yFormatted)}
                      </span>
                    </div>
                  ))}
                </div>
              );
            }}
            pointSize={6}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            enableGridX={false}
            enableGridY={false}
            enableArea={true}
            legends={[
              {
                anchor: "top",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: -50,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 180,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>

        <div className="bg-gray-100 mb-4">
          <div className="bg-white shadow">
            <div className="container mx-auto px-6">
              <div className="flex items-center justify-between py-5">
                <div>
                  <Link
                    to="/units"
                    className="inline-flex items-center text-sm text-gray-500 leading-none pb-2"
                  >
                    <i className="fas fa-angle-left"></i>
                    <span>Einheit</span>
                  </Link>
                  <div className="flex items-center">
                    <h1 className="px-2 text-2xl text-gray-900 font-extrabold">
                      {data?.unitById?.name}
                    </h1>
                  </div>
                </div>
                <div className="flex items-center">
                  <span className="shadow-sm rounded-md">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                      onClick={toggle}
                    >
                      {f({ id: "actions.edit" })}
                    </button>
                  </span>
                  <span className="ml-3 shadow-sm rounded-md">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline-red focus:border-red-700 active:bg-red-700 transition duration-150 ease-in-out"
                      onClick={onDelete}
                    >
                      {f({ id: "actions.delete" })}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container mx-auto p-6">
            <div className="w-full px-2 pb-6">
              <h2 className="text-lg font-medium text-gray-900 pb-6">
                {f({ id: "skills.name" })}
              </h2>
              <div className="bg-white rounded shadow text-gray-600">
                <Stats
                  humatics={data?.unitById?.humatics}
                  jobHumatics={data?.unitById?.jobHumatics}
                  actualRevenue={data.unitById?.actualRevenue}
                  targetRevenue={data.unitById?.targetRevenue}
                  knowledgeValue={data?.unitById?.knowledgeValue}
                />
              </div>
            </div>
            <div className="flex -mx-2">
              <div className="w-1/2 px-2">
                <div className="mb-6">
                  <h2 className="text-lg font-medium text-gray-900 pb-6">
                    {f({ id: "summary.name" })}
                  </h2>
                  <div className="bg-white rounded shadow overflow-hidden">
                    <div className="px-6 py-5">
                      <dl>
                        <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            {f({ id: "meta.description" })}
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {data?.unitById?.description}
                          </dd>
                        </div>
                        <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            Manager
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            <Link
                              to={`/employees/${data?.unitById?.managerId}/details`}
                            >
                              {data?.unitById?.manager?.firstName}{" "}
                              {data?.unitById?.manager?.lastName}
                            </Link>
                          </dd>
                        </div>
                        <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            Organisation
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            <Link
                              to={`/org/${data?.unitById?.organizationId}/details`}
                            >
                              {data?.unitById?.organization?.name}
                            </Link>
                          </dd>
                        </div>
                        <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            Übergeordnete Einheit
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {data?.unitById?.parent?.name}
                          </dd>
                        </div>
                        <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            {f({ id: "jobs.name" })}
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {data?.unitById?.jobCount}
                          </dd>
                        </div>
                        <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            {f({ id: "employees.target" })}
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {data?.unitById.required?.employeeCount}
                          </dd>
                        </div>
                        <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            {f({ id: "employees.actual" })}
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {data?.unitById?.employeeCount}
                          </dd>
                        </div>
                        <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            {f({ id: "salaries.target" })}
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {currencyFormater.format(
                              data?.unitById?.required?.totalSalary
                            )}
                          </dd>
                        </div>
                        <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            {f({ id: "salaries.actual" })}
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {currencyFormater.format(
                              data?.unitById?.totalSalary
                            )}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 px-2">
                <div className="mb-6">
                  <h2 className="text-lg font-medium text-gray-900 pb-6">
                    {f({ id: "revenues.name" })}
                  </h2>
                  <div className="bg-white rounded shadow overflow-hidden text-gray-600">
                    <RevenueFields
                      onSubmit={onUpdateRevenues}
                      data={data?.unitById?.revenues}
                    />
                  </div>
                </div>
                <div className="mb-6">
                  <h2 className="text-lg font-medium text-gray-900 pb-3 flex justify-between">
                    <span>{f({ id: "requirements.name" })}</span>

                    <a
                      href="/PeBeM.xlsx"
                      download
                      className="pt-1 pb-6 flex items-center gap-1 text-xs text-gray-400"
                    >
                      <DocumentDownloadIcon className="h-5 w-5" />
                      Personalberechnung nach PeBeM
                    </a>
                  </h2>

                  <div className="bg-white rounded shadow overflow-hidden text-gray-600">
                    <RequirementFields
                      onSubmit={onUpdateRequirements}
                      data={data?.unitById?.requirements}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Divider>
          <Divider.Content>{f({ id: "revenues.name" })}</Divider.Content>
        </Divider>

        <table className="my-6 min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <MetricTooltip
                  text={f({ id: "revenues.year.target.description" })}
                >
                  <>{f({ id: "revenues.year.target" })}</>
                </MetricTooltip>
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <MetricTooltip
                  text={f({ id: "revenues.year.actual.description" })}
                >
                  <>{f({ id: "revenues.year.actual" })}</>
                </MetricTooltip>
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <MetricTooltip
                  text={f({ id: "revenues.year.annualized.description" })}
                >
                  <>{f({ id: "revenues.year.annualized" })}</>
                </MetricTooltip>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td className="px-6 py-4">
                {currencyFormater.format(data?.unitById.targetRevenue)}
              </td>
              <td className="px-6 py-4">
                {currencyFormater.format(data?.unitById.actualRevenue)}
              </td>
              <td className="px-6 py-4">
                {currencyFormater.format(data?.unitById.annualizedRevenue)}
              </td>
            </tr>
          </tbody>
        </table>
      </SliderOverContext.Provider>
    </>
  );
};

export default Details;
