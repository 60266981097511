import { useQuery } from "@apollo/client";
import { PlusIcon } from "@heroicons/react/solid";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Link,
  RouteComponentProps,
  match,
  useHistory,
  useParams,
} from "react-router-dom";

import { EMPLOYEE_BY_ID } from "../../schema/employee";
import { SKILL_SCALE_MANY } from "../../schema/skillScale";
import { SkillScale } from "../../types";

import { PermissionRequired } from "components/ui/permissions";
import GroupedProfileChart from "../charts/GroupedProfileChart";
import { Error, Loading } from "../ui/Alerts";
import { H2 } from "../ui/Typo/Typo";
import Avatar from "./Avatar";

import { PermissionFlag } from "types/permission";
import ReviewList from "../reviews/List";
import { Divider } from "../ui/layout/Divider";

interface Props {
  location: RouteComponentProps<any>["location"];
  match?: match<ParamTypes>;
}

interface ParamTypes {
  id: string;
}

const Review = ({ location }: Props) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { formatMessage: f } = useIntl();

  const { loading, error, data } = useQuery(EMPLOYEE_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const { data: skillScaleData } = useQuery(SKILL_SCALE_MANY);

  useEffect(() => {
    if (!id) {
      history.push("/");
      return;
    }
  }, [id, history]);

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      <div className="bg-gray-100 mb-6">
        <div className="bg-white shadow">
          <div className="container mx-auto px-6">
            <div className="flex items-center justify-between py-5">
              <div>
                <Link
                  to="/jobs"
                  className="inline-flex items-center text-sm text-gray-500 leading-none pb-2"
                >
                  <i className="fas fa-angle-left"></i>
                  <span>{f({ id: "reviews.name" })}</span>
                </Link>
                <div className="flex items-center">
                  <Avatar employee={data?.employeeById} className="w-10 h-10" />
                  <h1 className="px-2 text-2xl text-gray-900 font-extrabold">
                    {data?.employeeById.firstName} {data?.employeeById.lastName}
                  </h1>
                </div>
              </div>
              <div className="flex items-center">
                <PermissionRequired
                  module="employees.review"
                  flag={PermissionFlag.WRITE}
                >
                  <span className="inline-flex rounded-md shadow-sm">
                    <Link
                      to={`/employees/${data?.employeeById._id}/review/form`}
                      className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray bg-coral-300 hover:bg-coral-200 focus:outline-none focus:shadow-outline-indigo focus:border-orange-400 active:bg-coral-400"
                    >
                      <PlusIcon className="w-5 h-5 mr-1" />
                      {f({ id: "reviews.new" })}
                    </Link>
                  </span>
                </PermissionRequired>
                <PermissionRequired module="employees.competency_profile">
                  <span className="ml-3 rounded-md shadow-sm">
                    <Link
                      to={`/employees/${data?.employeeById._id}/profile`}
                      className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                    >
                      {f({ id: "profile.name" })}
                    </Link>
                  </span>
                </PermissionRequired>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto p-6">
          <GroupedProfileChart
            width={960}
            height={300}
            data={data?.employeeById?.reviewCompetences}
            actualRevenue={data?.employeeById?.salary}
            markers={skillScaleData?.skillScaleMany.map((s: SkillScale) => ({
              label: s.name,
              value: s.value * 100,
            }))}
          />
        </div>
      </div>

      <Divider>
        <Divider.Content>Weitere Bewertungen</Divider.Content>
      </Divider>

      <div className="p-6">
        <H2>Frühere Bewertungen</H2>
        <ReviewList data={data?.employeeById?.reviews} />
      </div>
    </>
  );
};

export default Review;
