import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { useIntl } from "react-intl";

import { useQuery } from "@apollo/client";
import { localeVar } from "../../../cache";
import { TRANSLATION_MANY } from "../../../schema/translation";
import { Loading, Error } from "../../ui/Alerts";
import List from "../../translations/List";

const locales = [
  {
    name: "Deutsch",
    locale: "de",
  },
  {
    name: "Englisch",
    locale: "en",
  },
  {
    name: "Italienisch",
    locale: "it",
  },
  {
    name: "Russisch",
    locale: "ru",
  },
  {
    name: "Türkisch",
    locale: "tr",
  },
  {
    name: "Arabisch",
    locale: "ar",
  },
  {
    name: "Chinesisch",
    locale: "zh",
  },
];

const Language = () => {
  const { formatMessage: f } = useIntl();
  const [selected, setSelected] = useState(
    locales.find((item) => item.locale == localStorage?.getItem("locale")) ||
      locales[0]
  );
  const { loading, error, data } = useQuery(TRANSLATION_MANY);

  useEffect(() => {
    if (localStorage) {
      localStorage.setItem("locale", selected.locale);
      localeVar(selected.locale);
    }
  }, [selected]);

  return (
    <>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {f({ id: "user.language" })}
      </h3>
      <div className="mt-5 flex flex-col items-flex-start space-y-8">
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className="space-x-4 flex flex-row items-center">
            {locales.map((locale) => (
              <RadioGroup.Option
                key={locale.name}
                value={locale}
                className={({ active, checked }) =>
                  `${
                    active
                      ? "ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60"
                      : ""
                  }
                  ${
                    checked
                      ? "bg-brand-secondary bg-opacity-75 text-white"
                      : "bg-white"
                  }
                    relative rounded-lg shadow-md px-5 py-4 cursor-pointer flex focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? "text-black" : "text-gray-900"
                            }`}
                          >
                            {locale.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`inline ${
                              checked ? "text-sky-100" : "text-gray-500"
                            }`}
                          >
                            <span></span>{" "}
                          </RadioGroup.Description>
                        </div>
                      </div>
                      {checked && (
                        <div className="pl-2 flex-shrink-0 text-white">
                          <CheckIcon className="w-6 h-6" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>

        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Übersetzungen
        </h3>

        {loading && <Loading />}
        {error && <Error error={error} />}

        <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
          {`${data?.translationMany.length} Einträge`}
        </h2>
        <List data={data?.translationMany} />
      </div>
    </>
  );

  function CheckIcon(props: any) {
    return (
      <svg viewBox="0 0 24 24" fill="none" {...props}>
        <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
        <path
          d="M7 13l3 3 7-7"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
};

export default Language;
