import styled from "styled-components";

export const H1 = styled.h1.attrs({
  className:
    "text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10",
})``;

export const H2 = styled.h2.attrs({
  className:
    "text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate",
})``;

export const H3 = styled.h2.attrs({
  className:
    "text-gray-900 text-sm leading-5 font-extralight font-condensed truncate",
})``;
