import { useQuery } from "@apollo/client";
import { REVIEW_MANY } from "../../schema/review";

import Loading from "../ui/Alerts/Loading";
import Error from "../ui/Alerts/Error";
import Tabs from "../employees/Tabs";
import List from "./List";
import { H1 } from "../ui/Typo/Typo";

const Index = () => {
  const { loading, error, data } = useQuery(REVIEW_MANY);

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      <Tabs />

      {/* SectionHeader */}
      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
        <div className="ml-4 mt-2">
          <H1>Bewertungen</H1>
        </div>
      </div>

      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
        {`${data?.reviewMany.length} Einträge`}
      </h2>
      <List data={data?.reviewMany} />
    </>
  );
};

export default Index;
