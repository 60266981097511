const Notifications = () => {
  return (
    <>
      <h3 className="text-lg leading-6 font-medium text-gray-900">Benachrichtigungen</h3>
      <div className="mt-5 flex flex-row items-flex-start justify-between space-x-4"></div>
    </>
  );
};

export default Notifications;
