import { useState, useEffect, useRef } from "react";
import { Spring, config } from "react-spring/renderprops";
import { Link } from "react-router-dom";
import { useDrop } from "react-dnd";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { Disclosure } from "@headlessui/react";

// import { Unit } from "../../../types";
import { ORGANIZATION_TREE } from "../../../schema/organization";
import {
  EMPLOYEE_UPDATE_BY_ID,
  FILTER_EMPLOYEE_MANY,
} from "../../../schema/employee";
import { ItemTypes } from "../../../config/ItemTypes";

import { FormData } from "../Filter";

// import { Divider } from "../../ui/layout/Divider";
import Stats from "./Stats";
import UnitTooltip from "./UnitTooltip";
import Employee from "./Employee";

interface Props {
  data: any;
  hasChildren?: boolean;
  onClick?: () => void;
  filterData?: FormData;
}

const UnitNode = ({
  data,
  hasChildren = false,
  onClick,
  filterData,
}: Props) => {
  const client = useApolloClient();
  // const { refetch } = useQuery(ORGANIZATION_TREE, {
  //   variables: {
  //     _id: data?.unit.organizationId,
  //   },
  // });
  // const unit: Unit = data.data;
  const { data: unit } = data;

  const [updateEmployee] = useMutation(EMPLOYEE_UPDATE_BY_ID, {
    refetchQueries: [
      {
        query: ORGANIZATION_TREE,
        variables: { _id: unit.organizationId },
      },
      {
        query: FILTER_EMPLOYEE_MANY,
        variables: { unitId: unit._id },
      },
    ],
    update(cache, { data: { unitUpdateById } }) {
      console.log("updateEmployee", unitUpdateById);
    },
  });

  // const { data: employeeData } = useQuery(FILTER_EMPLOYEE_MANY, {
  //   variables: {
  //     unitId: unit._id,
  //   },
  // });

  const outer = useRef<HTMLDivElement>(null);
  const [x, setX] = useState(data.x);

  useEffect(() => {
    // Run once, after mounting
    // inner.current?.getBoundingClientRect()?.left;
    const width = outer.current?.clientWidth;
    if (width) {
      setX(width / 2);
    }
  }, [data]);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [ItemTypes.EMPLOYEE, ItemTypes.APPLICANT],
    drop: (item, monitor) => {
      console.log("drop", drop, item);

      if (monitor.didDrop()) {
        return;
      }
      const { employee } = item as any;

      updateEmployee({
        variables: {
          _id: employee._id,
          unitId: unit._id,
        },
      });

      client.cache.modify({
        id: client.cache.identify(unit),
        fields: {
          employeeCount: (prev) => {
            return prev + 1;
          },
        },
      });
      // client.cache.modify({
      //   id: client.cache.identify(employee.unit),
      //   fields: {
      //     employeeCount: (prev) => {
      //       return prev - 1;
      //     },
      //   },
      // });

      // refetch();
      return { name: "Unit" };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;

  return (
    <Spring
      config={config.stiff}
      from={{ left: data.x0, top: data.y0, opacity: 0 }}
      to={{ left: data.x, top: data.y, opacity: 1 }}
    >
      {(props) => (
        <div
          ref={outer}
          className={`absolute p-1 bg-white ${
            data.found === true ? "border-blue-200" : "border-gray-200"
          } border rounded-md shadow-sm text-xs`}
          style={{
            left: props.left,
            top: props.top,
            marginLeft: -x,
            maxWidth: 200,
          }}
        >
          <div
            ref={drop}
            className={
              isActive
                ? "p-4 rounded border-2 border-dashed border-blue-500"
                : ""
            }
          >
            <UnitTooltip unit={unit}>
              <Link
                to={`/units/${unit?._id}/details`}
                className="block text-center p-1"
              >
                {unit?.name}
              </Link>
            </UnitTooltip>

            <Stats
              showMetrics={["employeesH", "t", "phi", "r"]}
              humatics={unit?.humatics}
              jobHumatics={unit?.jobHumatics}
            />

            {isActive && <p>Loslassen zum hinzufügen</p>}

            {unit?.employeeCount > 0 && (
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="my-2 w-full flex justify-between px-2 py-1 text-xs font-medium text-left text-gray-900 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                      <span>{unit?.employeeCount} Mitarbeiter</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "transform rotate-180" : ""
                        } w-5 h-5 text-gray-500`}
                      />
                    </Disclosure.Button>
                    {(open || data.found) && (
                      <div>
                        <Disclosure.Panel
                          static
                          className="px-2 pt-2 pb-1 text-sm text-gray-500"
                        >
                          <div>
                            <p className="text-xs" style={{ maxWidth: 200 }}>
                              Zum Versetzen Mitarbeiter in anderen
                              Organigramm-Bereich ziehen
                            </p>
                            {unit?.employees.map((employee: any) => (
                              <Employee
                                key={employee._id}
                                employee={employee}
                                filterData={filterData}
                              />
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </div>
                    )}
                  </>
                )}
              </Disclosure>
            )}

            <button
              className="w-full flex justify-center rounded-md hover:bg-gray-100"
              onClick={() => {
                onClick?.();
              }}
            >
              {hasChildren ? (
                <ChevronUpIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600" />
              ) : (
                unit?.children?.length > 0 && (
                  <ChevronDownIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600" />
                )
              )}
            </button>
          </div>
        </div>
      )}
    </Spring>
  );
};

export default UnitNode;
