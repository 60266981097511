import { Transition } from "@headlessui/react";
import React, { useContext } from "react";
import SlideOverContext from "../../context/SliderOverContext";
import FormWrapper from "./FormWrapper";

interface Props {
  title: string
  description?: string
  formId?: string
  children: React.ReactNode
};

const SlideOver = ({ title, description, formId, children }: Props) => {
  const { open, toggle } = useContext(SlideOverContext);

  /*
  const ref = useRef(null);

  const clickOut = useCallback(
    (e) => {
      // if (!ref.current.contains(e.target) && open) {
      //   console.log("toggle");
      // }
    },
    [open, ref]
  );

  useEffect(() => {
    window.addEventListener('mousedown', clickOut);

    return () => {
      window.removeEventListener('mousedown', clickOut);
    };
  }, [clickOut]);
  */

  return (
    <Transition show={open}>
      <div className="fixed inset-0 overflow-hidden z-10">
        <div className="absolute inset-0 overflow-hidden">
          <section className="absolute inset-y-0 pl-16 max-w-full right-0 flex">
            <Transition.Child
              className="w-screen max-w-md"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <FormWrapper title={title} description={description} toggle={toggle} formId={formId}>
                {children}
              </FormWrapper>
            </Transition.Child>
          </section>
        </div>
      </div>
    </Transition>
  );
};

export default SlideOver;
