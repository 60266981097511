import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Popover, Transition } from "@headlessui/react";
import {
  CogIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/solid";
import { useIntl } from "react-intl";

import { useSpring, animated, config } from "react-spring";
import useHeight from "../../../hooks/useHeight";

import Nav, { NavItem } from "./components/Nav";
import Topbar from "./components/Topbar";
import Switch from "../Switch";

interface Props {
  navItems: Array<NavItem>;
  title: String;
}

const Header: React.FC<Props> = ({ navItems, title }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { formatMessage: f } = useIntl();

  const [heightRef, height] = useHeight();
  const slideInStyles = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, height: 0 },
    to: {
      opacity: isOpen ? 1 : 0,
      height: isOpen ? height : 0,
    },
  });

  return (
    <>
      <Nav navItems={navItems} />
      <header className="bg-white shadow relative">
        <animated.div style={{ ...slideInStyles, overflow: "hidden" }}>
          <div
            ref={heightRef}
            className="relative max-w-7xl mx-auto py-3 px-4 sm:px-6 lg:px-8 flex sm:flex-col ease-linear"
          >
            <Topbar isOpen={isOpen} />
          </div>
        </animated.div>
        <div className="absolute m-4 right-0 top-0 cursor-pointer">
          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button>
                  <CogIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                </Popover.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel
                    static
                    className="absolute z-10 w-screen max-w-sm px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 lg:max-w-3xl"
                  >
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                        <Switch />
                      </div>
                      <div className="p-4 bg-gray-50">
                        <Link
                          to="/users/settings"
                          className="flow-root px-2 py-2 transition duration-150 ease-in-out rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <span className="flex items-center">
                            <span className="text-sm font-medium text-gray-900">
                              {f({ id: "user.settings" })}
                            </span>
                          </span>
                          <span className="block text-sm text-gray-500">
                            {f({ id: "user.settings.description" })}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <div
            className="mt-2 animate-bounce"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <ChevronDoubleUpIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" />
            ) : (
              <ChevronDoubleDownIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" />
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
