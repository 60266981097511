import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { JobDescription } from "../../types";

interface Props {
  jobDescription: JobDescription;
}

const Item = ({ jobDescription }: Props) => {
  return (
    <tr>
      <td className="px-6 py-4">
        <div className="flex items-center">
          <Link
            to={`/jobDescriptions/${jobDescription._id}/details`}
            className="ml-4"
          >
            <div className="text-sm font-medium text-gray-900">
              {jobDescription.name}
            </div>
          </Link>
        </div>
      </td>
      <td className="px-6 py-4">
        <div className="text-sm font-medium text-gray-900">
          {jobDescription.title}
        </div>
      </td>
      <td className="px-6 py-4">
        <div className="text-sm font-medium text-gray-900">
          {jobDescription.description}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {jobDescription.createdAt &&
          format(parseISO(jobDescription.createdAt), "do MMMM", {
            locale: de,
          })}
      </td>
    </tr>
  );
};

export default Item;
