import { gql } from "@apollo/client";

export const SKILL_TYPE_MANY = gql`
  query {
    skillTypeMany {
      name
      description
      createdAt
      _id
    }
  }
`;

export const SKILL_TYPE_BY_ID = gql`
         query($_id: ID!) {
           skillTypeById(_id: $_id) {
             name
             description
             skills {
               name
               employeeCount
               jobCount
               seminarCount
               supply
               demand
               createdAt
               _id
             }
             createdAt
             _id
           }
         }
       `;

export const SKILL_TYPE_CREATE_ONE = gql`
  mutation($name: String!, $description: String) {
    skillTypeCreateOne(input: { name: $name, description: $description }) {
      _id
      name
    }
  }
`;

export const SKILL_TYPE_UPDATE_BY_ID = gql`
  mutation($_id: ID!, $name: String!, $description: String) {
    skillTypeUpdateById(
      input: { name: $name, description: $description }
      _id: $_id
    ) {
      _id
      name
    }
  }
`;

export const SKILL_TYPE_REMOVE_BY_ID = gql`
  mutation($_id: ID!) {
    skillTypeRemoveById(_id: $_id) {
      name
    }
  }
`;
