import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useQuery } from "@apollo/client";

import { EmployeeSelect } from "../common/form";
import { EMPLOYEE_MANY } from "../../schema/employee";
import { Team, Employee, Revenue, Requirement } from "../../types";

import { Loading, Error } from "../ui/Alerts";

interface Props {
  onSubmit: (formData: any) => void;
  data?: Team;
}

export type FormData = {
  name: string;
  description: string;
  leaderId: string;
  memberIds: Array<string>;
  revenues: Array<Revenue>;
  requirements: Array<Requirement>;
};

const Form = ({ onSubmit, data }: Props) => {
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: data,
  });
  const { loading, error, data: employeeData } = useQuery(EMPLOYEE_MANY);

  // useEffect(() => {
  //   setValue("name", data?.name);
  //   setValue("description", data?.description);
  //   setValue("memberIds", data?.memberIds);
  // }, [setValue, data]);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const options = employeeData?.employeeMany.map(
    (employee: Employee) =>
      ({
        id: employee._id,
        name: `${employee.firstName} ${employee.lastName}`,
      } as any)
  );

  console.log(watch());
  console.log("getValues", getValues("memberIds"));

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="slide-over-form">
      <div className="flex-1 flex flex-col justify-between">
        <div className="px-4 divide-y divide-gray-200 sm:px-6">
          <div className="space-y-6 pt-6 pb-5">
            <div className="space-y-1">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                {...register("name", { required: true })}
                className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />

              {errors.name && (
                <span className="text-sm font-light text-red-500">
                  Name fehlt
                </span>
              )}
            </div>
            <div className="space-y-1">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Beschreibung
              </label>
              <div className="mt-1">
                <textarea
                  id="description"
                  {...register("description")}
                  className="shadow-sm focus:ring-orange-500 focus:border-orange-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                ></textarea>
              </div>
              <p className="mt-2 text-sm text-gray-500">
                Kurze Beschreibung dieses Teams
              </p>
            </div>
            <div className="space-y-1">
              <label
                htmlFor="leader-id"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Leiter
              </label>
              <Controller
                control={control}
                name="leaderId"
                render={({ field }) => <EmployeeSelect {...field} />}
                defaultValue=""
              />
            </div>
            <div className="space-y-1">
              <label
                htmlFor="memberIds"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Mitglieder
              </label>
              <div className="mt-1">
                <Controller
                  control={control}
                  name="memberIds"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      placeholder="Mitglieder wählen..."
                      options={options}
                      value={options.filter(({ id }: any) =>
                        value?.includes(id)
                      )}
                      getOptionLabel={({ name }: any) => name}
                      getOptionValue={({ id }: any) => id}
                      isLoading={loading}
                      isMulti
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onBlur={onBlur}
                      onChange={(values: any) =>
                        onChange((values ?? []).map((d: any) => d.id))
                      }
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
