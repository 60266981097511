import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";

import { SKILL_COUNT } from "../../schema/skill";
import { EMPLOYEE_COUNT } from "../../schema/employee";
import { JOB_COUNT } from "../../schema/job";

const Stats = () => {
  const { data: skillData } = useQuery(SKILL_COUNT);
  const { data: employeeData } = useQuery(EMPLOYEE_COUNT);
  const { data: jobData } = useQuery(JOB_COUNT);

  const { formatMessage: f } = useIntl();

  return (
    <div>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {f({ id: "metrics.name" })}
      </h3>
      <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <Link to={`/skills`}>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6 text-center">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                  {f({ id: "skills.name" })}
                </dt>
                <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                  {skillData?.skillCount}
                </dd>
              </dl>
            </div>
          </div>
        </Link>
        <Link to={`/employees`}>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6 text-center">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                  {f({ id: "employees.name" })}
                </dt>
                <dd className="mt-1 text-3xl text-center leading-9 font-semibold text-gray-900">
                  {employeeData?.employeeCount}
                </dd>
              </dl>
            </div>
          </div>
        </Link>
        <Link to={`/jobs`}>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6 text-center">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                  {f({ id: "jobs.name" })}
                </dt>
                <dd className="mt-1 text-3xl text-center leading-9 font-semibold text-gray-900">
                  {jobData?.jobCount}
                </dd>
              </dl>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Stats;
