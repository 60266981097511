import { useForm } from "react-hook-form";

import type { Role } from "types/role";

interface Props {
  id: string;
  roles: Role[];
  onSubmit: (formData: any) => void;
}

export type FormData = {
  name: string;
  inheritFrom: string;
};

export const NewRoleForm = ({ id, roles = [], onSubmit = () => {} }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={id}>
      <div className="flex-1 flex flex-col justify-between">
        <div className="px-4 divide-y divide-gray-200 sm:px-6">
          <div className="space-y-6 pt-6 pb-5">
            <div className="space-y-1">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Rollename
              </label>
              <input
                type="text"
                id="name"
                {...register("name", { required: true })}
                className="mt-1 focus:ring-brand-secondary focus:border-brand-secondary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />

              {errors.name && (
                <span className="text-sm font-light text-red-500">
                  Benutzername fehlt
                </span>
              )}
            </div>

            <div className="space-y-1">
              <label
                htmlFor="inheritFrom"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Berechtigungen von dieser Rolle übernehmen
              </label>
              <select
                className="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-brand-secondary focus:border-brand-secondary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-brand-primary dark:focus:border-brand-primary"
                {...register("inheritFrom")}
              >
                <option></option>
                {roles.map((r: Role) => (
                  <option key={r._id} value={r._id}>
                    {r.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
