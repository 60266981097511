import styled from "styled-components";

const Spinner = styled.div`
  @keyframes rotate1 {
    0% {
      -webkit-transform: rotate(-360deg);
    }
  }
  @keyframes rotate2 {
    0%,
    50% {
      -webkit-transform: rotate(-360deg);
    }
  }
  @keyframes grow {
    0% {
      stroke-dashoffset: 0;
    }
  }

  margin: 0 auto;
  width: 2rem;
  height: 2rem;
  animation: rotate1 4.5s infinite linear;

  svg {
    animation: rotate2 2s infinite linear;
  }

  path {
    fill: none;
    stroke: #fecd1b;
    stroke-width: 18;
    stroke-dasharray: 600;
    stroke-dashoffset: 580;
    animation: grow 1s alternate infinite linear;
  }
`;

const Loading = () => {
  return (
    <Spinner>
      <svg viewBox="-10 -10 220 220">
        <path d="M200,100 C200,44.771525 155.228475,0 100,0 C44.771525,0 0,44.771525 0,100 C0,155.228475 44.771525,200 100,200 C155.228475,200 200,155.228475 200,100 Z"></path>
      </svg>
    </Spinner>
  );
};

export default Loading;
