import { gql } from "@apollo/client";

export const permissionTypes = gql`
  input PermissionFilterInput {
    name: String
  }

  input PermissionEntryInput {
    _id: ID!
    role: RoleInput!
    all: Int
    group: Int
    self: Int
  }

  input UpdatePermissionInput {
    _id: ID!
    name: String!
    displayName: String
    module: String
    entries: [PermissionEntryInput]
  }
`;

export const PERMISSION_MANY = gql`
  query(
    $filters: PermissionFilterInput
  ) {
    permissionMany(filters: $filters) {
      _id
      name
      displayName
      module
      entries {
        _id
        role {
          _id
          name
        }
        all
        group
        self
      }
    }
  }
`;

export const PERMISSION_BY_ID = gql`
  query {
    permissionById(id: ID) {
      _id
      name
      displayName
      module
      entries {
        _id
        role {
          _id
          name
        }
        all
        group
        self
      }
    }
  }
`;

export const UPDATE_PERMISSION = gql`
  mutation($permission: UpdatePermissionInput!) {
    updatePermission(
      permission: $permission
    ) {
      _id
      name
      displayName
      module
      entries {
        _id
        role {
          _id
          name
        }
        all
        group
        self
      }
    }
  }
`;