import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import Select from "react-select";

import { SKILL_MANY } from "../../schema/skill";
import { Skill } from "../../types";

const DashboardSearch = () => {
  const { formatMessage: f } = useIntl();

  const { loading, data: skillData } = useQuery(SKILL_MANY);

  const options =
    skillData?.skillMany.map(
      (skill: Skill) =>
        ({
          id: skill._id,
          name: skill.name,
        } as any)
    ) || [];

  const onChange = (values: []) => {
    console.log(values);
  };

  return (
    <>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {f({ id: "actions.search" })}
      </h3>
      <div className="mt-5">
        <Select
          placeholder="Kompetenzen wählen..."
          options={options}
          getOptionLabel={({ name }: any) => name}
          getOptionValue={({ id }: any) => id}
          isLoading={loading}
          isMulti
          className="text-xs"
          classNamePrefix="select"
          onChange={(values: any) =>
            onChange((values ?? []).map((d: any) => d.id))
          }
        />
      </div>
    </>
  );
};

export default DashboardSearch;
