import { useCallback } from "react";
import { useParams, useHistory, RouteComponentProps } from "react-router-dom";
import { useIntl } from "react-intl";

import { useQuery, useApolloClient } from "@apollo/client";
import { JOB_BY_ID } from "../../schema/job";
import { FILTER_VACANCY_MANY } from "../../schema/vacancy";

import { Loading, Error } from "../ui/Alerts";
import List from "../vacancies/List";
import { H1, H2 } from "../ui/Typo/Typo";

interface ParamTypes {
  id: string;
}

const Vacancies = ({}: RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { formatMessage: f } = useIntl();

  const {
    loading: jobLoading,
    error: jobError,
    data: jobData,
  } = useQuery(JOB_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const { data: vacancyData } = useQuery(FILTER_VACANCY_MANY, {
    variables: {
      jobId: id,
    },
  });

  return (
    <>
      {jobLoading && <Loading />}
      {jobError && <Error error={jobError} />}

      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
        <div className="ml-4 mt-2 mb-8">
          <H1>{f({ id: "vacancies.name" })}</H1>
          <H2>{jobData?.jobById.name}</H2>
        </div>
        <div className="p-6 bg-white ml-4 mt-2 flex-shrink-0">
          <span className="inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
              onClick={() => history.goBack()}
            >
              {f({ id: "actions.back" })}
            </button>
          </span>
        </div>
      </div>

      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
        {`${vacancyData?.vacancyMany.length} Einträge`}
      </h2>
      <List data={vacancyData?.vacancyMany} />
    </>
  );
};

export default Vacancies;
