import { gql } from "@apollo/client";

export const REVIEW_MANY = gql`
  query {
    reviewMany {
      name
      description
      employeeId
      employee {
        firstName
        lastName
      }
      createdAt
      _id
    }
  }
`;

export const REVIEW_BY_ID = gql`
  query($_id: ID!) {
    reviewById(_id: $_id) {
      name
      description
      employeeId
      employee {
        firstName
        lastName
        salary
        humatics {
          h
          t
          ef
          mu
        }
        competences {
          skillId
          skill {
            name
          }
          value
        }
      }
      humatics {
        h
        t
        ef
        mu
      }
      competences {
        skillId
        skill {
          name
        }
        value
      }
      competenceCoverage
      createdAt
      _id
    }
  }
`;

export const REVIEW_CREATE_ONE = gql`
  mutation(
    $name: String!
    $description: String
    $employeeId: ID!
    $competences: [CompetenceInput]
  ) {
    reviewCreateOne(
      input: {
        name: $name
        description: $description
        employeeId: $employeeId
        competences: $competences
      }
    ) {
      _id
      name
    }
  }
`;

export const REVIEW_UPDATE_BY_ID = gql`
  mutation($_id: ID!, $name: String!, $description: String, $employeeId: ID!) {
    reviewUpdateById(
      input: { name: $name, description: $description, employeeId: $employeeId }
      _id: $_id
    ) {
      _id
      name
    }
  }
`;

export const REVIEW_REMOVE_BY_ID = gql`
  mutation($_id: ID!) {
    reviewRemoveById(_id: $_id) {
      name
    }
  }
`;
