import { useFormContext } from "react-hook-form";

const LastNameField = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="last-name"
        className="block text-sm font-medium leading-5 text-gray-900"
      >
        Nachname
      </label>
      <input
        type="text"
        id="last-name"
        {...register("lastName", { required: true })}
        autoComplete="given-name"
        className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      />

      {errors.lastName && (
        <span className="text-sm font-light text-red-500">Nachname fehlt</span>
      )}
    </>
  );
};

export default LastNameField;
