import { useState, ReactElement } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Skill } from "../../types";

import {
  HorizontalNavigation,
  HorizontalNavigationOption,
} from "../ui/navigation/HorizontalNavigation";

import {
  NameField,
  CostsField,
  ExpirationYearField,
  DescriptionField,
  ColorField,
  SkillTypeField,
  SkillLevelField,
} from "../common/form";

const tabs: HorizontalNavigationOption[] = [
  { value: "common", label: "Allgemein" },
  { value: "extended", label: "Erweitert" },
];

interface Props {
  onSubmit: (formData: any) => void;
  data?: Skill;
}

export type FormData = {
  name: string;
  description: string;
  costs: number;
  expirationYear: number;
  color: string; // RGBColor
  skillTypeId: string;
  skillLevelId: string;
  core: boolean;
  future: boolean;
  forEmployee: boolean;
  forManager: boolean;
};

const Form = ({ onSubmit, data }: Props) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const methods = useForm<FormData>({ defaultValues: data });
  const { handleSubmit, register } = methods;

  let tab: ReactElement;
  switch (selectedTab.value) {
    case "extended":
      tab = (
        <>
          <div className="space-y-1">
            <fieldset>
              <legend className="text-base leading-6 font-medium text-gray-900">
                Art der Kompetenz
              </legend>
              <div className="mt-4">
                <div className="flex items-start">
                  <div className="h-5 flex items-center">
                    <input
                      type="checkbox"
                      id="core"
                      {...register("core")}
                      className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-5">
                    <label htmlFor="core" className="font-medium text-gray-700">
                      Kernkompetenz
                    </label>
                    <p className="text-gray-500">
                      Erklärungstext zu dieser Einstellung
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex items-start">
                  <div className="h-5 flex items-center">
                    <input
                      type="checkbox"
                      id="future"
                      {...register("future")}
                      className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-5">
                    <label
                      htmlFor="future"
                      className="font-medium text-gray-700"
                    >
                      Zukunftskompetenz
                    </label>
                    <p className="text-gray-500">
                      Zukünftig benötigte Kompetenz
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex items-start">
                  <div className="h-5 flex items-center">
                    <input
                      type="checkbox"
                      id="forEmployee"
                      {...register("forEmployee")}
                      className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-5">
                    <label
                      htmlFor="forEmployee"
                      className="font-medium text-gray-700"
                    >
                      Mitarbeiterkompetenz
                    </label>
                    <p className="text-gray-500">
                      Erklärungstext zu dieser Einstellung
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex items-start">
                  <div className="h-5 flex items-center">
                    <input
                      type="checkbox"
                      id="forManager"
                      {...register("forManager")}
                      className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-5">
                    <label
                      htmlFor="forManager"
                      className="font-medium text-gray-700"
                    >
                      Führungskompetenz
                    </label>
                    <p className="text-gray-500">
                      Erklärungstext zu dieser Einstellung
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </>
      );
      break;
    case "common":
      tab = (
        <>
          <div className="space-y-1">
            <NameField />
          </div>
          <div className="flex space-x-6">
            <div className="w-1/2 space-y-1">
              <CostsField />
            </div>
            <div className="w-1/2 space-y-1">
              <ExpirationYearField />
            </div>
          </div>
          <div className="space-y-1">
            <DescriptionField />
          </div>
          <div className="space-y-2">
            <ColorField color={data?.color} />
          </div>
          <div className="space-y-2">
            <SkillTypeField />
          </div>
          <div className="space-y-2">
            <SkillLevelField />
          </div>
        </>
      );
      break;
    default:
      throw Error("Tab unreachable");
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} id="slide-over-form">
        <div className="flex-1 flex flex-col justify-between">
          <div className="px-4 divide-y divide-gray-200 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              <HorizontalNavigation
                options={tabs}
                selected={selectedTab}
                onSelect={setSelectedTab}
              />
              {tab}
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default Form;
