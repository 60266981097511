import React from "react";
import { Route as ReactRouterRoute, Redirect, useLocation } from "react-router-dom";
import type { RouteProps } from "react-router-dom";

import { usePermissions } from "hooks/usePermissions";
import { scope, PermissionFlag } from "types/permission";

interface IProps extends RouteProps {
  module?: string;
  scopeRequired?: scope;
  flag: PermissionFlag;
  transparent?: boolean;
};

export const Route: React.FC<IProps> = ({
  module,
  scopeRequired,
  flag = PermissionFlag.READ,
  transparent = false,
  ...props
}) => {
  const location = useLocation();
  const { hasPermissionScope, loading } = usePermissions();

  const disabled = module ? (loading || !hasPermissionScope(module, flag, scopeRequired)) : false;

  if (loading) {
    return transparent ? <ReactRouterRoute {...props } />: null;
  }

  if (disabled) {
    const to = { pathname: "/users/login", state: { from: location } };
    return <ReactRouterRoute render={() => (<Redirect to={to} />)} />;
  }

  return <ReactRouterRoute {...props} />;
};

