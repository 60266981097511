import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Transition } from "@headlessui/react";
import styled, { css } from "styled-components";
import clsx from "clsx";
import { useIntl } from "react-intl";

import { IS_CONCEALED } from "../../schema/settings";
import { numberFormater, currencyFormater } from "../../config/i18n";
import { Humatics } from "../../types";
import MetricTooltip from "../competences/MetricTooltip";

interface BoxProps {
  colorGradient?: boolean | false;
}

const Box = styled.div.attrs({
  className: "bg-white shadow rounded-lg",
})`
  ${(props: BoxProps) =>
    props.colorGradient &&
    css`
      position: relative;

      &:after {
        position: absolute;
        left: 1%;
        bottom: 85px;
        content: "";
        width: 98%;
        height: 3px;
        background: #df3341;
        background: -moz-linear-gradient(
          left,
          #df3341 0%,
          #d4f355 50%,
          #61c0ec 100%
        );
        background: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(0%, #df3341),
          color-stop(50%, #d4f355),
          color-stop(100%, #61c0ec)
        );
        background: -webkit-linear-gradient(
          left,
          #df3341 0%,
          #d4f355 50%,
          #61c0ec 100%
        );
        background: -o-linear-gradient(
          left,
          #df3341 0%,
          #d4f355 50%,
          #61c0ec 100%
        );
        background: -ms-linear-gradient(
          left,
          #df3341 0%,
          #d4f355 50%,
          #61c0ec 100%
        );
        background: linear-gradient(
          to right,
          #df3341 0%,
          #d4f355 50%,
          #61c0ec 100%
        );
      }
    `}
`;

interface Props {
  title?: string;
  humatics: Humatics;
  jobHumatics?: Humatics;
  actualRevenue?: number;
  targetRevenue?: number;
  knowledgeValue?: number;
}

const Stats = ({
  title,
  humatics,
  jobHumatics,
  actualRevenue,
  targetRevenue,
  knowledgeValue,
}: Props) => {
  const { data: settingsData } = useQuery(IS_CONCEALED);
  const [isOpen, setIsOpen] = useState(true);

  const { formatMessage: f } = useIntl();

  const valueClassName = clsx(
    "mt-1 text-3xl leading-9 font-semibold text-gray-900",
    {
      concealed: settingsData.isConcealed,
    }
  );

  return (
    <div className="p-6 bg-gray-50 rounded-lg">
      <h3
        className="text-gray-500 text-xs font-medium uppercase tracking-wide cursor-pointer hover:underline"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title || f({ id: "stats.name" })}
      </h3>
      <Transition show={isOpen}>
        <div className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
          <Box>
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500">
                  <MetricTooltip text={f({ id: "humatics.h.sum.description" })}>
                    <>{f({ id: "humatics.h.sum" })}</>
                  </MetricTooltip>
                </dt>
                <dd className={valueClassName}>
                  {numberFormater.format(humatics?.employeesH)}
                </dd>
              </dl>
              <span className="text-sm leading-7 font-light text-gray-500 truncate">
                {f({ id: "target" })}:{" "}
                {jobHumatics
                  ? numberFormater.format(jobHumatics.employeesH)
                  : null}
              </span>
            </div>
          </Box>
          <Box colorGradient>
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500">
                  <MetricTooltip text={f({ id: "humatics.t.description" })}>
                    <>{f({ id: "humatics.t" })}</>
                  </MetricTooltip>
                </dt>
                <dd className={valueClassName}>
                  {numberFormater.format(humatics?.t)}
                </dd>
              </dl>
              <span className="text-sm leading-7 font-light text-gray-500 truncate">
                {f({ id: "target" })}:{" "}
                {jobHumatics ? numberFormater.format(jobHumatics.t) : null}
              </span>
            </div>
          </Box>
          <Box>
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500">
                  <MetricTooltip text={f({ id: "humatics.phi.description" })}>
                    <>{f({ id: "humatics.phi" })}</>
                  </MetricTooltip>
                </dt>
                <dd className={valueClassName}>
                  {numberFormater.format(humatics?.phi)}
                </dd>
                <span className="text-sm leading-7 font-light text-gray-500 truncate">
                  {f({ id: "target" })}:{" "}
                  {jobHumatics ? numberFormater.format(jobHumatics.phi) : null}
                </span>
              </dl>
            </div>
          </Box>
          <Box>
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500">
                  <MetricTooltip text={f({ id: "humatics.r.description" })}>
                    <>{f({ id: "humatics.r" })}</>
                  </MetricTooltip>
                </dt>
                <dd className={valueClassName}>
                  {numberFormater.format(Math.floor(humatics?.r || 0))}
                </dd>
                <span className="text-sm leading-7 font-light text-gray-500 truncate">
                  {f({ id: "target" })}:{" "}
                  {jobHumatics
                    ? numberFormater.format(Math.floor(jobHumatics?.r || 0))
                    : null}
                </span>
              </dl>
            </div>
          </Box>
          {(actualRevenue != null || targetRevenue != null) && (
            <Box>
              <div className="px-4 py-5 sm:p-6">
                <dl>
                  <dt className="text-sm leading-5 font-medium text-gray-500">
                    <MetricTooltip
                      text={f({ id: "job.revenues.target.description" })}
                    >
                      <>{f({ id: "revenues.year.target" })}</>
                    </MetricTooltip>
                  </dt>
                  <dd className={valueClassName}>
                    {targetRevenue && currencyFormater.format(targetRevenue)}
                  </dd>
                  <span className="text-sm leading-7 font-light text-gray-500 truncate">
                    {actualRevenue && (
                      <>
                        {f({ id: "actual" })}:{" "}
                        {currencyFormater.format(actualRevenue)}
                      </>
                    )}
                  </span>
                </dl>
              </div>
            </Box>
          )}
          <Box>
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500">
                  <MetricTooltip text={f({ id: "humatics.m.description" })}>
                    <>{f({ id: "humatics.m" })}</>
                  </MetricTooltip>
                </dt>
                <dd className={valueClassName}>
                  {knowledgeValue != null
                    ? currencyFormater.format(knowledgeValue)
                    : null}
                </dd>
              </dl>
            </div>
          </Box>
        </div>
      </Transition>
    </div>
  );
};

export default Stats;
