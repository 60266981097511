import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Transition } from "@headlessui/react";
import {
  useParams,
  useHistory,
  match,
  RouteComponentProps,
} from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  VACANCY_BY_ID,
  VACANCY_UPDATE_BY_ID,
  VACANCY_REMOVE_BY_ID,
  VACANCY_MANY,
} from "../../schema/vacancy";
import { SKILL_MANY } from "../../schema/skill";
import { SKILL_SCALE_MANY } from "../../schema/skillScale";
import { Competence, SkillScale } from "../../types";

import { useDrop } from "react-dnd";
import { ItemTypes } from "../../config/ItemTypes";

import SliderOverContext from "../../context/SliderOverContext";
import SlideOverList from "../skills/SliderOverList/SlideOverList";
import useToggle from "../../hooks/useToogle";

import { Loading, Error } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import PageHeader from "../ui/Headings/PageHeader";
import { Breadcrumb } from "../ui/navigation/Breadcrumb";

import Form, { FormData } from "./Form";
import { H3 } from "../ui/Typo/Typo";
import CompetenceFields from "../competences/Fields";
import Stats from "../competences/Stats";

import ProfileChart from "../charts/ProfileChart";

interface ParamTypes {
  id: string;
}

interface Props {
  required: string;
  match?: match<ParamTypes>;
}

const Profile = ({}: Props & RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { formatMessage: f } = useIntl();

  const [open, toggle] = useToggle();
  const [openSkills, toggleSkills] = useToggle();
  const [isOpen, setIsOpen] = useState(false);

  const { loading, error, data } = useQuery(VACANCY_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateVacancy] = useMutation(VACANCY_UPDATE_BY_ID, {
    refetchQueries: [{ query: VACANCY_MANY }],
  });
  const [updateVacancyCompetences] = useMutation(VACANCY_UPDATE_BY_ID, {
    refetchQueries: [
      {
        query: VACANCY_BY_ID,
        variables: {
          _id: id,
        },
      },
    ],
    update(cache, { data: { vacancyUpdateById } }) {
      try {
        cache.writeQuery({
          query: VACANCY_BY_ID,
          variables: { _id: id },
          data: {
            vacancyUpdateById: vacancyUpdateById,
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
  });
  const [deleteVacancy] = useMutation(VACANCY_REMOVE_BY_ID, {
    refetchQueries: [{ query: VACANCY_MANY }],
  });
  const { data: skillData } = useQuery(SKILL_MANY);
  const { data: skillScaleData } = useQuery(SKILL_SCALE_MANY);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.SKILL,
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return;
      }
      console.log(`Dropped ${(item as any).skill.name}`);
      const skill = (item as any).skill;

      const newCompetence = {
        skillId: skill._id,
        value: 0,
      };

      const competences = data?.vacancyById.competences.map(
        (c: Competence) => ({
          skillId: c.skillId,
          value: c.value,
        })
      );

      updateVacancyCompetences({
        variables: {
          _id: id,
          competences: [...competences, newCompetence],
        },
      });

      return { name: "Profile" };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    if (!id) {
      history.push("/");
      return;
    }
  }, [id, history]);

  const onUpdateCompetences = (competences: Competence[]) => {
    updateVacancy({
      variables: {
        _id: id,
        competences: competences.map((c) => ({
          skillId: c.skillId,
          value: +c.value,
        })),
      },
    });
  };

  const onDeleteCompetence = (competence: Competence) => {
    const competences = data?.vacancyById.competences
      .map((item: any) => {
        const { __typename, ...obj } = item;
        return obj;
      })
      .filter((item: any) => {
        return item.skillId !== competences.skillId;
      });

    updateVacancyCompetences({
      variables: {
        _id: id,
        competences: competences,
      },
    });
  };

  const onUpdate = (formData: FormData) => {
    updateVacancy({
      variables: {
        _id: id,
        name: formData.name,
        description: formData.description,
        salary: +formData.salary,
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteVacancy({ variables: { _id: id } });
    history.goBack();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const isActive = canDrop && isOver;

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Stellenangebot bearbeiten">
          <Form onSubmit={onUpdate} data={data.vacancyById} />
        </SlideOver>
      </SliderOverContext.Provider>

      <SliderOverContext.Provider
        value={{ open: openSkills, toggle: toggleSkills }}
      >
        <SlideOverList
          data={skillData?.skillMany}
          competences={data.vacancyById?.competences}
          onSelect={(competences: Competence[]) =>
            onUpdateCompetences(competences)
          }
          defaultCompetenceValue={50}
        >
          {f({ id: "profile.actions.drop" })}
        </SlideOverList>
      </SliderOverContext.Provider>

      <PageHeader
        title={data.vacancyById.name}
        onUpdate={toggle}
        onDelete={onDelete}
      >
        <Breadcrumb>
          <Breadcrumb.Item url="/">{f({ id: "nav.home" })}</Breadcrumb.Item>
          <Breadcrumb.Item url="/vacancies">
            {f({ id: "vacancies.name" })}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{`${data.vacancyById?.name}`}</Breadcrumb.Item>
          <Breadcrumb.Item>{f({ id: "profile.name" })}</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>

      {/* Stats */}
      <Stats
        humatics={data.vacancyById?.humatics}
        targetRevenue={data.vacancyById?.targetRevenue}
      />

      <hr className="my-6 border-2 rounded-full border-gray-200" />

      <div className="my-6">
        <span className="relative z-0 inline-flex shadow-sm">
          <button
            type="button"
            className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
            onClick={toggleSkills}
          >
            {f({ id: "competences.add" })}
          </button>
          <button
            type="button"
            className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
            onClick={() => setIsOpen(!isOpen)}
          >
            {f({ id: "views.table" })}
          </button>
        </span>
      </div>

      <Transition show={isOpen}>
        <CompetenceFields
          data={data.vacancyById?.competences}
          onSubmit={(formData) => onUpdateCompetences(formData.competences)}
        />
      </Transition>

      <div
        ref={drop}
        className={
          isActive ? "p-4 rounded border-2 border-dashed border-blue-500" : ""
        }
      >
        <div className="my-4 text-xs uppercase font-light text-gray-500">
          <H3>
            {isActive
              ? "Loslassen zum hinzufügen"
              : "Kompetenz hier reinziehen"}
          </H3>
        </div>

        <ProfileChart
          width={900}
          height={400}
          data={data.vacancyById?.competences}
          markers={skillScaleData?.skillScaleMany.map((s: SkillScale) => ({
            label: s.name,
            value: s.value * 100,
          }))}
          revenue={data.vacancyById?.targetRevenue}
          onDelete={onDeleteCompetence}
          onChange={(competences) => {
            updateVacancyCompetences({
              variables: {
                _id: id,
                competences: competences.map((c: Competence) => ({
                  skillId: c.skillId,
                  value: c.value,
                })),
              },
            });
          }}
        />
      </div>
    </>
  );
};

export default Profile;
