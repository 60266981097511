import { gql } from "@apollo/client";

export const SKILL_SCALE_MANY = gql`
  query {
    skillScaleMany {
      name
      description
      level
      value
      range
      createdAt
      _id
    }
  }
`;

export const SKILL_SCALE_CREATE_ONE = gql`
  mutation($name: String!, $description: String, $level: Int!, $value: Float) {
    skillScaleCreateOne(
      input: {
        name: $name
        description: $description
        level: $level
        value: $value
      }
    ) {
      _id
      name
    }
  }
`;

export const SKILL_SCALE_UPDATE_BY_ID = gql`
  mutation(
    $_id: ID!
    $name: String!
    $description: String
    $level: Int!
    $value: Float
  ) {
    skillScaleUpdateById(
      input: {
        name: $name
        description: $description
        level: $level
        value: $value
      }
      _id: $_id
    ) {
      _id
      name
    }
  }
`;

export const SKILL_SCALE_REMOVE_BY_ID = gql`
  mutation($_id: ID!) {
    skillScaleRemoveById(_id: $_id) {
      name
    }
  }
`;
