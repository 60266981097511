import { ReactElement, ReactNode, useState } from "react";
import { useIntl } from "react-intl";

import { TranslateIcon } from "@heroicons/react/solid";

import {
  VerticalNavigation,
  VerticalNavigationOption,
} from "../../ui/navigation/VerticalNavigation";

import { LicenseProductTab } from "../LicenseProductTab";
//import Payment from "./Payment";
import Account from "./Account";
import Interface from "./Interface";
import Language from "./Language";
import Notifications from "./Notifications";

const Settings = () => {
  const { formatMessage: f } = useIntl();

  const tabs: VerticalNavigationOption<string>[] = [
    {
      type: "option",
      id: "language",
      value: "#language",
      label: f({ id: "user.language" }),
      icon: <TranslateIcon className="h-5 w-5" />,
      renderOption: (children: ReactNode, option: any) => (
        <a href={option.value}>{children}</a>
      ),
    },
    // {
    //   type: "option",
    //   id: "payment",
    //   value: "#payment",
    //   label: f({ id: "user.payments" }),
    //   icon: <CreditCardIcon className="h-5 w-5" />,
    //   renderOption: (children: ReactNode, option: any) => (
    //     <a href={option.value}>{children}</a>
    //   ),
    // },
    // {
    //   type: "option",
    //   id: "account",
    //   value: "#account",
    //   label: f({ id: "user.account" }),
    //   icon: <CogIcon className="h-5 w-5" />,
    //   renderOption: (children: ReactNode, option: any) => (
    //     <a href={option.value}>{children}</a>
    //   ),
    // },
    // {
    //   type: "option",
    //   id: "notifications",
    //   value: "#notifications",
    //   label: f({ id: "user.notifications" }),
    //   icon: <BellIcon className="h-5 w-5" />,
    //   renderOption: (children: ReactNode, option: any) => (
    //     <a href={option.value}>{children}</a>
    //   ),
    // },
    // {
    //   type: "option",
    //   id: "interface",
    //   value: "#interface",
    //   label: f({ id: "user.api" }),
    //   icon: <CubeTransparentIcon className="h-5 w-5" />,
    //   renderOption: (children: ReactNode, option: any) => (
    //     <a href={option.value}>{children}</a>
    //   ),
    // },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  let tab: ReactElement;
  switch (selectedTab.id) {
    case "language":
      tab = <Language />;
      break;
    case "payment":
      tab = <LicenseProductTab />;
      break;
    case "account":
      tab = <Account />;
      break;
    case "notifications":
      tab = <Notifications />;
      break;
    case "interface":
      tab = <Interface />;
      break;
    default:
      throw Error("Tab unreachable");
  }

  return (
    <main className="flex">
      <aside className="w-64 h-screen bg-white">
        <nav className="mt-10">
          <VerticalNavigation
            options={tabs}
            selected={selectedTab}
            onSelect={setSelectedTab}
          />
        </nav>
      </aside>

      <div className="w-full bg-gray-50 dark:bg-gray-900 dark:text-white text-gray-600 text-sm">
        <div className="flex-grow flex flex-col p-5">{tab}</div>
      </div>
    </main>
  );
};

export default Settings;
