import { gql } from "@apollo/client";

export const PRODUCT_MANY = gql`
  query {
    productMany {
      name
      description
      price
      requiredPurchase
      type
      _id
    }
  }
`;