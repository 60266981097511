import { useState, useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import axios from "axios";
import { useFormContext } from "react-hook-form";
import { useDropzone } from "react-dropzone";

import { Employee } from "../../../types";
import Avatar from "../../employees/Avatar";

interface Props {
  data?: Employee;
}

const PhotoField = ({ data }: Props) => {
  const client = useApolloClient();
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const formData = new FormData();
      formData.append("photo", acceptedFiles[0]);
      // formData.append("employeeId", data ? data._id : '');

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_FILES_URI}/photo/upload`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setValue("photo", response.data.url);

      client.cache.modify({
        id: client.cache.identify(data as any),
        fields: {
          photo() {
            return response.data.url;
          },
        },
      });
    },
    [client.cache, data, setValue]
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    // accept: "image/jpeg, image/jpg, image/png",
    maxFiles: 1,
  });

  return (
    <>
      <div className="space-y-0">
        <label className="block text-sm font-medium leading-5 text-gray-900">
          Photo
        </label>

        <input type="hidden" {...register("photo")} />
        <button type="button" onClick={open}>
          <Avatar employee={data} className="w-10 h-10" />
        </button>
      </div>
      <div className="space-y-0">
        <div
          {...getRootProps({
            className:
              "mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md",
          })}
        >
          <input {...getInputProps()} />

          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
            {isDragActive ? (
              <p>Dateien hier hineinziehen ...</p>
            ) : (
              <>
                <p className="mt-1 text-sm text-gray-600">
                  <button className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out">
                    Datei hochladen
                  </button>{" "}
                  oder hineinziehen
                </p>
                <p className="mt-1 text-xs text-gray-500">
                  PNG, JPG, GIF up to 10MB
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PhotoField;
