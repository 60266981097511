import { forwardRef } from "react";
import { useQuery } from "@apollo/client";
import { EMPLOYEE_MANY } from "../../../schema/employee";
import { Employee } from "../../../types";

import { Loading, Error } from "../../ui/Alerts";

const EmployeeSelect = forwardRef((props: any, ref: any) => {
  const { loading, error, data } = useQuery(EMPLOYEE_MANY);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <select
      {...props}
      ref={ref}
      autoComplete="type"
      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
    >
      <option value=""></option>
      {data.employeeMany.map((employee: Employee) => (
        <option key={employee._id} value={employee._id}>
          {employee.firstName} {employee.lastName}
        </option>
      ))}
    </select>
  );
});

export default EmployeeSelect;
