import { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";

import styled from "styled-components";

import { percentFormater, currencyFormater } from "../../config/i18n";

const SVG = styled.svg`
  path,
  line {
    fill: none;
    stroke: rgba(153, 153, 153, 0.2);
    stroke-width: 1px;
    shape-rendering: crispEdges;
  }
  .marker {
    line {
      stroke: #0028da;
      stroke-width: 1px;
      stroke-linecap: round;
    }
    text {
      fill: #0028da;
    }
  }
`;

interface MarkerItem {
  label: string;
  value: number;
}
interface Props {
  width: number;
  height: number;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  data: any[];
  markers?: MarkerItem[];
  actualRevenue?: number;
}

const GroupedProfileChart = ({
  width,
  height,
  top = 40,
  right = 100,
  bottom = 20,
  left = 30,
  data = [],
  markers = [],
  actualRevenue = 0,
}: Props) => {
  const ref = useRef(null);

  /**
   * Scales
   */

  const x0Scale = d3
    .scaleBand()
    .rangeRound([0, width - right])
    .padding(0.1);
  const x1Scale = d3.scaleBand();
  const yScale = d3.scaleLinear().range([height - bottom, 0]);

  const xAxis = d3
    .axisBottom(x0Scale)
    .tickSize(0)
    .tickFormat((val, index) => data[index]?.skill?.name);
  const yAxis = d3.axisLeft(yScale);

  //const colorScale = d3.scaleOrdinal().range(["#fecd1b", "#485155", "#0132e4"]);
  const colorScale = d3.scaleOrdinal(d3.schemePaired);

  /**
   * MouseHandler
   */

  const rectMouseOverHandler = useCallback((event: MouseEvent, d: any) => {
    const x = event.offsetX - 100;
    const y = 0;
    let html = `<div>${d.skill?.name}</div>`;

    if (actualRevenue) {
      d.values.forEach((item: any) => {
        const sum = data.reduce(
          (memo: any, obj: any) =>
            memo +
            obj.values.find((names: any) => item.name === names.name).value,
          0
        );
        const percent = percentFormater.format(item.value / 100);
        const revenue = currencyFormater.format(
          (item.value / sum) * actualRevenue
        );
        html += `<div>${item.name} ${percent} (${revenue})</div>`;
      });
    }

    d3.select("#tooltip")
      .classed("hidden", false)
      .transition()
      .style("left", `${x}px`)
      .style("top", `${y + 12}px`);
    d3.select("#tooltip span").html(html);
  }, []);

  const rectMouseOutHandler = useCallback((event: MouseEvent) => {
    d3.select("#tooltip").classed("hidden", true);
  }, []);

  /**
   * Draw the chart
   */
  const draw = () => {
    const svg = d3.select(ref.current);

    const skillIds = data.map((d: any) => d.skill._id);
    const names = data[0].values.map((d: any) => d.name);

    x0Scale.domain(skillIds);
    x1Scale.domain(names).range([0, x0Scale.bandwidth()]);

    const maxValue =
      d3.max(data, (item: any) => {
        return d3.max(item.values, (d: any) => {
          return d.value as number;
        });
      }) || 0;
    yScale.domain([0, maxValue]);

    svg
      .select(".x-axis")
      .call(xAxis as any)
      .selectAll("text")
      .attr("text-anchor", "start")
      .attr("transform", "rotate (30 -10 0)");

    svg
      .select(".y-axis")
      .call(yAxis as any)
      .append("text")
      .attr("transform", "rotate(-90)")
      //.attr("y", 6)
      //.attr("dy", ".71em")
      .style("text-anchor", "end")
      .style("font-weight", "bold")
      .text("Wert in %");

    const group = svg
      .selectAll(".group")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "g")
      .attr("transform", (d: any) => `translate(${x0Scale(d.skill._id)},0)`)
      .on("mouseover", rectMouseOverHandler)
      .on("mouseout", rectMouseOutHandler);

    group
      .selectAll("rect")
      .data((d: any) => d.values)
      .enter()
      .append("rect")
      .attr("width", x1Scale.bandwidth())
      .attr("height", (d) => height - yScale(0))
      .attr("x", (d: any) => x1Scale(d.name) as number)
      .attr("y", (d: any) => yScale(0))
      .style("fill", (d: any) => colorScale(d.name) as string);

    group
      .selectAll("rect")
      .transition()
      .duration(1000)
      .attr("y", (d: any) => yScale(d.value))
      .attr("height", (d: any) => height - yScale(d.value));

    const legend = svg
      .selectAll(".legend")
      .data(data[0].values.map((d: any) => d.name))
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr("transform", (d, i) => `translate(${-800 + i * 150},-40)`)
      .style("opacity", "0");

    legend
      .append("rect")
      .attr("x", width - 18)
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", (d: any) => colorScale(d) as string);

    legend
      .append("text")
      .attr("x", width - 24)
      .attr("y", 9)
      .attr("dy", ".35em")
      .attr("class", "bg-white text-xs")
      .style("text-anchor", "end")
      .text((d: any) => d);

    legend.transition().duration(500).style("opacity", "1");

    // Marker
    const marker = svg.selectAll(".marker").data(markers);

    const markerEnter = marker.enter().append("g").attr("class", "marker");

    // Enter
    markerEnter
      .append("line")
      .attr("x1", 0)
      .attr("x2", width - right)
      .attr("y1", (d) => yScale(d.value))
      .attr("y2", (d) => yScale(d.value))
      .attr("stroke-width", 2)
      .attr("stroke-dasharray", "1,8");

    // Update
    marker
      .select("line")
      .transition()
      .duration(500)
      .attr("x1", 0)
      .attr("x2", width - right)
      .attr("y1", (d) => yScale(d.value))
      .attr("y2", (d) => yScale(d.value));

    // Enter
    markerEnter
      .append("text")
      .attr("x", width - right)
      .attr("y", (d) => yScale(d.value))
      .attr("dy", "0.4em")
      .attr("text-anchor", "start")
      .attr("font-size", "0.7em")
      .text((d) => `${percentFormater.format(d.value / 100)} ${d.label}`);

    // Update
    marker
      .select("text")
      .text((d) => `${percentFormater.format(d.value / 100)} ${d.label}`)
      .transition()
      .duration(500)
      .attr("x", width - right)
      .attr("y", (d) => yScale(d.value));

    // Exit
    marker
      .exit()
      .transition()
      .duration(300)
      .attr("y1", 0)
      .attr("y", 0)
      .remove();
  };

  const drawHandler = useCallback(() => {
    if (data.length) {
      draw();
    }
  }, [data, markers]);

  useEffect(() => {
    drawHandler();
  }, [data, drawHandler]);

  return (
    <div className="relative">
      {/* Tooltip */}
      <div
        id="tooltip"
        className="hidden absolute bg-black text-white text-xs rounded py-1 px-4"
      >
        <span></span>
        <svg
          className="absolute text-black h-2 w-full left-0 top-full"
          x="0px"
          y="0px"
          viewBox="0 0 255 255"
          xmlSpace="preserve"
        >
          <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
        </svg>
      </div>

      <SVG width={width + right} height={height + top + bottom + 60}>
        <g ref={ref} transform={`translate(${left},${top})`}>
          <g className="x-axis" transform={`translate(0,${height})`}></g>
          <g className="y-axis" transform={`translate(0,0)`}></g>
        </g>
      </SVG>
    </div>
  );
};

export default GroupedProfileChart;
