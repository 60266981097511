import { Transition } from "@headlessui/react";
import { useSpring, animated } from "react-spring";
import { useQuery } from "@apollo/client";
import clsx from "clsx";
import { TrendingDownIcon, TrendingUpIcon } from "@heroicons/react/outline";
import { useIntl } from "react-intl";

import { EMPLOYEE_HUMATICS } from "../../../../schema/employee";
import { ORGANIZATION_KNOWLEDGE_VALUE } from "../../../../schema/organization";
import { METRIC_HUMATICS_PREVIOUS_PERIOD } from "../../../../schema/metric";
import { IS_CONCEALED } from "../../../../schema/settings";
import { currencyFormater, numberFormater } from "../../../../config/i18n";

import { Loading } from "../../Alerts";

interface Props {
  isOpen: boolean;
}

const Topbar = ({ isOpen }: Props) => {
  const { loading, data: employeeData } = useQuery(EMPLOYEE_HUMATICS, {
    pollInterval: 10000,
  });
  const { data: orgData } = useQuery(ORGANIZATION_KNOWLEDGE_VALUE, {
    pollInterval: 10000,
  });
  const { data: previousData } = useQuery(METRIC_HUMATICS_PREVIOUS_PERIOD, {
    pollInterval: 10000,
  });
  const { data: settingsData } = useQuery(IS_CONCEALED);

  const { formatMessage: f } = useIntl();

  // Animations
  const flipStyles = useSpring({
    opacity: loading ? 0 : 1,
    transform: `perspective(600px) rotateX(${loading ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80, duration: 500 },
  });

  const valueClassName = clsx("text-xl font-bold", {
    concealed: settingsData.isConcealed,
  });

  const renderIcon = (metric: string) => {
    const prev = previousData?.metricHumaticsPreviousPeriod.values[metric];
    const current = employeeData?.employeeHumatics?.[metric];
    if (prev > current) {
      return <TrendingDownIcon className="w-5 mr-1 text-red-500 float-left" />;
    } else if (prev < current) {
      return <TrendingUpIcon className="w-5 mr-1 text-green-500 float-left" />;
    }
  };

  return (
    <>
      {loading && <Loading />}

      <Transition show={isOpen}>
        <animated.div
          style={flipStyles}
          className="flex flex-row lg:flex-nowrap sm:flex-wrap w-full lg:space-x-2 space-y-0 mb-2 lg:mb-4"
        >
          <div className="w-full sm:w-1/2 lg:w-1/5">
            <div className="widget w-full p-3 rounded-lg bg-white border border-gray-100 dark:bg-gray-900 dark:border-gray-800">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-col">
                  <div className="text-xs uppercase font-light text-gray-500">
                    {f({ id: "humatics.h" })}
                  </div>
                  <div className={valueClassName}>
                    {renderIcon("h")}
                    {numberFormater.format(
                      employeeData?.employeeHumatics?.employeesH || 0
                    )}
                  </div>
                </div>
                <svg
                  className="stroke-current text-gray-500"
                  fill="none"
                  height="24"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                  <circle cx="9" cy="7" r="4"></circle>
                  <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/5">
            <div className="widget w-full p-3 rounded-lg bg-white border border-gray-100 dark:bg-gray-900 dark:border-gray-800">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-col">
                  <div className="text-xs uppercase font-light text-gray-500">
                    {f({ id: "humatics.t" })}
                  </div>
                  <div className={valueClassName}>
                    {renderIcon("t")}
                    {numberFormater.format(
                      employeeData?.employeeHumatics?.t || 0
                    )}
                  </div>
                </div>
                <svg
                  className="stroke-current text-gray-500"
                  fill="none"
                  height="24"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/5">
            <div className="widget w-full p-3 rounded-lg bg-white border border-gray-100 dark:bg-gray-900 dark:border-gray-800">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-col">
                  <div className="text-xs uppercase font-light text-gray-500">
                    {f({ id: "humatics.r" })}
                  </div>
                  <div className={valueClassName}>
                    {renderIcon("r")}
                    {numberFormater.format(
                      Math.floor(employeeData?.employeeHumatics?.r || 0)
                    )}
                  </div>
                </div>
                <svg
                  className="stroke-current text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/5">
            <div className="widget w-full p-3 rounded-lg bg-white border border-gray-100 dark:bg-gray-900 dark:border-gray-800">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-col">
                  <div className="text-xs uppercase font-light text-gray-500">
                    {f({ id: "humatics.phi" })}
                  </div>
                  <div className={valueClassName}>
                    {renderIcon("phi")}
                    {numberFormater.format(
                      employeeData?.employeeHumatics?.phi || 0
                    )}
                  </div>
                </div>
                <svg
                  className="stroke-current text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/5">
            <div className="widget w-full p-3 rounded-lg bg-white border border-gray-100 dark:bg-gray-900 dark:border-gray-800">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-col">
                  <div className="text-xs uppercase font-light text-gray-500">
                    {f({ id: "humatics.m" })}
                  </div>
                  <div className={valueClassName}>
                    {renderIcon("m")}
                    {currencyFormater.format(
                      orgData?.organizationKnowledgeValue || 0
                    )}
                  </div>
                </div>
                <svg
                  className="stroke-current text-gray-500"
                  fill="none"
                  width="24"
                  height="24"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                >
                  <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                  <path
                    fill="#fff"
                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                  />
                </svg>
              </div>
            </div>
          </div>
        </animated.div>
      </Transition>
    </>
  );
};

export default Topbar;
