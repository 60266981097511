import { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";

interface Props {
  width: number;
  height: number;
  value: number;
  fillColor?: string;
}

const Arc = ({
  width = 100,
  height = 100,
  value = 10,
  fillColor = "#0028da",
}: Props) => {
  const ref = useRef(null);

  const drawHandler = useCallback(() => {
    renderChart();
  }, [value]);

  useEffect(() => {
    drawHandler();
  }, [value]);

  const renderChart = () => {
    const outerRadius = width / 2;
    const innerRadius = outerRadius - 5;

    const tau = 2 * Math.PI; // http://tauday.com/tau-manifesto

    const convertPercentToAngle = (percent: number) => {
      return (percent / 100) * tau;
    };

    // An arc function with all values bound except the endAngle. So, to compute an
    // SVG path string for a given angle, we pass an object with an endAngle
    // property to the `arc` function, and it will return the corresponding string.
    const arcFn = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .startAngle(0);

    // Returns a tween for a transition’s "d" attribute, transitioning any selected
    // arcs from their current angle to the specified new angle.
    // see http://bl.ocks.org/mbostock/5100636
    const arcTween = (newAngle: number) => {
      return function (d: any) {
        const interpolateFn = d3.interpolate(d.endAngle, newAngle);
        return function (t: any) {
          d.endAngle = interpolateFn(t);
          return arcFn(d);
        };
      };
    };

    // Get the SVG container
    const svg = d3.select(ref.current);

    // Remove previous nodes
    svg.selectAll("g.arc").remove();

    const ring = svg
      .append("g")
      .attr("class", "arc")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    // Add the background arc, from 0 to 100% (tau).
    const background = ring
      .append("path")
      .datum({ endAngle: tau })
      .attr("class", "background")
      .style("fill", "#F9FBFB")
      .attr("d", arcFn as any);

    // Add the foreground arc, currently showing 12.7%.
    const foreground = ring
      .append("path")
      // .datum({endAngle: 0.127 * tau})
      .datum({ endAngle: 0 })
      .attr("class", "foreground")
      .style("fill", fillColor)
      .attr("d", arcFn as any);

    // Add the text
    const formatPercent = d3.format(".0%");
    const text = ring
      .append("text")
      .attr("x", -15)
      .attr("y", -5)
      .attr("dy", "0.71em")
      .classed("text-xs", true)
      .text(formatPercent(value / 100));

    // Ease transition
    const t = d3.transition().ease(d3.easeCircle);

    const angle = convertPercentToAngle(value);
    foreground
      // @ts-ignore
      .transition(t)
      .duration(500)
      .attrTween("d", arcTween(angle) as any);
  };

  return <svg ref={ref} style={{ margin: "0 auto" }} />;
};

export default Arc;
