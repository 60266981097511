//import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Switch } from "@headlessui/react";
import { useIntl } from "react-intl";

import { isConcealedVar } from "../../cache";
import { IS_CONCEALED } from "../../schema/settings";

const SwitchController = () => {
  const { data: settingsData } = useQuery(IS_CONCEALED);
  const { formatMessage: f } = useIntl();

  const setSwitchValue = (value: boolean) => {
    localStorage.setItem("concealed", (value as unknown) as string);
    isConcealedVar(value);
  };

  const switchValue = settingsData.isConcealed;

  return (
    <div className="flex items-center justify-center">
      <div className="w-full max-w-xs mx-auto">
        <Switch.Group as="div" className="flex items-center space-x-4">
          <Switch.Label className="text-sm">
            {f({ id: "metrics.actions.hide" })}
          </Switch.Label>
          <Switch
            as="button"
            checked={switchValue}
            onChange={setSwitchValue}
            className={`${
              switchValue ? "bg-draintree-600" : "bg-gray-200"
            } relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}
          >
            {({ checked }) => (
              <span
                className={`${
                  checked ? "translate-x-5" : "translate-x-0"
                } inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
              />
            )}
          </Switch>
        </Switch.Group>
      </div>
    </div>
  );
};

export default SwitchController;
