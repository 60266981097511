import {
  AcademicCapIcon,
  AdjustmentsIcon,
  CollectionIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";

import { useIntl } from "react-intl";

const Tabs = () => {
  const { formatMessage: f } = useIntl();

  return (
    <div>
      <div className="sm:hidden">
        <select className="form-select block w-full" defaultValue="Kompetenzen">
          <option>{f({ id: "skills.name" })}</option>
          <option>{f({ id: "skillTypes.name" })}</option>
          <option>Kompetenzlevel</option>
          <option>{f({ id: "skillGroups.name" })}</option>
          <option>{f({ id: "skillScales.name" })}</option>
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="flex -mb-px">
            <Link
              to="/skills"
              className="group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              <AcademicCapIcon className="mr-1 h-6 w-6 text-gray-400 group-hover:text-gray-500" />
              <span>{f({ id: "skills.name" })}</span>
            </Link>
            <Link
              to="/skillTypes"
              className="ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              <CollectionIcon className="mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              <span>{f({ id: "skillTypes.name" })}</span>
            </Link>
            <Link
              to="/skillLevels"
              className="ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              <CollectionIcon className="mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              <span>Kompetenzlevel</span>
            </Link>
            <Link
              to="/skillGroups"
              className="ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              <CollectionIcon className="mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              <span>{f({ id: "skillGroups.name" })}</span>
            </Link>
            <Link
              to="/skillScales"
              className="ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              <AdjustmentsIcon className="mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              <span>{f({ id: "skillScales.name" })}</span>
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
