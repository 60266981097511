import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Transition } from "@headlessui/react";
import {
  useParams,
  useHistory,
  match,
  RouteComponentProps,
} from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  APPLICANT_BY_ID,
  APPLICANT_UPDATE_BY_ID,
  APPLICANT_REMOVE_BY_ID,
  APPLICANT_MANY,
} from "../../schema/applicant";
import { SKILL_MANY } from "../../schema/skill";
import { SKILL_SCALE_MANY } from "../../schema/skillScale";
import { Competence, SkillScale } from "../../types";

import { useDrop } from "react-dnd";
import { ItemTypes } from "../../config/ItemTypes";

import SliderOverContext from "../../context/SliderOverContext";
import SlideOverList from "../skills/SliderOverList/SlideOverList";
import useToggle from "../../hooks/useToogle";

import { Loading, Error } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import PageHeader from "../ui/Headings/PageHeader";
import { Breadcrumb } from "../ui/navigation/Breadcrumb";

import Form, { FormData } from "./Form";
import { H3 } from "../ui/Typo/Typo";
import CompetenceFields from "../competences/Fields";
import Stats from "../competences/Stats";

import ProfileChart from "../charts/ProfileChart";

interface ParamTypes {
  id: string;
}

interface Props {
  required: string;
  match?: match<ParamTypes>;
}

const Profile = ({}: Props & RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { formatMessage: f } = useIntl();

  const [open, toggle] = useToggle();
  const [openSkills, toggleSkills] = useToggle();
  const [isOpen, setIsOpen] = useState(false);

  const { loading, error, data } = useQuery(APPLICANT_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateApplicant] = useMutation(APPLICANT_UPDATE_BY_ID, {
    refetchQueries: [{ query: APPLICANT_MANY }],
  });
  const [updateApplicantCompetences] = useMutation(APPLICANT_UPDATE_BY_ID, {
    refetchQueries: [
      {
        query: APPLICANT_BY_ID,
        variables: {
          _id: id,
        },
      },
    ],
    update(cache, { data: { applicantUpdateById } }) {
      try {
        cache.writeQuery({
          query: APPLICANT_BY_ID,
          variables: { _id: id },
          data: {
            applicantById: applicantUpdateById,
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
  });
  const [deleteApplicant] = useMutation(APPLICANT_REMOVE_BY_ID, {
    refetchQueries: [{ query: APPLICANT_MANY }],
  });
  const { data: skillData } = useQuery(SKILL_MANY);
  const { data: skillScaleData } = useQuery(SKILL_SCALE_MANY);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.SKILL,
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return;
      }
      console.log(`Dropped ${(item as any).skill.name}`);
      const skill = (item as any).skill;

      const newCompetence = {
        skillId: skill._id,
        value: 0,
      };

      const competences = data?.applicantById.competences.map(
        (c: Competence) => ({
          skillId: c.skillId,
          value: c.value,
        })
      );

      updateApplicantCompetences({
        variables: {
          _id: id,
          competences: [...competences, newCompetence],
        },
      });

      return { name: "Profile" };
    },
    // drop: () => (
    //   { name: 'Profile' }
    // ),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    if (!id) {
      history.push("/");
      return;
    }
  }, [id, history]);

  const onUpdateCompetences = (competences: Competence[]) => {
    updateApplicant({
      variables: {
        _id: id,
        competences: competences.map((c) => ({
          skillId: c.skillId,
          value: +c.value,
        })),
      },
    });
  };

  const onDeleteCompetence = (competence: Competence) => {
    const competences = data?.applicantById.competences
      .map((c: any) => ({
        skillId: c.skillId,
        value: +c.value,
      }))
      .filter((item: any) => {
        return item.skillId !== competence.skillId;
      });

    updateApplicantCompetences({
      variables: {
        _id: id,
        competences: competences,
      },
    });
  };

  const onUpdate = (formData: FormData) => {
    updateApplicant({
      variables: {
        _id: id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        photo: formData.photo,
        entryDate: formData.entryDate,
        vacancyId: formData.vacancyId ? formData.vacancyId : null,
        salary: +formData.salary,
        unitId: formData.unitId ? formData.unitId : null,
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteApplicant({ variables: { _id: id } });
    history.goBack();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const isActive = canDrop && isOver;

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Bewerber bearbeiten">
          <Form onSubmit={onUpdate} data={data.applicantById} />
        </SlideOver>
      </SliderOverContext.Provider>

      <SliderOverContext.Provider
        value={{ open: openSkills, toggle: toggleSkills }}
      >
        <SlideOverList
          data={skillData?.skillMany}
          applicant={data.applicantById}
          competences={data.applicantById?.competences}
          onSelect={(competences: Competence[]) =>
            onUpdateCompetences(competences)
          }
          defaultCompetenceValue={50}
        >
          {f({ id: "profile.actions.drop" })}
        </SlideOverList>
      </SliderOverContext.Provider>

      <PageHeader
        title={`${data.applicantById?.firstName} ${data.applicantById?.lastName}`}
        onUpdate={toggle}
        onDelete={onDelete}
      >
        <Breadcrumb>
          <Breadcrumb.Item url="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item url="/applicants">Bewerber</Breadcrumb.Item>
          <Breadcrumb.Item>{`${data.applicantById?.firstName} ${data.applicantById?.lastName}`}</Breadcrumb.Item>
          <Breadcrumb.Item>Profil</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>

      <Stats humatics={data.applicantById?.humatics} />

      <hr className="my-6 border-2 rounded-full border-gray-200" />

      <div className="my-6">
        <span className="relative z-0 inline-flex shadow-sm">
          <button
            type="button"
            className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
            onClick={toggleSkills}
          >
            Kompetenzen hinzufügen
          </button>
          <button
            type="button"
            className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
            onClick={() => setIsOpen(!isOpen)}
          >
            Tabellarische Darstellung
          </button>
        </span>
      </div>

      <Transition show={isOpen}>
        <CompetenceFields
          data={data.applicantById?.competences}
          onSubmit={(formData) => onUpdateCompetences(formData.competences)}
        />
      </Transition>

      <div
        ref={drop}
        className={
          isActive ? "p-4 rounded border-2 border-dashed border-blue-500" : ""
        }
      >
        <div className="my-4 text-xs uppercase font-light text-gray-500">
          <H3>
            {isActive
              ? "Loslassen zum hinzufügen"
              : "Kompetenz hier reinziehen"}
          </H3>
        </div>

        <ProfileChart
          width={900}
          height={400}
          data={data.applicantById?.competences}
          markers={skillScaleData?.skillScaleMany.map((s: SkillScale) => ({
            label: s.name,
            value: s.value * 100,
          }))}
          revenue={data.applicantById?.targetRevenue}
          onDelete={onDeleteCompetence}
          onChange={(competences) => {
            // console.log(
            //   "onChange",
            //   competences.map((d) => d.value)
            // );

            updateApplicantCompetences({
              variables: {
                _id: id,
                competences: competences.map((c: Competence) => ({
                  skillId: c.skillId,
                  value: c.value,
                })),
              },
            });
          }}
        />
      </div>
    </>
  );
};

export default Profile;
