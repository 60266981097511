import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";

import { UNIT_MANY } from "../../schema/unit";
import { Unit } from "../../types";
import { currencyFormater, percentFormater } from "../../config/i18n";

import { Loading, Error } from "../ui/Alerts";
import Tabs from "../organization/Tabs";
import { H1 } from "../ui/Typo/Typo";

const Revenues = () => {
  const { loading, error, data } = useQuery(UNIT_MANY);
  const { formatMessage: f } = useIntl();

  let requiredEmployeeCount = 0;
  let requiredSalary =
    data?.unitMany?.reduce((memo: any, unit: Unit) => {
      return memo + unit.required?.totalSalary;
    }, 0) || [];
  let employeeCount = 0;
  let totalSalary =
    data?.unitMany?.reduce((memo: any, unit: Unit) => {
      return memo + unit.totalSalary;
    }, 0) || [];

  let targetRevenue = 0;
  let actualRevenue = 0;

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      <Tabs />

      <H1>Umsatzmatrix</H1>
      <div className="flex flex-col py-6">
        <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {f({ id: "unit.name" })}
                  </th>
                  <th
                    colSpan={4}
                    className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {f({ id: "target" })}
                  </th>
                  <th
                    colSpan={4}
                    className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {f({ id: "actual" })}
                  </th>
                </tr>
                <tr>
                  <td></td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-bold">
                    {f({ id: "employees.target" })}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-bold">
                    {f({ id: "salaries.target" })}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-bold">
                    %
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-bold">
                    {f({ id: "revenues.name" })}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-bold">
                    {f({ id: "employees.actual" })}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-bold">
                    {f({ id: "salaries.actual" })}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-bold">
                    %
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-bold">
                    {f({ id: "revenues.name" })}
                  </td>
                </tr>
              </thead>
              <tbody>
                {data?.unitMany.map((unit: Unit) => {
                  requiredEmployeeCount += unit.required?.employeeCount;
                  employeeCount += unit.employeeCount;
                  targetRevenue += unit.targetRevenue;
                  actualRevenue += unit.actualRevenue;
                  return (
                    <tr
                      key={unit._id}
                      className="divide-x divide-y divide-dotted"
                    >
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                        <Link to={`/units/${unit._id}/details`}>
                          {unit.name}
                        </Link>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                        {unit.required?.employeeCount}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                        {unit.required &&
                          currencyFormater.format(unit.required?.totalSalary)}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                        {percentFormater.format(
                          unit.required?.totalSalary / requiredSalary
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                        {currencyFormater.format(unit.targetRevenue)}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                        {unit.employeeCount}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                        {currencyFormater.format(unit.totalSalary)}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                        {percentFormater.format(unit.totalSalary / totalSalary)}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                        {currencyFormater.format(unit.actualRevenue)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr className="divide-x divide-y divide-dotted border-solid border-gray-500 border-t-2">
                  <td></td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm font-bold leading-5 text-gray-500">
                    {requiredEmployeeCount}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm font-bold leading-5 text-gray-500">
                    {currencyFormater.format(requiredSalary)}
                  </td>
                  <td></td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm font-bold leading-5 text-gray-500">
                    {currencyFormater.format(targetRevenue)}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm font-bold leading-5 text-gray-500">
                    {employeeCount}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm font-bold leading-5 text-gray-500">
                    {currencyFormater.format(totalSalary)}
                  </td>
                  <td></td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm font-bold leading-5 text-gray-500">
                    {currencyFormater.format(actualRevenue)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Revenues;
