import { useApolloClient } from "@apollo/client";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { isLoggedInVar, roleVar } from "../../../cache";

export type FormData = {
  username: string;
};

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>();
  const history = useHistory();
  const client = useApolloClient();

  const onSubmit = async (data: FormData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_URI}/forgotPassword`,
        data
      );

      const { token, role } = response?.data;
      localStorage.setItem("token", token as string);
      localStorage.setItem("role", role as string);
      isLoggedInVar(true);
      roleVar(role);
      client.resetStore();

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      history.push("/");
    } catch (e) {
      setError("username", {
        type: "manual",
        message: e.message,
      });
      console.error(e);
    }
  };

  return (
    <div className="absolute h-100 left-0 top-0 min-w-full min-h-full flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-auto w-1/2" src="/logo.svg" alt="8Skills" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Passwort vergessen?
          </h2>

          <p className="mt-2 text-center text-sm text-gray-600">
            Oder {}
            <Link
              to="/users/signup"
              className="font-medium text-orange-600 hover:text-orange-500"
            >
              anmelden
            </Link>
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">
                Benutzername
              </label>
              <input
                type="text"
                id="username"
                autoComplete="username"
                placeholder="Benutzername"
                {...register("username", { required: "Benutzername fehlt" })}
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm"
              />
            </div>
          </div>

          <div className="flex flex-col space-y-1">
            {errors.username && (
              <span className="text-sm font-light text-red-500">
                {errors.username.message}
              </span>
            )}
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-coral-500 hover:bg-coral-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400"
            >
              Passwort anfordern
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
