import { useContext } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { animated, config, useSpring } from "react-spring";
// import { DevTool } from "@hookform/devtools";
import { useQuery } from "@apollo/client";
import { RefreshIcon } from "@heroicons/react/solid";
import { useIntl } from "react-intl";
import Select from "react-select";

import FilterContext from "../../context/FilterContext";
import { SKILL_MANY } from "../../schema/skill";
import { EmploymentStatus, Skill } from "../../types";

import EmploymentStatusField from "../../components/common/form/EmploymentStatusField";
import { Divider } from "../ui/layout/Divider";

interface Props {
  onSubmit: (formData: any) => void;
  data?: any;
}

export type FormData = {
  query: string;
  skillIds: string[] | undefined;
  employmentStatus: EmploymentStatus;
};

const Filter = ({ onSubmit, data }: Props) => {
  const { open } = useContext(FilterContext);
  const { formatMessage: f } = useIntl();

  const methods = useForm<FormData>({ defaultValues: data });
  const { handleSubmit, reset, control } = methods;

  const { loading, data: skillData } = useQuery(SKILL_MANY);

  const options =
    skillData?.skillMany.map(
      (skill: Skill) =>
        ({
          id: skill._id,
          name: skill.name,
        } as any)
    ) || [];

  const slideInStyles = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, width: 0 },
    to: {
      opacity: open ? 1 : 0,
      width: open ? 400 : 0,
    },
  });

  return (
    <animated.div style={{ ...slideInStyles, overflow: "hidden" }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} id="filter-form">
          <div className="bg-gray-100 dark:bg-gray-900 dark:text-white text-gray-600 px-4 py-2 rounded-md">
            <div className="my-5 flex justify-between items-center">
              <div className="text-xs text-gray-400 tracking-wider">FILTER</div>
              <RefreshIcon
                className="w-4 h-4 text-gray-400 cursor-pointer"
                onClick={() => reset()}
              />
            </div>

            <div className="my-5 bg-white p-3 w-full flex flex-col rounded-md dark:bg-gray-800 shadow">
              <div className="space-y-1 py-2">
                <label
                  htmlFor="skillIds"
                  className="block text-sm font-medium leading-5 text-gray-900"
                >
                  {f({ id: "skills.name" })}
                </label>
                <Controller
                  control={control}
                  name="skillIds"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      placeholder="Kompetenzen wählen..."
                      options={options}
                      value={options.filter(({ id }: any) =>
                        value?.includes(id)
                      )}
                      getOptionLabel={({ name }: any) => name}
                      getOptionValue={({ id }: any) => id}
                      isLoading={loading}
                      isMulti
                      className="text-xs"
                      classNamePrefix="select"
                      onBlur={onBlur}
                      onChange={(values: any) =>
                        onChange((values ?? []).map((d: any) => d.id))
                      }
                    />
                  )}
                />
              </div>
            </div>

            <Divider />

            <div className="my-5 bg-white p-3 w-full flex flex-col rounded-md dark:bg-gray-800 shadow">
              <div className="space-y-1 py-2">
                <EmploymentStatusField />
              </div>
            </div>

            <button
              type="submit"
              form="filter-form"
              className="mx-auto inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded-md text-white bg-gray-500 hover:bg-gray-400 focus:outline-none focus:shadow-outline-indigo focus:border-orange-600 active:bg-coral-600 transition duration-150 ease-in-out"
            >
              Anwenden
            </button>
          </div>
        </form>
      </FormProvider>
    </animated.div>
  );
};

export default Filter;
