import { useLazyQuery } from "@apollo/client";
import { Transition } from "@headlessui/react";
import React, {
  ChangeEvent,
  KeyboardEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useIntl } from "react-intl";

import { FILTER_SKILL_MANY } from "../../../schema/skill";
import { SKILL_GROUP_MANY } from "../../../schema/skillGroup";
import { SKILL_LEVEL_MANY } from "../../../schema/skillLevel";
import { SKILL_TYPE_MANY } from "../../../schema/skillType";

import SlideOverContext from "../../../context/SliderOverContext";
import {
  Applicant,
  Competence,
  Employee,
  Skill,
  SkillGroup,
  SkillLevel,
  SkillType,
} from "../../../types";
import SkillGroupItem from "./SkillGroupItem";
import SkillLevelItem from "./SkillLevelItem";
import SkillTypeItem from "./SkillTypeItem";
import Item from "./SlideOverListItem";

import {
  HorizontalNavigation,
  HorizontalNavigationOption,
} from "../../ui/navigation/HorizontalNavigation";

interface Props {
  data: Array<Skill>;
  employee?: Employee;
  applicant?: Applicant;
  competences?: Competence[];
  onSelect?: (competences: Competence[]) => void;
  defaultCompetenceValue?: number;
  children: React.ReactNode;
}

const List = ({
  data = [],
  employee,
  competences,
  onSelect,
  defaultCompetenceValue = 0,
  children,
}: Props) => {
  const { open, toggle } = useContext(SlideOverContext);
  const { formatMessage: f } = useIntl();

  const [tabs, setTabs] = useState<HorizontalNavigationOption[]>([]);
  const [selectedTab, setSelectedTab] = useState<HorizontalNavigationOption>();

  const [skills, setSkills] = useState(data);
  const [skillTypes, setSkillTypes] = useState([]);
  const [skillLevels, setSkillLevels] = useState([]);
  const [skillGroups, setSkillGroups] = useState([]);

  // const searchInputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState("");
  const [getSkills, { data: skillData }] = useLazyQuery(FILTER_SKILL_MANY, {
    variables: {
      name: query,
    },
  });

  const [getSkillTypes, { data: skillTypeData }] = useLazyQuery(
    SKILL_TYPE_MANY
  );

  const [getSkillLevels, { data: skillLevelData }] = useLazyQuery(
    SKILL_LEVEL_MANY
  );

  const [getSkillGroups, { data: skillGroupData }] = useLazyQuery(
    SKILL_GROUP_MANY
  );

  useEffect(() => {
    // Init skills
    setSkills(data);

    // Init tabs
    const _tabs: HorizontalNavigationOption[] = [
      { value: "all", label: "Alle" },
      { value: "skillTypes", label: "Kompetenztypen" },
      { value: "skillLevels", label: "Kompetenzlevel" },
      { value: "skillGroups", label: "Kompetenzgruppen" },
    ];

    if (employee?.job) {
      _tabs.splice(1, 0, { value: "job", label: "Arbeitsplatz" });
    }
    setTabs(_tabs);
    setSelectedTab(_tabs[0]);
  }, [data]);

  useEffect(() => {
    // Reset values
    setQuery("");
    setSkills([]);

    if (selectedTab?.value === "job") {
      // TODO: fetch from server
      const jobSkillIds =
        employee?.job?.competences.map((c: Competence) => c.skillId) || [];
      const filteredData = data.filter((skill: Skill) =>
        jobSkillIds?.includes(skill._id)
      );
      setSkills(filteredData);
    } else if (selectedTab?.value === "skillTypes") {
      getSkillTypes();
    } else if (selectedTab?.value === "skillGroups") {
      getSkillGroups();
    } else {
      setSkills(data);
    }
  }, [selectedTab, data, employee, getSkillGroups, getSkillTypes]);

  // Update SkillTypes
  useEffect(() => {
    setSkillTypes(skillTypeData?.skillTypeMany);
  }, [skillTypeData]);

  // Update SkillLevel
  useEffect(() => {
    setSkillLevels(skillLevelData?.skillLevelMany);
  }, [skillLevelData]);

  // Update SkillGroups
  useEffect(() => {
    setSkillGroups(skillGroupData?.skillGroupMany);
  }, [skillGroupData]);

  // Update Skills after search
  useEffect(() => {
    if (skillData?.skillMany) {
      setSkills(skillData.skillMany);
    }
  }, [skillData]);

  // Perform search
  const search = (name: string) => {
    setQuery(name);
    getSkills({
      variables: {
        name: name,
      },
    });
  };

  const onSelectCompetence = (skill: Skill, checked: boolean) => {
    const jobCompetence = employee?.job.competences.find(
      (c: Competence) => c.skillId === skill._id
    );
    console.log("jobCompetence", jobCompetence);

    const newCompetence = {
      skillId: skill._id,
      value: jobCompetence?.value || defaultCompetenceValue,
      skill: skill,
      description: "",
      position: 0,
    };

    let _competences =
      competences?.map((c: any) => ({
        skillId: c.skillId,
        value: +c.value,
        skill: c.skill,
        description: c.skill.description,
        position: c.position,
      })) || [];

    if (checked) {
      // Insert
      onSelect?.([..._competences, newCompetence]);
    } else {
      // Delete
      _competences = _competences?.filter((c: any) => {
        return c.skillId !== skill._id;
      });
      onSelect?.(_competences);
    }
  };

  return (
    <Transition show={open}>
      <div className="fixed inset-0 overflow-hidden z-10">
        <div className="absolute inset-0 overflow-hidden">
          <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              className="w-screen max-w-md"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                <header className="p-6">
                  <div className="flex items-start justify-between space-x-3">
                    <div className="space-y-1">
                      <h2 className="text-lg leading-7 font-medium text-gray-900">
                        {f({ id: "skills.name" })}
                      </h2>
                      <p className="text-sm text-gray-500 leading-5">
                        {children}
                      </p>
                    </div>
                    <div className="h-7 flex items-center">
                      <button
                        aria-label="Close panel"
                        className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150"
                        onClick={toggle}
                      >
                        <svg
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </header>
                <div className="border-b border-gray-200">
                  <div className="px-6">
                    {/* Search */}
                    <div className="w-full flex md:ml-0">
                      <label htmlFor="slide-over-search" className="sr-only">
                        {f({ id: "actions.search" })}
                      </label>
                      <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                        <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                          <svg
                            className="h-5 w-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            />
                          </svg>
                        </div>
                        <input
                          id="slide-over-search"
                          className="block w-full h-full pl-8 pr-3 py-2 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm"
                          placeholder={f({ id: "search.placeholder.name" })}
                          onBlur={(event: ChangeEvent<HTMLInputElement>) =>
                            search(event?.currentTarget?.value)
                          }
                          onKeyPress={(
                            event: KeyboardEvent<HTMLInputElement>
                          ) => {
                            if (event.key === "Enter") {
                              search(event?.currentTarget?.value);
                            }
                          }}
                        />
                      </div>
                    </div>

                    {/* Nav */}
                    <HorizontalNavigation
                      options={tabs}
                      selected={selectedTab}
                      onSelect={setSelectedTab}
                    />
                  </div>
                </div>
                <ul className="divide-y divide-gray-200 overflow-y-auto">
                  {selectedTab?.value === "skillTypes" &&
                    !skills.length &&
                    skillTypes?.map((skillType: SkillType) => (
                      <SkillTypeItem
                        key={skillType._id}
                        skillType={skillType}
                        onSelect={(skills) => setSkills(skills)}
                      />
                    ))}

                  {selectedTab?.value === "skillLevels" &&
                    !skills.length &&
                    skillLevels?.map((skillLevel: SkillLevel) => (
                      <SkillLevelItem
                        key={skillLevel._id}
                        skillLevel={skillLevel}
                        onSelect={(skills) => setSkills(skills)}
                      />
                    ))}

                  {selectedTab?.value === "skillGroups" &&
                    !skills.length &&
                    skillGroups?.map((skillGroup: SkillGroup) => (
                      <SkillGroupItem
                        key={skillGroup._id}
                        skillGroup={skillGroup}
                        onSelect={(skills) => setSkills(skills)}
                      />
                    ))}

                  {skills?.map((skill: Skill) => (
                    <Item
                      key={skill._id}
                      skill={skill}
                      checked={
                        competences?.find(
                          (c: Competence) => c.skillId === skill._id
                        ) != null
                      }
                      onSelect={(checked: boolean) => {
                        onSelectCompetence(skill, checked);
                      }}
                    />
                  ))}
                </ul>
              </div>
            </Transition.Child>
          </section>
        </div>
      </div>
    </Transition>
  );
};

export default List;
