import { useParams, useHistory, RouteComponentProps } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  INVITATION_BY_ID,
  INVITATION_UPDATE_BY_ID,
  INVITATION_REMOVE_BY_ID,
  INVITATION_MANY,
} from "../../schema/invitation";
import { ROLE_MANY } from "schema/role";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { Loading, Error } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import PageHeader from "../ui/Headings/PageHeader";
import { Breadcrumb } from "../ui/navigation/Breadcrumb";

import Form, { FormData } from "./Form";

interface ParamTypes {
  id: string;
}

const Details = ({}: RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [open, toggle] = useToggle();

  const { loading: invitationLoading, error: invitationError, data: invitationData } = useQuery(INVITATION_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const { loading: roleLoading, error: roleError, data: roleData } = useQuery(ROLE_MANY);
  const [updateInvitation] = useMutation(INVITATION_UPDATE_BY_ID, {
    refetchQueries: [{ query: INVITATION_MANY }],
  });
  const [deleteInvitation] = useMutation(INVITATION_REMOVE_BY_ID, {
    refetchQueries: [{ query: INVITATION_MANY }],
  });

  const onUpdate = (formData: FormData) => {
    updateInvitation({
      variables: {
        _id: id,
        employeeId: formData.employeeId,
        organizationId: formData.organizationId,
        role: formData.role,
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteInvitation({ variables: { _id: id } });
    history.goBack();
  };

  const loading = invitationLoading || roleLoading;
  const error = invitationError || roleError;

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Einladung bearbeiten">
          <Form onSubmit={onUpdate} data={invitationData?.invitationById} roles={roleData?.roleMany} />
        </SlideOver>
        <PageHeader
          title={invitationData?.invitationById.signupToken}
          onUpdate={toggle}
          onDelete={onDelete}
        >
          <Breadcrumb>
            <Breadcrumb.Item url="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item url="/invitations">Einladungen</Breadcrumb.Item>
            <Breadcrumb.Item>
              {invitationData?.invitationById.employeeId}
            </Breadcrumb.Item>
          </Breadcrumb>
        </PageHeader>

        <p className="px-6 mt-1 max-w-2xl text-sm font-mono leading-5 text-gray-500">
          {invitationData?.invitationById.signupToken}
        </p>
      </SliderOverContext.Provider>
    </>
  );
};

export default Details;
