import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Transition, Menu } from "@headlessui/react";
import { useIntl } from "react-intl";

import { Employee } from "../../../types";
import Avatar from "../Avatar";

interface Props {
  employee: Employee;
}

const Item = ({ employee }: Props) => {
  const { formatMessage: f } = useIntl();

  return (
    <li className="px-6 py-5 relative">
      <div className="group flex justify-between items-center space-x-2">
        <Link
          to={`/employees/${employee._id}/details`}
          className="-m-1 p-1 block"
        >
          <span className="absolute inset-0 group-hover:bg-gray-50"></span>
          <div className="flex-1 flex items-center min-w-0 relative">
            <span className="flex-shrink-0 inline-block relative">
              <Avatar employee={employee} className="w-10 h-10" />
              <span className="absolute top-0 right-0 block h-2.5 w-2.5 rounded-full text-white shadow-solid bg-green-400"></span>
            </span>
            <div className="ml-4 truncate">
              <div className="text-sm leading-5 font-medium text-gray-900 truncate">
                {employee.firstName} {employee.lastName}
              </div>
              <div className="text-sm leading-5 text-gray-500 truncate">
                {employee.email}
              </div>
            </div>
          </div>
        </Link>
        <div className="relative inline-block text-left">
          <Menu as="div">
            {({ open }) => (
              <>
                <Menu.Button as={Fragment}>
                  <button
                    className="group relative w-8 h-8 inline-flex items-center justify-center focus:outline-none"
                    id={`options-menu-employee-${employee._id}`}
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="flex items-center justify-center h-full w-full rounded-full group-focus:bg-gray-200 transition ease-in-out duration-150">
                      <svg
                        className="w-5 h-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                      </svg>
                    </span>
                  </button>
                </Menu.Button>

                <Transition
                  show={open}
                  className="origin-top-right absolute z-10 top-0 right-9 w-48 rounded-md shadow-lg"
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-out duration-100"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <div className="rounded-md bg-white shadow-xs">
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby={`options-menu-employee-${employee._id}`}
                    >
                      <Link
                        to={`/employees/${employee._id}/profile`}
                        className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        role="menuitem"
                      >
                        {f({ id: "profile.name" })}
                      </Link>
                      <a
                        href={`mailto:${employee.email}`}
                        className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        role="menuitem"
                      >
                        Nachricht senden
                      </a>
                    </div>
                  </div>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    </li>
  );
};

export default Item;
