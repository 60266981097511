import { useContext, useState } from "react";
import { animated, config, useSpring } from "react-spring";

import { Switch } from "@headlessui/react";
import { RefreshIcon } from "@heroicons/react/solid";

import FilterContext from "../../context/FilterContext";

import { Divider } from "../ui/layout/Divider";

interface Props {
  onSubmit: (formData: any) => void;
  data?: any;
}

export type FormData = {
  core?: boolean;
  future?: boolean;
  forEmployee?: boolean;
  forManager?: boolean;
  color?: string | null;
};

const Filter = ({ onSubmit }: Props) => {
  const { open } = useContext(FilterContext);

  const [core, setCore] = useState(false);
  const [future, setFuture] = useState(false);
  const [forEmployee, setForEmployee] = useState(false);
  const [forManager, setForManager] = useState(false);
  const [color, setColor] = useState("");

  const submitData = () => {
    onSubmit?.({
      core,
      future,
      forEmployee,
      forManager,
      color,
    });
    return false;
  };

  const resetData = () => {
    setCore(false);
    setFuture(false);
    setForEmployee(false);
    setForManager(false);
    setColor("");
    onSubmit?.({});
  };

  const slideInStyles = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, width: 0 },
    to: {
      opacity: open ? 1 : 0,
      width: open ? 400 : 0,
    },
  });

  return (
    <animated.div style={{ ...slideInStyles, overflow: "hidden" }}>
      <div className="bg-gray-100 dark:bg-gray-900 dark:text-white text-gray-600 px-4 py-2 rounded-md">
        <div className="my-5 flex justify-between items-center">
          <div className="text-xs text-gray-400 tracking-wider">FILTER</div>
          <RefreshIcon
            className="w-4 h-4 text-gray-400 cursor-pointer"
            onClick={() => resetData()}
          />
        </div>

        <div className="my-5 bg-white p-3 w-full flex flex-col rounded-md dark:bg-gray-800 shadow">
          <div className="flex flex-col flex-wrap items-end mx-2 space-y-4">
            <Switch.Group as="div" className="flex items-center space-x-4">
              <Switch.Label className="text-xs">Kernkompetenz</Switch.Label>
              <Switch
                as="button"
                checked={core}
                onChange={setCore}
                className={`${
                  core ? "bg-draintree-600" : "bg-gray-200"
                } relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}
              >
                {({ checked }) => (
                  <span
                    className={`${
                      checked ? "translate-x-5" : "translate-x-0"
                    } inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
                  />
                )}
              </Switch>
            </Switch.Group>

            <Switch.Group as="div" className="flex items-center space-x-4">
              <Switch.Label className="text-xs">Zukunftskompetenz</Switch.Label>
              <Switch
                as="button"
                checked={future}
                onChange={setFuture}
                className={`${
                  future ? "bg-draintree-600" : "bg-gray-200"
                } relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}
              >
                {({ checked }) => (
                  <span
                    className={`${
                      checked ? "translate-x-5" : "translate-x-0"
                    } inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
                  />
                )}
              </Switch>
            </Switch.Group>

            <Switch.Group as="div" className="flex items-center space-x-4">
              <Switch.Label className="text-xs">
                Mitarbeiterkompetenz
              </Switch.Label>
              <Switch
                as="button"
                checked={forEmployee}
                onChange={setForEmployee}
                className={`${
                  forEmployee ? "bg-draintree-600" : "bg-gray-200"
                } relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}
              >
                {({ checked }) => (
                  <span
                    className={`${
                      checked ? "translate-x-5" : "translate-x-0"
                    } inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
                  />
                )}
              </Switch>
            </Switch.Group>

            <Switch.Group as="div" className="flex items-center space-x-4">
              <Switch.Label className="text-xs">Führungskompetenz</Switch.Label>
              <Switch
                as="button"
                checked={forManager}
                onChange={setForManager}
                className={`${
                  forManager ? "bg-draintree-600" : "bg-gray-200"
                } relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}
              >
                {({ checked }) => (
                  <span
                    className={`${
                      checked ? "translate-x-5" : "translate-x-0"
                    } inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
                  />
                )}
              </Switch>
            </Switch.Group>
          </div>
        </div>

        <Divider />

        <button
          onClick={() => submitData()}
          className="mx-auto inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded-md text-white bg-gray-500 hover:bg-gray-400 focus:outline-none focus:shadow-outline-indigo focus:border-orange-600 active:bg-coral-600 transition duration-150 ease-in-out"
        >
          Anwenden
        </button>
      </div>
    </animated.div>
  );
};

export default Filter;
