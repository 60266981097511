import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { SkillGroup, Skill } from "../../types";

interface Props {
  skillGroup: SkillGroup;
}

const Item = ({ skillGroup }: Props) => {
  return (
    <tr>
      <td className="px-6 py-4">
        <div className="flex items-center">
          <Link to={`/skillGroups/${skillGroup._id}/details`} className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {skillGroup.name}
            </div>
            <p className="text-sm text-gray-500 overflow-ellipsis">
              {skillGroup.description}
            </p>
          </Link>
        </div>
      </td>
      <td className="px-6 py-4">
        <div className="flex flex-wrap items-center">
          {skillGroup.skills?.map((skill: Skill) => (
            <span
              key={skill._id}
              className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
            >
              {skill.name}
            </span>
          ))}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {skillGroup.createdAt &&
          format(parseISO(skillGroup.createdAt), "do MMMM", {
            locale: de,
          })}
      </td>
    </tr>
  );
};

export default Item;
