import { gql } from "@apollo/client";

export const SKILL_LEVEL_MANY = gql`
  query {
    skillLevelMany {
      name
      description
      createdAt
      _id
    }
  }
`;

export const SKILL_LEVEL_BY_ID = gql`
  query($_id: ID!) {
    skillLevelById(_id: $_id) {
      name
      description
      skills {
        name
        employeeCount
        jobCount
        seminarCount
        supply
        demand
        createdAt
        _id
      }
      createdAt
      _id
    }
  }
`;

export const SKILL_LEVEL_CREATE_ONE = gql`
  mutation($name: String!, $description: String) {
    skillLevelCreateOne(input: { name: $name, description: $description }) {
      _id
      name
    }
  }
`;

export const SKILL_LEVEL_UPDATE_BY_ID = gql`
  mutation($_id: ID!, $name: String!, $description: String) {
    skillLevelUpdateById(
      input: { name: $name, description: $description }
      _id: $_id
    ) {
      _id
      name
    }
  }
`;

export const SKILL_LEVEL_REMOVE_BY_ID = gql`
  mutation($_id: ID!) {
    skillLevelRemoveById(_id: $_id) {
      name
    }
  }
`;
