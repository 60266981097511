import { CheckIcon, PlusIcon } from "@heroicons/react/solid";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { TEAM_BY_ID, TEAM_MANY, TEAM_UPDATE_BY_ID } from "../../schema/team";

import { percentFormater } from "../../config/i18n";
import { EmployeeMatch, Team } from "../../types";
import Arc from "../charts/Arc";
import Avatar from "../employees/Avatar";

interface Props {
  match: EmployeeMatch;
  team?: Team;
  type?: "applicants" | "employees";
}

const Item = ({ match, team, type = "employees" }: Props) => {
  const { formatMessage: f } = useIntl();
  const formattedScore = percentFormater.format(match.score);

  const [updateTeam] = useMutation(TEAM_UPDATE_BY_ID, {
    refetchQueries: [
      { query: TEAM_MANY },
      {
        query: TEAM_BY_ID,
        variables: {
          _id: team?._id,
        },
      },
    ],
  });

  const renderTeamButton = () => {
    if (!team) {
      return null;
    }

    const isMember = team.memberIds.includes(match._id);

    const onClick = () => {
      let memberIds = [...team.memberIds];
      if (isMember) {
        memberIds = memberIds.filter((item) => item !== match._id);
      } else {
        memberIds.push(match._id);
      }

      updateTeam({
        variables: {
          _id: team._id,
          memberIds: memberIds,
        },
      });
    };

    return (
      <button
        className="inline-flex items-center h-6 m-4 p-2 rounded-md shadow-sm text-xs text-gray-700 dark:text-gray-400 dark:border-gray-800 border border-gray-200"
        onClick={onClick}
      >
        {isMember ? (
          <>
            <CheckIcon className="w-5 h-5 text-green-500" />
            Team Mitglied
          </>
        ) : (
          <>
            <PlusIcon className="w-5 h-5 text-black" />
            Zum Team hinzufügen
          </>
        )}
      </button>
    );
  };

  return (
    <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow">
      <div className="flex-1 flex flex-col p-8">
        <Link to={`/${type}/${match._id}/details`}>
          <Avatar employee={match as any} className="mx-auto w-24 h-24" />
        </Link>

        <span className="mt-6 flex items-flext-start justify-between">
          <Arc width={50} height={50} value={match.score * 100} />
          <h3 className="text-gray-900 text-sm leading-5 font-medium">
            {match.firstName} {match.lastName}
          </h3>
        </span>

        {renderTeamButton()}

        <dl className="mt-1 flex flex-col justify-between">
          <dt className="sr-only">Kompetenzen</dt>
          <dd className="text-gray-500 text-sm leading-5">
            {match.matchingSkills.length} Kompetenzen identisch
          </dd>
          <dt className="sr-only">Match</dt>
          <dd className="mt-3">
            <span className="px-2 py-1 text-teal-800 text-xs leading-4 font-medium bg-coral-100 rounded-full">
              {formattedScore} Übereinstimmung
            </span>
          </dd>
        </dl>
      </div>
      <div className="border-t border-gray-200">
        <div className="-mt-px flex">
          <div className="w-0 flex-1 flex border-r border-gray-200">
            <Link
              to={`/${type}/${match._id}/details`}
              className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
            >
              {f({ id: "actions.details" })}
            </Link>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <Link
              to={`/${type}/${match._id}/profile`}
              className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
            >
              <svg
                className="w-5 h-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
              </svg>
              <span className="ml-1">{f({ id: "profile.name" })}</span>
            </Link>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Item;
