import { useFormContext } from "react-hook-form";

const ExpirationYearField = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="expiration-year"
        className="block text-sm font-medium text-gray-700"
      >
        Verfällt im Jahr
      </label>
      <input
        type="number"
        defaultValue={new Date().getFullYear() + 5}
        id="expiration-year"
        {...register("expirationYear", {
          valueAsNumber: true,
          min: new Date().getFullYear(),
          minLength: 4,
          maxLength: 4,
        })}
        className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      />
    </>
  );
};

export default ExpirationYearField;
