import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { SkillType } from "../../types";

interface Props {
  skillType: SkillType;
};

const Item = ({ skillType }: Props) => {
  return (
    <tr>
      <td className="px-6 py-4">
        <div className="flex items-center">
          <Link to={`/skillTypes/${skillType._id}/details`} className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {skillType.name}
            </div>
            <p className="text-sm text-gray-500 overflow-ellipsis">
              {skillType.description}
            </p>
          </Link>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {skillType.createdAt &&
          format(parseISO(skillType.createdAt), "do MMMM", {
            locale: de,
          })}
      </td>
    </tr>
  );
};

export default Item;
