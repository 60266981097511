import { Link } from "react-router-dom";
import { Vacancy } from "../../types";
import ContextMenu from "./ContextMenu";

interface Props {
  vacancy: Vacancy;
};

const Item = ({ vacancy }: Props) => {
  return (
    <li className="col-span-1 flex items-center bg-white border border-gray-200 rounded-md shadow-sm overflow-visible">
      <Link
        to={`/vacancies/${vacancy._id}/details`}
        className="flex-shrink-0 flex items-center justify-center w-16 h-16 text-white text-center text-sm leading-5 font-medium bg-pink-600 rounded-tl rounded-bl"
      >
        {vacancy.name.charAt(0).toUpperCase()}
      </Link>
      <div className="flex-1 px-4 py-2 truncate">
        <Link
          to={`/vacancies/${vacancy._id}/details`}
          className="text-gray-900 text-sm leading-5 font-medium hover:text-gray-600 transition ease-in-out duration-150"
        >
          {vacancy.name}
        </Link>
        <p className="text-sm leading-5 text-gray-500">{vacancy.description}</p>
      </div>
      <div className="flex-shrink-0 pr-2">
        <ContextMenu vacancy={vacancy} />
      </div>
    </li>
  );
};

export default Item;
