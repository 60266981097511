import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/solid";

import { de } from "date-fns/locale";
import { User } from "../../types";

interface Props {
  user: User;
}

const Item = ({ user }: Props) => {
  return (
    <tr>
      <td className="px-6 py-4">
        <div className="flex items-center">
          <Link to={`/users/${user._id}/details`} className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {user.username}
            </div>
          </Link>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <span className="text-sm font-medium text-gray-500">
          {user.locked ? (
            <LockClosedIcon className="w-4 h-4 text-red-500" />
          ) : (
            <LockOpenIcon className="w-4 h-4" />
          )}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          {user.role.name}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {user.createdAt &&
          format(parseISO(user.createdAt), "do MMMM", {
            locale: de,
          })}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {user.loginAt &&
          format(parseISO(user.loginAt), "do MMMM HH:mm", {
            locale: de,
          })}
      </td>
    </tr>
  );
};

export default Item;
