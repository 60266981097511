import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

import { useParams, useHistory, RouteComponentProps } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  ORGANIZATION_BY_ID,
  ORGANIZATION_UPDATE_BY_ID,
  ORGANIZATION_REMOVE_BY_ID,
  ORGANIZATION_MANY,
  ORGANIZATION_TREE,
} from "../../schema/organization";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { Loading, Error } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import Form, { FormData } from "./Form";

import Stats from "../units/Stats";
import { Divider } from "../ui/layout/Divider";
import RevenueFields from "../revenues/Fields";
import MetricTooltip from "../competences/MetricTooltip";
import { currencyFormater } from "../../config/i18n";

interface ParamTypes {
  id: string;
}

const Details = ({}: RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [open, toggle] = useToggle();

  const { formatMessage: f } = useIntl();

  const { loading, error, data } = useQuery(ORGANIZATION_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateOrganization] = useMutation(ORGANIZATION_UPDATE_BY_ID, {
    refetchQueries: [{ query: ORGANIZATION_MANY }],
  });
  const [deleteOrganization] = useMutation(ORGANIZATION_REMOVE_BY_ID, {
    refetchQueries: [{ query: ORGANIZATION_MANY }],
  });

  const { data: treeData } = useQuery(ORGANIZATION_TREE, {
    variables: {
      _id: id,
    },
  });

  const onUpdateRevenues = (formData: FormData) => {
    updateOrganization({
      variables: {
        _id: id,
        revenues: formData.revenues.map((r) => ({
          ...r,
          year: +r.year,
          target: +r.target,
          actual: +r.actual,
        })),
      },
    });
    history.goBack();
  };

  const onUpdate = (formData: FormData) => {
    updateOrganization({
      variables: {
        _id: id,
        name: formData.name,
        description: formData.description,
        managerId: formData.managerId,
      },
    });
    toggle();
  };

  const onDelete = () => {
    deleteOrganization({ variables: { _id: id } });
    history.goBack();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Organisation bearbeiten">
          <Form onSubmit={onUpdate} data={data?.organizationById} />
        </SlideOver>

        <div className="bg-gray-100 mb-4">
          <div className="bg-white shadow">
            <div className="container mx-auto px-6">
              <div className="flex items-center justify-between py-5">
                <div>
                  <Link
                    to="/jobs"
                    className="inline-flex items-center text-sm text-gray-500 leading-none pb-2"
                  >
                    <i className="fas fa-angle-left"></i>
                    <span>Organisation</span>
                  </Link>
                  <div className="flex items-center">
                    <h1 className="px-2 text-2xl text-gray-900 font-extrabold">
                      {data?.organizationById.name}
                    </h1>
                  </div>
                </div>
                <div className="flex items-center">
                  <span className="shadow-sm rounded-md">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                      onClick={toggle}
                    >
                      {f({ id: "actions.edit" })}
                    </button>
                  </span>
                  <span className="ml-3 shadow-sm rounded-md">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline-red focus:border-red-700 active:bg-red-700 transition duration-150 ease-in-out"
                      onClick={onDelete}
                    >
                      {f({ id: "actions.delete" })}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container mx-auto p-6">
            <div className="w-full px-2 pb-6">
              <h2 className="text-lg font-medium text-gray-900 pb-6">
                {f({ id: "skills.name" })}
              </h2>
              <div className="bg-white rounded shadow text-gray-600">
                <Stats
                  humatics={data?.organizationById?.humatics}
                  jobHumatics={data?.organizationById?.jobHumatics}
                  actualRevenue={data.organizationById?.actualRevenue}
                  targetRevenue={data.organizationById?.targetRevenue}
                  knowledgeValue={data?.organizationById?.knowledgeValue}
                />
              </div>
            </div>
            <div className="flex -mx-2">
              <div className="w-1/2 px-2">
                <div className="mb-6">
                  <h2 className="text-lg font-medium text-gray-900 pb-6">
                    {f({ id: "summary.name" })}
                  </h2>
                  <div className="bg-white rounded shadow overflow-hidden">
                    <div className="px-6 py-5">
                      <dl>
                        <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            {f({ id: "meta.description" })}
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {data?.organizationById?.description}
                          </dd>
                        </div>
                        <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            Manager
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {data?.organizationById?.manager?.firstName}{" "}
                            {data?.organizationById?.manager?.lastName}
                          </dd>
                        </div>
                        <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            {f({ id: "employees.target" })}
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {data?.organizationById.requiredEmployeeCount}
                          </dd>
                        </div>
                        <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            {f({ id: "employees.actual" })}
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {data?.organizationById.employeeCount}
                          </dd>
                        </div>
                        <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            {f({ id: "salaries.target" })}
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {currencyFormater.format(
                              data?.organizationById?.requiredSalary
                            )}
                          </dd>
                        </div>
                        <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm leading-5 font-medium text-gray-500">
                            {f({ id: "salaries.actual" })}
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            {currencyFormater.format(
                              data?.organizationById?.totalSalary
                            )}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 px-2">
                <div className="mb-6">
                  <h2 className="text-lg font-medium text-gray-900 pb-6">
                    {f({ id: "revenues.name" })}
                  </h2>
                  <div className="bg-white rounded shadow overflow-hidden text-gray-600">
                    <RevenueFields
                      onSubmit={onUpdateRevenues}
                      data={data?.organizationById?.revenues}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Divider>
          <Divider.Content>{f({ id: "revenues.name" })}</Divider.Content>
        </Divider>

        <table className="my-6 min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <MetricTooltip
                  text={f({ id: "revenues.year.target.description" })}
                >
                  <>{f({ id: "revenues.year.target" })}</>
                </MetricTooltip>
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <MetricTooltip
                  text={f({ id: "revenues.year.actual.description" })}
                >
                  <>{f({ id: "revenues.year.actual" })}</>
                </MetricTooltip>
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <MetricTooltip
                  text={f({ id: "revenues.year.annualized.description" })}
                >
                  <>{f({ id: "revenues.year.annualized" })}</>
                </MetricTooltip>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td className="px-6 py-4">
                {currencyFormater.format(data?.organizationById.targetRevenue)}
              </td>
              <td className="px-6 py-4">
                {currencyFormater.format(data?.organizationById.actualRevenue)}
              </td>
              <td className="px-6 py-4">
                {currencyFormater.format(
                  data?.organizationById.annualizedRevenue
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </SliderOverContext.Provider>
    </>
  );
};

export default Details;
