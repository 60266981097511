import { isWithinInterval, parseISO } from "date-fns";
import { Competence, Employee } from "../../types";

// Collapse the node and all it's children
export const collapse = (d: any, i: number) => {
  if (d.children) {
    d._children = d.children;
    d._children.forEach(collapse);
    d.children = null;
  }
};

export const expand = (d: any) => {
  var children = d.children ? d.children : d._children;
  if (d._children) {
    d.children = d._children;
    d._children = null;
  }
  if (children) {
    children.forEach(expand);
  }
};

/**
 * Toggle children by switching around
 * values on _children and children.
 */
export const toggleChildren = (node: any) => {
  if (node.children) {
    node._children = node.children;
    node.children = null;
  } else if (node._children) {
    node.children = node._children;
    node._children = null;
  }
  return node;
};

/**
 * Align children to position of parent
 * @param {Array} nodes
 * @param {Object} parent node
 * @returns {Array}
 */
export function alignChildren(nodes: any, parent: any): Array<any> {
  nodes.forEach((d: any) => {
    if (d === parent) {
      if (d.children) {
        // expanded children
        d.children.forEach((c: any) => {
          c.x0 = parent.x;
          c.y0 = parent.y;
        });
      }
      if (d._children) {
        // collapsed children
        d._children.forEach((c: any) => {
          c.x0 = parent.x;
          c.y0 = parent.y;
        });
      }
    }
  });
  return nodes;
}

// Creates a curved (diagonal) path from parent to the child nodes
// const diagonal = d3.linkHorizontal().x(function(d:any) { return d.y; }).y(function(d:any) { return d.x; });
export const diagonal = (s: any, d: any) => {
  const path = `M ${s.x} ${s.y}
          C ${(s.x + d.x) / 2} ${s.y},
            ${(s.x + d.x) / 2} ${d.y},
            ${d.x} ${d.y}`;

  return path;
};

/**
 * A recursive helper function for performing some setup by walking through all nodes
 * @param parent
 * @param visitFn
 * @param childrenFn
 * @returns
 */
export const visit = (
  parent: any,
  visitFn: (parent: any) => void,
  childrenFn: (parent: any) => []
) => {
  if (!parent) return;
  visitFn(parent);
  var children = childrenFn(parent);
  if (children != null) {
    var count = children.length;
    for (var i = 0; i < count; i++) {
      visit(children[i], visitFn, childrenFn);
    }
  }
};

/**
 * Recursively search the tree
 * @param {Object} node
 * @param {String} query
 * @param {Array} path
 * @returns {Object}
 */
export const searchTree = (node: any, query: string, path: any[] = []): any => {
  const regex = new RegExp(`${query}`, "i");

  const name = node.data.name.toString();
  const employeeNames =
    node.data.employees?.map((e: any) => [e.firstName, e.lastName]).flat() ||
    [];

  if (
    name.search(regex) > -1 ||
    employeeNames.find((name: string) => regex.test(name))
  ) {
    // if query is found return, add the node to the path and return it
    path.push(node);
    return path;
  } else if (node.children || node._children) {
    // if children are collapsed d3 will have them instantiated as _children
    const children = node.children || node._children;
    for (let i = 0; i < children.length; i++) {
      path.push(node); // we assume this path is the right one
      const found = searchTree(children[i], query, path);
      if (found) {
        // we were right, this should return the bubbled-up path from the first if statement
        return found;
      } else {
        // we were wrong, remove this parent from the path and continue iterating
        path.pop();
      }
    }
  } else {
    // not the right object, return false so it will continue to iterate in the loop
    return false;
  }
};

export const findMatches = (filterData: any, e: Employee) => {
  const results: boolean[] = [];

  let employeeCompetences = e.competences;

  // Filter Skills
  if (filterData?.skillIds && filterData?.skillIds.length > 0) {
    employeeCompetences = e.competences.filter((c: Competence) =>
      filterData.skillIds.includes(c.skillId)
    );
    const matchSkill =
      employeeCompetences.length === filterData.skillIds.length;
    results.push(matchSkill);
  }

  // Filter Retire Date
  if (filterData?.retireDateStart && filterData?.retireDateEnd) {
    let matchRetireDate = isWithinInterval(parseISO(e.retireDate), {
      start: filterData?.retireDateStart,
      end: filterData?.retireDateEnd,
    });
    results.push(matchRetireDate);
  }

  // Filter Termination Date
  if (filterData?.terminationDateStart && filterData?.terminationDateEnd) {
    let matchTerminationDate = isWithinInterval(parseISO(e.terminationDate), {
      start: filterData?.terminationDateStart,
      end: filterData?.terminationDateEnd,
    });
    results.push(matchTerminationDate);
  }

  // Filter SkillScale
  if (filterData?.skillScaleRange && filterData?.skillScaleRange.length === 2) {
    let matchSkillScale =
      employeeCompetences.filter((c: Competence) => {
        const [min, max] = filterData.skillScaleRange;
        const value = c.value / 100;
        console.log(c.skill.name, min, max, value);
        return value > min && value <= max;
      }).length > 0;
    results.push(matchSkillScale);
  }

  let success = results.length > 0 && results.every(Boolean);

  // console.log(
  //   "findMatches",
  //   filterData,
  //   e.firstName,
  //   e.lastName,
  //   employeeCompetences,
  //   results,
  //   success
  // );

  return success;
};

/**
 * Recursively search the tree
 * @param {Object} node
 * @param {Object} filterData
 * @param {Array} path
 * @returns {Object}
 */
export const filterTree = (
  node: any,
  filterData: any,
  path: any[] = []
): any => {
  const hasMatches = node.data.employees?.find((e: Employee) => {
    const success = findMatches(filterData, e);
    if (success) {
      return e._id;
    }
  });

  if (hasMatches) {
    // if query is found return, add the node to the path and return it
    path.push(node);
    return path;
  } else if (node.children || node._children) {
    // if children are collapsed d3 will have them instantiated as _children
    const children = node.children || node._children;
    for (let i = 0; i < children.length; i++) {
      path.push(node); // we assume this path is the right one
      const found = filterTree(children[i], filterData, path);
      if (found) {
        // we were right, this should return the bubbled-up path from the first if statement
        return found;
      } else {
        // we were wrong, remove this parent from the path and continue iterating
        path.pop();
      }
    }
  } else {
    // not the right object, return false so it will continue to iterate in the loop
    return false;
  }
};

/**
 * Higlight path
 * @param {Array} paths
 */
export const highlightPath = (paths: any[] = []) => {
  for (let i = 0; i < paths.length; i++) {
    //console.log("highlightPath", paths[i].data.name);
    if (paths[i].id !== "1") {
      // i.e. not root
      paths[i].found = true;
      if (paths[i]._children) {
        // if children are hidden: open them, otherwise: don't do anything
        paths[i].children = paths[i]._children;
        paths[i]._children = null;
      }
    }
  }
};
