import { useState } from "react";

const useToggle = (
  initialState: boolean = false
): [boolean, () => void, () => void, () => void] => {
  const [isOpen, setOpen] = useState<boolean>(initialState);

  const open = () => {
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  const toggle = () => {
    setOpen(!isOpen);
  };

  return [isOpen, toggle, open, close];
};

export default useToggle;
