import { useMutation, useQuery } from "@apollo/client";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";

import { useIntl } from "react-intl";

import { JOB_CREATE_ONE, JOB_MANY_GROUP_BY_UNIT } from "../../schema/job";
import { JobByUnit } from "../../types/job";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { PermissionRequired } from "components/ui/permissions";
import { PermissionFlag, scope } from "types/permission";
import { Error, Loading } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import { H1 } from "../ui/Typo/Typo";
import Form, { FormData } from "./Form";
import List from "./List";
import Tabs from "./Tabs";

const Index = () => {
  const { loading, error, data } = useQuery(JOB_MANY_GROUP_BY_UNIT);
  const [createJob] = useMutation(JOB_CREATE_ONE, {
    refetchQueries: [{ query: JOB_MANY_GROUP_BY_UNIT }],
  });
  const [open, toggle] = useToggle();
  const { formatMessage: f } = useIntl();

  const onSubmit = (formData: FormData) => {
    createJob({
      variables: {
        name: formData.name,
        description: formData.description,
        jobDescriptionId: formData.jobDescriptionId
          ? formData.jobDescriptionId
          : null,
        salary: +formData.salary,
        costs: +formData.costs,
        unitId: formData.unitId ? formData.unitId : null,
      },
    });
    toggle();
  };

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      <PermissionRequired
        module="jobs.show"
        flag={PermissionFlag.WRITE}
        scopeRequired={scope.ALL}
      >
        <SliderOverContext.Provider value={{ open: open, toggle }}>
          <SlideOver title="Arbeitsplatz hinzufügen">
            <Form onSubmit={onSubmit} />
          </SlideOver>
        </SliderOverContext.Provider>
      </PermissionRequired>

      <Tabs />

      {/* SectionHeader */}
      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
        <div className="ml-4 mt-2">
          <H1>{f({ id: "jobs.name" })}</H1>
        </div>
        <div className="p-6 bg-white ml-4 mt-2 flex-shrink-0">
          <PermissionRequired
            module="jobs.show"
            flag={PermissionFlag.WRITE}
            scopeRequired={scope.ALL}
          >
            <span className="inline-flex rounded-md shadow-sm">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray bg-coral-300 hover:bg-coral-200 focus:outline-none focus:shadow-outline-indigo focus:border-orange-400 active:bg-coral-400"
                onClick={toggle}
              >
                <svg
                  className="group-hover:text-light-blue-600 text-light-blue-500 mr-2"
                  width="12"
                  height="20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 5a1 1 0 011 1v3h3a1 1 0 110 2H7v3a1 1 0 11-2 0v-3H2a1 1 0 110-2h3V6a1 1 0 011-1z"
                  />
                </svg>
                {f({ id: "jobs.new" })}
              </button>
            </span>
          </PermissionRequired>
        </div>
      </div>
      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
        {`${data?.jobManyGroupByUnit.reduce(
          (memo: any, item: JobByUnit) => memo + item.count,
          0
        )} Einträge`}
      </h2>

      <div className="py-4">
        {data?.jobManyGroupByUnit.map((item: JobByUnit, index: number) => {
          return (
            <Disclosure key={index} defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between w-full mb-2 px-4 py-2 text-sm font-medium text-left text-white bg-brand-primary rounded-lg hover:bg-gray-900 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    <span>{item.name}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-gray-400`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="pl-4 pb-4">
                    <List data={item.jobs} />
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          );
        })}
      </div>
    </>
  );
};

export default Index;
