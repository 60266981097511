import { useEffect } from "react";
import {
  useParams,
  useHistory,
  match,
  RouteComponentProps,
} from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { useIntl } from "react-intl";

import { TEAM_BY_ID } from "../../schema/team";
import { Requirement } from "../../types";

import { Loading, Error } from "../ui/Alerts";
import List from "../matches/List";

interface Props {
  location: RouteComponentProps<any>["location"];
  match?: match<ParamTypes>;
}

interface ParamTypes {
  id: string;
}

const Match = ({ location }: Props) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (!id) {
      history.push("/");
      return;
    }
  }, [id, history]);

  const { loading, error, data } = useQuery(TEAM_BY_ID, {
    variables: {
      _id: id,
    },
  });

  const renderDisclosure = (r: Requirement) => {
    return (
      <Disclosure key={r.jobId}>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full mb-2 px-4 py-2 text-sm font-medium text-left text-gray-900 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
              <span className="font-semibold">{r.quantity}</span>
              <span>{r.job?.name}</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180" : ""
                } w-5 h-5 text-gray-500`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
              <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
                {`${r.job?.matches.length} Ergebnisse`}
              </h2>

              <List data={r.job?.matches} team={data?.teamById} />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  };

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      <div className="pb-6">
        <div className="inline-flex items-center text-sm text-gray-500 leading-none pb-2">
          <span>{f({ id: "teams.match" })}</span>
        </div>
        <div className="flex items-center">
          <h1 className="px-2 text-2xl text-gray-900 font-extrabold">
            {data?.teamById.name}
          </h1>
        </div>
      </div>

      {data?.teamById.requirements.map(renderDisclosure)}
    </>
  );
};

export default Match;
