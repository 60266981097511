import { gql } from "@apollo/client";

export const TRANSLATION_MANY = gql`
  query {
    translationMany {
      name
      locales {
        de
        en
        it
        ru
        tr
        ar
        zh
      }
      createdAt
      _id
    }
  }
`;

export const TRANSLATION_CREATE_ONE = gql`
  mutation($name: String!, $locales: TranslationLocalesInput) {
    translationCreateOne(input: { name: $name, locales: $locales }) {
      _id
      name
    }
  }
`;

export const TRANSLATION_UPDATE_BY_ID = gql`
  mutation($_id: ID!, $name: String!, $locales: TranslationLocalesInput) {
    translationUpdateById(
      input: { name: $name, locales: $locales }
      _id: $_id
    ) {
      _id
      name
    }
  }
`;

export const TRANSLATION_REMOVE_BY_ID = gql`
  mutation($_id: ID!) {
    translationRemoveById(_id: $_id) {
      name
    }
  }
`;
