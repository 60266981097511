import { useState, useEffect, ChangeEvent } from "react";
import styled from "styled-components";

const FormWrapper = styled.div`
  position: relative;

  .search-icon {
    position: relative;
    cursor: pointer;
    margin-left: -4px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;

    svg {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      height: 20px;
      width: 20px;
      position: absolute;
      left: -22px;
      top: 10px;
      fill: white;
    }
  }

  input {
    border: none;
    padding-left: 15px;
    text-indent: -9999px;
    transition: width 0.2s cubic-bezier(0, 1.2, 0.4, 1.1);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  input::-webkit-input-placeholder,
  input:-moz-placeholder,
  input::-moz-placeholder,
  input:-ms-input-placeholder {
    color: white;
  }

  input:focus::-webkit-input-placeholder,
  input:focus:-moz-placeholder,
  input:focus::-moz-placeholder,
  input:focus:-ms-input-placeholder {
    opacity: 0.5;
  }

  input:focus {
    text-indent: 0;
    width: 300px;
    padding-left: 15px;
  }
`;

interface Props {
  placeholder?: string;
  // onChange?: (event: ChangeEvent) => void;
  onChange?: (value: any) => void;
}

/*

const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncecValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncecValue(value), delay);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
};
*/

const Search = ({ placeholder, onChange }: Props) => {
  const [query, setQuery] = useState("");

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        onChange?.(query);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [query]);

  return (
    <>
      <FormWrapper>
        <input
          id="search"
          className="rounded-full w-8 h-8 bg-gray-200 text-sm text-gray-500 outline-none cursor-pointer"
          type="text"
          placeholder={placeholder}
          onBlur={(event) => onChange?.(event.target.value)}
          onChange={(event) => setQuery(event.target.value)}
        />
        <label htmlFor="search" className="search-icon">
          <svg>
            <use href="#icon-search"></use>
          </svg>
        </label>
      </FormWrapper>

      <span style={{ display: "none" }}>
        <svg xmlns="http://www.w3.org/2000/svg">
          <symbol viewBox="0 0 512 512" id="icon-search">
            <title>search</title>
            <path
              d="M460.355,421.59L353.844,315.078c20.041-27.553,31.885-61.437,31.885-98.037	C385.729,124.934,310.793,50,218.686,50C126.58,50,51.645,124.934,51.645,217.041c0,92.106,74.936,167.041,167.041,167.041
          c34.912,0,67.352-10.773,94.184-29.158L419.945,462L460.355,421.59z M100.631,217.041c0-65.096,52.959-118.056,118.055-118.056
          c65.098,0,118.057,52.959,118.057,118.056c0,65.096-52.959,118.056-118.057,118.056C153.59,335.097,100.631,282.137,100.631,217.041 	z"
            />{" "}
          </symbol>
        </svg>
      </span>
    </>
  );
};

export default Search;
