import { useForm, FormProvider, Controller } from "react-hook-form";
import Select from "react-select";
import { useQuery } from "@apollo/client";

import { SKILL_MANY } from "../../schema/skill";
import { EMPLOYEE_MANY } from "../../schema/employee";
import { SkillType, Skill, Employee } from "../../types";

import Loading from "../ui/Alerts/Loading";
import Error from "../ui/Alerts/Error";
import { Divider } from "../ui/layout/Divider";
import { SeminarTypeSelect } from "../common/form";
import { DateField } from "../common/form";

interface Props {
  onSubmit: (formData: any) => void;
  data?: SkillType;
}

export type FormData = {
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  price: number;
  location: string;
  subjectIds: Array<string>;
  participantIds: Array<string>;
  seminarTypeId: string;
};

const Form = ({ onSubmit, data }: Props) => {
  const methods = useForm<FormData>({ defaultValues: data });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = methods;
  const {
    loading: skillLoading,
    error: skillError,
    data: skillData,
  } = useQuery(SKILL_MANY);
  const {
    loading: employeeLoading,
    error: employeeError,
    data: employeeData,
  } = useQuery(EMPLOYEE_MANY);

  const skillOptions =
    skillData?.skillMany.map(
      (skill: Skill) =>
        ({
          id: skill._id,
          name: skill.name,
        } as any)
    ) || [];

  const employeeOptions =
    employeeData?.employeeMany.map(
      (employee: Employee) =>
        ({
          id: employee._id,
          name: `${employee.firstName} ${employee.lastName}`,
        } as any)
    ) || [];

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} id="slide-over-form">
        <div className="flex-1 flex flex-col justify-between">
          <div className="px-4 divide-y divide-gray-200 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              <div className="space-y-1">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-5 text-gray-900"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  {...register("name", { required: true })}
                  className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />

                {errors.name && (
                  <span className="text-sm font-light text-red-500">
                    Name fehlt
                  </span>
                )}
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium leading-5 text-gray-900"
                >
                  Beschreibung
                </label>
                <div className="mt-1">
                  <textarea
                    id="description"
                    {...register("description")}
                    className="shadow-sm focus:ring-orange-500 focus:border-orange-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                  ></textarea>
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Kurze Beschreibung dieses Seminars
                </p>
              </div>

              <Divider>
                <Divider.Content>Mehr</Divider.Content>
              </Divider>

              <div className="flex space-x-6">
                <div className="space-y-1">
                  <DateField name="startDate" label="Startdatum" />
                </div>
                <div className="space-y-1">
                  <DateField name="endDate" label="Enddatum" />
                </div>
              </div>
              <div className="flex space-x-6">
                <div className="space-y-1">
                  <div className="space-y-1">
                    <label
                      htmlFor="price"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Preis
                    </label>
                    <input
                      type="number"
                      id="price"
                      {...register("price", {
                        required: true,
                        valueAsNumber: true,
                      })}
                      className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                    {errors.price && (
                      <span className="text-sm font-light text-red-500">
                        Preis fehlt
                      </span>
                    )}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="location"
                    className="block text-sm font-medium leading-5 text-gray-900"
                  >
                    Ort
                  </label>
                  <input
                    type="text"
                    id="location"
                    {...register("location")}
                    className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />

                  {errors.location && (
                    <span className="text-sm font-light text-red-500">
                      Ort fehlt
                    </span>
                  )}
                </div>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="skill-type-id"
                  className="block text-sm font-medium leading-5 text-gray-900"
                >
                  Seminartyp
                </label>
                <Controller
                  control={control}
                  name="seminarTypeId"
                  render={({ field }) => <SeminarTypeSelect {...field} />}
                  rules={{ required: true }}
                />
                {errors.seminarTypeId && (
                  <span className="text-sm font-light text-red-500">
                    Seminartyp fehlt
                  </span>
                )}
              </div>
              <div className="space-y-1">
                {skillLoading && <Loading />}
                {skillError && <Error error={skillError} />}
                <label
                  htmlFor="subjectIds"
                  className="block text-sm font-medium leading-5 text-gray-900"
                >
                  Kompetenzen
                </label>
                <div className="mt-1">
                  <Controller
                    control={control}
                    name="subjectIds"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        placeholder="Kompetenz wählen..."
                        options={skillOptions}
                        value={skillOptions.filter(({ id }: any) =>
                          value?.includes(id)
                        )}
                        getOptionLabel={({ name }: any) => name}
                        getOptionValue={({ id }: any) => id}
                        isLoading={skillLoading}
                        isMulti
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onBlur={onBlur}
                        onChange={(values: any) =>
                          onChange((values ?? []).map((d: any) => d.id))
                        }
                      />
                    )}
                  />
                </div>
                <div className="space-y-1">
                  {employeeLoading && <Loading />}
                  {employeeError && <Error error={employeeError} />}
                  <label
                    htmlFor="participantIds"
                    className="block text-sm font-medium leading-5 text-gray-900"
                  >
                    Teilnehmer
                  </label>
                  <div className="mt-1">
                    <Controller
                      control={control}
                      name="participantIds"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          placeholder="Teilnehmer wählen..."
                          options={employeeOptions}
                          value={employeeOptions.filter(({ id }: any) =>
                            value?.includes(id)
                          )}
                          getOptionLabel={({ name }: any) => name}
                          getOptionValue={({ id }: any) => id}
                          isLoading={employeeLoading}
                          isMulti
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onBlur={onBlur}
                          onChange={(values: any) =>
                            onChange((values ?? []).map((d: any) => d.id))
                          }
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default Form;
