import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import { EmployeeSelect, OrganizationSelect } from "../common/form";
import { Invitation, Role } from "../../types";

interface Props {
  onSubmit: (formData: any) => void;
  data?: Invitation;
  roles: Role[];
}

export type FormData = {
  employeeId: string;
  organizationId: string;
  role: string;
};

const Form = ({ onSubmit, data, roles }: Props) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ defaultValues: data });
  const { formatMessage: f } = useIntl();

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="slide-over-form">
      <div className="flex-1 flex flex-col justify-between">
        <div className="px-4 divide-y divide-gray-200 sm:px-6">
          <div className="space-y-6 pt-6 pb-5">
            <div className="space-y-1">
              <label
                htmlFor="skill-type-id"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                {f({ id: "employees.name" })}
              </label>
              <Controller
                control={control}
                name="employeeId"
                render={({ field }) => <EmployeeSelect {...field} />}
                rules={{ required: true }}
                defaultValue=""
              />
              {errors.employeeId && (
                <span className="text-sm font-light text-red-500">
                  Mitarbeiter fehlt
                </span>
              )}
            </div>
            <div className="space-y-1">
              <label
                htmlFor="skill-type-id"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Organisation
              </label>
              <Controller
                control={control}
                name="organizationId"
                render={({ field }) => <OrganizationSelect {...field} />}
                rules={{ required: true }}
                defaultValue=""
              />
              {errors.organizationId && (
                <span className="text-sm font-light text-red-500">
                  Organisation fehlt
                </span>
              )}
            </div>
            <div className="space-y-1">
              <label
                htmlFor="role"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Rolle
              </label>
              <div className="mt-1">
                <div className="rounded-md shadow-sm">
                  <select
                    id="role"
                    {...register("role", { required: true })}
                    className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 rounded focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                  >
                    <option></option>
                    {roles.map((r: Role) => (
                      <option key={r._id} value={r._id}>
                        {r.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
