import { find, propEq, groupBy, prop, pipe, toPairs, assoc, map } from "ramda";

import { TPermissionRow } from "./PermissionRow";
import type { Role } from "types/role";
import type { Permission, PermissionEntry } from "types/permission";

type EntryFinder = (x: PermissionEntry) => boolean;

export const byRoleId = (roleId: string): EntryFinder => (e: PermissionEntry) => e?.role?._id === roleId;

export const getEntryForRole = (roleId: string) => (p: Permission): TPermissionRow => ({
  _id: p._id,
  name: p.name,
  module: p.module,
  displayName: p.displayName,
  entry: find(byRoleId(roleId), p.entries),
})

export const byModule = groupBy<Permission, string>(prop("module"));

export const permissionsByModule = (roleId: string | null) => pipe(
  byModule,
  toPairs
);

export const findRole = (roleId: string) => (roles: Role[]) => find(propEq("_id", roleId), roles) || null;

export const mapEntries = (entry: PermissionEntry) => map((e: PermissionEntry) => e._id === entry._id ? entry : e);

export const replaceEntry = (permission: Permission, entry: PermissionEntry): Permission => {
  const entries = mapEntries(entry)(permission.entries);
  return assoc('entries', entries, permission);
};
