import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

import Tooltip from "../../ui/overlays/Tooltip";
import MicroChart from "../../charts/MicroChart";
import { numberFormater, percentFormater } from "../../../config/i18n";

interface Props {
  employee: any;
  children: React.ReactNode;
}

const EmployeeTooltip = ({ employee, children }: Props) => {
  const { humatics } = employee;
  const jobHumatics = employee.job?.humatics;

  const { formatMessage: f } = useIntl();

  return (
    <div className="p-1 flex items-center align-middle">
      {children}
      <Tooltip>
        <p className="text-sm font-bold text-gray-800 pb-1">
          {employee?.firstName} {employee?.lastName}
        </p>
        <p className="text-xs leading-4 text-gray-600 pb-1"></p>

        <div className="mt-1 flex flex-row items-center">
          <div className="w-12 h-6 mr-2">
            <MicroChart data={employee.competences} />
          </div>
          <dl className="flex flex-col justify-between">
            <dt className="sr-only">Betriebszugehörigkeit</dt>
            <dd className="text-gray-400 text-xs">
              {Math.floor(employee.seniority)} Jahre im Betrieb
            </dd>
            <dt className="sr-only">Alter</dt>
            <dd className="text-gray-400 text-xs">
              {Math.floor(employee.age)} Jahre alt
            </dd>
            <dt className="sr-only">{f({ id: "competencecoverage" })}</dt>
            <dd className="text-gray-400 text-xs">
              {f({ id: "competencecoverage" })}{" "}
              {percentFormater.format(employee.competenceCoverage)}{" "}
            </dd>
          </dl>
        </div>

        <table className="mt-3 divide-y divide-gray-200 font-normal text-xs">
          <thead className="bg-gray-100">
            <tr>
              <th
                scope="col"
                className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase"
              ></th>
              <th
                scope="col"
                className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase"
              >
                {f({ id: "actual" })}
              </th>
              <th
                scope="col"
                className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase"
              >
                {f({ id: "target" })}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td className="px-3 py-2">{f({ id: "humatics.h" })}</td>
              <td className="px-3 py-2">
                {humatics ? numberFormater.format(humatics.h) : null}
              </td>
              <td className="px-3 py-2">
                {jobHumatics ? numberFormater.format(jobHumatics.h) : null}
              </td>
            </tr>
            <tr>
              <td className="px-3 py-2">{f({ id: "humatics.t" })}</td>
              <td className="px-3 py-2">
                {humatics ? numberFormater.format(humatics.t) : null}
              </td>
              <td className="px-3 py-2">
                {jobHumatics ? numberFormater.format(jobHumatics.t) : null}
              </td>
            </tr>
            <tr>
              <td className="px-3 py-2">{f({ id: "humatics.ef" })}</td>
              <td className="px-3 py-2">
                {humatics ? numberFormater.format(humatics.ef) : null}
              </td>
              <td className="px-3 py-2">
                {jobHumatics ? numberFormater.format(jobHumatics.ef) : null}
              </td>
            </tr>
            <tr>
              <td className="px-3 py-2">{f({ id: "humatics.mu" })}</td>
              <td className="px-3 py-2">
                {humatics ? numberFormater.format(humatics.mu) : null}
              </td>
              <td className="px-3 py-2">
                {jobHumatics ? numberFormater.format(jobHumatics.mu) : null}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="pt-3 flex items-center justify-between">
          <Link
            to={`/employees/${employee?._id}/profile`}
            className="text-xs font-bold text-gray-500"
          >
            {f({ id: "profile.name" })}
          </Link>

          <Link to={`/employees/${employee?._id}/details`} className="ml-4">
            <button className="focus:outline-none bg-gray-500 transition duration-150 ease-in-out hover:bg-gray-400 rounded text-white px-5 py-1 text-xs">
              {f({ id: "actions.details" })}
            </button>
          </Link>
        </div>
      </Tooltip>
    </div>
  );
};

export default EmployeeTooltip;
