import { createContext } from "react";

type ContextType = {
  open: boolean
  toggle: () => void
};

const SliderOverContext = createContext<ContextType>({
  open: false,
  toggle: () => {},
});

export default SliderOverContext;