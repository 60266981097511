import { useMutation, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import {
  Link,
  RouteComponentProps,
  useHistory,
  useParams,
} from "react-router-dom";

import {
  VACANCY_BY_ID,
  VACANCY_MANY,
  VACANCY_REMOVE_BY_ID,
  VACANCY_UPDATE_BY_ID,
} from "../../schema/vacancy";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { Error, Loading } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import { Divider } from "../ui/layout/Divider";
import Form, { FormData } from "./Form";

import { currencyFormater } from "../../config/i18n";
import ProfileChart from "../charts/ProfileChart";
import MetricTooltip from "../competences/MetricTooltip";
import Stats from "../competences/Stats";

interface ParamTypes {
  id: string;
}

const Details = ({}: RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [open, toggle] = useToggle();
  const { formatMessage: f } = useIntl();

  const { loading, error, data } = useQuery(VACANCY_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateVacancy] = useMutation(VACANCY_UPDATE_BY_ID, {
    refetchQueries: [{ query: VACANCY_MANY }],
  });
  const [deleteVacancy] = useMutation(VACANCY_REMOVE_BY_ID, {
    refetchQueries: [{ query: VACANCY_MANY }],
  });

  const onUpdate = (formData: FormData) => {
    updateVacancy({
      variables: {
        _id: id,
        name: formData.name,
        description: formData.description,
        salary: +formData.salary,
        unitId: formData.unitId,
        jobId: formData.jobId,
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteVacancy({ variables: { _id: id } });
    history.goBack();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const { unitId, unit, salary, competences, humatics } = data.vacancyById;

  const formattedSalary = currencyFormater.format(salary);

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Stellenangebot bearbeiten">
          <Form onSubmit={onUpdate} data={data.vacancyById} />
        </SlideOver>
      </SliderOverContext.Provider>

      <div className="bg-gray-100 mb-4">
        <div className="bg-white shadow">
          <div className="container mx-auto px-6">
            <div className="flex items-center justify-between py-5">
              <div>
                <Link
                  to="/vacancies"
                  className="inline-flex items-center text-sm text-gray-500 leading-none pb-2"
                >
                  <i className="fas fa-angle-left"></i>
                  <span>Stellenangebot</span>
                </Link>
                <div className="flex items-center">
                  <h1 className="px-2 text-2xl text-gray-900 font-extrabold">
                    {data.vacancyById.name}
                  </h1>
                </div>
              </div>
              <div className="flex items-center">
                <span className="shadow-sm rounded-md">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                    onClick={toggle}
                  >
                    {f({ id: "actions.edit" })}
                  </button>
                </span>
                <span className="ml-3 shadow-sm rounded-md">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline-red focus:border-red-700 active:bg-red-700 transition duration-150 ease-in-out"
                    onClick={onDelete}
                  >
                    {f({ id: "actions.delete" })}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto p-6">
          <div className="w-full px-2 pb-6">
            <h2 className="text-lg font-medium text-gray-900 pb-6">
              {f({ id: "skills.name" })}
            </h2>
            <div className="bg-white rounded shadow text-gray-600">
              <Stats
                humatics={humatics}
                targetRevenue={data.vacanyById?.targetRevenue}
              />
            </div>
          </div>
          <div className="flex -mx-2">
            <div className="w-1/2 px-2">
              <div className="mb-6">
                <h2 className="text-lg font-medium text-gray-900 pb-6">
                  {f({ id: "summary.name" })}
                </h2>
                <div className="bg-white rounded shadow overflow-hidden">
                  <div className="px-6 py-5">
                    <dl>
                      <div className="flex">
                        <div className="w-1/2 mb-6">
                          <dt className="text-sm font-medium text-gray-500">
                            {f({ id: "unit.name" })}
                          </dt>
                          <dd className="text-base font-normal text-gray-900">
                            <Link to={`/units/${unitId}/details`}>
                              {unit?.name}
                            </Link>
                          </dd>
                        </div>
                        <div className="w-1/2 mb-6">
                          <dt className="text-sm font-medium text-gray-500">
                            {f({ id: "salary.name" })}
                          </dt>
                          <dd className="text-base font-normal text-gray-900">
                            {formattedSalary}
                          </dd>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="w-full mb-6">
                          <dt className="text-sm font-medium text-gray-500">
                            {f({ id: "job.name" })}
                          </dt>
                          <dd className="text-base font-normal text-gray-900">
                            <Link
                              to={`/jobs/${data.vacancyById.jobId}/details`}
                            >
                              {data.vacancyById.job?.name}
                            </Link>
                          </dd>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="w-full mb-6">
                          <dt className="text-sm font-medium text-gray-500">
                            {f({ id: "meta.description" })}
                          </dt>
                          <dd className="text-sm font-normal text-gray-900">
                            {data.vacancyById.description}
                          </dd>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2 px-2">
              <h2 className="text-lg font-medium text-gray-900 pb-6">
                {f({ id: "skills.name" })}
              </h2>
              <div className="bg-white rounded shadow text-gray-600">
                <div>
                  <div className="flex"></div>
                  <div className="flex overflow-hidden">
                    <div className="w-full">
                      <ProfileChart
                        width={400}
                        height={200}
                        readonly={true}
                        data={competences}
                      />
                    </div>
                  </div>
                  <div className="flex bg-white px-6 py-4 border-t">
                    <Link
                      to={`/vacancies/${id}/profile`}
                      className="w-full text-center px-6 py-2 bg-coral-500 rounded"
                    >
                      <span className="text-white">
                        {f({ id: "profile.name" })}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Divider>
        <Divider.Content>{f({ id: "revenues.name" })}</Divider.Content>
      </Divider>

      <table className="my-6 min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <MetricTooltip
                text={f({ id: "revenues.year.target.description" })}
              >
                <>{f({ id: "revenues.year.target" })}</>
              </MetricTooltip>
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <MetricTooltip
                text={f({ id: "revenues.year.actual.description" })}
              >
                <>{f({ id: "revenues.year.actual" })}</>
              </MetricTooltip>
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <MetricTooltip
                text={f({ id: "revenues.year.annualized.description" })}
              >
                <>{f({ id: "revenues.year.annualized" })}</>
              </MetricTooltip>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          <tr>
            <td className="px-6 py-4">
              {currencyFormater.format(data?.vacancyById.targetRevenue)}
            </td>
            <td className="px-6 py-4">
              {currencyFormater.format(data?.vacancyById.actualRevenue)}
            </td>
            <td className="px-6 py-4">
              {currencyFormater.format(data?.vacancyById.annualizedRevenue)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Details;
