import { gql } from "@apollo/client";

export const typeDefs = gql`
  extend type Query {
    isConcealed: Boolean!
  }
`;

export const IS_CONCEALED = gql`
  query {
    isConcealed @client
  }
`;
