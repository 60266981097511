import { useApolloClient } from "@apollo/client";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

export type FormData = {
  password: string;
};

interface ParamTypes {
  resetPasswordToken: string;
}

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>();

  const { resetPasswordToken } = useParams<ParamTypes>();
  const history = useHistory();
  const client = useApolloClient();

  const onSubmit = async (data: FormData) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_AUTH_URI}/resetPassword/${resetPasswordToken}`,
        data
      );

      history.push("/");
    } catch (e) {
      setError("password", {
        type: "manual",
        message: e.message,
      });
      console.error(e);
    }
  };

  return (
    <div className="absolute h-100 left-0 top-0 min-w-full min-h-full flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-auto w-1/2" src="/logo.svg" alt="8Skills" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Passwort zurücksetzen
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="password" className="sr-only">
                Passwort
              </label>
              <input
                type="password"
                id="password"
                placeholder="Neues Passwort"
                autoComplete="off"
                {...register("password", { required: "Passwort fehlt" })}
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm"
              />
            </div>
          </div>

          <div className="flex flex-col space-y-1">
            {errors.password && (
              <span className="text-sm font-light text-red-500">
                {errors.password.message}
              </span>
            )}
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-coral-500 hover:bg-coral-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400"
            >
              Absenden
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
