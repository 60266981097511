import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";

import { JOB_BY_ID } from "../../schema/job";

import { Vacancy, Competence } from "../../types";
import { UnitSelect, JobSelect } from "../common/form";

interface Props {
  onSubmit: (formData: any) => void;
  data?: Vacancy;
}

export type FormData = {
  name: string;
  description: string;
  salary: number;
  competences: Array<Competence>;
  unitId: string;
  jobId: string;
};

const Form = ({ onSubmit, data }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm<FormData>({ defaultValues: data });

  const selectedJobId = watch("jobId");

  const [getJob, { called: jobCalled, data: jobData }] = useLazyQuery(
    JOB_BY_ID,
    {
      variables: {
        _id: selectedJobId,
      },
    }
  );

  useEffect(() => {
    // for new vacancy records only
    if (!data?._id && selectedJobId) {
      //console.log("JobId changed to", selectedJobId);
      getJob({ variables: { _id: selectedJobId } });
    }
  }, [data, selectedJobId]);

  useEffect(() => {
    // job data fetched
    if (jobData?.jobById) {
      // filter out unwanted attributes
      const competences = jobData.jobById?.competences.map((c: Competence) => ({
        skillId: c.skillId,
        value: c.value,
      }));
      // assign competences from job to employee
      setValue("competences", competences, {
        shouldValidate: false,
      });
    }
  }, [jobCalled, jobData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="slide-over-form">
      <div className="flex-1 flex flex-col justify-between">
        <div className="px-4 divide-y divide-gray-200 sm:px-6">
          <div className="space-y-6 pt-6 pb-5">
            <div className="space-y-1">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                {...register("name", { required: true })}
                className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />

              {errors.name && (
                <span className="text-sm font-light text-red-500">
                  Name fehlt
                </span>
              )}
            </div>
            <div className="space-y-1">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Beschreibung
              </label>
              <div className="mt-1">
                <textarea
                  id="description"
                  {...register("description")}
                  className="shadow-sm focus:ring-orange-500 focus:border-orange-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                ></textarea>
              </div>
              <p className="mt-2 text-sm text-gray-500">
                Kurze Beschreibung zu diesem Stellenangebot
              </p>
            </div>
            <div className="space-y-1">
              <label
                htmlFor="salary"
                className="block text-sm font-medium text-gray-700"
              >
                Gehalt
              </label>
              <input
                type="number"
                id="salary"
                {...register("salary", { required: true, valueAsNumber: true })}
                className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              {errors.salary && (
                <span className="text-sm font-light text-red-500">
                  Gehalt fehlt
                </span>
              )}
            </div>
            <div className="space-y-2">
              <label
                htmlFor="unit-id"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Einheit
              </label>
              <Controller
                control={control}
                name="unitId"
                render={({ field }) => <UnitSelect {...field} />}
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="unit-id"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Arbeitsplatz
              </label>
              <Controller
                control={control}
                name="jobId"
                render={({ field }) => <JobSelect {...field} />}
                rules={{ required: true }}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
