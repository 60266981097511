import { useFormContext } from "react-hook-form";

const DescriptionField = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="description"
        className="block text-sm font-medium leading-5 text-gray-900"
      >
        Beschreibung
      </label>
      <textarea
        id="description"
        {...register("description")}
        className="h-32 shadow-sm focus:ring-orange-500 focus:border-orange-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
      ></textarea>
      <p className="mt-2 text-sm text-gray-500">
        Kurzer Beschreibungstext
      </p>
    </>
  );
};

export default DescriptionField;
