import clsx from "clsx";
import { Link } from "react-router-dom";

type CardProps = {
  title: string;
  text?: string;
  href: string;
  src?: string;
  className?: string;
};

const Card = ({ title, text, href, src, className }: CardProps) => (
  <Link
    to={href}
    className={clsx(
      "relative border shadow-sm rounded-md w-auto h-32 overflow-hidden",
      className
    )}
  >
    {src && <img className="w-full h-auto rounded-md" src={src} alt="" />}
    <div
      className={clsx("absolute top-0 start-0 end-0", {
        ["left-0 w-full h-full bg-black bg-opacity-20"]: src,
      })}
    >
      <div className="p-4 md:p-5">
        <h3 className="text-lg font-bold text-white">{title}</h3>
        <p className="mt-1 text-white">{text}</p>
      </div>
    </div>
  </Link>
);

export { Card };
