import { gql } from "@apollo/client";

export const FILTER_SEMINAR_MANY = gql`
  query ($skillIds: [ID]) {
    seminarMany(skillIds: $skillIds) {
      name
      description
      participantIds
      participants {
        _id
        firstName
        lastName
        photo
      }
      createdAt
      _id
    }
  }
`;

export const SEMINAR_MANY = gql`
  query {
    seminarMany {
      name
      description
      startDate
      endDate
      price
      location
      subjectIds
      participantIds
      participants {
        _id
        firstName
        lastName
        photo
      }
      seminarTypeId
      createdAt
      _id
    }
  }
`;

export const SEMINAR_BY_ID = gql`
  query ($_id: ID!) {
    seminarById(_id: $_id) {
      name
      description
      startDate
      endDate
      price
      location
      subjectIds
      subjects {
        _id
        name
      }
      participantIds
      participants {
        _id
        firstName
        lastName
        photo
      }
      seminarTypeId
      seminarType {
        name
      }
      createdAt
      _id
    }
  }
`;

export const SEMINAR_CREATE_ONE = gql`
  mutation (
    $name: String!
    $description: String
    $startDate: DateTime
    $endDate: DateTime
    $price: Float
    $location: String
    $subjectIds: [ID]
    $participantIds: [ID]
    $seminarTypeId: ID!
  ) {
    seminarCreateOne(
      input: {
        name: $name
        description: $description
        startDate: $startDate
        endDate: $endDate
        price: $price
        location: $location
        subjectIds: $subjectIds
        participantIds: $participantIds
        seminarTypeId: $seminarTypeId
      }
    ) {
      _id
      name
    }
  }
`;

export const SEMINAR_UPDATE_BY_ID = gql`
  mutation (
    $_id: ID!
    $name: String!
    $description: String
    $startDate: DateTime
    $endDate: DateTime
    $price: Float
    $location: String
    $subjectIds: [ID]
    $participantIds: [ID]
    $seminarTypeId: ID!
  ) {
    seminarUpdateById(
      input: {
        name: $name
        description: $description
        startDate: $startDate
        endDate: $endDate
        price: $price
        location: $location
        subjectIds: $subjectIds
        participantIds: $participantIds
        seminarTypeId: $seminarTypeId
      }
      _id: $_id
    ) {
      _id
      name
    }
  }
`;

export const SEMINAR_REMOVE_BY_ID = gql`
  mutation ($_id: ID!) {
    seminarRemoveById(_id: $_id) {
      name
    }
  }
`;
