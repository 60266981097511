import { gql } from "@apollo/client";
import { MATCH_FIELDS } from "./employee";

export const TEAM_MANY = gql`
  query {
    teamMany {
      name
      description
      leaderId
      leader {
        firstName
        lastName
      }
      memberIds
      members {
        firstName
        lastName
        photo
        _id
      }
      createdAt
      _id
    }
  }
`;

export const TEAM_BY_ID = gql`
  query ($_id: ID!) {
    teamById(_id: $_id) {
      name
      description
      leaderId
      memberIds
      members {
        firstName
        lastName
        photo
        _id
      }
      revenues {
        year
        target
        actual
      }
      requirements {
        quantity
        jobId
        job {
          name
          description
          matches {
            ...MatchFields
          }
        }
      }
      knowledgeValue
      actualRevenue
      annualizedRevenue
      targetRevenue
      humatics {
        competenceValues
        employeesH
        m
        h
        t
        phi
        r
      }
      createdAt
      _id
    }
  }
  ${MATCH_FIELDS}
`;

export const TEAM_CREATE_ONE = gql`
  mutation (
    $name: String!
    $description: String
    $leaderId: ID
    $memberIds: [ID]
  ) {
    teamCreateOne(
      input: {
        name: $name
        description: $description
        leaderId: $leaderId
        memberIds: $memberIds
      }
    ) {
      _id
      name
      memberIds
    }
  }
`;

export const TEAM_UPDATE_BY_ID = gql`
  mutation (
    $_id: ID!
    $name: String
    $description: String
    $leaderId: ID
    $memberIds: [ID]
    $revenues: [RevenueInput]
    $requirements: [RequirementInput]
  ) {
    teamUpdateById(
      input: {
        name: $name
        description: $description
        leaderId: $leaderId
        memberIds: $memberIds
        revenues: $revenues
        requirements: $requirements
      }
      _id: $_id
    ) {
      _id
      name
      memberIds
    }
  }
`;

export const TEAM_REMOVE_BY_ID = gql`
  mutation ($_id: ID!) {
    teamRemoveById(_id: $_id) {
      name
    }
  }
`;

export const TEAM_TREE = gql`
         query($teamIds: [ID]!) {
           teamTree(teamIds: $teamIds) {
             name
             _id
             humatics {
               employeesH
               h
               t
               r
               phi
             }
             children {
               name
               humatics {
                 h
                 t
                 phi
                 r
               }
               _id
               children {
                 firstName
                 lastName
                 humatics {
                   h
                   t
                   ef
                   r
                 }
                 job {
                   humatics {
                     h
                     t
                     ef
                     r
                   }
                 }
                 _id
               }
             }
           }
         }
       `;
