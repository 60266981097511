import React from "react";
import clsx from "clsx";
import { Link as ReactRouterLink } from "react-router-dom";
import type { LinkProps } from "react-router-dom";

import { usePermissions } from "hooks/usePermissions";
import { scope, PermissionFlag } from "types/permission";

export interface IProps extends LinkProps {
  module?: string;
  flag?: PermissionFlag;
  scopeRequired?: scope,
}

export const Link: React.FC<IProps> = ({
  module,
  flag = PermissionFlag.READ,
  scopeRequired = scope.SELF,
  ...props
}) => {
  const { hasPermissionScope, loading } = usePermissions();

  const disabled = module ? (loading || !hasPermissionScope(module, flag, scopeRequired)) : false;

  if (disabled) {
    return (
      <span
        className={clsx(props.className, "text-opacity-25 cursor-not-allowed")}
      >
        {props.children}
      </span>
    );
  }

  return <ReactRouterLink { ...props } />;
};
