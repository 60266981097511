import React from "react";
import Item from "./Item";
import { Vacancy } from "../../types";

interface Props {
  data: Array<Vacancy>;
};

const List: React.FC<Props> = ({ data = [] }) => {
  return (
    <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
      {data.map((vacancy: Vacancy) => (
        <Item key={vacancy._id} vacancy={vacancy} />
      ))}
    </ul>
  );
};

export default List;
