import { gql } from "@apollo/client";

export const localeTypes = gql`
  extend type Query {
    locale: String!
  }
`;

export const LOCALE = gql`
  query {
    locale @client
  }
`;
