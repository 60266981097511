import { ReactNode } from "react";
import { useIntl } from "react-intl";

import { H2 } from "../Typo/Typo";

interface Props {
  title?: String;
  onUpdate?: () => void;
  onDelete?: () => void;
  children?: ReactNode;
}

const PageHeader = ({ title, onUpdate, onDelete, children }: Props) => {
  const { formatMessage: f } = useIntl();

  return (
    <div className="p-6 bg-white">
      <div>
        <div>{children}</div>
        <div className="mt-2 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <H2>{title}</H2>
          </div>
          <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
            {onUpdate && (
              <span className="shadow-sm rounded-md">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                  onClick={() => {
                    onUpdate?.();
                  }}
                >
                  {f({ id: "actions.edit" })}
                </button>
              </span>
            )}
            {onDelete && (
              <span className="ml-3 shadow-sm rounded-md">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline-red focus:border-red-700 active:bg-red-700 transition duration-150 ease-in-out"
                  onClick={() => {
                    onDelete?.();
                  }}
                >
                  {f({ id: "actions.delete" })}
                </button>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
