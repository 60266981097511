import { gql } from "@apollo/client";

export const METRIC_SKILLS_BY_DATE = gql`
  query ($skillId: ID!) {
    metricSkillsByDate(skillId: $skillId) {
      skillId
      date
      values {
        supply
        demand
      }
    }
  }
`;

export const METRIC_HUMATICS_BY_DATE = gql`
  query($type: String!, $objectId: ID) {
    metricHumaticsByDate(type: $type, objectId: $objectId) {
      date
      values {
        m
        h
        employeesH
        t
        ef
        phi
        mu
        r
        knowledgeValue
      }
    }
  }
`;

export const METRIC_HUMATICS_PREVIOUS_PERIOD = gql`
  query {
    metricHumaticsPreviousPeriod {
      date
      values {
        m
        h
        t
        ef
        phi
        mu
        r
      }
    }
  }
`;

export const METRIC_HUMATICS_BY_DATE_GROUP = gql`
  query($type: String!, $objectIds: [ID]) {
    metricHumaticsByDateGroup(type: $type, objectIds: $objectIds) {
      date
      objectId
      values {
        m
        h
        t
        ef
        phi
        mu
        r
      }
    }
  }
`;