import { useFormContext, Controller } from "react-hook-form";

import UnitSelect from "./UnitSelect";

const UnitField = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="unit-id"
        className="block text-sm font-medium leading-5 text-gray-900"
      >
        Einheit
      </label>
      <Controller
        control={control}
        name="unitId"
        render={({ field }) => <UnitSelect {...field} />}
      />
    </>
  );
};

export default UnitField;
