import { useMutation, useQuery } from "@apollo/client";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import {
  SKILL_LEVEL_BY_ID,
  SKILL_LEVEL_MANY,
  SKILL_LEVEL_REMOVE_BY_ID,
  SKILL_LEVEL_UPDATE_BY_ID,
} from "../../schema/skillLevel";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { Error, Loading } from "../ui/Alerts";
import PageHeader from "../ui/Headings/PageHeader";
import { Breadcrumb } from "../ui/navigation/Breadcrumb";
import SlideOver from "../ui/SlideOver";

import List from "../skills/List";
import Form, { FormData } from "./Form";

interface ParamTypes {
  id: string;
}

const Details = ({}: RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [open, toggle] = useToggle();

  const { loading, error, data } = useQuery(SKILL_LEVEL_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateSkillLevel] = useMutation(SKILL_LEVEL_UPDATE_BY_ID, {
    refetchQueries: [{ query: SKILL_LEVEL_MANY }],
  });
  const [deleteSkillLevel] = useMutation(SKILL_LEVEL_REMOVE_BY_ID, {
    refetchQueries: [{ query: SKILL_LEVEL_MANY }],
  });

  const onUpdate = (formData: FormData) => {
    updateSkillLevel({
      variables: {
        _id: id,
        name: formData.name,
        description: formData.description,
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteSkillLevel({ variables: { _id: id } });
    history.goBack();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Kompetenzlevel bearbeiten">
          <Form onSubmit={onUpdate} data={data.skillLevelById} />
        </SlideOver>
        <PageHeader
          title={data?.skillLevelById?.name}
          onUpdate={toggle}
          onDelete={onDelete}
        >
          <Breadcrumb>
            <Breadcrumb.Item url="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item url="/skillLevels">Kompetenzlevel</Breadcrumb.Item>
            <Breadcrumb.Item>{data?.skillLevelById?.name}</Breadcrumb.Item>
          </Breadcrumb>
        </PageHeader>
      </SliderOverContext.Provider>

      <p className="px-6 mt-1 max-w-2xl text-sm leading-5 text-gray-500">
        {data.skillLevelById.description}
      </p>

      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
        {`${data?.skillLevelById?.skills.length} Einträge`}
      </h2>
      <List data={data?.skillLevelById?.skills} />
    </>
  );
};

export default Details;
