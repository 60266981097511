import { Link } from "react-router-dom";
import { useParams, useHistory, RouteComponentProps } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { useIntl } from "react-intl";

import {
  JOB_DESCRIPTION_BY_ID,
  JOB_DESCRIPTION_UPDATE_BY_ID,
  JOB_DESCRIPTION_REMOVE_BY_ID,
  JOB_DESCRIPTION_MANY,
} from "../../schema/jobDescription";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { Loading, Error } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";

import Form, { FormData } from "./Form";

interface ParamTypes {
  id: string;
}

const Details = ({}: RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [open, toggle] = useToggle();
  const { formatMessage: f } = useIntl();

  const { loading, error, data } = useQuery(JOB_DESCRIPTION_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateJobDescription] = useMutation(JOB_DESCRIPTION_UPDATE_BY_ID, {
    refetchQueries: [{ query: JOB_DESCRIPTION_MANY }],
  });
  const [deleteJobDescription] = useMutation(JOB_DESCRIPTION_REMOVE_BY_ID, {
    refetchQueries: [{ query: JOB_DESCRIPTION_MANY }],
  });

  const onUpdate = (formData: FormData) => {
    updateJobDescription({
      variables: {
        _id: id,
        name: formData.name,
        title: formData.title,
        description: formData.description,
        location: formData.location,
        validFrom: formData.validFrom,
        specifications: formData.specifications,
        responsibilities: formData.responsibilities,
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteJobDescription({ variables: { _id: id } });
    history.goBack();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Arbeitsplatzbeschreibung bearbeiten">
          <Form onSubmit={onUpdate} data={data.jobDescriptionById} />
        </SlideOver>
      </SliderOverContext.Provider>

      <div className="bg-gray-100">
        <div className="bg-white shadow">
          <div className="container mx-auto px-6">
            <div className="flex items-center justify-between py-5">
              <div>
                <Link
                  to="/jobDescriptions"
                  className="inline-flex items-center text-sm text-gray-500 leading-none pb-2"
                >
                  <i className="fas fa-angle-left"></i>
                  <span>Arbeitsplatzbeschreibung</span>
                </Link>
                <div className="flex items-center">
                  <h1 className="px-2 text-2xl text-gray-900 font-extrabold">
                    {data?.jobDescriptionById?.name}
                  </h1>
                </div>
              </div>
              <div className="flex items-center">
                <span className="shadow-sm rounded-md">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                    onClick={toggle}
                  >
                    {f({ id: "actions.edit" })}
                  </button>
                </span>
                <span className="ml-3 shadow-sm rounded-md">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline-red focus:border-red-700 active:bg-red-700 transition duration-150 ease-in-out"
                    onClick={onDelete}
                  >
                    {f({ id: "actions.delete" })}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto p-6">
          <div className="flex -mx-2">
            <div className="w-3/5 px-2">
              <div className="mb-6">
                <h2 className="text-lg font-medium text-gray-900 pb-6">
                  {f({ id: "jobDescription.responsibilities" })}
                </h2>
                <div className="bg-white rounded shadow text-gray-600 mb-6">
                  <div className="px-6 py-5">
                    {data?.jobDescriptionById.responsibilities}
                  </div>
                </div>
              </div>
              <div>
                <h2 className="text-lg font-medium text-gray-900 pb-6">
                  {f({ id: "jobDescription.specifications" })}
                </h2>
                <div className="bg-white rounded shadow overflow-hidden">
                  <div className="px-6 py-5">
                    {data?.jobDescriptionById.specifications}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-2/5 px-2">
              <h2 className="text-lg font-medium text-gray-900 pb-6">
                {f({ id: "summary.name" })}
              </h2>
              <div className="bg-white rounded shadow overflow-hidden">
                <div className="px-6 py-5">
                  <dl>
                    <div className="flex">
                      <div className="w-1/2 mb-6">
                        <dt className="text-sm font-medium text-gray-500">
                          {f({ id: "jobDescription.name" })}
                        </dt>
                        <dd className="text-base font-normal text-gray-900">
                          {data?.jobDescriptionById.name}
                        </dd>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="w-full mb-6">
                        <dt className="text-sm font-medium text-gray-500">
                          {f({ id: "jobDescription.title" })}
                        </dt>
                        <dd className="text-sm font-normal text-gray-900">
                          {data?.jobDescriptionById.title}
                        </dd>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="w-full mb-6">
                        <dt className="text-sm font-medium text-gray-500">
                          {f({ id: "jobDescription.description" })}
                        </dt>
                        <dd className="text-sm font-normal text-gray-900">
                          {data?.jobDescriptionById.description}
                        </dd>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="w-1/2 mb-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Startdatum
                        </dt>
                        <dd className="text-base font-normal text-gray-900">
                          {data?.jobDescriptionById.validFrom &&
                            format(
                              parseISO(data?.jobDescriptionById.validFrom),
                              "dd.MM.yyyy",
                              {
                                locale: de,
                              }
                            )}
                        </dd>
                      </div>
                      <div className="w-1/2 mb-6">
                        <dt className="text-sm font-medium text-gray-500">
                          {f({ id: "jobDescription.location" })}
                        </dt>
                        <dd className="text-base font-normal text-gray-900">
                          {data?.jobDescriptionById.location}
                        </dd>
                      </div>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
