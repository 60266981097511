import { useFormContext, Controller } from "react-hook-form";

import JobSelect from "./JobSelect";

const JobField = () => {
  const {
    formState: { errors },
    control
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="job-id"
        className="block text-sm font-medium leading-5 text-gray-900"
      >
        Arbeitsplatz
      </label>
      <Controller
        control={control}
        name="jobId"
        render={({ field }) => <JobSelect {...field} />}
      />
    </>
  );
};

export default JobField;
