import { Link } from "react-router-dom";
import { UserIcon, MailOpenIcon, KeyIcon } from "@heroicons/react/outline";
import { useIntl } from "react-intl";

const Tabs = () => {
  const { formatMessage: f } = useIntl();

  return (
    <div>
      <div className="sm:hidden">
        <select className="form-select block w-full" defaultValue="Kompetenzen">
          <option>{f({ id: "invitations.name" })}</option>
          <option>{f({ id: "permissions.name" })}</option>
          <option>{f({ id: "users.name" })}</option>
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="flex -mb-px">
            <Link
              to="/invitations"
              className="group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              <MailOpenIcon className="mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              <span>{f({ id: "invitations.name" })}</span>
            </Link>
            <Link
              to="/permissions"
              className="ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              <KeyIcon className="mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              <span>{f({ id: "permissions.name" })}</span>
            </Link>
            <Link
              to="/users"
              className="ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              <UserIcon className="mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              <span>{f({ id: "users.name" })}</span>
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
