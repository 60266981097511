import React from "react";
import { useIntl } from "react-intl";

import { Skill } from "../../types";
import MetricTooltip from "../competences/MetricTooltip";
import Item from "./Item";

import { PermissionRequired } from "components/ui/permissions";

interface Props {
  data: Array<Skill>;
}

const List: React.FC<Props> = ({ data = [] }) => {
  const { formatMessage: f } = useIntl();

  return (
    <div className="mt-3 flex flex-col space-y-6">
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {f({ id: "meta.name" })}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Verwendung
                  </th>
                  <PermissionRequired module="competencies.value.employee">
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <MetricTooltip
                        text={f({ id: "skills.supply.description" })}
                      >
                        <>{f({ id: "skills.supply" })}</>
                      </MetricTooltip>
                    </th>
                  </PermissionRequired>
                  <PermissionRequired module="competencies.value.workplace">
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <MetricTooltip
                        text={f({ id: "skills.demand.description" })}
                      >
                        <>{f({ id: "skills.demand" })}</>
                      </MetricTooltip>
                    </th>
                  </PermissionRequired>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Level
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Erstellt
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data?.map((skill: Skill) => (
                  <Item key={skill._id} skill={skill} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
