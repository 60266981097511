import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Link,
  RouteComponentProps,
  match,
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom";

import { ResponsiveLine } from "@nivo/line";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";

import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  EMPLOYEE_BY_ID,
  EMPLOYEE_MANY,
  EMPLOYEE_MANY_GROUP_BY_UNIT,
  EMPLOYEE_REMOVE_BY_ID,
  EMPLOYEE_UPDATE_BY_ID,
} from "../../schema/employee";
import { METRIC_HUMATICS_BY_DATE } from "../../schema/metric";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { File, Review } from "../../types";
import FileList from "../files/List";
import { Error, Loading } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import { Divider } from "../ui/layout/Divider";
import Avatar from "./Avatar";
import Dropzone from "./Dropzone";
import Form, { FormData as FormDataType } from "./Form";

import { BlurOverlay, PermissionRequired } from "components/ui/permissions";
import { PermissionFlag } from "types/permission";
import { currencyFormater, numberFormater } from "../../config/i18n";
import ProfileChart from "../charts/ProfileChart";
import MetricTooltip from "../competences/MetricTooltip";
import Stats from "../competences/Stats";
import RevenueFields from "../revenues/Fields";
import { H3 } from "../ui/Typo/Typo";

interface ParamTypes {
  id: string;
}

interface Props {
  required: string;
  match?: match<ParamTypes>;
}

const Details = ({}: Props & RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [open, toggle] = useToggle();
  const { formatMessage: f } = useIntl();

  const client = useApolloClient();
  const { loading, error, data } = useQuery(EMPLOYEE_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateEmployee] = useMutation(EMPLOYEE_UPDATE_BY_ID, {
    refetchQueries: [
      { query: EMPLOYEE_MANY },
      { query: EMPLOYEE_MANY_GROUP_BY_UNIT, variables: { skillIds: null } },
      {
        query: EMPLOYEE_BY_ID,
        variables: {
          _id: id,
        },
      },
    ],

    /*
    update(cache, { data: { employeeUpdateById } }) {
      try {
        cache.writeQuery({
          query: EMPLOYEE_BY_ID,
          variables: { _id: id },
          data: {
            employeeById: employeeUpdateById,
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
    */
  });

  const [deleteEmployee] = useMutation(EMPLOYEE_REMOVE_BY_ID, {
    refetchQueries: [
      { query: EMPLOYEE_MANY },
      { query: EMPLOYEE_MANY_GROUP_BY_UNIT, variables: { skillIds: null } },
    ],
  });

  const { data: chartData } = useQuery(METRIC_HUMATICS_BY_DATE, {
    variables: { type: "employee", objectId: id },
  });

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.forEach((file: any) => {
        formData.append("file", file);
      });

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_FILES_URI}/upload`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // Merge with actual employee files
      const fileIds = [...data?.employeeById.fileIds, ...response.data];
      updateEmployee({
        variables: {
          _id: id,
          fileIds: fileIds,
        },
      });
    },
    [id, updateEmployee, data]
  );

  useEffect(() => {
    if (!id) {
      history.push("/");
      return;
    }
  }, [id, history]);

  const onUpdate = (formData: FormDataType) => {
    const { __typename, ...address } = formData.address as any;
    updateEmployee({
      variables: {
        _id: id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        gender: formData.gender,
        email: formData.email,
        phone: formData.phone,
        address: address,
        photo: formData.photo,
        birthDate: formData.birthDate,
        hireDate: formData.hireDate,
        retireDate: formData.retireDate,
        terminationDate: formData.terminationDate,
        personnalNumber: formData.personnalNumber,
        jobId: formData.jobId,
        salary: +formData.salary,
        unitId: formData.unitId,
        managerId: formData.managerId ? formData.managerId : null,
        employmentType: formData.employmentType,
        employmentStatus: formData.employmentStatus,
      },
    });
    history.goBack();
  };

  const onUpdateRevenues = (formData: FormDataType) => {
    updateEmployee({
      variables: {
        _id: id,
        revenues: formData.revenues.map((r) => ({
          ...r,
          year: +r.year,
          target: +r.target,
          actual: +r.actual,
        })),
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteEmployee({ variables: { _id: id } });
    history.goBack();
  };

  const onDeleteFile = (file: File) => {
    const fileIds = data?.employeeById.fileIds.filter(
      (id: string) => id !== file._id
    );
    updateEmployee({
      variables: {
        _id: id,
        fileIds: fileIds,
      },
    });

    client.cache.modify({
      id: client.cache.identify(data?.employeeById),
      fields: {
        fileIds(existing = []) {
          return existing.filter((fileId: string) => fileId !== file._id);
        },
      },
    });
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const {
    firstName,
    lastName,
    email,
    hireDate,
    job,
    salary,
    competences,
  } = data.employeeById;

  const formattedSalary = currencyFormater.format(salary);

  const filterChartData = (key: String) => {
    return (
      chartData?.metricHumaticsByDate?.map((d: any) => {
        //@ts-ignore
        return { x: d.date, y: d.values[key] };
      }) || []
    );
  };

  const groupedData = [
    {
      id: f({ id: "humatics.h" }),
      data: filterChartData("h"),
    },
    {
      id: f({ id: "humatics.t" }),
      data: filterChartData("t"),
    },
    {
      id: f({ id: "humatics.ef" }),
      data: filterChartData("ef"),
    },
    {
      id: f({ id: "humatics.mu" }),
      data: filterChartData("mu"),
    },
  ];

  return (
    <>
      <PermissionRequired
        module="employees.details"
        flag={PermissionFlag.WRITE}
      >
        <SliderOverContext.Provider value={{ open: open, toggle }}>
          <SlideOver title={f({ id: "employee.actions.edit" })}>
            <Form onSubmit={onUpdate} data={data.employeeById} />
          </SlideOver>
        </SliderOverContext.Provider>
      </PermissionRequired>

      <H3>Kompetenzwerte des Mitarbeiters</H3>
      <div className="pt-2 pb-6 h-64">
        <BlurOverlay module="employees.humatics">
          <ResponsiveLine
            data={groupedData}
            margin={{ top: 60, right: 50, bottom: 50, left: 60 }}
            theme={{ background: "#f7fafc" }}
            xScale={{
              type: "time",
              format: "%Y-%m-%d",
              useUTC: false,
              precision: "day",
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
              type: "linear",
              stacked: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              format: "%b",
              tickValues: "every 4 weeks",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Zeitverlauf",
              legendPosition: "middle",
              legendOffset: 36,
            }}
            axisLeft={{
              tickSize: 10,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Wert",
              legendPosition: "middle",
              legendOffset: -50,
            }}
            curve="monotoneX"
            enablePointLabel={false}
            enableSlices="x"
            sliceTooltip={({ slice }) => {
              return (
                <div className="flex flex-col items-center bg-white shadow-sm text-xs rounded py-1 px-2">
                  {slice.points.map((point) => (
                    <div
                      key={point.id}
                      style={{ color: point.serieColor }}
                      className="leading-normal"
                    >
                      <span>{point.serieId}</span>{" "}
                      <span className="font-bold">
                        {numberFormater.format(+point.data.yFormatted)}
                      </span>
                    </div>
                  ))}
                </div>
              );
            }}
            pointSize={6}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={false}
            enableArea={false}
            enableGridX={false}
            enableGridY={false}
            legends={[
              {
                anchor: "top",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: -50,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 180,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </BlurOverlay>
      </div>

      <div className="bg-gray-100 mb-6">
        <div className="bg-white shadow">
          <div className="container mx-auto px-6">
            <div className="flex items-center justify-between py-5">
              <div>
                <Link
                  to="/jobs"
                  className="inline-flex items-center text-sm text-gray-500 leading-none pb-2"
                >
                  <i className="fas fa-angle-left"></i>
                  <span>{job?.name}</span>
                </Link>
                <div className="flex items-center">
                  <Avatar employee={data.employeeById} className="w-10 h-10" />
                  <h1 className="px-2 text-2xl text-gray-900 font-extrabold">
                    {firstName} {lastName}
                  </h1>
                </div>
              </div>
              <div className="flex items-center">
                <PermissionRequired
                  module="employees.details"
                  flag={PermissionFlag.WRITE}
                >
                  <span className="shadow-sm rounded-md">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                      onClick={toggle}
                    >
                      {f({ id: "actions.edit" })}
                    </button>
                  </span>
                </PermissionRequired>
                <PermissionRequired
                  module="employees.details"
                  flag={PermissionFlag.WRITE}
                >
                  <span className="ml-3 shadow-sm rounded-md">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline-red focus:border-red-700 active:bg-red-700 transition duration-150 ease-in-out"
                      onClick={onDelete}
                    >
                      {f({ id: "actions.delete" })}
                    </button>
                  </span>
                </PermissionRequired>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto p-6">
          <PermissionRequired module="employees.competency_profile">
            <div className="w-full px-2 pb-6">
              <h2 className="text-lg font-medium text-gray-900 pb-6">
                {f({ id: "skills.name" })}
              </h2>
              <div className="bg-white rounded shadow text-gray-600 mb-6">
                <Stats
                  humatics={data.employeeById?.humatics}
                  actualRevenue={data.employeeById?.annualizedRevenue}
                  targetRevenue={data.employeeById?.targetRevenue}
                  competenceCoverage={data.employeeById?.competenceCoverage}
                  knowledgeValue={data.employeeById?.knowledgeValue}
                />
              </div>
            </div>
          </PermissionRequired>

          <div className="flex -mx-2">
            <div className="w-1/2 px-2">
              <div className="mb-6">
                <h2 className="text-lg font-medium text-gray-900 pb-6">
                  {f({ id: "summary.name" })}
                </h2>
                <div className="bg-white rounded shadow overflow-hidden">
                  <div className="px-6 py-5">
                    <dl>
                      <div className="flex">
                        <PermissionRequired module="employees.workplace">
                          <div className="w-3/5 mb-6">
                            <dt className="text-sm font-medium text-gray-500">
                              {f({ id: "job.name" })}
                            </dt>
                            <dd className="text-base font-normal text-gray-900">
                              <Link to={`/jobs/${job?._id}/details`}>
                                {job?.name}
                              </Link>
                            </dd>
                          </div>
                        </PermissionRequired>
                        <PermissionRequired module="employees.salary">
                          <div className="w-2/5 mb-6">
                            <dt className="text-sm font-medium text-gray-500">
                              {f({ id: "salary.name" })}
                            </dt>
                            <dd className="text-base font-normal text-gray-900">
                              {formattedSalary}
                            </dd>
                          </div>
                        </PermissionRequired>
                      </div>
                      <div className="flex">
                        <div className="w-3/5 mb-6">
                          <dt className="text-sm font-medium text-gray-500">
                            E-Mail
                          </dt>
                          <dd className="text-base font-normal text-gray-900">
                            <a href={`mailto:${email}`}>{email}</a>
                          </dd>
                        </div>
                        <div className="w-2/5 mb-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Einstellungsdatum
                          </dt>
                          <dd className="text-base font-normal text-gray-900">
                            {hireDate &&
                              format(parseISO(hireDate), "dd.MM.yyyy", {
                                locale: de,
                              })}
                          </dd>
                        </div>
                      </div>
                      <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Beschäftigungsstatus
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {data?.employeeById?.employmentStatus}
                        </dd>
                      </div>

                      <div className="flex mt-12">
                        <div className="w-full">
                          <dt className="text-sm font-medium text-gray-500">
                            {data?.employeeById.fileIds.length} Dateien
                          </dt>
                          <dd className="mt-2">
                            <Dropzone onDrop={onDrop} />
                            <FileList
                              data={data?.employeeById.files}
                              onDelete={onDeleteFile}
                            />
                          </dd>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2 px-2">
              {/* Reviews */}
              <PermissionRequired module="employees.review">
                <div className="mb-6">
                  <h2 className="text-lg font-medium text-gray-900 pb-6">
                    {f({ id: "reviews.name" })}
                  </h2>
                  <div className="bg-white rounded shadow overflow-hidden text-gray-600">
                    {data?.employeeById?.reviews.map(
                      (review: Review, index: number) => {
                        return (
                          <dl key={review._id}>
                            <div
                              className={`${
                                index % 2 ? "bg-gray-50" : "bg-white-50"
                              } px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}
                            >
                              <dt className="text-sm font-medium text-gray-500">
                                <Link to={`/reviews/${review._id}/details`}>
                                  {review.name}
                                </Link>
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {format(
                                  parseISO(review.createdAt),
                                  "dd.MM.yyyy",
                                  {
                                    locale: de,
                                  }
                                )}
                              </dd>
                            </div>
                          </dl>
                        );
                      }
                    )}
                  </div>
                </div>
              </PermissionRequired>

              {/* Revenues */}
              <div className="mb-6">
                <h2 className="text-lg font-medium text-gray-900 pb-6">
                  {f({ id: "revenues.name" })}
                </h2>
                <div className="bg-white rounded shadow overflow-hidden text-gray-600">
                  <RevenueFields
                    onSubmit={onUpdateRevenues}
                    data={data?.employeeById?.revenues}
                  />
                </div>
              </div>

              {/* Profile */}
              <h2 className="text-lg font-medium text-gray-900 pb-6">
                {f({ id: "skills.name" })}
              </h2>
              <div className="bg-white rounded shadow text-gray-600">
                <div>
                  <div className="flex"></div>
                  <div className="flex overflow-hidden">
                    <div className="w-full">
                      <ProfileChart
                        width={400}
                        height={200}
                        readonly={true}
                        data={competences}
                      />
                    </div>
                  </div>
                  <div className="flex bg-white px-6 py-4 border-t">
                    <Link
                      to={`/employees/${id}/profile`}
                      className="w-full text-center px-6 py-2 bg-coral-500 rounded"
                    >
                      <span className="text-white">
                        {f({ id: "profile.name" })}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Divider>
        <Divider.Content>{f({ id: "revenues.name" })}</Divider.Content>
      </Divider>

      <table className="my-6 min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <MetricTooltip
                text={f({ id: "revenues.year.target.description" })}
              >
                <>{f({ id: "revenues.year.target" })}</>
              </MetricTooltip>
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <MetricTooltip
                text={f({ id: "revenues.year.actual.description" })}
              >
                <>{f({ id: "revenues.year.actual" })}</>
              </MetricTooltip>
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <MetricTooltip
                text={f({ id: "revenues.year.annualized.description" })}
              >
                <>{f({ id: "revenues.year.annualized" })}</>
              </MetricTooltip>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          <tr>
            <td className="px-6 py-4">
              {currencyFormater.format(data?.employeeById.targetRevenue)}
            </td>
            <td className="px-6 py-4">
              {currencyFormater.format(data?.employeeById.actualRevenue)}
            </td>
            <td className="px-6 py-4">
              {currencyFormater.format(data?.employeeById.annualizedRevenue)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default withRouter(Details);
