import React from "react";

import { H2 } from "components/ui/Typo/Typo";
import type { Permission } from "types/permission";
import type { Role } from "types/role";
import { PermissionRow } from "./PermissionRow";

interface IProps {
  title: string;
  role: Role | null;
  permissions: Permission[];
  isEditable?: boolean;
}

export const PermissionTable: React.FC<IProps> = ({
  title = "",
  role,
  permissions = [],
  isEditable = true,
}) => {
  return (
    <>
      <div className="mb-2 mt-6 first:mt-0">
        <H2>
          <span className="font-light">Modul:</span> {title}
        </H2>
      </div>
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Regel
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Alle
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Team
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Nur selbst
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {permissions.map((permission: Permission) => (
              <PermissionRow
                key={permission._id}
                role={role}
                permission={permission}
                isEditable={isEditable}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
