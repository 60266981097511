import React from "react";
import { useIntl } from "react-intl";

import Item from "./Item";
import MetricTooltip from "../competences/MetricTooltip";
import { Employee } from "../../types";
import { PermissionRequired } from "components/ui/permissions";

interface Props {
  data: Array<Employee>;
}

const List: React.FC<Props> = ({ data = [] }) => {
  const { formatMessage: f } = useIntl();

  return (
    <div className="mt-3 flex flex-col space-y-6">
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 table-auto">
              <thead className="bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    className="w-10 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  ></th>
                  <th
                    scope="col"
                    className="w-24 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  ></th>
                  <th
                    scope="col"
                    className="w-48 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {f({ id: "meta.name" })}
                  </th>
                  <PermissionRequired module="employees.competency_profile">
                    <th
                      scope="col"
                      className="w-40 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {f({ id: "skills.name" })}
                    </th>
                  </PermissionRequired>
                  <PermissionRequired module="employees.workplace">
                    <th
                      scope="col"
                      className="w-1/4 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {f({ id: "job.name" })}
                    </th>
                  </PermissionRequired>
                  <PermissionRequired module="employees.salary">
                    <th
                      scope="col"
                      className="w-5 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <MetricTooltip text={f({ id: "salary.job.description" })}>
                        <>{f({ id: "salary.employee" })}</>
                      </MetricTooltip>
                    </th>
                  </PermissionRequired>
                  <th
                    scope="col"
                    className="w-10 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  ></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data.map((employee: Employee) => (
                  <Item key={employee._id} employee={employee} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
