import { useMutation } from "@apollo/client";
import { PencilIcon } from "@heroicons/react/solid";

import { useIntl } from "react-intl";

import {
  TRANSLATION_MANY,
  TRANSLATION_UPDATE_BY_ID,
  TRANSLATION_REMOVE_BY_ID,
} from "../../schema/translation";
import { Translation } from "../../types";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import SlideOver from "../ui/SlideOver";
import Form, { FormData } from "./Form";

interface Props {
  translation: Translation;
}

const Item = ({ translation }: Props) => {
  const { formatMessage: f } = useIntl();

  const [open, toggle] = useToggle();

  const [updateTranslation] = useMutation(TRANSLATION_UPDATE_BY_ID, {
    refetchQueries: [{ query: TRANSLATION_MANY }],
  });
  const [deleteTranslation] = useMutation(TRANSLATION_REMOVE_BY_ID, {
    refetchQueries: [{ query: TRANSLATION_MANY }],
  });

  const onUpdate = (formData: FormData) => {
    updateTranslation({
      variables: {
        _id: translation._id,
        name: formData.name,
        locales: formData.locales,
      },
    });
    toggle();
  };

  const onDelete = () => {
    deleteTranslation({ variables: { _id: translation._id } });
  };

  return (
    <SliderOverContext.Provider value={{ open: open, toggle }}>
      <SlideOver title="Übersetzung bearbeiten">
        <Form onSubmit={onUpdate} data={translation} />
      </SlideOver>
      <tr>
        <td className="px-6 py-4">
          <div className="flex items-center">
            <div className="text-gray-900 text-sm leading-5 font-medium">
              {translation.name}
              <p className="text-sm text-gray-500 overflow-ellipsis">
                {f({ id: translation.name })}
              </p>
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <span className="relative z-0 inline-flex shadow-sm">
            <button
              className="inline-flex items-center h-7 pl-2.5 pr-2 rounded-l-md text-xs text-gray-700 border border-r-0 border-gray-200 leading-none py-0"
              onClick={toggle}
            >
              <PencilIcon className="mr-1 h-4 w-4 text-gray-400 group-hover:text-gray-500 " />
              Bearbeiten
            </button>
            <button
              className="inline-flex items-center h-7 pl-2.5 pr-2 rounded-r-md text-xs text-white bg-red-600 hover:bg-red-500 border border-l-0 border-red-700 leading-none py-0"
              onClick={onDelete}
            >
              Löschen
            </button>
          </span>
        </td>
      </tr>
    </SliderOverContext.Provider>
  );
};

export default Item;