import { ReactNode } from "react";
interface Props {
  colorName: "green" | "yellow" | "red" | "gray";
  children: ReactNode;
}

const Badge = ({ colorName = "green", children }: Props) => {
  const bgClassName = () => {
    switch (colorName) {
      case "green":
        return "inline-flex whitespace-nowrap items-center mx-0.5 px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800";
      case "yellow":
        return "inline-flex whitespace-nowrap items-center mx-0.5 px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-yellow-100 text-yellow-800";
      case "red":
        return "inline-flex whitespace-nowrap items-center mx-0.5 px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-red-100 text-red-800";
      default:
        return "inline-flex whitespace-nowrap items-center mx-0.5 px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800";
    }
  };

  const txtClassName = () => {
    switch (colorName) {
      case "green":
        return "-ml-0.5 mr-1.5 h-2 w-2 text-green-400";
      case "yellow":
        return "-ml-0.5 mr-1.5 h-2 w-2 text-yellow-400";
      case "red":
        return "-ml-0.5 mr-1.5 h-2 w-2 text-red-400";
      default:
        return "-ml-0.5 mr-1.5 h-2 w-2 text-gray-400";
    }
  };

  return (
    <span className={bgClassName()}>
      <svg className={txtClassName()} fill="currentColor" viewBox="0 0 8 8">
        <circle cx="4" cy="4" r="3" />
      </svg>
      {children}
    </span>
  );
};

export default Badge;
