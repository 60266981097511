import { Dialog } from "@headlessui/react";

interface ConfirmDialogProps {
  title: string;
  description?: string;
  isOpen: boolean;
  closeDialog: () => void;
  onConfirm: (confirmed: boolean) => void;
}

const Confirm: React.FC<ConfirmDialogProps> = ({
  title,
  description,
  isOpen,
  closeDialog,
  onConfirm,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen px-4">
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
        <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-sm w-full p-6 z-10">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            {title}
          </Dialog.Title>
          <div className="mt-4">
            <p className="text-sm text-gray-500">{description}</p>
          </div>
          <div className="mt-6 flex justify-end space-x-4">
            <button
              className="px-4 py-2 bg-gray-500 text-white rounded-md"
              onClick={() => {
                onConfirm(false); // User clicked cancel
                closeDialog();
              }}
            >
              Abbrechen
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded-md"
              onClick={() => {
                onConfirm(true); // User clicked confirm
                closeDialog();
              }}
            >
              Weiter
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export { Confirm };
