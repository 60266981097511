import { useIntl } from "react-intl";

interface Props {
  title: string;
  description?: string;
  formId?: string;
  toggle: () => void;
  children: React.ReactNode;
}

const FormWrapper = ({
  title,
  toggle,
  children,
  formId = "slide-over-form",
  description = "Füllen Sie die erforderlichen Felder unten aus um ein neues Element zu erstellen.",
}: Props) => {
  const { formatMessage: f } = useIntl();

  return (
    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
      <div className="flex-1 h-0 overflow-y-auto">
        <header className="space-y-1 py-6 px-4 bg-brand-secondary sm:px-6">
          <div className="flex items-center justify-between space-x-3">
            <h2 className="text-lg leading-7 font-medium text-white">
              {title}
            </h2>
            <div className="h-7 flex items-center">
              <button
                aria-label="Close panel"
                className="text-orange-50 hover:text-white transition ease-in-out duration-150"
                onClick={toggle}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div>
            <p className="text-sm leading-5 text-orange-50">{description}</p>
          </div>
        </header>
        {children}
      </div>
      <div className="flex-shrink-0 px-4 py-4 space-x-4 flex justify-end">
        <span className="inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
            onClick={toggle}
          >
            {f({ id: "actions.cancel" })}
          </button>
        </span>
        <span className="inline-flex rounded-md shadow-sm">
          <button
            type="submit"
            form={formId}
            className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-indigo active:bg-gray-700 transition duration-150 ease-in-out"
          >
            {f({ id: "actions.save" })}
          </button>
        </span>
      </div>
    </div>
  );
};

export default FormWrapper;
