import { useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Link,
  RouteComponentProps,
  match,
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom";

import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";

import { useMutation, useQuery } from "@apollo/client";
import {
  APPLICANT_BY_ID,
  APPLICANT_MANY,
  APPLICANT_REMOVE_BY_ID,
  APPLICANT_UPDATE_BY_ID,
} from "../../schema/applicant";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { currencyFormater } from "../../config/i18n";
import Avatar from "../employees/Avatar";
import { Error, Loading } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import Form, { FormData as FormDataType } from "./Form";

import Stats from "../competences/Stats";

interface ParamTypes {
  id: string;
}

interface Props {
  required: string;
  match?: match<ParamTypes>;
}

const Details = ({ location, match }: Props & RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [open, toggle] = useToggle();

  const { formatMessage: f } = useIntl();

  const { loading, error, data } = useQuery(APPLICANT_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateApplicant] = useMutation(APPLICANT_UPDATE_BY_ID, {
    refetchQueries: ["APPLICANT_MANY", "APPLICANT_BY_ID"],
  });

  const [deleteApplicant] = useMutation(APPLICANT_REMOVE_BY_ID, {
    refetchQueries: [{ query: APPLICANT_MANY }],
  });

  useEffect(() => {
    if (!id) {
      history.push("/");
      return;
    }
  }, [id, history]);

  const onUpdate = (formData: FormDataType) => {
    updateApplicant({
      variables: {
        _id: id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        photo: formData.photo,
        entryDate: formData.entryDate,
        vacancyId: formData.vacancyId,
        salary: +formData.salary,
        unitId: formData.unitId,
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteApplicant({ variables: { _id: id } });
    history.goBack();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Bewerber bearbeiten">
          <Form onSubmit={onUpdate} data={data.applicantById} />
        </SlideOver>
      </SliderOverContext.Provider>

      <div className="bg-gray-100 mb-6">
        <div className="bg-white shadow">
          <div className="container mx-auto px-6">
            <div className="flex items-center justify-between py-5">
              <div>
                <Link
                  to="/vacancies"
                  className="inline-flex items-center text-sm text-gray-500 leading-none pb-2"
                >
                  <i className="fas fa-angle-left"></i>
                  <span>{data?.applicantById.vacancy?.name}</span>
                </Link>
                <div className="flex items-center">
                  <Avatar
                    employee={data.vacancyById as any}
                    className="w-10 h-10"
                  />
                  <h1 className="px-2 text-2xl text-gray-900 font-extrabold">
                    {data?.applicantById.firstName}{" "}
                    {data?.applicantById.lastName}
                  </h1>
                </div>
              </div>
              <div className="flex items-center">
                <span className="shadow-sm rounded-md">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                    onClick={toggle}
                  >
                    {f({ id: "actions.edit" })}
                  </button>
                </span>
                <span className="ml-3 shadow-sm rounded-md">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline-red focus:border-red-700 active:bg-red-700 transition duration-150 ease-in-out"
                    onClick={onDelete}
                  >
                    {f({ id: "actions.delete" })}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto p-6">
          <div className="flex -mx-2">
            <div className="w-3/5 px-2">
              <div className="mb-6">
                <h2 className="text-lg font-medium text-gray-900 pb-6">
                  {f({ id: "summary.name" })}
                </h2>
                <div className="bg-white rounded shadow overflow-hidden">
                  <div className="px-6 py-5">
                    <dl>
                      <div className="flex">
                        <div className="w-1/2 mb-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Stelle
                          </dt>
                          <dd className="text-base font-normal text-gray-900">
                            <Link
                              to={`/vacancies/${data?.applicantById.vacancyId}/details`}
                            >
                              {data?.applicantById.vacancy?.name}
                            </Link>
                          </dd>
                        </div>
                        <div className="w-1/2 mb-6">
                          <dt className="text-sm font-medium text-gray-500">
                            {f({ id: "salary.name" })}
                          </dt>
                          <dd className="text-base font-normal text-gray-900">
                            {currencyFormater.format(
                              data?.applicantById.salary
                            )}
                          </dd>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="w-1/2 mb-6">
                          <dt className="text-sm font-medium text-gray-500">
                            E-Mail
                          </dt>
                          <dd className="text-base font-normal text-gray-900">
                            {data?.applicantById.email}
                          </dd>
                        </div>
                        <div className="w-1/2 mb-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Einstellungsdatum
                          </dt>
                          <dd className="text-base font-normal text-gray-900">
                            {data?.applicantById.entryDate &&
                              format(
                                parseISO(data?.applicantById.entryDate),
                                "dd.MM.yyyy",
                                {
                                  locale: de,
                                }
                              )}
                          </dd>
                        </div>
                      </div>
                    </dl>
                  </div>
                  <div>
                    <button className="flex items-center justify-center w-full px-6 py-2 bg-gray-100">
                      <span className="text-sm font-medium text-gray-500 mr-2">
                        Mehr anzeigen
                      </span>
                      <i className="fas fa-angle-down"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-2/5 px-2">
              <h2 className="text-lg font-medium text-gray-900 pb-6">
                Kompetenzen
              </h2>
              <div className="bg-white rounded shadow overflow-hidden text-gray-600 mb-6">
                <div>
                  <div className="flex">
                    <Stats
                      humatics={data.applicantById?.humatics}
                      actualRevenue={data.applicantById?.annualizedRevenue}
                      targetRevenue={data.applicantById?.targetRevenue}
                      competenceCoverage={
                        data.applicantById?.competenceCoverage
                      }
                    />
                  </div>
                  <div className="flex">
                    <div className="w-1/6 p-6">{/* ProfileChart */}</div>
                  </div>
                  <div className="flex bg-white px-6 py-4 border-t">
                    <Link
                      to={`/applicants/${id}/profile`}
                      className="w-full text-center px-6 py-2 bg-coral-500 rounded"
                    >
                      <span className="text-white">Kompetenzprofil</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Details);
