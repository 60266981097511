import React, { useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/outline";

interface Props {
  children: React.ReactNode;
}

const Tooltip = ({ children }: Props) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="flex-col md:flex-row flex items-center md:justify-center flex-wrap">
        <div
          className="relative mt-20 md:mt-0"
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <div className="mx-1 cursor-pointer">
            <InformationCircleIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" />
          </div>
          {show && (
            <div
              role="tooltip"
              className="z-20 -mt-20 w-64 absolute transition duration-150 ease-in-out left-0 ml-8 shadow-lg bg-white p-4 rounded"
            >
              <svg
                className="absolute left-0 -ml-2 bottom-0 top-0 h-full"
                width="9px"
                height="16px"
                viewBox="0 0 9 16"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <g
                    transform="translate(-874.000000, -1029.000000)"
                    fill="#FFFFFF"
                  >
                    <g transform="translate(850.000000, 975.000000)">
                      <g transform="translate(24.000000, 0.000000)">
                        <polygon
                          transform="translate(4.500000, 62.000000) rotate(-90.000000) translate(-4.500000, -62.000000) "
                          points="4.5 57.5 12.5 66.5 -3.5 66.5"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              {children}
            </div>
          )}{" "}
        </div>
      </div>
    </>
  );
};
export default Tooltip;
