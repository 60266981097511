import { useParams, useHistory, RouteComponentProps } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  SKILL_TYPE_BY_ID,
  SKILL_TYPE_UPDATE_BY_ID,
  SKILL_TYPE_REMOVE_BY_ID,
  SKILL_TYPE_MANY,
} from "../../schema/skillType";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { Loading, Error } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import PageHeader from "../ui/Headings/PageHeader";
import { Breadcrumb } from "../ui/navigation/Breadcrumb";

import Form, { FormData } from "./Form";
import List from "../skills/List";

interface ParamTypes {
  id: string;
}

const Details = ({}: RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [open, toggle] = useToggle();

  const { loading, error, data } = useQuery(SKILL_TYPE_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateSkillType] = useMutation(SKILL_TYPE_UPDATE_BY_ID, {
    refetchQueries: [{ query: SKILL_TYPE_MANY }],
  });
  const [deleteSkillType] = useMutation(SKILL_TYPE_REMOVE_BY_ID, {
    refetchQueries: [{ query: SKILL_TYPE_MANY }],
  });

  const onUpdate = (formData: FormData) => {
    updateSkillType({
      variables: {
        _id: id,
        name: formData.name,
        description: formData.description,
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteSkillType({ variables: { _id: id } });
    history.goBack();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Kompetenztyp bearbeiten">
          <Form onSubmit={onUpdate} data={data.skillTypeById} />
        </SlideOver>
        <PageHeader
          title={data?.skillTypeById?.name}
          onUpdate={toggle}
          onDelete={onDelete}
        >
          <Breadcrumb>
            <Breadcrumb.Item url="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item url="/skillTypes">Kompetenztypen</Breadcrumb.Item>
            <Breadcrumb.Item>{data?.skillTypeById?.name}</Breadcrumb.Item>
          </Breadcrumb>
        </PageHeader>
      </SliderOverContext.Provider>

      <p className="px-6 mt-1 max-w-2xl text-sm leading-5 text-gray-500">
        {data.skillTypeById.description}
      </p>

      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
        {`${data?.skillTypeById?.skills.length} Einträge`}
      </h2>
      <List data={data?.skillTypeById?.skills} />
    </>
  );
};

export default Details;
