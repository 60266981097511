import { gql } from "@apollo/client";

export const JOB_DESCRIPTION_MANY = gql`
  query {
    jobDescriptionMany {
      name
      title
      description
      location
      validFrom
      specifications
      responsibilities
      createdAt
      _id
    }
  }
`;

export const JOB_DESCRIPTION_BY_ID = gql`
  query($_id: ID!) {
    jobDescriptionById(_id: $_id) {
      name
      title
      description
      location
      validFrom
      specifications
      responsibilities
      createdAt
      _id
    }
  }
`;

export const JOB_DESCRIPTION_CREATE_ONE = gql`
  mutation(
    $name: String!
    $title: String
    $description: String
    $location: String
    $validFrom: DateTime
    $specifications: String
    $responsibilities: String
  ) {
    jobDescriptionCreateOne(
      input: {
        name: $name
        title: $title
        description: $description
        location: $location
        validFrom: $validFrom
        specifications: $specifications
        responsibilities: $responsibilities
      }
    ) {
      _id
      name
    }
  }
`;

export const JOB_DESCRIPTION_UPDATE_BY_ID = gql`
  mutation(
    $_id: ID!
    $name: String!
    $title: String
    $description: String
    $location: String
    $validFrom: DateTime
    $specifications: String
    $responsibilities: String
  ) {
    jobDescriptionUpdateById(
      input: {
        name: $name
        title: $title
        description: $description
        location: $location
        validFrom: $validFrom
        specifications: $specifications
        responsibilities: $responsibilities
      }
      _id: $_id
    ) {
      _id
      name
    }
  }
`;

export const JOB_DESCRIPTION_REMOVE_BY_ID = gql`
  mutation($_id: ID!) {
    jobDescriptionRemoveById(_id: $_id) {
      name
    }
  }
`;
