import { useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { animated, config, useSpring } from "react-spring";
// import { DevTool } from "@hookform/devtools";
import { useQuery } from "@apollo/client";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, RefreshIcon } from "@heroicons/react/solid";
import { useIntl } from "react-intl";
import Select from "react-select";

import FilterContext from "../../context/FilterContext";
import { SKILL_MANY } from "../../schema/skill";
import { SKILL_GROUP_MANY } from "../../schema/skillGroup";
import { SKILL_SCALE_MANY } from "../../schema/skillScale";
import { Skill, SkillGroup, SkillScale } from "../../types";

import { DateField, SkillGroupField, SkillScaleField } from "../common/form";
import { Divider } from "../ui/layout/Divider";

interface Props {
  onSubmit: (formData: any) => void;
  data?: any;
}

export type FormData = {
  query: string;
  retireDateStart: Date;
  retireDateEnd: Date;
  terminationDateStart: Date;
  terminationDateEnd: Date;
  skillIds: string[];
  applicantId: string;
  skillGroupId: string;
  skillScaleId: string;
  skillScaleRange: [number, number];
};

const Filter = ({ onSubmit, data }: Props) => {
  const { formatMessage: f } = useIntl();

  const { open } = useContext(FilterContext);
  const [selectedSkillGroup, setSelectedSkillGroup] = useState<SkillGroup>();

  const methods = useForm<FormData>({ defaultValues: data });
  const { handleSubmit, reset, control, watch, setValue } = methods;
  const watchFields = watch(["skillGroupId", "skillScaleId"]);

  const { loading, data: skillData } = useQuery(SKILL_MANY);
  const { data: skillGroupData } = useQuery(SKILL_GROUP_MANY);
  const { data: skillScaleData } = useQuery(SKILL_SCALE_MANY);

  useEffect(() => {
    setValue("skillIds", selectedSkillGroup?.skillIds || [], {
      shouldDirty: true,
    });
  }, [selectedSkillGroup]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      // Handle skill group selection
      if (name === "skillGroupId") {
        const result = skillGroupData?.skillGroupMany.find(
          (skillGroup: SkillGroup) => skillGroup._id === value.skillGroupId
        );
        setSelectedSkillGroup(result);
      }
      // Handle skill scale selection
      if (name === "skillScaleId") {
        const result = skillScaleData?.skillScaleMany.find(
          (skillScale: SkillScale) => skillScale._id === value.skillScaleId
        );
        setValue("skillScaleRange", result.range, { shouldDirty: true });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, skillGroupData, skillScaleData]);

  const resetForm = () => {
    reset();
  };

  const options =
    skillData?.skillMany.map(
      (skill: Skill) =>
        ({
          id: skill._id,
          name: skill.name,
        } as any)
    ) || [];

  const slideInStyles = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, width: 0 },
    to: {
      opacity: open ? 1 : 0,
      width: open ? 400 : 0,
    },
  });

  return (
    <animated.div style={{ ...slideInStyles, overflow: "hidden" }}>
      {/* <DevTool control={control} /> */}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} id="filter-form">
          <div className="bg-gray-100 dark:bg-gray-900 dark:text-white text-gray-600 px-4 py-2 rounded-md">
            <div className="my-5 flex justify-between items-center">
              <div className="text-xs text-gray-400 tracking-wider">FILTER</div>
              <RefreshIcon
                className="w-4 h-4 text-gray-400 cursor-pointer"
                onClick={() => resetForm()}
              />
            </div>

            <div className="my-5 bg-white p-3 w-full flex flex-col rounded-md dark:bg-gray-800 shadow">
              <div className="space-y-1 py-2">
                <label
                  htmlFor="skillIds"
                  className="block text-sm font-medium leading-5 text-gray-900"
                >
                  {f({ id: "skills.name" })}
                </label>
                <Controller
                  control={control}
                  name="skillIds"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      placeholder="Kompetenzen wählen..."
                      options={options}
                      value={options.filter(({ id }: any) =>
                        value?.includes(id)
                      )}
                      getOptionLabel={({ name }: any) => name}
                      getOptionValue={({ id }: any) => id}
                      isLoading={loading}
                      isMulti
                      className="text-xs"
                      classNamePrefix="select"
                      onBlur={onBlur}
                      onChange={(values: any) =>
                        onChange((values ?? []).map((d: any) => d.id))
                      }
                    />
                  )}
                />
              </div>
              <div className="py-2">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                        <span>Rentendatum</span>
                        <ChevronDownIcon
                          className={`${
                            open ? "transform rotate-180" : ""
                          } w-5 h-5 text-gray-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-2 pt-2 pb-2 text-sm text-gray-500">
                        <div className="flex space-x-3">
                          <div className="space-y-1">
                            <DateField name="retireDateStart" label="Von" />
                          </div>
                          <div className="space-y-1">
                            <DateField name="retireDateEnd" label="Bis" />
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
              <div className="py-2">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                        <span>Vertragsendedatum</span>
                        <ChevronDownIcon
                          className={`${
                            open ? "transform rotate-180" : ""
                          } w-5 h-5 text-gray-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-2 pt-2 pb-2 text-sm text-gray-500">
                        <div className="flex space-x-3">
                          <div className="space-y-1">
                            <DateField
                              name="terminationDateStart"
                              label="Von"
                            />
                          </div>
                          <div className="space-y-1">
                            <DateField name="terminationDateEnd" label="Bis" />
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>

            <Divider />

            <div className="my-5 bg-white p-3 w-full flex flex-col rounded-md dark:bg-gray-800 shadow">
              <div className="space-y-1 py-2">
                <SkillGroupField />
              </div>
              <div className="space-y-1 py-2">
                <SkillScaleField />
              </div>
            </div>

            <button
              type="submit"
              form="filter-form"
              className="mx-auto inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded-md text-white bg-gray-500 hover:bg-gray-400 focus:outline-none focus:shadow-outline-indigo focus:border-orange-600 active:bg-coral-600 transition duration-150 ease-in-out"
            >
              Anwenden
            </button>
          </div>
        </form>
      </FormProvider>
    </animated.div>
  );
};

export default Filter;
