import { useEffect, useState, Fragment } from "react";
import {
  useParams,
  useHistory,
  match,
  RouteComponentProps,
} from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { ResponsiveBar } from "@nivo/bar";
import { useIntl } from "react-intl";

import { EMPLOYEE_BY_ID } from "../../schema/employee";
import { JOB_MANY } from "../../schema/job";
import { FILTER_SEMINAR_MANY } from "../../schema/seminar";
import { FILTER_VACANCY_MANY } from "../../schema/vacancy";
import { Competence, Job } from "../../types";

import { Loading, Error } from "../ui/Alerts";
import { H2 } from "../ui/Typo/Typo";
import { Divider } from "../ui/layout/Divider";
import SeminarList from "../seminars/List";
import VacancyList from "../vacancies/List";
import { percentFormater } from "../../config/i18n";

interface Props {
  location: RouteComponentProps<any>["location"];
  match?: match<ParamTypes>;
}

interface ParamTypes {
  id: string;
}

const Training = ({ location }: Props) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (!id) {
      history.push("/");
      return;
    }
  }, [id, history]);

  const { loading, error, data } = useQuery(EMPLOYEE_BY_ID, {
    variables: {
      _id: id,
    },
  });

  const [getSeminar, { data: seminarData }] = useLazyQuery(FILTER_SEMINAR_MANY);
  const [getVacancy, { data: vacancyData }] = useLazyQuery(
    FILTER_VACANCY_MANY,
    {
      variables: { jobId: data?.employeeById.jobId },
    }
  );

  const { data: jobData } = useQuery(JOB_MANY);

  const jobs = jobData?.jobMany || [];
  const [selectedJob, setSelectedJob] = useState<Job>(data?.employeeById.job);
  const [combinedSkills, setCombinedSkills] = useState<object[]>([]);

  useEffect(() => {
    const job = selectedJob || data?.employeeById.job;

    if (job) {
      const uniqueSkills = new Set([
        ...job.competences.map((c: Competence) => c.skillId),
        ...data.employeeById.competences.map((c: Competence) => c.skillId),
      ]);

      const results = Array.from(uniqueSkills).map((id) => {
        const c1 = job.competences.find((c: Competence) => c.skillId === id);
        const c2 = data.employeeById.competences.find(
          (c: Competence) => c.skillId === id
        );
        return {
          Arbeitsplatz: c1 ? c1.value : 0,
          Mitarbeiter: c2 ? c2.value : 0,
          skillId: c1 ? c1.skillId : c2 ? c2.skillId : null,
          skillName: c1?.skill?.name || c2?.skill?.name,
        };
      });
      setCombinedSkills(results as any);
    }
  }, [data, selectedJob]);

  useEffect(() => {
    if (data && data.employeeById) {
      const skillIds = combinedSkills
        .filter((item: any) => item.Arbeitsplatz > item.Mitarbeiter)
        .map((item: any) => item.skillId);

      getSeminar({
        variables: {
          skillIds: skillIds,
        },
      });
      getVacancy({
        variables: {
          jobId: data.employeeById.jobId,
        },
      });
    }
  }, [data, combinedSkills, getSeminar, getVacancy]);

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      <div className="flex items-center justify-between py-5">
        <div className="pb-6">
          <div className="inline-flex items-center text-sm text-gray-500 leading-none pb-2">
            <span>Weiterbildung</span>
          </div>
          <div className="flex items-center">
            <h1 className="px-2 text-2xl text-gray-900 font-extrabold">
              {data?.employeeById.firstName} {data?.employeeById.lastName}
            </h1>
          </div>
        </div>
        <div className="flex items-center">
          <span className="shadow-sm rounded-md">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
              onClick={() => history.goBack()}
            >
              {f({ id: "actions.back" })}
            </button>
          </span>
        </div>
      </div>

      <Divider />

      <div className="p-6">
        <div className="flex items-center">
          <H2>Kompetenzabweichungen</H2>
          <div className="w-72 ml-3">
            <Listbox value={selectedJob} onChange={setSelectedJob}>
              {({ open }) => (
                <>
                  <div className="relative mt-1">
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                      <span className="block truncate">
                        {selectedJob?.name}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options
                        static
                        className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                      >
                        {jobs.map((job: Job, index: number) => (
                          <Listbox.Option
                            key={index}
                            className={({ active }) =>
                              `${
                                active
                                  ? "text-amber-900 bg-amber-100"
                                  : "text-gray-900"
                              }
                                cursor-default select-none relative py-2 pl-10 pr-4`
                            }
                            value={job}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`${
                                    selected ? "font-medium" : "font-normal"
                                  } block truncate`}
                                >
                                  {job.name}
                                </span>
                                {selected ? (
                                  <span
                                    className={`${
                                      active
                                        ? "text-amber-600"
                                        : "text-amber-600"
                                    }
                                      absolute inset-y-0 left-0 flex items-center pl-3`}
                                  >
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        </div>

        <div className="h-96 pb-6">
          <ResponsiveBar
            data={combinedSkills}
            keys={["Arbeitsplatz", "Mitarbeiter"]}
            indexBy={"skillName"}
            minValue={-100}
            maxValue={100}
            enableGridX={true}
            enableGridY={false}
            margin={{ top: 100, right: 50, bottom: 150, left: 60 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={["#fecd1b", "#0132e4"]}
            groupMode={"grouped"}
            labelFormat={(v) =>
              typeof v === "number" ? `${percentFormater.format(v / 100)}` : ""
            }
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            tooltip={(item: any) => (
              <div className="flex flex-col items-center text-center text-xs p-0.5">
                <span className="font-bold">{item.indexValue}</span>
                <span className="font-semibold" style={{ color: item.color }}>
                  {item.id}
                </span>
                {percentFormater.format(+item.value / 100)}
              </div>
            )}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 60,
              legend: "Kompetenz",
              legendPosition: "middle",
              legendOffset: 140,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Wert",
              legendPosition: "middle",
              legendOffset: -50,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: "color",
              modifiers: [["brighter", 3]],
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "top",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: -50,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 15,
              },
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </div>

        <Divider>
          <Divider.Content>Weitere Ergebnisse</Divider.Content>
        </Divider>

        <div className="p-6">
          <H2>{f({ id: "seminars.name" })}</H2>
          <h2 className="mt-6 text-gray-500 text-xs font-medium uppercase tracking-wide">
            {`${seminarData?.seminarMany.length} Einträge`}
          </h2>
          <SeminarList data={seminarData?.seminarMany} />
        </div>

        <div className="p-6">
          <H2>{f({ id: "vacancies.name" })}</H2>
          <h2 className="mt-6 text-gray-500 text-xs font-medium uppercase tracking-wide">
            {`${vacancyData?.vacancyMany.length} Einträge`}
          </h2>
          <VacancyList data={vacancyData?.vacancyMany} />
        </div>
      </div>
    </>
  );
};

export default Training;
