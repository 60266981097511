import { gql } from "@apollo/client";

export const SKILL_GROUP_MANY = gql`
  query {
    skillGroupMany {
      name
      description
      skillIds
      skills {
        name
      }
      createdAt
      _id
    }
  }
`;

export const SKILL_GROUP_BY_ID = gql`
  query($_id: ID!) {
    skillGroupById(_id: $_id) {
      name
      description
      skillIds
      skills {
        name
        supply
        demand
        createdAt
        _id
      }
      createdAt
      _id
    }
  }
`;

export const SKILL_GROUP_CREATE_ONE = gql`
  mutation($name: String!, $description: String, $skillIds: [ID]) {
    skillGroupCreateOne(
      input: { name: $name, description: $description, skillIds: $skillIds }
    ) {
      _id
      name
    }
  }
`;

export const SKILL_GROUP_UPDATE_BY_ID = gql`
  mutation($_id: ID!, $name: String!, $description: String, $skillIds: [ID]) {
    skillGroupUpdateById(
      input: { name: $name, description: $description, skillIds: $skillIds }
      _id: $_id
    ) {
      _id
      name
    }
  }
`;

export const SKILL_GROUP_REMOVE_BY_ID = gql`
  mutation($_id: ID!) {
    skillGroupRemoveById(_id: $_id) {
      name
    }
  }
`;
