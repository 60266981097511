import { useForm } from "react-hook-form";

import { License } from "types/license";

interface IProps {
  license: License;
  onSubmit: (license: License) => void;
};

export const LicenseReactivationForm = ({
  license,
  onSubmit,
}: IProps) => {
  const { handleSubmit } = useForm();

  const submit = () => onSubmit(license);

  return (
    <form id="license-reactivation-form" className="p-4" onSubmit={handleSubmit(submit)}>
      Sind Sie sicher, dass Sie die Lizenz für das Produkt
      "<b>{license.product.name}</b>" mit sofortiger Wirkung reaktiveren.
      möchten?
    </form>
  );
};
