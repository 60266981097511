import { useParams, useHistory, RouteComponentProps } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  SEMINAR_TYPE_BY_ID,
  SEMINAR_TYPE_UPDATE_BY_ID,
  SEMINAR_TYPE_REMOVE_BY_ID,
  SEMINAR_TYPE_MANY,
} from "../../schema/seminarType";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { Loading, Error } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import PageHeader from "../ui/Headings/PageHeader";
import { Breadcrumb } from "../ui/navigation/Breadcrumb";

import Form, { FormData } from "./Form";
import List from "../skills/List";

interface ParamTypes {
  id: string;
}

const Details = ({}: RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [open, toggle] = useToggle();

  const { loading, error, data } = useQuery(SEMINAR_TYPE_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateSeminarType] = useMutation(SEMINAR_TYPE_UPDATE_BY_ID, {
    refetchQueries: [{ query: SEMINAR_TYPE_MANY }],
  });
  const [deleteSeminarType] = useMutation(SEMINAR_TYPE_REMOVE_BY_ID, {
    refetchQueries: [{ query: SEMINAR_TYPE_MANY }],
  });

  const onUpdate = (formData: FormData) => {
    updateSeminarType({
      variables: {
        _id: id,
        name: formData.name,
        description: formData.description,
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteSeminarType({ variables: { _id: id } });
    history.goBack();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Seminartyp bearbeiten">
          <Form onSubmit={onUpdate} data={data.seminarTypeById} />
        </SlideOver>
        <PageHeader
          title={data?.seminarTypeById?.name}
          onUpdate={toggle}
          onDelete={onDelete}
        >
          <Breadcrumb>
            <Breadcrumb.Item url="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item url="/seminarTypes">Seminartypen</Breadcrumb.Item>
            <Breadcrumb.Item>{data?.seminarTypeById?.name}</Breadcrumb.Item>
          </Breadcrumb>
        </PageHeader>
        <p className="px-6 mt-1 max-w-2xl text-sm leading-5 text-gray-500">
          {data.seminarTypeById.description}
        </p>

        <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
          {`${data?.seminarTypeById?.seminars.length} Einträge`}
        </h2>
        <List data={data?.seminarTypeById?.seminars} />
      </SliderOverContext.Provider>
    </>
  );
};

export default Details;
