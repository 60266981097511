import type { Role } from './role';

export enum scope {
  NONE = 0,
  SELF = 1,
  GROUP = 2,
  ALL = 3,
};

export enum PermissionFlag {
  NONE = 0,
  READ = 1,
  WRITE = 2,
};

export type PermissionEntry = {
  role: Role;
  all: PermissionFlag;
  group: PermissionFlag;
  self: PermissionFlag;
  [key: string]: PermissionFlag | Role;
};

export type Permission = {
  _id: string;
  name: string;
  displayName?: string;
  module: string;
  entries: [PermissionEntry];
};
