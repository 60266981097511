import { gql } from "@apollo/client";
import { MATCH_FIELDS as EMPLOYEE_MATCH_FIELDS } from "./employee";
import { MATCH_FIELDS as APPLICANT_MATCH_FIELDS } from "./applicant";

export const FILTER_VACANCY_MANY = gql`
  query ($jobId: ID) {
    vacancyMany(jobId: $jobId) {
      name
      description
      salary
      _id
    }
  }
`;

export const VACANCY_MANY = gql`
  query {
    vacancyMany {
      name
      description
      salary
      humatics {
        competenceValues
        h
        t
        ef
        mu
      }
      competences {
        skillId
        value
      }
      unitId
      jobId
      _id
    }
  }
`;

export const VACANCY_BY_ID = gql`
  query ($_id: ID!) {
    vacancyById(_id: $_id) {
      name
      description
      salary
      humatics {
        competenceValues
        h
        t
        ef
        mu
      }
      competences {
        skillId
        skill {
          name
        }
        value
      }
      matches {
        applicants {
          ...ApplicantMatchFields
        }
        employees {
          ...MatchFields
        }
      }
      unitId
      unit {
        name
      }
      jobId
      job {
        name
      }
      targetRevenue
      _id
    }
  }
  ${EMPLOYEE_MATCH_FIELDS}
  ${APPLICANT_MATCH_FIELDS}
`;

export const VACANCY_CREATE_ONE = gql`
  mutation (
    $name: String!
    $description: String
    $salary: Float!
    $competences: [CompetenceInput]
    $unitId: ID
    $jobId: ID!
  ) {
    vacancyCreateOne(
      input: {
        name: $name
        description: $description
        salary: $salary
        competences: $competences
        unitId: $unitId
        jobId: $jobId
      }
    ) {
      _id
      name
    }
  }
`;

export const VACANCY_UPDATE_BY_ID = gql`
  mutation (
    $_id: ID!
    $name: String
    $description: String
    $salary: Float
    $competences: [CompetenceInput]
    $unitId: ID
    $jobId: ID
  ) {
    vacancyUpdateById(
      input: {
        name: $name
        description: $description
        salary: $salary
        competences: $competences
        unitId: $unitId
        jobId: $jobId
      }
      _id: $_id
    ) {
      _id
      name
    }
  }
`;

export const VACANCY_REMOVE_BY_ID = gql`
  mutation ($_id: ID!) {
    vacancyRemoveById(_id: $_id) {
      name
    }
  }
`;
