import { Link } from "react-router-dom";
import { BriefcaseIcon, NewspaperIcon } from "@heroicons/react/outline";

import { useIntl } from "react-intl";

const Tabs = () => {
  const { formatMessage: f } = useIntl();

  return (
    <div>
      <div className="sm:hidden">
        <select className="form-select block w-full" defaultValue="Kompetenzen">
          <option>{f({ id: "jobs.name" })}</option>
          <option>{f({ id: "jobDescriptions.name" })}</option>
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="flex -mb-px">
            <Link
              to="/jobs"
              className="group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              <BriefcaseIcon className="mr-1 h-6 w-6 text-gray-400 group-hover:text-gray-500" />
              <span>{f({ id: "jobs.name" })}</span>
            </Link>
            <Link
              to="/jobDescriptions"
              className="ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              <NewspaperIcon className="mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              <span>{f({ id: "jobDescriptions.name" })}</span>
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
