import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

export interface BreadcrumbProps {
  children: Array<ReactNode>;
}

export function Breadcrumb(props: BreadcrumbProps) {
  return (
    <>
      <nav className="sm:hidden">
        <Link
          to="/"
          className="flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 focus:outline-none focus:underline transition duration-150 ease-in-out"
        >
          <svg
            className="flex-shrink-0 mx-2 h-5 w-5 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
          Zurück
        </Link>
      </nav>
      <nav className="hidden sm:flex items-center text-sm leading-5 font-medium">
        {props.children.map((option, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              {index !== props.children.length && index !== 0 && (
                <svg
                  className="flex-shrink-0 mx-2 h-5 w-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
              {option}
            </React.Fragment>
          );
        })}
      </nav>
    </>
  );
}

Breadcrumb.Item = function (props: { url?: string; children: ReactNode }) {
  if (!props.url) {
    return (
      <div className="text-gray-500 hover:text-gray-700 focus:outline-none focus:underline transition duration-150 ease-in-out">
        {props.children}
      </div>
    );
  } else {
    return (
      <Link
        to={props.url}
        className="text-gray-500 hover:text-gray-700 focus:outline-none focus:underline transition duration-150 ease-in-out"
      >
        {props.children}
      </Link>
    );
  }
};

Breadcrumb.Icon = function (props: { children: ReactNode }) {
  return (
    <div className="w-5 h-5 text-neutral-400 hover:text-neutral-500">
      {props.children}
    </div>
  );
};
