import { useEffect } from "react";
import {
  useParams,
  useHistory,
  match,
  RouteComponentProps,
} from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";

import {
  EMPLOYEE_BY_ID,
  EMPLOYEE_MATCH_COMPETENCES,
} from "../../schema/employee";
import { Competence } from "../../types";

import { Loading, Error } from "../ui/Alerts";
import List from "../matches/List";

interface Props {
  location: RouteComponentProps<any>["location"];
  match?: match<ParamTypes>;
}

interface ParamTypes {
  id: string;
}

const Match = ({ location }: Props) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (!id) {
      history.push("/");
      return;
    }
  }, [id, history]);

  const { loading, error, data } = useQuery(EMPLOYEE_BY_ID, {
    variables: {
      _id: id,
    },
  });

  const competences = (location as any).state.competences.map(
    (c: Competence) => ({
      skillId: c.skillId,
      value: c.value,
    })
  );

  const { data: matchData } = useQuery(EMPLOYEE_MATCH_COMPETENCES, {
    variables: {
      competences: competences,
    },
  });

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      <div className="pb-6">
        <div className="inline-flex items-center text-sm text-gray-500 leading-none pb-2">
          <span>{f({ id: "employees.match" })}</span>
        </div>
        <div className="flex items-center">
          <h1 className="px-2 text-2xl text-gray-900 font-extrabold">
            {data?.employeeById.firstName} {data?.employeeById.lastName}
          </h1>
        </div>
      </div>

      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
        {`${matchData?.employeeMatch.length || 0} Ergebnisse`}
      </h2>
      <List data={matchData?.employeeMatch} />
    </>
  );
};

export default Match;
