import { gql } from "@apollo/client";

export const USER_MANY = gql`
  query {
    userMany {
      username
      role {
        _id
        name
      }
      employeeId
      locked
      createdAt
      loginAt
      _id
    }
  }
`;

export const USER_BY_ID = gql`
  query($_id: ID!) {
    userById(_id: $_id) {
      username
      role {
        _id
        name
      }
      employeeId
      locked
      createdAt
      loginAt
      _id
    }
  }
`;

export const USER_CURRENT = gql`
  query {
    userCurrent {
      username
      role {
        _id
        name
      }
      employeeId
      locked
      createdAt
      loginAt
      _id
    }
  }
`;

export const USER_CREATE_ONE = gql`
  mutation(
    $username: String!
    $password: String!
    $employeeId: String!
    $locked: Boolean
    $role: String!
  ) {
    userCreateOne(
      input: {
        username: $username
        password: $password
        employeeId: $employeeId
        locked: $locked
        role: $role
      }
    ) {
      _id
      username
    }
  }
`;

export const USER_UPDATE_BY_ID = gql`
  mutation(
    $_id: ID!
    $username: String!
    $password: String!
    $employeeId: String
    $locked: Boolean
    $role: String!
  ) {
    userUpdateById(
      input: {
        username: $username
        password: $password
        employeeId: $employeeId
        locked: $locked
        role: $role
      }
      _id: $_id
    ) {
      _id
      username
    }
  }
`;

export const USER_REMOVE_BY_ID = gql`
  mutation($_id: ID!) {
    userRemoveById(_id: $_id) {
      username
    }
  }
`;
