import React from "react";
import { useFormContext } from "react-hook-form";

// const AddressForm = ({ register }: any) => {
const AddressForm = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <div className="space-y-1">
        <label
          htmlFor="street"
          className="block text-sm font-medium leading-5 text-gray-900"
        >
          Strasse
        </label>
        <input
          type="text"
          id="street"
          {...register("address.street")}
          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div className="flex space-x-6">
        <div className="space-y-1">
          <label
            htmlFor="zip"
            className="block text-sm font-medium leading-5 text-gray-900"
          >
            PLZ
          </label>
          <input
            type="text"
            id="zip"
            {...register("address.zip", {
              maxLength: { value: 5, message: "Max. 5 Zeichen" },
            })}
            className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div className="space-y-1">
          <label
            htmlFor="city"
            className="block text-sm font-medium leading-5 text-gray-900"
          >
            Stadt
          </label>
          <input
            type="text"
            id="city"
            {...register("address.city")}
            className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div className="space-y-1">
        <label
          htmlFor="country-id"
          className="block text-sm font-medium leading-5 text-gray-900"
        >
          Land
        </label>
        <input
          type="text"
          id="country-id"
          {...register("address.countryId")}
          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </>
  );
};

export default AddressForm;
