import clsx from "clsx";
import {
  filter,
  find,
  fromPairs,
  includes,
  map,
  pathOr,
  pipe,
  prop,
  sum,
  values,
} from "ramda";
import { useForm } from "react-hook-form";

import { License } from "types/license";
import { Product } from "types/product";

interface IArgs {
  _id: string;
  seats: number;
  price: number;
  selected: boolean;
  type: string;
}
const calculateTotal = ({ _id, seats, price, selected, type }: IArgs) => {
  const isSelected = !(selected === false);
  const total = type === "subscription" ? seats * price : price;
  return [_id, isSelected && !isNaN(total) ? total : 0];
};
export interface IProductData {
  _id: string;
  licenseId?: string;
  price: number;
  selected: boolean;
  seats: number;
  type: string;
}
export interface IFormData {
  products: IProductData[];
}
const getTotals = pipe<IFormData, any, any, any>(
  pathOr([], ["products"]),
  map(calculateTotal),
  fromPairs
);

const activeLicenseForProduct = (
  productId: string,
  licenses: License[]
): License | undefined =>
  find(
    (l) =>
      l.product._id === productId &&
      includes(l.status, ["active", "in_trial", "non_renewing"]),
    licenses
  );

const licenseForProduct = (
  productId: string,
  licenses: License[]
): License | undefined => find((l) => l.product._id === productId, licenses);

const makeDefaults = (
  products: Product[],
  licenses: License[],
  employeeCount: number
): IProductData[] =>
  products.map((p) => {
    const license = activeLicenseForProduct(p._id, licenses);
    return {
      _id: p._id,
      price: p.price,
      selected: Boolean(license),
      seats: employeeCount,
      type: p ? p.type : "",
    };
  });

const fitlerSelected = filter<IProductData>(prop("selected"));
const addLicenses = (licenses: License[]) => (
  data: IProductData[]
): IProductData[] => {
  return map(
    (d: IProductData) => ({
      ...d,
      licenseId: licenseForProduct(d._id, licenses)?._id,
    }),
    data
  );
};
const getProducts = (licenses: License[]) =>
  pipe<IProductData[], IProductData[], IProductData[]>(
    fitlerSelected,
    addLicenses(licenses)
  );

interface ProductRowProp {
  product: Product;
  highlighted: boolean;
  register: Function;
  namePrefix: string;
  employeeCount: number;
  total?: number;
  license?: License;
}

const ProductRow = ({
  product,
  highlighted,
  register,
  namePrefix,
  total,
  license,
  employeeCount,
}: ProductRowProp) => {
  return (
    <tr
      key={product._id}
      className={clsx({
        "bg-gray-100": highlighted,
      })}
    >
      <td className="p-2">
        <input
          type="hidden"
          value={product._id}
          name={`${namePrefix}._id`}
          {...register(`${namePrefix}._id`)}
        />
        <input
          type="hidden"
          value={product.price}
          name={`${namePrefix}.price`}
          {...register(`${namePrefix}.price`, {
            valueAsNumber: true,
          })}
        />
        <input
          type="hidden"
          value={product.type}
          name={`${namePrefix}.type`}
          {...register(`${namePrefix}.type`)}
        />
        <input
          type="checkbox"
          disabled={Boolean(license)}
          name={`${namePrefix}.selected`}
          {...register(`${namePrefix}.selected`)}
        />
      </td>
      <td className="font-bold p-2">{product.name}</td>
      <td className="p-2">{product.description}</td>
      <td className="p-2">
        {product.type === "subscription" ? (
          <input
            type="number"
            min={employeeCount}
            name={`${namePrefix}.seats`}
            readOnly={Boolean(license)}
            {...register(`${namePrefix}.seats`, {
              valueAsNumber: true,
            })}
          />
        ) : (
          "Firmenlizenz"
        )}
      </td>
      <td className="text-right p-2">
        {product.type === "subscription" ? `${product.price / 100}€` : null}
      </td>
      <td className="text-right p-2">
        {product.type === "addon"
          ? `${product.price / 100}€`
          : `${((total || 0) / 100).toFixed(2)}€`}
      </td>
    </tr>
  );
};

interface IProps {
  products: Product[];
  licenses: License[];
  employeeCount: number;
  onSubmit: (data: IProductData[]) => void;
}

export const LicenseConfigurator = ({
  products,
  licenses,
  employeeCount,
  onSubmit,
}: IProps) => {
  const { register, handleSubmit, watch } = useForm<IFormData>({
    defaultValues: {
      products: makeDefaults(products, licenses, employeeCount),
    },
  });

  const allFields = watch();
  const totals = getTotals(allFields);
  const total = sum(values(totals)) / 100;
  const submitForm = (data: IFormData) => {
    onSubmit(getProducts(licenses)(data.products));
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <table className="table-auto mb-8 text-sm">
        <thead>
          <tr className="bg-brand-secondary">
            <th className="p-2 rounded-tl-md"></th>
            <th className="p-2">Modulname</th>
            <th className="p-2">Modulbeschreibung</th>
            <th className="p-2">Anzahl Lizenzen</th>
            <th className="p-2">
              Einzelpreis
              <br />
              <span className="text-xs">(User/Monat)</span>
            </th>
            <th className="p-2 rounded-tr-md">Gesamtpreis</th>
          </tr>
        </thead>
        <tbody>
          {products.map((p: Product, idx: number) => (
            <ProductRow
              key={p._id}
              product={p}
              highlighted={Boolean(idx % 2)}
              register={register}
              namePrefix={`products.${idx}`}
              total={totals[p._id]}
              employeeCount={employeeCount}
              license={activeLicenseForProduct(p._id, licenses)}
            />
          ))}
          <tr className="border-t-4 border-double text-md">
            <td colSpan={5} className="font-bold p-2">
              Monatliche Gebühr
            </td>
            <td className="text-right p-2">{total.toFixed(2)}€</td>
          </tr>
        </tbody>
      </table>

      <div className="flex justify-end w-full">
        <button
          className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray bg-coral-300 hover:bg-coral-200 focus:outline-none focus:shadow-outline-indigo focus:border-orange-400 active:bg-coral-400"
          type="submit"
          disabled={!total}
        >
          Bestellen
        </button>
      </div>
    </form>
  );
};
