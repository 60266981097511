import { useQuery } from "@apollo/client";

import { useIntl } from "react-intl";

import SkillChart1 from "./SkillChart1";
import SkillChart2 from "./SkillChart2";
import SkillChart3 from "./SkillChart3";

import { EMPLOYEE_REDUNDANT_COMPETENCES } from "../../schema/employee";

import { Loading } from "../ui/Alerts";

const DashboardSkills = () => {
  const { formatMessage: f } = useIntl();
  const { loading, data: barData } = useQuery(EMPLOYEE_REDUNDANT_COMPETENCES);

  return (
    <>
      {loading && <Loading />}

      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {f({ id: "skills.name" })}
      </h3>
      <div className="mt-5 flex flex-row items-flex-start justify-between space-x-4">
        <div className="w-full space-y-4">
          <SkillChart1 />
          <SkillChart2 />
          <SkillChart3 />
        </div>
      </div>
    </>
  );
};

export default DashboardSkills;
