import { useFormContext } from "react-hook-form";

const NameField = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="name"
        className="block text-sm font-medium leading-5 text-gray-900"
      >
        Name
      </label>
      <input
        type="text"
        id="name"
        {...register("name", { required: true })}
        className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      />

      {errors.name && (
        <span className="text-sm font-light text-red-500">Name fehlt</span>
      )}
    </>
  );
};

export default NameField;
