import { filter, keys, map, pipe } from "ramda";
import React from "react";
import { useIntl } from "react-intl";

import { PermissionFlag } from "types/permission";

const getKeys = pipe(
  keys,
  map((k: string) => parseInt(k, 10)),
  filter((k: number) => !isNaN(k))
);

interface IProps {
  value: PermissionFlag;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const PermissionSelector: React.FC<IProps> = ({
  value,
  disabled = false,
  onChange = () => {},
}) => {
  const { formatMessage: f } = useIntl();

  const getTranslation = (flag?: string): string => {
    if (!flag) {
      return "---";
    }
    return f({ id: `permissions.flags.${flag.toLowerCase()}` });
  };

  return (
    <select
      value={value}
      onChange={onChange}
      disabled={disabled}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-brand-secondary focus:border-brand-secondary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-brand-primary dark:focus:border-brand-primary"
    >
      {getKeys(PermissionFlag).map((k: number, idx: number) => (
        <option key={idx} value={k}>
          {getTranslation(PermissionFlag[k])}
        </option>
      ))}
    </select>
  );
};
