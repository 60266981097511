import { forwardRef } from "react";

const EmploymentStatusSelect = forwardRef((props: any, ref: any) => {
  return (
    <select
      {...props}
      ref={ref}
      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
      defaultValue="employed"
    >
      <option value="employed">Angestellt</option>
      <option value="retired">Pensioniert</option>
      <option value="inactive">Inaktiv</option>
    </select>
  );
});

export default EmploymentStatusSelect;
