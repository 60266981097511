import { gql } from "@apollo/client";

export const roleTypes = gql`
  input RoleInput {
    _id: String
    name: String
  }

  input RolePermissionInheritance {
    roleId: String
  }
`;

export const ROLE_MANY = gql`
  query {
    roleMany {
      name
      isEditable
      _id
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation($role: RoleInput!, $inheritFrom: RolePermissionInheritance) {
    createRole(
      role: $role
      inheritFrom: $inheritFrom
    ) {
      _id
      name
      isEditable
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation($roleId: String!) {
    deleteRole(
      roleId: $roleId
    ) {
      _id
    }
  }
`;
