export enum LicenseType {
  SUBSCRIPTION = 'subscription',
  ADDON = 'addon',
};

export enum LicenseStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  CANCELLED = 'cancelled',
  NON_RENEWING = 'non_renewing',
  IN_TRIAL = 'in_trial',
}

export type ProductPartial = {
  _id: string;
  name: string;
};

export type License = {
  _id: string;
  product: ProductPartial;
  users: string[];
  owner: string;
  status: LicenseStatus;
  key: string;
  seats: number;
  type: LicenseType;
};