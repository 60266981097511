import {
  IdentificationIcon,
  BookOpenIcon,
  DocumentReportIcon,
  UserIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";

export const navItems = [
  {
    navUrl: "/",
    navItem: "Home",
    roles: ["admin", "employee", "guest"],
    main: true,
    icon: null,
  },
  {
    navUrl: "/org",
    navItem: "Organisation",
    roles: ["admin", "employee"],
    main: true,
    icon: null,
  },
  {
    navUrl: "/skills",
    navItem: "Kompetenzen",
    roles: ["admin", "employee", "guest"],
    main: true,
    icon: null,
  },
  {
    navUrl: "/jobs",
    navItem: "Arbeitsplätze",
    roles: ["admin", "employee"],
    main: true,
    icon: null,
  },
  {
    navUrl: "/employees",
    navItem: "Mitarbeiter",
    roles: ["admin", "manager", "employee"],
    main: true,
    icon: null,
  },
  {
    navUrl: "/teams",
    navItem: "Teams",
    roles: ["admin", "manager", "employee"],
    icon: UserGroupIcon,
  },
  {
    navUrl: "/recruiting",
    navItem: "Recruiting",
    roles: ["admin", "manager", "employee"],
    main: false,
    icon: IdentificationIcon,
  },
  {
    navUrl: "/seminars",
    navItem: "Seminare",
    roles: ["admin", "manager", "employee"],
    main: false,
    icon: BookOpenIcon,
  },
  {
    navUrl: "/users",
    navItem: "Benutzer",
    roles: ["admin"],
    main: true,
    icon: UserIcon,
  },
  {
    navUrl: "/reports",
    navItem: "Reports",
    roles: ["admin", "manager"],
    main: false,
    icon: DocumentReportIcon,
  },
];
