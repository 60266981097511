import { useForm, FormProvider, Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import { JobDescription } from "../../types";
import { DateField } from "../common/form";

interface Props {
  onSubmit: (formData: any) => void;
  data?: JobDescription;
}

export type FormData = {
  name: string;
  title: string;
  description: string;
  location: string;
  validFrom: string;
  specifications: string;
  responsibilities: string;
};

const Form = ({ onSubmit, data }: Props) => {
  const methods = useForm<FormData>({ defaultValues: data });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const { formatMessage: f } = useIntl();

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} id="slide-over-form">
        <div className="flex-1 flex flex-col justify-between">
          <div className="px-4 divide-y divide-gray-200 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              <div className="space-y-1">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-5 text-gray-900"
                >
                  {f({ id: "jobDescription.name" })}
                </label>
                <input
                  type="text"
                  id="name"
                  {...register("name", { required: true })}
                  className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />

                {errors.name && (
                  <span className="text-sm font-light text-red-500">
                    Name fehlt
                  </span>
                )}
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium leading-5 text-gray-900"
                >
                  {f({ id: "jobDescription.title" })}
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="title"
                    {...register("title")}
                    className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium leading-5 text-gray-900"
                >
                  {f({ id: "jobDescription.description" })}
                </label>
                <div className="mt-1">
                  <textarea
                    id="description"
                    {...register("description")}
                    className="shadow-sm focus:ring-orange-500 focus:border-orange-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                  ></textarea>
                </div>
              </div>
              <div className="flex space-x-6">
                <div className="space-y-1">
                  <div className="space-y-1">
                    <DateField name="validFrom" label="Eintrittsdatum" />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="location"
                    className="block text-sm font-medium leading-5 text-gray-900"
                  >
                    {f({ id: "jobDescription.location" })}
                  </label>
                  <input
                    type="text"
                    id="location"
                    {...register("location")}
                    className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />

                  {errors.location && (
                    <span className="text-sm font-light text-red-500">
                      Ort fehlt
                    </span>
                  )}
                </div>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="specifications"
                  className="block text-sm font-medium leading-5 text-gray-900"
                >
                  {f({ id: "jobDescription.specifications" })}
                </label>
                <div className="mt-1">
                  <textarea
                    id="specifications"
                    {...register("specifications")}
                    className="shadow-sm focus:ring-orange-500 focus:border-orange-500 mt-1 block w-full h-32 sm:text-sm border-gray-300 rounded-md"
                  ></textarea>
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Anforderungsprofil und Ziele für den Arbeitsplatzinhaber
                </p>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="responsibilities"
                  className="block text-sm font-medium leading-5 text-gray-900"
                >
                  {f({ id: "jobDescription.responsibilities" })}
                </label>
                <div className="mt-1">
                  <textarea
                    id="responsibilities"
                    {...register("responsibilities")}
                    className="shadow-sm focus:ring-orange-500 focus:border-orange-500 mt-1 block w-full h-32 sm:text-sm border-gray-300 rounded-md"
                  ></textarea>
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Fachliche und allgemeine Aufgaben zur Erfüllung der
                  Arbeitsplatzanforderungen
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default Form;
