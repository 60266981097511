import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Transition } from "@headlessui/react";
import {
  useParams,
  useHistory,
  match,
  RouteComponentProps,
} from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";

import {
  JOB_BY_ID,
  JOB_UPDATE_BY_ID,
  JOB_REMOVE_BY_ID,
  JOB_MANY,
  FILTER_JOB_MANY,
} from "../../schema/job";
import { SKILL_MANY, FILTER_SKILL_MANY } from "../../schema/skill";
import { SKILL_SCALE_MANY } from "../../schema/skillScale";
import { Competence, SkillScale } from "../../types";

import { useDrop } from "react-dnd";
import { ItemTypes } from "../../config/ItemTypes";

import SliderOverContext from "../../context/SliderOverContext";
import SlideOverList from "../skills/SliderOverList/SlideOverList";
import useToggle from "../../hooks/useToogle";

import { Loading, Error } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import PageHeader from "../ui/Headings/PageHeader";
import { Breadcrumb } from "../ui/navigation/Breadcrumb";

import Form, { FormData } from "./Form";
import { H3 } from "../ui/Typo/Typo";
import CompetenceFields from "../competences/Fields";
import Stats from "./Stats";

import ProfileChart from "../charts/ProfileChart";

interface ParamTypes {
  id: string;
}

interface Props {
  required: string;
  match?: match<ParamTypes>;
}

const Profile = ({}: Props & RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { formatMessage: f } = useIntl();

  const [open, toggle] = useToggle();
  const [openSkills, toggleSkills] = useToggle();
  const [isOpen, setIsOpen] = useState(false);

  const { loading, error, data } = useQuery(JOB_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateJob] = useMutation(JOB_UPDATE_BY_ID, {
    refetchQueries: [{ query: JOB_MANY }],
  });
  const [updateJobCompetences] = useMutation(JOB_UPDATE_BY_ID, {
    refetchQueries: [
      {
        query: JOB_BY_ID,
        variables: {
          _id: id,
        },
      },
      {
        query: FILTER_SKILL_MANY,
      },
      {
        query: FILTER_JOB_MANY,
      },
    ],
    update(cache, { data: { jobUpdateById } }) {
      try {
        cache.writeQuery({
          query: JOB_BY_ID,
          variables: { _id: id },
          data: {
            jobUpdateById: jobUpdateById,
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
  });
  const [deleteJob] = useMutation(JOB_REMOVE_BY_ID, {
    refetchQueries: [{ query: JOB_MANY }],
  });
  const { data: skillData } = useQuery(SKILL_MANY);
  const { data: skillScaleData } = useQuery(SKILL_SCALE_MANY);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.SKILL,
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return;
      }
      console.log(`Dropped ${(item as any).skill.name}`);
      const skill = (item as any).skill;

      const newCompetence = {
        skillId: skill._id,
        value: 50,
      };

      const competences = data?.jobById.competences.map((c: Competence) => ({
        skillId: c.skillId,
        value: c.value,
      }));

      updateJobCompetences({
        variables: {
          _id: id,
          competences: [...competences, newCompetence],
        },
      });

      return { name: "Profile" };
    },
    // drop: () => (
    //   { name: 'Profile' }
    // ),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    if (!id) {
      history.push("/");
      return;
    }
  }, [id, history]);

  const onUpdateCompetences = (competences: Competence[]) => {
    updateJob({
      variables: {
        _id: id,
        competences: competences.map((c) => ({
          skillId: c.skillId,
          value: +c.value,
        })),
      },
    });
  };

  const onDeleteCompetence = (competence: Competence) => {
    const competences = data?.jobById.competences
      .map((c: any) => ({
        skillId: c.skillId,
        value: +c.value,
      }))
      .filter((item: any) => {
        return item.skillId !== competence.skillId;
      });

    updateJobCompetences({
      variables: {
        _id: id,
        competences: competences,
      },
    });
  };

  const onUpdate = (formData: FormData) => {
    updateJob({
      variables: {
        _id: id,
        name: formData.name,
        description: formData.description,
        salary: +formData.salary,
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteJob({ variables: { _id: id } });
    history.goBack();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const isActive = canDrop && isOver;

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title={f({ id: "job.actions.edit" })}>
          <Form onSubmit={onUpdate} data={data.jobById} />
        </SlideOver>
      </SliderOverContext.Provider>

      <SliderOverContext.Provider
        value={{ open: openSkills, toggle: toggleSkills }}
      >
        <SlideOverList
          data={skillData?.skillMany}
          competences={data.jobById?.competences}
          onSelect={(competences: Competence[]) =>
            onUpdateCompetences(competences)
          }
          defaultCompetenceValue={50}
        >
          {f({ id: "profile.actions.drop" })}
        </SlideOverList>
      </SliderOverContext.Provider>

      <PageHeader
        title={data.jobById.name}
        onUpdate={toggle}
        onDelete={onDelete}
      >
        <Breadcrumb>
          <Breadcrumb.Item url="/">{f({ id: "nav.home" })}</Breadcrumb.Item>
          <Breadcrumb.Item url="/jobs">
            {f({ id: "jobs.name" })}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{`${data.jobById?.name}`}</Breadcrumb.Item>
          <Breadcrumb.Item>{f({ id: "profile.name" })}</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>

      {/* Stats */}
      <Stats
        humatics={data.jobById?.humatics}
        actualRevenue={data.jobById?.actualRevenue}
        targetRevenue={data.jobById?.targetRevenue}
      />

      <hr className="my-6 border-2 rounded-full border-gray-200" />

      <div className="my-6">
        <span className="relative z-0 inline-flex shadow-sm">
          <button
            type="button"
            className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
            onClick={toggleSkills}
          >
            {f({ id: "competences.add" })}
          </button>
          <button
            type="button"
            className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
            onClick={() => setIsOpen(!isOpen)}
          >
            {f({ id: "views.table" })}
          </button>
        </span>
      </div>

      <Transition show={isOpen}>
        <CompetenceFields
          data={data.jobById?.competences}
          onSubmit={(formData) => onUpdateCompetences(formData.competences)}
        />
      </Transition>

      <div
        ref={drop}
        className={
          isActive ? "p-4 rounded border-2 border-dashed border-blue-500" : ""
        }
      >
        <div className="my-4 text-xs uppercase font-light text-gray-500">
          <H3>
            {isActive
              ? "Loslassen zum hinzufügen"
              : f({ id: "profile.actions.drag" })}
          </H3>
        </div>

        <ProfileChart
          width={900}
          height={400}
          data={data.jobById?.competences}
          markers={skillScaleData?.skillScaleMany.map((s: SkillScale) => ({
            label: s.name,
            value: s.value * 100,
          }))}
          revenue={data.jobById?.targetRevenue}
          onDelete={onDeleteCompetence}
          onChange={(competences) => {
            updateJobCompetences({
              variables: {
                _id: id,
                competences: competences.map((c: Competence) => ({
                  skillId: c.skillId,
                  value: c.value,
                })),
              },
            });
          }}
        />
      </div>
    </>
  );
};

export default Profile;
