import { useQuery } from "@apollo/client";

import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { ResponsiveBar } from "@nivo/bar";

import { useIntl } from "react-intl";

import { SKILL_MANY } from "../../schema/skill";
import { Skill } from "../../types";

import { H3 } from "../ui/Typo/Typo";
import { currencyFormater } from "../../config/i18n";

import { Loading } from "../ui/Alerts";

const SkillChart = () => {
  const { formatMessage: f } = useIntl();
  const { loading, data } = useQuery(SKILL_MANY, {
    variables: {
      filter: { core: false },
    },
  });

  const defs = [
    {
      id: "dots",
      type: "patternDots",
      background: "inherit",
      color: "rgba(255, 255, 255, 0.3)",
      size: 4,
      padding: 1,
      stagger: true,
    },
    {
      id: "lines",
      type: "patternLines",
      background: "inherit",
      color: "rgba(255, 255, 255, 0.3)",
      rotation: -45,
      lineWidth: 6,
      spacing: 10,
    },
  ];

  const barData =
    data?.skillMany
      .map((skill: Skill) => ({
        id: skill._id,
        label: skill.name,
        value: skill.supply,
        demand: skill.demand,
        color: skill.color,
        future: skill.future,
      }))
      .sort((a: any, b: any) => a.value - b.value)
      .slice(0, 5) || [];

  return (
    <>
      {loading && <Loading />}

      <div className="flex flex-wrap space-x-4 justify-start items-center">
        <H3>{f({ id: "dashboard.skills.redundant" })}</H3>
      </div>

      <div className="w-full h-64 rounded-lg shadow bg-white dark:bg-gray-800">
        <ResponsiveBar
          data={barData}
          keys={["value"]}
          indexBy="label"
          margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          tooltip={(item: any) => (
            <div className="flex flex-col items-center text-center text-xs p-0.5 bg-white shadow-sm">
              <span className="font-semibold" style={{ color: item.color }}>
                {item.label}
              </span>
              <span className="font-light">
                {item.data.future ? "Zukunftskompetenz" : null}
              </span>
              <dl className="px-3 flex flex-row items-center space-x-2 justify-between">
                <dt className="text-xs leading-5 font-bold text-gray-500">
                  {f({ id: "revenues.actual" })}
                </dt>
                <dd className="text-xs">
                  {currencyFormater.format(item.value)}
                </dd>
                <dt className="text-xs leading-5 font-bold text-gray-500">
                  {f({ id: "revenues.target" })}
                </dt>
              </dl>
            </div>
          )}
          defs={defs}
          fill={[{ match: (d: any) => d.data.future, id: "dots" }]}
          labelFormat="d"
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={null}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </div>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
              <span>{f({ id: "views.table" })}</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180" : ""
                } w-5 h-5 text-gray-500`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Kompetenz
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {f({ id: "revenues.actual" })}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {f({ id: "revenues.target" })}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {barData?.map((item: any) => (
                    <tr key={item.id}>
                      <td className="px-6 py-4">{item.label}</td>
                      <td className="px-6 py-4">
                        {currencyFormater.format(item.value)}
                      </td>
                      <td className="px-6 py-4">
                        {currencyFormater.format(item.demand)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default SkillChart;
