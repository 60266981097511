import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { PlusIcon } from "@heroicons/react/solid";
import { useEffect } from "react";
import { useIntl } from "react-intl";

import {
  ORGANIZATION_CREATE_ONE,
  ORGANIZATION_MANY,
  ORGANIZATION_TREE,
} from "../../schema/organization";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { TreeLayout } from "../orgchart/TreeLayout";
import { Error, Loading } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import { H1 } from "../ui/Typo/Typo";
import Form, { FormData } from "./Form";
import List from "./List";
import Tabs from "./Tabs";

const Organization = () => {
  const [open, toggle] = useToggle();
  const { formatMessage: f } = useIntl();

  const { loading, error, data, refetch } = useQuery(ORGANIZATION_MANY);
  const [createOrganization] = useMutation(ORGANIZATION_CREATE_ONE, {
    refetchQueries: [{ query: ORGANIZATION_MANY }],
  });
  const [getOrganizationTree, { data: treeData }] = useLazyQuery(
    ORGANIZATION_TREE,
    {
      variables: {
        _id: data?.organizationMany[0]?._id,
      },
    }
  );

  useEffect(() => {
    if (data?.organizationMany.length) {
      getOrganizationTree();
    }
  }, [data]);

  const onSubmit = (formData: FormData) => {
    createOrganization({
      variables: {
        name: formData.name,
        description: formData.description,
        managerId: formData.managerId ? formData.managerId : null,
      },
    });
    toggle();
    refetch();
  };

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Organisation hinzufügen">
          <Form onSubmit={onSubmit} />
        </SlideOver>

        <Tabs />

        {/* SectionHeader */}
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
          <div className="ml-4 mt-2">
            <H1>{f({ id: "organizations.name" })}</H1>
          </div>
          <div className="p-6 bg-white ml-4 mt-2 flex-shrink-0">
            <span className="inline-flex rounded-md shadow-sm">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray bg-coral-300 hover:bg-coral-200 focus:outline-none focus:shadow-outline-indigo focus:border-orange-400 active:bg-coral-400"
                onClick={toggle}
              >
                <PlusIcon className="w-5 h-5 mr-1" />
                {f({ id: "organizations.new" })}
              </button>
            </span>
          </div>
        </div>
      </SliderOverContext.Provider>

      <div className="bg-gray-50 h-72 border rounded-md p-3 mb-6 overflow-hidden">
        <TreeLayout data={treeData?.organizationTree} />
      </div>

      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
        {`${data?.organizationMany.length} Einträge`}
      </h2>
      <List data={data?.organizationMany} />
    </>
  );
};

export default Organization;
