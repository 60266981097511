import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

import Tooltip from "../../ui/overlays/Tooltip";
import { numberFormater } from "../../../config/i18n";

interface Props {
  unit: any;
  children: React.ReactNode;
}

const UnitTooltip = ({ unit, children }: Props) => {
  const { humatics, jobHumatics } = unit;
  const { formatMessage: f } = useIntl();

  return (
    <div className="p-1 flex items-center align-middle">
      {children}
      <Tooltip>
        <p className="text-sm font-bold text-gray-800 pb-1">{unit?.name}</p>
        <p className="text-xs leading-4 text-gray-600 pb-1">
          {unit?.description}
        </p>

        <table className="divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th
                scope="col"
                className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase"
              ></th>
              <th
                scope="col"
                className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase"
              >
                {f({ id: "actual" })}
              </th>
              <th
                scope="col"
                className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase"
              >
                {f({ id: "target" })}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td className="px-3 py-2">{f({ id: "humatics.h.sum" })}</td>
              <td className="px-3 py-2">
                {humatics ? numberFormater.format(humatics.employeesH) : null}
              </td>
              <td className="px-3 py-2">
                {jobHumatics
                  ? numberFormater.format(jobHumatics.employeesH)
                  : null}
              </td>
            </tr>
            <tr>
              <td className="px-3 py-2">{f({ id: "humatics.t" })}</td>
              <td className="px-3 py-2">
                {humatics ? numberFormater.format(humatics.t) : null}
              </td>
              <td className="px-3 py-2">
                {jobHumatics ? numberFormater.format(jobHumatics.t) : null}
              </td>
            </tr>
            <tr>
              <td className="px-3 py-2">{f({ id: "humatics.phi" })}</td>
              <td className="px-3 py-2">
                {humatics ? numberFormater.format(humatics.phi) : null}
              </td>
              <td className="px-3 py-2">
                {jobHumatics ? numberFormater.format(jobHumatics.phi) : null}
              </td>
            </tr>
            <tr>
              <td className="px-3 py-2">{f({ id: "humatics.r" })}</td>
              <td className="px-3 py-2">
                {humatics ? numberFormater.format(humatics.r) : null}
              </td>
              <td className="px-3 py-2">
                {jobHumatics ? numberFormater.format(jobHumatics.r) : null}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="pt-3 flex items-center justify-between">
          <span className="text-xs font-bold text-gray-500 cursor-pointer"></span>
          <Link to={`/units/${unit?._id}/details`} className="ml-4">
            <button className="focus:outline-none bg-gray-500 transition duration-150 ease-in-out hover:bg-gray-400 rounded text-white px-5 py-1 text-xs">
              {f({ id: "actions.details" })}
            </button>
          </Link>
        </div>
      </Tooltip>
    </div>
  );
};

export default UnitTooltip;
