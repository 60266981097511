import { gql } from "@apollo/client";

export const FILTER_APPLICANT_MANY = gql`
  query ($skillId: ID!) {
    applicantMany(
      sort: NAME_ASC
      filter: { _operators: { competences: { skillId: { in: [$skillId] } } } }
    ) {
      firstName
      lastName
      gender
      email
      photo
      _id
    }
  }
`;

export const APPLICANT_MANY = gql`
  query {
    applicantMany(sort: NAME_ASC) {
      firstName
      lastName
      gender
      email
      photo
      entryDate
      vacancyId
      vacancy {
        jobId
      }
      salary
      humatics {
        competenceValues
        h
        t
        ef
        mu
      }
      competences {
        skillId
        value
      }
      unitId
      unit {
        name
      }
      _id
    }
  }
`;

export const APPLICANT_BY_ID = gql`
  query ($_id: ID!) {
    applicantById(_id: $_id) {
      firstName
      lastName
      gender
      email
      photo
      entryDate
      vacancyId
      vacancy {
        name
      }
      salary
      humatics {
        competenceValues
        h
        t
        ef
        mu
      }
      competences {
        skillId
        skill {
          name
        }
        value
      }
      unitId
      targetRevenue
      fileIds
      _id
    }
  }
`;

export const APPLICANT_CREATE_ONE = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $gender: Gender
    $email: String!
    $photo: String
    $entryDate: DateTime
    $vacancyId: ID
    $salary: Float!
    $unitId: ID
  ) {
    applicantCreateOne(
      input: {
        firstName: $firstName
        lastName: $lastName
        gender: $gender
        email: $email
        photo: $photo
        entryDate: $entryDate
        vacancyId: $vacancyId
        salary: $salary
        unitId: $unitId
      }
    ) {
      _id
      firstName
    }
  }
`;

export const APPLICANT_UPDATE_BY_ID = gql`
  mutation (
    $_id: ID!
    $firstName: String
    $lastName: String
    $gender: Gender
    $email: String
    $photo: String
    $entryDate: DateTime
    $vacancyId: ID
    $salary: Float
    $competences: [CompetenceInput]
    $unitId: ID
    $fileIds: [ID]
  ) {
    applicantUpdateById(
      input: {
        firstName: $firstName
        lastName: $lastName
        gender: $gender
        email: $email
        photo: $photo
        entryDate: $entryDate
        vacancyId: $vacancyId
        salary: $salary
        competences: $competences
        unitId: $unitId
        fileIds: $fileIds
      }
      _id: $_id
    ) {
      _id
      firstName
    }
  }
`;

export const APPLICANT_REMOVE_BY_ID = gql`
  mutation ($_id: ID!) {
    applicantRemoveById(_id: $_id) {
      _id
      firstName
    }
  }
`;

export const APPLICANT_COMPETENCES = gql`
  query {
    applicantCompetences {
      _id
      value
      name
    }
  }
`;

export const APPLICANT_HUMATICS = gql`
  query {
    applicantHumatics {
      m
      h
      t
      r
      phi
    }
  }
`;

export const APPLICANT_MATCH_COMPETENCES = gql`
  query ($competences: [CompetenceInput]) {
    applicantMatch(competences: $competences) {
      firstName
      lastName
      photo
      matchingSkills
      score
      _id
    }
  }
`;

export const MATCH_FIELDS = gql`
  fragment ApplicantMatchFields on ApplicantMatch {
    firstName
    lastName
    photo
    matchingSkills
    score
    _id
  }
`;
