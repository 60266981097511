import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { Review } from "../../types";

interface Props {
  review: Review;
}

const Item = ({ review }: Props) => {
  return (
    <tr>
      <td className="px-6 py-4">
        <div className="flex items-center">
          <Link to={`/reviews/${review._id}/details`} className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {review.name}
            </div>
            <p className="text-sm text-gray-500 overflow-ellipsis">
              {review.description}
            </p>
          </Link>
        </div>
      </td>
      <td className="px-6 py-4">
        <Link
          to={`/employees/${review.employeeId}/details`}
          className="text-sm ml-4"
        >
          {review.employee?.firstName} {review.employee?.lastName}
        </Link>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {review.createdAt &&
          format(parseISO(review.createdAt), "do MMMM HH:mm", {
            locale: de,
          })}
      </td>
    </tr>
  );
};

export default Item;
