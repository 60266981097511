import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { User, Role } from "../../types";
import { Employee } from "types/employee";
import { assoc } from "ramda";

const makeDefaultValues = (data?: User) => {
  if (!data) {
    return;
  }
  return assoc("role", data.role._id, data);
};

interface Props {
  onSubmit: (formData: any) => void;
  data?: User;
  employees: Employee[];
  roles: Role[];
}

export type FormData = {
  username: string;
  password: string;
  locked: boolean;
  role: string;
  employeeId: string;
};

const Form = ({ onSubmit, data, employees = [], roles = [] }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ defaultValues: makeDefaultValues(data) });
  const { formatMessage: f } = useIntl();

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="slide-over-form">
      <div className="flex-1 flex flex-col justify-between">
        <div className="px-4 divide-y divide-gray-200 sm:px-6">
          <div className="space-y-6 pt-6 pb-5">
            <div className="space-y-1">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Benutzername
              </label>
              <input
                type="text"
                id="username"
                {...register("username", { required: true })}
                className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />

              {errors.username && (
                <span className="text-sm font-light text-red-500">
                  Benutzername fehlt
                </span>
              )}
            </div>
            <div className="space-y-1">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Passwort
              </label>
              <input
                type="password"
                id="password"
                {...register("password", { required: true })}
                className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              {errors.password && (
                <span className="text-sm font-light text-red-500">
                  Passwort fehlt
                </span>
              )}
            </div>
            <div className="space-y-1">
              <label
                htmlFor="locked"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Gesperrt
              </label>
              <input
                type="checkbox"
                id="locked"
                {...register("locked")}
                className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded"
              />
            </div>
            <div className="space-y-1">
              <label
                htmlFor="role"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Rolle
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-xs rounded-md shadow-sm">
                  <select
                    id="role"
                    {...register("role", { required: true })}
                    className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 rounded focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                  >
                    <option></option>
                    {roles.map((r: Role) => (
                      <option key={r._id} value={r._id}>
                        {r.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="space-y-1">
              <label
                htmlFor="employee"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                {f({ id: "employees.name" })}
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-xs rounded-md shadow-sm">
                  <select
                    id="employeeId"
                    {...register("employeeId", { required: true })}
                    className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 rounded focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                  >
                    <option></option>
                    {employees.map((e: Employee) => (
                      <option key={e._id} value={e._id}>
                        {e.firstName} {e.lastName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
