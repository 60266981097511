// https://github.com/codelab-ai/codelab.ai/tree/9ebef4f89069e33d7ad1302a26b7cfeb8eeb42c1/libs/alpha/ui/d3/src/tree
import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

import { diagonal, searchTree, filterTree, highlightPath } from "./TreeUtils";

import { FormData } from "./Filter";

import {
  RootNode,
  OrganizationNode,
  UnitNode,
  TeamNode,
  JobNode,
  EmployeeNode,
} from "./nodes";
import "./index.css";

type TreeData = {
  name: string;
  _id?: string;
  children?: Array<TreeData>;
};

interface Props {
  data: TreeData;
  query?: string;
  filterData?: FormData;
}

// interface Node {
//   children?: Node[];
//   data: TreeData;
//   depth: number;
//   height: number;
//   parent: Node;
//   value: number;
//   x: number;
//   y: number;
// }

// const diagonal = d3.linkHorizontal().x(function(d:any) { return d.y; }).y(function(d:any) { return d.x; });

// https://www.d3indepth.com/layouts/
// const Tree: React.FC = () => {
export const TreeLayout: React.FC<Props> = ({
  data = {},
  query,
  filterData,
}) => {
  const [nodes, setNodes] = useState<any[]>([]);

  const wrapper = useRef<HTMLDivElement>(null);
  const container = useRef<SVGSVGElement>(null);
  // const { width, height } = useWindowDimensions();
  // const [width, height] = [800, 400];
  const width = container?.current?.scrollWidth || 1024;
  const height = container?.current?.scrollHeight || 300;
  const paddingX = 20;
  const nodeWidth = 200;
  const nodeHeight = 200;
  let i = 0;

  // Declares a tree layout and assigns the size
  // const treeLayout = d3.tree().size([width, height]);
  const treeLayout = d3
    .tree()
    .nodeSize([nodeWidth + paddingX, nodeHeight])
    .separation(function (a, b) {
      return a.parent === b.parent ? 1 : 1.25;
    });

  // Assigns parent, children, height, depth
  // TODO: d3.stratify data?
  const root = d3.hierarchy(data, (d: any) => d.children);

  // Position root node at center
  (root as any).x0 = width / 2;
  (root as any).y0 = 0;

  // Collapse after the second level
  // root.children?.forEach(collapse);

  useEffect(() => {
    search(query);
    filter(filterData);
    update(root);
  }, [data, query, filterData]);

  /**
   * Search the tree
   * @param {String} query
   */
  const search = (query: string | undefined) => {
    if (!query) {
      return;
    }
    // reset `found` attribute on every node
    root.each((node: any) => (node.found = false));
    const paths = searchTree(root, query);
    if (typeof paths !== "undefined") {
      highlightPath(paths);
    }
  };

  /**
   * Filter the tree
   * @param {FormData} filterData
   */
  const filter = (filterData: FormData | undefined) => {
    if (!filterData) {
      return;
    }

    root.each((node: any) => {
      // reset `found` attribute on every node
      node.found = false;
      const paths = filterTree(node, filterData);
      if (typeof paths !== "undefined") {
        highlightPath(paths);
      }
    });

    /*
    const paths = filterTree(root, filterData);
    if (typeof paths !== "undefined") {
      highlightPath(paths);
    }
    */
  };

  // FIXME: Replace with D3ZoomEvent
  const zoomHandler = (event: any) => {
    const { y, k } = event.transform;
    const left = width / 2 - nodeWidth / 2; // center
    const x = event.transform.x + left;

    // transform nodes
    d3.select(wrapper.current)
      //.select(".tree")
      .style("left", `${x}px`)
      .style("top", `${y}px`)
      .style("transform-origin", "left top")
      .style("transform", `scale(${k})`);

    // transform links
    d3.select(container.current)
      .select("g")
      .attr("transform", `translate(${x},${y}) scale(${k})`);
  };

  const update = (source: any) => {
    var treeData = treeLayout(root);
    let _nodes = treeData.descendants();
    const links = treeData.descendants().slice(1);
    if (_nodes.length === 1) {
      return;
    }

    // Call visit function to establish maxLabelLength
    // var totalNodes = 0;
    // var maxLabelLength = 0;
    // visit(treeData, function(d) {
    //     totalNodes++;
    //     maxLabelLength = Math.max(d.name.length, maxLabelLength);

    // }, function(d) {
    //     return d.children && d.children.length > 0 ? d.children : null;
    // });
    // console.log("visit", totalNodes, maxLabelLength);

    // Align children to position of parent
    // _nodes = alignChildren(nodes, source);

    // Normalize for fixed-depth.
    _nodes.forEach((d: any) => {
      d.x = d.x + width / 2 + nodeWidth / 2;
      d.y = d.depth * nodeHeight;
    });

    const svg = d3.select(container.current);

    // Apply zoom behavior to the container
    const zoom = d3.zoom().scaleExtent([0.2, 2]).on("zoom", zoomHandler);

    // @ts-ignore
    svg.call(zoom);

    var node = svg
      .select("g")
      .selectAll("g.node")
      .data(_nodes, (d: any) => d.data._id || (d.id = ++i));

    // Enter any new modes at the parent's previous position.
    const nodeEnter = node
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", `translate(${source.x0},${source.y0})`)
      .on("click", clickNode);

    // Add Circle for the nodes
    nodeEnter
      .append("circle")
      // .attr("cx", function (d) {
      //   return d.x;
      // })
      // .attr("cy", function (d) {
      //   return d.y;
      // })
      .attr("class", "node")
      .attr("r", 1e-6)
      .style("fill", "#c0c0c0");

    // @ts-ignore
    const nodeUpdate = nodeEnter.merge(node);

    // Transition to the proper position for the node
    nodeUpdate
      .transition()
      .duration(50)
      .attr("transform", (d) => `translate(${d.x},${d.y})`);

    // Update the node attributes and style
    nodeUpdate
      .select("circle.node")
      .attr("r", 4)
      .classed("found", (d: any) => d.found === true)
      .style("fill", (d: any) => {
        return d._children ? "#fecd1b" : "#ffffff";
      })
      .attr("cursor", "pointer");

    // Remove any exiting nodes
    const nodeExit = node
      .exit()
      .transition()
      .duration(50)
      .attr("transform", `translate(${source.x},${source.y})`)
      .remove();

    // On exit reduce the node circles size to 0
    nodeExit.select("circle").attr("r", 1e-6);

    const link = svg
      .select("g")
      .selectAll("path.link")
      .data(links, (d: any) => d.data._id);

    // Enter any new links at the parent's previous position.
    const linkEnter = link
      .enter()
      .insert("path", "g")
      .attr("class", "link")
      .classed("found", (d: any) => d.found === true)
      .attr("d", (d) => {
        const o = {
          x: source.x0,
          y: source.y0,
        };
        return diagonal(o, o);
      });

    // @ts-ignore
    const linkUpdate = linkEnter.merge(link);

    // Transition back to the parent element position
    linkUpdate
      .transition()
      .duration(200)
      .attr("d", function (d) {
        return diagonal(d, d.parent);
      });

    // Remove any exiting links
    link
      .exit()
      .transition()
      .duration(200)
      .attr("d", function (d) {
        const o = {
          x: source.x,
          y: source.y,
        };
        return diagonal(o, o);
      })
      .remove();

    // Store the old positions for transition.
    _nodes.forEach((d: any) => {
      // d.y = d.depth * nodeHeight;
      d.x0 = d.x;
      d.y0 = d.y;
    });

    setNodes(_nodes);
  };

  const clickNode = (e: Event, d: any) => {
    update(d);
  };

  // Node | HierarchyNode<any>
  const renderNode = (d: any, index: number) => {
    const dataType = d.data.__typename;

    const clickHandler = () => {
      //update(d);

      d3.select(container.current)
        .selectAll("g.node")
        .filter((_d: any) => _d.data._id === d.data._id)
        .dispatch("click");
    };

    if (index === 0 && dataType === "OrganizationTree") {
      return <OrganizationNode key={index} data={d} />;
    }

    switch (dataType) {
      case "OrganizationTree":
        return (
          <UnitNode
            key={index}
            data={d}
            hasChildren={Boolean(d.children)}
            onClick={clickHandler}
            filterData={filterData}
          />
        );
      case "TeamTree":
        return (
          <TeamNode
            key={index}
            data={d}
            hasChildren={Boolean(d.children)}
            onClick={clickHandler}
            filterData={filterData}
          />
        );
      case "TeamTreeJob":
        return (
          <JobNode
            key={index}
            data={d}
            hasChildren={Boolean(d.children)}
            onClick={clickHandler}
            filterData={filterData}
          />
        );
      case "Employee":
        return (
          <EmployeeNode
            key={index}
            data={d}
            hasChildren={Boolean(d.children)}
            onClick={clickHandler}
            filterData={filterData}
          />
        );
      default:
        return <RootNode key={index} data={d} />;
    }
  };

  return (
    <div>
      <div ref={wrapper} className="w-full h-full relative tree">
        {nodes.map(renderNode)}
      </div>
      <svg width="100%" height={height + 50} ref={container}>
        <g transform="translate(0, 5)">
          <g className="nodes" />
          <g className="links" />
        </g>
      </svg>
    </div>
  );
};
