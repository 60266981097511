import { FC } from "react";
import Item from "./Item";
import { File } from "../../types";

interface Props {
  data: Array<File>;
  onDelete?: (file: File) => void;
};

const List: FC<Props> = ({ data = [], onDelete }) => {
  return (
    <ul className="border border-gray-200 rounded">
      {data?.map((file: File) => (
        <Item key={file._id} file={file} onDelete={onDelete} />
      ))}
    </ul>
  );
};

export default List;