import { ArrowNarrowUpIcon, ArrowNarrowDownIcon } from "@heroicons/react/solid";

interface Props {
  value: number;
};

const Arrow = ({ value }: Props) => {
  return value > 0 ? (
    <ArrowNarrowUpIcon className="w-4 text-green-500" />
    ) : (
    <ArrowNarrowDownIcon className="w-4 text-red-500" />
  );
};

export default Arrow;
