import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { FilterIcon } from "@heroicons/react/outline";

import { Team, Employee } from "../../types";
import Avatar from "../employees/Avatar";

interface Props {
  team: Team;
}

const Item = ({ team }: Props) => {
  return (
    <tr>
      <td className="px-6 py-4">
        <div className="flex items-center">
          <Link to={`/teams/${team._id}/details`} className="ml-4">
            <div className="text-sm font-medium text-gray-900">{team.name}</div>
            <p className="text-sm text-gray-500 overflow-ellipsis">
              {team.description}
            </p>
          </Link>
        </div>
      </td>
      <td className="px-6 py-4">
        <div className="text-sm text-gray-500">
          {team.leader?.firstName} {team.leader?.lastName}
        </div>
        <Link to={`/employees/${team.leaderId}/details`}>
          <Avatar employee={team.leader} className="w-10 h-10" />
        </Link>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-500">
          {team.memberIds?.length} Mitglieder
        </div>

        <div className="pt-2 px-6 flex items-center flex-no-wrap">
          {team.members.map((member: Employee) => (
            <Link
              key={member._id}
              to={`/employees/${member._id}/details`}
              className="w-12 h-12 bg-cover bg-center rounded-md -ml-2"
            >
              <Avatar
                employee={member}
                className="h-full w-full overflow-hidden object-cover rounded-full border-2 border-white dark:border-gray-700 shadow"
              />
            </Link>
          ))}
        </div>
      </td>
      <td className="px-6 py-4">
        <Link
          to={{
            pathname: `/teams/${team._id}/match`,
            state: { competences: [] },
          }}
        >
          <button className="inline-flex items-center h-6 pl-2.5 pr-2 rounded-md shadow-sm text-xs text-gray-700 dark:text-gray-400 dark:border-gray-800 border border-gray-200 leading-none py-0">
            Passende Mitarbeiter
            <FilterIcon className="ml-2 h-4 w-4 text-gray-400 group-hover:text-gray-500 " />
          </button>
        </Link>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {team.createdAt &&
          format(parseISO(team.createdAt), "do MMMM", {
            locale: de,
          })}
      </td>
    </tr>
  );
};

export default Item;
