import { useQuery } from "@apollo/client";
import { find, pathOr, pipe, propEq } from "ramda";

import { LICENSES_MANY } from "schema/license";
import { PERMISSION_MANY } from "schema/permission";
import { USER_CURRENT } from "schema/user";
import type { Permission, PermissionEntry } from "types/permission";
import { PermissionFlag, scope } from "types/permission";
import type { User } from "types/user";
import { getUpperLimit } from "./utils";

type PermissionFinder = (perms: Permission[]) => PermissionEntry | undefined;

const permForRole = (
  role: string | undefined,
  name: string
): PermissionFinder =>
  pipe(
    find(propEq("name", name)),
    pathOr([], ["entries"]),
    find((p) => pathOr(null, ["role", "name"], p) === role)
  );

interface IHookData {
  getPermissionScope: (x: string, level: PermissionFlag) => scope;
  hasPermissionScope: (
    x: string,
    level: PermissionFlag,
    scopeRequired?: scope
  ) => boolean;
  canRead: (x: string) => scope;
  canWrite: (x: string) => scope;
  hasReadScope: (x: string, y?: scope) => boolean;
  hasWriteScope: (x: string, y?: scope) => boolean;
  protectRead: (x: string, scopeRequired: scope) => (f: Function) => Function;
  protectWrite: (x: string, scopeRequired: scope) => (f: Function) => Function;
  loading: boolean;
}

export const usePermissions = (): IHookData => {
  const { data: permData, loading: permLoading } = useQuery(PERMISSION_MANY);
  const { data: userData, loading: userLoading } = useQuery(USER_CURRENT);
  const { data: licenseData, loading: licenseLoading } = useQuery(
    LICENSES_MANY
  );

  const permissions = pathOr([], ["permissionMany"], permData);
  const user = pathOr<User | null>(null, ["userCurrent"], userData);
  const loading = permLoading || userLoading || licenseLoading;

  const getPermissionScope = (module: string, level: PermissionFlag) => {
    if (!loading) {
      const getFlags = permForRole(user?.role.name, module);
      const flags = getFlags(permissions);
      const limit = getUpperLimit(module, licenseData?.licenseMany || []);

      if (limit(flags?.all || PermissionFlag.NONE) >= level) return scope.ALL;
      if (limit(flags?.group || PermissionFlag.NONE) >= level)
        return scope.GROUP;
      if (limit(flags?.self || PermissionFlag.NONE) >= level) return scope.SELF;
    }

    return scope.ALL;
  };

  const wrap = (
    module: string,
    scopeRequired: scope,
    level: PermissionFlag
  ) => {
    return (f: Function) => {
      const scopeValue = getPermissionScope(module, level);
      if (scopeValue >= scopeRequired) {
        return f;
      } else {
        return () => {};
      }
    };
  };

  return {
    getPermissionScope,
    hasPermissionScope(module, flag, scopeRequired = scope.SELF) {
      return getPermissionScope(module, flag) >= scopeRequired;
    },
    canRead(module) {
      return getPermissionScope(module, PermissionFlag.READ);
    },
    canWrite(module) {
      return getPermissionScope(module, PermissionFlag.WRITE);
    },
    hasReadScope(module, scopeRequired = scope.SELF) {
      return getPermissionScope(module, PermissionFlag.READ) >= scopeRequired;
    },
    hasWriteScope(module, scopeRequired = scope.SELF) {
      return getPermissionScope(module, PermissionFlag.WRITE) >= scopeRequired;
    },
    protectRead(module, scopeRequired) {
      return wrap(module, scopeRequired, PermissionFlag.READ);
    },
    protectWrite(module, scopeRequired) {
      return wrap(module, scopeRequired, PermissionFlag.WRITE);
    },
    get loading() {
      return loading;
    },
  };
};
