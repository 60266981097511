import { H1 } from "./ui/Typo/Typo";

const Error = () => {
  return (
    <>
      <H1>Fehler</H1>
      <p>
        Es konnte keine Verbindung zum Server hergestellt werden
      </p>
    </>
  );
};

export default Error;
