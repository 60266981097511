import React from "react";
import Item from "./Item";
import { EmployeeMatch, Team } from "../../types";

interface Props {
  data: Array<EmployeeMatch>;
  team?: Team;
  type?: "applicants" | "employees";
}

const List: React.FC<Props> = ({ data = [], team, type = "employees" }) => {
  return (
    <ul className="mt-3 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {data?.map((match: EmployeeMatch) => (
        <Item key={match._id} match={match} team={team} type={type} />
      ))}
    </ul>
  );
};

export default List;
