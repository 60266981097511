import { useFormContext } from "react-hook-form";

const SalaryField = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="salary"
        className="block text-sm font-medium text-gray-700"
      >
        Gehalt
      </label>
      <input
        type="number"
        id="salary"
        {...register("salary", {
          required: true,
          valueAsNumber: true,
          min: 0,
        })}
        className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      />
      {errors.salary && (
        <span className="text-sm font-light text-red-500">Gehalt fehlt</span>
      )}
    </>
  );
};

export default SalaryField;
