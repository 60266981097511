import { useFormContext, Controller } from "react-hook-form";

import EmploymentStatusSelect from "./EmploymentStatusSelect";

const EmploymentStatusField = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <>
      <label
        htmlFor="employment-status"
        className="block text-sm font-medium leading-5 text-gray-900"
      >
        Beschäftigungsstatus
      </label>
      <Controller
        control={control}
        name="employmentStatus"
        render={({ field }) => <EmploymentStatusSelect {...field} />}
      />
    </>
  );
};

export default EmploymentStatusField;
