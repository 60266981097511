import { IdentificationIcon, NewspaperIcon, BriefcaseIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

const Tabs = () => {
  return (
    <div>
      <div className="sm:hidden">
        <select
          className="form-select block w-full"
          defaultValue="Organisation"
        >
          <option>Stellenangebote</option>
          <option>Bewerber</option>
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="flex -mb-px">
            <Link
              to="/vacancies"
              className="group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              <NewspaperIcon className="mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              <span>Stellenangebote</span>
            </Link>
            <Link
              to="/applicants"
              className="ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              <BriefcaseIcon className="mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              <span>Bewerber</span>
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
