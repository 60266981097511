export const currencyFormater = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currencyDisplay: "symbol",
  currency: "EUR",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const numberFormater = new Intl.NumberFormat("de-DE", {
  maximumSignificantDigits: 3,
});

export const percentFormater = new Intl.NumberFormat("de-DE", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
