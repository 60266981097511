import { useMutation } from "@apollo/client";
import { assoc } from "ramda";
import React from "react";

import { UPDATE_PERMISSION } from "schema/permission";
import type { PermissionEntry } from "types/permission";
import { Permission, PermissionFlag } from "types/permission";
import type { Role } from "types/role";
import { PermissionSelector } from "./PermSelector";
import { getEntryForRole, replaceEntry } from "./functions";

export type TPermissionRow = {
  _id: string;
  name: string;
  module: string;
  displayName?: string;
  entry: PermissionEntry | undefined;
};

interface IProps {
  permission: Permission;
  role: Role | null;
  isEditable?: boolean;
}

interface IState {
  row: TPermissionRow;
  loading: boolean;
}

export const PermissionRow: React.FC<IProps> = ({
  permission,
  role,
  isEditable = true,
}) => {
  const getEntry = getEntryForRole(role?._id || "");
  const row = getEntry(permission);
  const [state, setState] = React.useState<IState>({ row, loading: false });
  const [updatePermission] = useMutation(UPDATE_PERMISSION, {
    onCompleted: () => {
      setState((prev) => ({ ...prev, loading: false }));
    },
  });

  const isDisabled = !isEditable || state.loading;
  const handleChange = (field: string) => (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (!row.entry) {
      return;
    }

    const newEntry = assoc(field, parseInt(e.target.value, 10), row.entry);
    const newPermission = replaceEntry(permission, newEntry);
    const newRow = assoc("entry", newEntry, row);
    setState({ ...state, row: newRow, loading: true });
    updatePermission({ variables: { permission: newPermission } });
  };

  React.useEffect(() => {
    setState({ ...state, row });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return (
    <React.Fragment key={state.row.name}>
      <tr>
        <td className="px-6 py-4 w-64">
          <div className="flex items-center text-sm">
            {state.row.displayName || state.row.name}
          </div>
        </td>

        <td className="px-6 py-4">
          <div className="flex items-center">
            <PermissionSelector
              value={state.row.entry?.all || PermissionFlag.NONE}
              onChange={handleChange("all")}
              disabled={isDisabled}
            />
          </div>
        </td>

        <td className="px-6 py-4">
          <div className="flex items-center">
            <PermissionSelector
              value={state.row.entry?.group || PermissionFlag.NONE}
              onChange={handleChange("group")}
              disabled={isDisabled}
            />
          </div>
        </td>

        <td className="px-6 py-4">
          <div className="flex items-center">
            <PermissionSelector
              value={state.row.entry?.self || PermissionFlag.NONE}
              onChange={handleChange("self")}
              disabled={isDisabled}
            />
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};
