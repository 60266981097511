import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";
import { de } from "date-fns/locale";
import axios from "axios";

import { Applicant, Competence } from "../../types";
import VacancySelect from "../common/form/VacancySelect";
import { UnitSelect } from "../common/form";
import Avatar from "../employees/Avatar";

registerLocale("de", de);

interface Props {
  onSubmit: (formData: any) => void;
  data?: Applicant;
}

export type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  photo: string;
  entryDate: Date;
  vacancyId: string;
  salary: number;
  competences: Array<Competence>;
  unitId: string;
  fileIds: Array<string>;
};

const Form = ({ onSubmit, data }: Props) => {
  const client = useApolloClient();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    control,
  } = useForm<FormData>({ defaultValues: data });

  // useEffect(() => {
  //   setValue(
  //     "entryDate",
  //     parseISO(data?.entryDate || new Date().toISOString())
  //   );
  // }, [setValue, data]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const formData = new FormData();
      formData.append("photo", acceptedFiles[0]);

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_FILES_URI}/photo/upload`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setValue("photo", response.data.url);

      client.cache.modify({
        id: client.cache.identify(data as any),
        fields: {
          photo() {
            return response.data.url;
          },
        },
      });
    },
    [client.cache, data, setValue]
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    // accept: "image/jpeg, image/jpg, image/png",
    maxFiles: 1,
  });

  // console.log(watch());

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="slide-over-form">
      <div className="flex-1 flex flex-col justify-between">
        <div className="px-4 divide-y divide-gray-200 sm:px-6">
          <div className="space-y-6 pt-6 pb-5">
            <div className="flex space-x-6">
              <div className="space-y-1">
                <label className="block text-sm font-medium leading-5 text-gray-900">
                  Photo
                </label>

                <input type="hidden" {...register("photo")} />
                <button type="button" onClick={open}>
                  <Avatar employee={data as any} className="w-10 h-10" />
                </button>
              </div>
              <div className="space-y-1">
                <div
                  {...getRootProps({ className: "mt-2 text-sm text-gray-500" })}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <>
                      <p>
                        Datei reinziehen oder klicken um Dateien auszuwählen
                      </p>
                      <em>(Nur *.jpeg und *.png Bilder sind möglich)</em>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="flex space-x-6">
              <div className="space-y-1">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-5 text-gray-900"
                >
                  Vorname
                </label>
                <input
                  type="text"
                  id="first-name"
                  {...register("firstName", { required: true })}
                  autoComplete="given-name"
                  className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />

                {errors.firstName && (
                  <span className="text-sm font-light text-red-500">
                    Vorname fehlt
                  </span>
                )}
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-5 text-gray-900"
                >
                  Nachname
                </label>
                <input
                  type="text"
                  id="last-name"
                  {...register("lastName", { required: true })}
                  autoComplete="family-name"
                  className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />

                {errors.lastName && (
                  <span className="text-sm font-light text-red-500">
                    Nachname fehlt
                  </span>
                )}
              </div>
            </div>
            <div className="space-y-1">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                E-Mail
              </label>
              <input
                type="email"
                id="last-name"
                {...register("email", { required: true })}
                autoComplete="email"
                className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />

              {errors.email && (
                <span className="text-sm font-light text-red-500">
                  E-Mail fehlt
                </span>
              )}
            </div>
            <div className="flex space-x-6">
              <div className="space-y-1">
                <label
                  htmlFor="entry-date"
                  className="block text-sm font-medium leading-5 text-gray-900"
                >
                  Eintrittsdatum
                </label>
                <Controller
                  control={control}
                  name="entryDate"
                  rules={{ required: false }}
                  render={({ field }) => (
                    <ReactDatePicker
                      dateFormat="dd.MM.yyyy"
                      locale="de"
                      placeholderText="Datum wählen"
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      selected={
                        typeof field.value === "string"
                          ? parseISO(field.value)
                          : field.value
                      }
                      className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  )}
                />
                {errors.entryDate && (
                  <span className="text-sm font-light text-red-500">
                    Eintrittsdatum fehlt
                  </span>
                )}
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="salary"
                  className="block text-sm font-medium text-gray-700"
                >
                  Gehalt
                </label>
                <input
                  type="number"
                  id="salary"
                  {...register("salary", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                {errors.salary && (
                  <span className="text-sm font-light text-red-500">
                    Gehalt fehlt
                  </span>
                )}
              </div>
            </div>
            <div className="space-y-2">
              <label
                htmlFor="vacancy-id"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Stellenangebot
              </label>
              <Controller
                control={control}
                name="vacancyId"
                render={({ field }) => <VacancySelect {...field} />}
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="unit-id"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Einheit
              </label>
              <Controller
                control={control}
                name="unitId"
                render={({ field }) => <UnitSelect {...field} />}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
