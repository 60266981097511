import { useForm } from "react-hook-form";
import { SkillScale } from "../../types";

interface Props {
  onSubmit: (formData: any) => void;
  data?: SkillScale;
}

export type FormData = {
  name: string;
  description: string;
  level: number;
  value: number;
};

const Form = ({ onSubmit, data }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ defaultValues: data });

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="slide-over-form">
      <div className="flex-1 flex flex-col justify-between">
        <div className="px-4 divide-y divide-gray-200 sm:px-6">
          <div className="space-y-6 pt-6 pb-5">
            <div className="space-y-1">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                {...register("name", { required: true })}
                className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />

              {errors.name && (
                <span className="text-sm font-light text-red-500">
                  Name fehlt
                </span>
              )}
            </div>
            <div className="space-y-1">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Beschreibung
              </label>
              <div className="mt-1">
                <textarea
                  id="description"
                  {...register("description")}
                  className="shadow-sm focus:ring-orange-500 focus:border-orange-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                ></textarea>
              </div>
              <p className="mt-2 text-sm text-gray-500">
                Kurze Beschreibung dieser Kompetenzskalierung
              </p>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="space-y-1">
                <label
                  htmlFor="level"
                  className="block text-sm font-medium text-gray-700"
                >
                  Level
                </label>
                <input
                  type="number"
                  id="level"
                  min={0}
                  max={10}
                  {...register("level", {
                    required: true,
                    min: 0,
                    max: 10,
                    valueAsNumber: true,
                  })}
                  className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                {errors.level && (
                  <span className="text-sm font-light text-red-500">
                    Stufe fehlt
                  </span>
                )}
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="value"
                  className="block text-sm font-medium text-gray-700"
                >
                  Wert
                </label>
                <input
                  type="number"
                  id="value"
                  min={0}
                  max={100}
                  step="0.1"
                  {...register("value", {
                    required: true,
                    min: 0,
                    max: 100,
                    valueAsNumber: true,
                  })}
                  className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                {errors.value && (
                  <span className="text-sm font-light text-red-500">
                    Wert fehlt
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
