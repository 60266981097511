import { useForm, Controller } from "react-hook-form";
import { EmployeeSelect, OrganizationSelect, UnitSelect } from "../common/form";
import { Unit, Revenue, Requirement } from "../../types";

interface Props {
  onSubmit: (formData: any) => void;
  data?: Unit;
}

export type FormData = {
  name: string;
  description: string;
  managerId: string;
  organizationId: string;
  parentId: string;
  revenues: Array<Revenue>;
  requirements: Array<Requirement>;
};

const Form = ({ onSubmit, data }: Props) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ defaultValues: data });

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="slide-over-form">
      <div className="flex-1 flex flex-col justify-between">
        <div className="px-4 divide-y divide-gray-200 sm:px-6">
          <div className="space-y-6 pt-6 pb-5">
            <div className="space-y-1">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                {...register("name", { required: true })}
                className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />

              {errors.name && (
                <span className="text-sm font-light text-red-500">
                  Name fehlt
                </span>
              )}
            </div>
            <div className="space-y-1">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Beschreibung
              </label>
              <div className="mt-1">
                <textarea
                  id="description"
                  {...register("description")}
                  className="shadow-sm focus:ring-orange-500 focus:border-orange-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                ></textarea>
              </div>
              <p className="mt-2 text-sm text-gray-500">
                Kurze Beschreibung zu dieser Einheit
              </p>
            </div>
            <div className="space-y-1">
              <label
                htmlFor="manager-id"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Manager
              </label>
              <Controller
                control={control}
                name="managerId"
                render={({ field }) => <EmployeeSelect {...field} />}
                defaultValue=""
              />
              {errors.managerId && (
                <span className="text-sm font-light text-red-500">
                  Manager fehlt
                </span>
              )}
            </div>
            <div className="space-y-1">
              <label
                htmlFor="organization-id"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Organisation
              </label>
              <Controller
                control={control}
                name="organizationId"
                render={({ field }) => <OrganizationSelect {...field} />}
                rules={{ required: true }}
                defaultValue=""
              />
              {errors.organizationId && (
                <span className="text-sm font-light text-red-500">
                  Organisation fehlt
                </span>
              )}
            </div>
            <div className="space-y-2">
              <label
                htmlFor="parent-id"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Übergeordnete Einheit
              </label>
              <Controller
                control={control}
                name="parentId"
                render={({ field }) => <UnitSelect {...field} />}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
