import { includes, map, pipe, filter } from "ramda";

import { RULE_LICENSE_MAPPING } from "./mappings";
import { PermissionFlag } from "types/permission";
import { LicenseStatus } from "types/license";
import type { License } from "types/license";

const ACTIVE_STATES = [
  LicenseStatus.ACTIVE,
  LicenseStatus.IN_TRIAL,
  LicenseStatus.NON_RENEWING,
];

const getLicenseNames = pipe<
  License[],
  License[],
  string[]
>(
  filter((l: License) => includes(l.status, ACTIVE_STATES)),
  map((l: License) => l.product.name),
);

export const getUpperLimit = (
  ruleName: string,
  licenses: License[],
): (requestedAccess: PermissionFlag) => PermissionFlag => {
  const licenseNames = getLicenseNames(licenses);
  const config = RULE_LICENSE_MAPPING[ruleName];

  return (requestedAccess: PermissionFlag) => {
    if (!config) { return requestedAccess; }

    return includes(config.requires, licenseNames)
      ? Math.min(config.maxLevelExisting, requestedAccess)
      : Math.min(config.maxLevelMissing, requestedAccess);
  }
};
