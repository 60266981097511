import { useState, useEffect, useRef } from "react";
import { Spring, config } from "react-spring/renderprops";

interface Props {
  data: any;
}

const RootNode = ({ data }: Props) => {
  const outer = useRef<HTMLDivElement>(null);
  const [x, setX] = useState(data.x);

  useEffect(() => {
    // Run once, after mounting
    // inner.current?.getBoundingClientRect()?.left;
    const width = outer.current?.clientWidth;
    if (width) {
      setX(width / 2);
    }
  }, [data]);

  const { data: organization } = data;

  return (
    <Spring
      config={config.stiff}
      from={{ left: data.x0, top: data.y0, opacity: 0 }}
      to={{ left: data.x, top: data.y, opacity: 1 }}
    >
      {(props) => (
        <div
          ref={outer}
          className="absolute p-1 bg-white border border-gray-200 rounded-md shadow-sm text-xs"
          style={{ left: props.left, top: props.top, marginLeft: -x }}
        >
          {organization?.name}
        </div>
      )}
    </Spring>
  );
};

export default RootNode;
