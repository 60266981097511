import { MouseEvent } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { PlusIcon } from "@heroicons/react/solid";

import { useDrag, DragSourceMonitor } from "react-dnd";
import { ItemTypes } from "../../../config/ItemTypes";

import { useMutation } from "@apollo/client";
import { EMPLOYEE_CREATE_ONE, EMPLOYEE_MANY } from "../../../schema/employee";
import { Applicant } from "../../../types";

import Avatar from "../../employees/Avatar";

interface Props {
  applicant: Applicant;
}

const Item = ({ applicant }: Props) => {
  const { formatMessage: f } = useIntl();

  const [createEmployee] = useMutation(EMPLOYEE_CREATE_ONE, {
    refetchQueries: [
      {
        query: EMPLOYEE_MANY,
        variables: {
          isApplicant: true,
        },
      },
    ],
  });

  // Drag & Drop
  const [{ isDragging, opacity }, drag] = useDrag({
    item: {
      type: ItemTypes.APPLICANT,
      applicant: applicant,
    },
    options: {
      dropEffect: "copy",
    },
    end: (item: { type: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      console.log("isDragging", isDragging);

      if (item && dropResult) {
        console.log(
          `You dropped ${(item as any).applicant.firstName} into ${
            dropResult.name
          }!`
        );
      }
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging(),
    }),
  });

  const hire = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    createEmployee({
      variables: {
        firstName: applicant.firstName,
        lastName: applicant.lastName,
        gender: applicant.gender,
        email: applicant.email,
        photo: applicant.photo,
        hireDate: applicant.entryDate,
        applicantId: applicant._id,
        jobId: applicant.vacancy?.jobId,
        salary: +applicant.salary,
        competences: applicant.competences,
        unitId: applicant.unitId,
      },
    });
  };

  return (
    <li ref={drag} className="px-6 py-5 relative cursor-move">
      <div className="group flex justify-between items-center space-x-2">
        <Link
          to={`/applicants/${applicant._id}/details`}
          className="-m-1 p-1 block overflow-hidden"
        >
          <span className="absolute inset-0 group-hover:bg-gray-50"></span>
          <div className="flex-1 flex items-center min-w-0 relative">
            <Avatar
              key={applicant._id}
              employee={applicant as any}
              className="w-10 h-10"
            />
            <div className="ml-4 truncate">
              <div className="text-sm leading-5 font-medium text-gray-900 truncate">
                {applicant.firstName} {applicant.lastName}
              </div>
              <button
                className="inline-flex items-center h-6 px-1 my-1 rounded-md text-xs text-gray-700 border border-gray-200 leading-none py-0"
                onClick={hire}
              >
                <PlusIcon className="mr-1 h-4 w-4 text-gray-400 group-hover:text-gray-500 " />
                {f({ id: "actions.simulate" })}
              </button>
            </div>
          </div>
        </Link>
      </div>
    </li>
  );
};

export default Item;
