import { gql } from "@apollo/client";

export const authTypes = gql`
  extend type Query {
    isLoggedIn: Boolean!
    role: String!
  }
`;

export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export const ROLE = gql`
  query {
    role @client
  }
`;