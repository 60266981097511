import { MouseEvent } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { MinusIcon } from "@heroicons/react/solid";

import { useDrag, DragSourceMonitor } from "react-dnd";
import { ItemTypes } from "../../../config/ItemTypes";

import { useMutation } from "@apollo/client";
import { EMPLOYEE_REMOVE_BY_ID, EMPLOYEE_MANY } from "../../../schema/employee";
import { ORGANIZATION_TREE } from "../../../schema/organization";
import { Employee } from "../../../types";

import Avatar from "../../employees/Avatar";

interface Props {
  employee: Employee;
}

const Item = ({ employee }: Props) => {
  const { formatMessage: f } = useIntl();

  const [deleteEmployee] = useMutation(EMPLOYEE_REMOVE_BY_ID, {
    refetchQueries: [
      { query: EMPLOYEE_MANY, variables: { isApplicant: true } },
    ],
  });

  const onDelete = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    deleteEmployee({
      variables: { _id: employee._id },
    });
  };

  // Drag & Drop
  const [{ isDragging, opacity }, drag] = useDrag({
    item: {
      type: ItemTypes.EMPLOYEE,
      employee: employee,
    },
    options: {
      dropEffect: "copy",
    },
    end: (item: { type: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      console.log("isDragging", isDragging);

      if (item && dropResult) {
        console.log(
          `You dropped ${(item as any).employee.firstName} into ${
            dropResult.name
          }!`
        );
      }
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <li ref={drag} className="px-6 py-5 relative">
      <div className="group flex justify-between items-center space-x-2">
        <Link
          to={`/employees/${employee._id}/details`}
          className="-m-1 p-1 block"
        >
          <span className="absolute inset-0 group-hover:bg-gray-50"></span>
          <div className="flex-1 flex items-center max-w-4 min-w-0 relative">
            <span className="flex-shrink-0 inline-block relative">
              <Avatar employee={employee} className="w-10 h-10" />
              <span className="absolute top-0 right-0 block h-2.5 w-2.5 rounded-full text-white shadow-solid bg-green-400"></span>
            </span>
            <div className="ml-4 truncate">
              <div className="text-sm leading-5 font-medium text-gray-900 truncate">
                {employee.firstName} {employee.lastName}
              </div>
              <div className="text-sm leading-5 text-gray-500 truncate">
                {employee.email}
              </div>
              <button
                className="inline-flex items-center h-6 px-1 my-1 rounded-md text-xs text-white bg-red-600 border border-red-700 leading-none py-0"
                onClick={onDelete}
              >
                <MinusIcon className="mr-1 h-4 w-4 text-white" />
                {f({ id: "actions.delete" })}
              </button>
            </div>
          </div>
        </Link>
      </div>
    </li>
  );
};

export default Item;
